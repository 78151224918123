import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../../components/Accordion';
import { FormattedMessage } from 'react-intl';
import ContactViaFormAccordionContent from '../ContactViaFormAccordionContent';

const ContactViaFormAccordion = ({ language }) => {
  return (
    <Accordion
      title={
        <FormattedMessage
          id='test-result.partner-code.css-support.app-accordion.title'
          defaultMessage='Contact via contact form'
        />
      }
      content={<ContactViaFormAccordionContent language={language} />}
    />
  );
};

ContactViaFormAccordion.propTypes = {
  language: PropTypes.string
};

export default ContactViaFormAccordion;
