import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const CircleLoader = ({ className }) => {
  return (
    <div className={cx('circle-loader', className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

CircleLoader.propTypes = {
  className: PropTypes.string
};

export default CircleLoader;
