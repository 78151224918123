import React from 'react';
import PropTypes from 'prop-types';
import TitleWithCheckbox from '../../../components/TitleWithCheckbox';

const TreatmentMonths = ({ title, checkboxList }) => {
  return (
    <TitleWithCheckbox
      title={title}
      checkboxList={checkboxList}
      readonly={true}
      checked={true}
    />
  );
};

TreatmentMonths.propTypes = {
  title: PropTypes.object,
  checkboxList: PropTypes.array
};

export default TreatmentMonths;
