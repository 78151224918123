const getCallbackFromURL = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let callback = urlParams.get('callback');
  const benefitCode = urlParams.get('benefitCode');
  if (benefitCode) {
    callback = `${callback}&benefitCode=${benefitCode}`;
  }

  return callback;
};

export { getCallbackFromURL };
