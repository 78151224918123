import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormTitle from '../FormWrapper/FormTitle';
import FormText from '../FormWrapper/FormText';
import FormButton from '../FormWrapper/FormButton';
import {
  AUTH_ERROR,
  AUTH_ERROR_CUSTOM_MESSAGE
} from '../../constants/errorCodes';

import Input from '../Input';

import lockIcon from '../../../assets/svg/lock-icon.svg';

import './index.scss';

const ERROR_MESSAGES = {
  [AUTH_ERROR.WRONG_PASSWORD]: 'auth.wrong-password-error',
  [AUTH_ERROR.DEFAULT]: 'auth.default-error',
  [AUTH_ERROR_CUSTOM_MESSAGE.LOGIN_TO_MANY_REQUESTS]:
    'auth.login-to-many-requests-error',
  [AUTH_ERROR_CUSTOM_MESSAGE.WRONG_COMBINATION]: 'auth.wrong-combination-error'
};

const EnterPasswordForm = ({
  password,
  setPassword,
  signInUser,
  email,
  isLoading,
  error,
  setError,
  setUserState,
  onForgotPasswordButtonClick,
  intl
}) => {
  const passwordInputLabel = intl.formatMessage({
    id: 'auth.password-input-label'
  });

  const handleOnChange = value => {
    if (error) {
      setError(null);
    }
    setPassword(value);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    signInUser(email, password);
  };

  const handleBackButtonClick = () => {
    setUserState(null);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <FormTitle
        title='auth.enter-your-password'
        defaultTitle='Enter your password'
      />
      <FormText
        text='auth.enter-your-password-explanation'
        defaultText='Please enter your HealthYourself password for {email}.'
        values={{ email: email }}
      />
      <Input
        placeholder='**********'
        type='password'
        name='current-password'
        autoComplete='current-password'
        label={passwordInputLabel}
        value={password}
        onChange={e => handleOnChange(e.target.value)}
        icon={lockIcon}
        error={ERROR_MESSAGES[error]}
        autoFocus
      />
      <div className='enter-password-form__buttons-wrapper'>
        <FormButton
          className='enter-password-form__back-button'
          text='auth.back'
          defaultText='Back'
          onClick={handleBackButtonClick}
          disabled={isLoading}
          backArrow
        />
        <FormButton
          text='auth.sign-in'
          defaultText='Sign in'
          submit
          isLoading={isLoading}
          disabled={!!error || !password || password.length === 0}
        />
      </div>
      <div className='enter-password-form__text'>
        <FormattedMessage
          id='auth.forgot-password'
          defaultMessage='Forgot password?'
        />
        <button
          className='enter-password-form__button'
          onClick={onForgotPasswordButtonClick}
        >
          <FormattedMessage id='auth.reset-now' defaultMessage='Reset now' />
        </button>
      </div>
    </form>
  );
};

EnterPasswordForm.propTypes = {
  password: PropTypes.string,
  setPassword: PropTypes.func,
  signInUser: PropTypes.func,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  setError: PropTypes.func,
  setUserState: PropTypes.func,
  onForgotPasswordButtonClick: PropTypes.func,
  intl: PropTypes.any
};

export default EnterPasswordForm;
