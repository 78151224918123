import React from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';
import RisksModalCard from '../RisksModalCard';
import Modal from '../Modal';
import Carousel from '../Carousel';

import breakpoints from '../../constants/breakpoints';

import './index.scss';

const SLIDES_TO_SHOW = {
  [breakpoints.name.XSMALL]: 1,
  [breakpoints.name.SMALL]: 1,
  [breakpoints.name.MEDIUM]: 2,
  [breakpoints.name.LARGE]: 3,
  [breakpoints.name.XLARGE]: 4
};

const renderCards = (data, breakpoint) => {
  return data.map(risk => {
    return <RisksModalCard key={risk.key} data={risk} breakpoint={breakpoint} />;
  });
};

const RisksModal = ({ breakpoint, data, onCloseButtonClick, isModalClosing}) => {
  const isExtraSmall = breakpoint === breakpoints.name.XSMALL;
  const isSmall = [breakpoints.name.XSMALL, breakpoints.name.SMALL].includes(breakpoint);

  return (
    <Modal onCloseButtonClick={onCloseButtonClick} isModalClosing={isModalClosing}>
      <div className='risk-modal__content'>
        <Carousel
          centerPadding={isExtraSmall ? 25 : 40}
          breakpoint={breakpoint}
          hasArrows
          inModal
          slidesToShow={SLIDES_TO_SHOW[breakpoint]}
          variableWidth={isSmall}
          notCentered={isSmall}
        >
          {renderCards(data, breakpoint)}
        </Carousel>
      </div>
    </Modal>
  );
};

RisksModal.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.array,
  onCloseButtonClick: PropTypes.func,
  isModalClosing: PropTypes.bool
};

export default withWindow(RisksModal);
