import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withScroll from '../../windowDecorators/withScroll';
import withWindow from '../../windowDecorators/withWindow';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { getUser } from '../../actions/user';

import { Close, Save } from '@material-ui/icons';

import IconButton from '../IconButton';
import Button from '../Button';
import LanguageDropdown from '../LanguageDropdown';
import ProfileButton from '../ProfileButton';

import useIsSticky from '../../hooks/isSticky';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { ReactComponent as LogoGradient } from '../../../assets/svg/logo-gradient-small.svg';
import { ReactComponent as Revert } from '../../../assets/svg/revert.svg';

import { getSiteHref } from '../../utils/helpers';

import './index.scss';

const handleSaveButtonClick = () => {
  console.log('save');
};

const getShouldShowAccountButton = userData => {
  const token = localStorage.getItem('accessToken');

  return token && userData;
};

const Header = ({
  breakpoint,
  scrollPositionY,
  searchParams,
  changeLanguage,
  language,
  flowCountry,
  conversationFlowKey,
  availableLanguages,
  restartConversationFlowKey,
  userData,
  dispatch
}) => {
  const getUserToken = () => {
    return localStorage.getItem('accessToken');
  };

  useEffect(() => {
    const token = getUserToken();
    if (
      (!userData ||
        (userData &&
          Object.keys(userData).length === 0 &&
          Object.getPrototypeOf(userData) === Object.prototype)) &&
      token
    ) {
      dispatch(getUser());
    }
  }, [dispatch, userData]);

  const { source } = searchParams;
  const isScrolled = useIsSticky(scrollPositionY, 0);

  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);
  const isSmall = ['xsmall', 'small'].includes(breakpoint);

  const headerClasses = cx({
    header: true,
    'header--scrolled': isScrolled && isSmall
  });

  const userId = localStorage.getItem('userId');

  let logoButtonHref = getSiteHref(flowCountry);

  const resetButtonHref = `/?conversationFlowKey=${restartConversationFlowKey}&lang=${language}${
    source ? `&source=${window.btoa(source)}` : ''
  }${userId && userId !== 'undefined' ? `&userId=${userId}` : ''}`;

  const showAccountButton = getShouldShowAccountButton(userData);
  const closeButtonUrl = showAccountButton
    ? '/myhealth'
    : source
    ? source
    : getSiteHref(flowCountry);

  const isAllergyCheckRecommendation =
    conversationFlowKey === 'allergyCheckRecommendation';

  return (
    <div className={headerClasses}>
      <div className='header__content'>
        {showAccountButton ? (
          <ProfileButton data={userData} />
        ) : (
          <>
            {isSmall ? (
              <div className='header__icons-wrapper'>
                <IconButton
                  className='header__reset-button'
                  href={resetButtonHref}
                  noNewTab
                >
                  <Revert className='header__revert-icon' />
                </IconButton>
                <IconButton
                  className='header__save_button'
                  onClick={handleSaveButtonClick}
                >
                  <Save className='header__save-icon' />
                </IconButton>
              </div>
            ) : (
              <Button
                href={resetButtonHref}
                className='header__reset-button--large'
                noNewTab
              >
                <Revert className='header__revert-icon' />
                <FormattedMessage
                  id='app.start-over'
                  defaultMessage='Start over'
                />
              </Button>
            )}
          </>
        )}
        {isAllergyCheckRecommendation ? (
          <a className='header__logo-wrapper' href={logoButtonHref}>
            <LogoGradient className='header__logo--gradient' />
          </a>
        ) : (
          <a className='header__logo-wrapper' href={logoButtonHref}>
            <Logo className='header__logo' />
          </a>
        )}

        {isSmallOrMedium ? (
          <div className='header__right-buttons'>
            <LanguageDropdown
              onChange={changeLanguage}
              value={language}
              availableLanguages={availableLanguages}
            />
            <IconButton
              className='header__close-button'
              href={closeButtonUrl}
              noNewTab
            >
              <Close />
            </IconButton>
          </div>
        ) : (
          <Button
            className='header__continue-later-button'
            onClick={handleSaveButtonClick}
          >
            <Save />{' '}
            <FormattedMessage
              id='app.continue-later'
              defaultMessage='Continue later'
            />
          </Button>
        )}
        {!isSmallOrMedium && (
          <LanguageDropdown
            className={source ? 'header__language-dropdown' : ''}
            onChange={changeLanguage}
            value={language}
            availableLanguages={availableLanguages}
          />
        )}
      </div>
      {!isSmallOrMedium && (
        <IconButton
          className='header__close-button'
          href={closeButtonUrl}
          noNewTab
        >
          <Close />
        </IconButton>
      )}
    </div>
  );
};

Header.propTypes = {
  breakpoint: PropTypes.string,
  scrollPositionY: PropTypes.number,
  searchParams: PropTypes.object,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  flowCountry: PropTypes.string,
  conversationFlowKey: PropTypes.string,
  restartConversationFlowKey: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
  // From connect
  userData: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  userData: state.user.get('user'),
  userLoading: state.user.get('loading')
});

export default connect(mapStateToProps)(withScroll(withWindow(Header)));
