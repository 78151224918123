import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';

const JumpToReportButton = ({ cta }) => {
  // Write me a fucntion that will get position of element with id "doctor" and scroll it smoothly to it and add 30px more
  const onClick = () => {
    const element = document.getElementById('doctor');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      return null;
    }
  };

  return (
    <Button variant={cta.variant} size='small' onClick={onClick}>
      {cta.text}
    </Button>
  );
};

JumpToReportButton.propTypes = {
  cta: PropTypes.object
};

export default JumpToReportButton;
