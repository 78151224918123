import React from 'react';
import PropTypes from 'prop-types';
import OrderProductCardWithDescription from '../../../components/OrderProductCardWithDescription';

import './index.scss';

const EmployeeHealthProgramProductCards = ({ productCards, handleModal }) => {
  return (
    <div className='employee-health-program-product-cards'>
      {productCards.map(
        (card, index) =>
          card && (
            <OrderProductCardWithDescription
              key={index}
              productCard={card}
              onClick={() => handleModal(card)}
            />
          )
      )}
    </div>
  );
};

EmployeeHealthProgramProductCards.propTypes = {
  productCards: PropTypes.array,
  handleModal: PropTypes.func
};

export default EmployeeHealthProgramProductCards;
