import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Background from '../../components/Background';
import Button from '../../components/Button';
import useAuthorization from '../../hooks/useAuthorization';

import './index.scss';
import { useEffect } from 'react';

const TestHome = ({ dispatch }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const { signOutUser, currentUser } = useAuthorization(dispatch);

  useEffect(() => {
    const userData = currentUser;
    if (userData) {
      setUser(userData);
    }
  }, [currentUser]);

  useEffect(() => {
    if (user !== undefined) {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <Background>
      <div>
        {isLoading && <div>Loading ...</div>}
        {!isLoading && user && (
          <div className='test-home__content'>
            Good job<Button onClick={signOutUser}>Log out</Button>
          </div>
        )}
        {!isLoading && user === null && (
          <div>
            <div className='test-home__content'>
              You are not logged in{' '}
              <Button onClick={() => history.push('/authorization')}>
                Log in
              </Button>
            </div>
          </div>
        )}
      </div>
    </Background>
  );
};

TestHome.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(TestHome);
