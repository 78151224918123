import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import YearPicker from './YearPicker';
import MonthPicker from './MonthPicker';
import DayPicker from './DayPicker';
import HourPicker from './HourPicker';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const MAIN_SLIDER_NAME = 'slide-content__children';

const DATE_PICKER_TYPE = {
  YEAR: 'year',
  YEAR_MONTH: 'yearMonth',
  YEAR_MONTH_DAY: 'yearMonthDay',
  YEAR_MONTH_DAY_TIME: 'yearMonthDayTime'
};

// value can be 01 that is why we need to check first digit
const getFilledDatePart = value => {
  return value < 10 && value[0] !== '0' ? `0${value}` : value;
};

const getDatePickerType = represent => {
  if (represent.length === 1 && represent.includes('year')) {
    return DATE_PICKER_TYPE.YEAR;
  }

  if (
    represent.length === 2 &&
    ['year', 'month'].every(item => represent.includes(item))
  ) {
    return DATE_PICKER_TYPE.YEAR_MONTH;
  }

  if (
    represent.length === 3 &&
    ['year', 'month', 'day'].every(item => represent.includes(item))
  ) {
    return DATE_PICKER_TYPE.YEAR_MONTH_DAY;
  }

  if (
    represent.length === 4 &&
    ['year', 'month', 'day', 'time'].every(item => represent.includes(item))
  ) {
    return DATE_PICKER_TYPE.YEAR_MONTH_DAY_TIME;
  }
};

const formatDate = dateTime => {
  const date = new Date(dateTime);
  let hours = date.getHours();
  hours = hours ? hours : 24; // the hour '0' should be '12'
  const moths = date.getMonth() + 1;

  return (
    date.getDate() + '.' + moths + '.' + date.getFullYear() + '  ' + hours + 'h'
  );
};

const join = (date, form, separator) => {
  const format = m => {
    let f = new Intl.DateTimeFormat('en', m);
    return f.format(date);
  };
  return form.map(format).join(separator);
};

const format = [{ day: 'numeric' }, { month: 'numeric' }, { year: 'numeric' }];

const DatePicker = ({ data, setCurrentScreenAnswer, answerValue }) => {
  const { represent, defaultValue, max, min } = data;
  const datePickerElement = useRef(null);
  const [chosenYear, setChosenYear] = useState();
  const [datePickerType, setDayPickerType] = useState(
    getDatePickerType(represent)
  );
  const [chosenMonth, setChosenMonth] = useState({});
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [chosenDay, setChosenDay] = useState();
  const [isDayOpen, setIsDayOpen] = useState(false);
  const [chosenHour, setChosenHour] = useState();
  const [isHourOpen, setIsHourOpen] = useState();
  const [showError, setShowError] = useState();

  useEffect(() => {
    const parentClass = datePickerElement.current.parentNode.classList[0];
    // Check if this element is in main slider
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;
    if (isInMainSlide) {
      let defaultDate;
      if (answerValue && answerValue.value) {
        defaultDate = new Date(answerValue.value);
      } else {
        defaultDate = new Date(defaultValue);
      }

      setChosenHour(defaultDate.getHours());
      setChosenYear(defaultDate.getFullYear());

      setChosenMonth({
        name: defaultDate.toLocaleString('en-US', { month: 'short' }),
        value: defaultDate.getMonth() + 1
      });
      setChosenDay(defaultDate.getDate());
      setCurrentScreenAnswer(defaultDate.getDate());
    }
  }, [data, defaultValue, answerValue, setCurrentScreenAnswer]);

  useEffect(() => {
    setDayPickerType(getDatePickerType(represent));
  }, [represent]);

  useEffect(() => {
    const chosenDate = new Date(
      `${chosenMonth.name} ${chosenDay}, ${chosenYear} ${chosenHour}:00:00`
    );
    const maxDate = new Date(max);
    const minDate = new Date(min);
    const parentClass = datePickerElement.current.parentNode.classList[0];
    // Check if this element is in main slider
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;

    if (chosenDate && isInMainSlide) {
      if (
        chosenDate.getTime() <= maxDate.getTime() &&
        minDate.getTime() <= chosenDate.getTime()
      ) {
        if (showError) {
          setShowError(false);
        }
      } else {
        if (!showError) {
          setShowError(true);
        }
      }
    }
  }, [chosenYear, chosenMonth, chosenDay, chosenHour, max, min, showError]);

  useEffect(() => {
    const parentClass = datePickerElement.current.parentNode.classList[0];
    // Check if this element is in main slider
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;

    if (isInMainSlide) {
      let date = '';
      // Year
      if (datePickerType === DATE_PICKER_TYPE.YEAR) {
        if (chosenYear) {
          if (!showError) {
            date = `${chosenYear}-01-01`;
          }
        }
      }
      // Year and month (we are adding 01 at the end as a day)
      if (datePickerType === DATE_PICKER_TYPE.YEAR_MONTH) {
        if (chosenYear && chosenMonth.value) {
          const year = chosenYear;
          const month = getFilledDatePart(chosenMonth.value);
          if (!showError) {
            date = `${year}-${month}-01`;
          }
        }
      }

      // Year, month and day
      if (datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY) {
        if (chosenYear && chosenMonth.value && chosenDay) {
          if (!showError) {
            const year = chosenYear;
            const month = getFilledDatePart(chosenMonth.value);
            const day = getFilledDatePart(chosenDay);
            date = `${year}-${month}-${day}`;
          }
        }
      }

      // Year, month, day, time
      if (datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY_TIME) {
        if (chosenYear && chosenMonth.value && chosenDay && chosenHour) {
          if (!showError) {
            const year = chosenYear;
            // Because new Date expect month - 1
            const month = getFilledDatePart(chosenMonth.value - 1);
            const day = getFilledDatePart(chosenDay);
            const hour = chosenHour;
            const minutes = '0';
            // This returns UTC time
            date = new Date(year, month, day, hour, minutes).toISOString();
          }
        }
      }

      setCurrentScreenAnswer(date);
    }
  }, [
    chosenYear,
    chosenMonth.value,
    setCurrentScreenAnswer,
    represent,
    chosenDay,
    chosenHour,
    datePickerType,
    showError
  ]);

  const getRange = () => {
    let minDate = '';
    let maxDate = '';
    if (represent.includes('time')) {
      minDate = formatDate(min);
      maxDate = formatDate(max);
    } else {
      minDate = join(min, format, '.');
      maxDate = join(max, format, '.');
    }

    return `  ${minDate} - ${maxDate}`;
  };

  const datePickerClasses = cx({
    'date-picker': true,
    'date-picker__year-month': datePickerType === DATE_PICKER_TYPE.YEAR_MONTH,
    'date-picker__year-month--month-open':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH && isMonthOpen,
    'date-picker__year-month-day':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY,
    'date-picker__year-month-day--month-open':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY && isMonthOpen,
    'date-picker__year-month-day--day-open':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY && isDayOpen,
    'date-picker__year-month-day-time':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY_TIME,
    'date-picker__year-month-day-time--day-open':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY_TIME && isDayOpen,
    'date-picker__year-month-day-time--time-open':
      datePickerType === DATE_PICKER_TYPE.YEAR_MONTH_DAY_TIME && isHourOpen
  });

  return (
    <div className={datePickerClasses} ref={datePickerElement}>
      <div className='date-picker__content'>
        <div className='date-picker__inner-content'>
          {represent.includes('year') && (
            <YearPicker chosenYear={chosenYear} setChosenYear={setChosenYear} />
          )}
          {represent.includes('month') && (
            <MonthPicker
              chosenYear={chosenYear}
              chosenMonth={chosenMonth}
              setChosenMonth={setChosenMonth}
              setIsOpen={setIsMonthOpen}
              isOpen={isMonthOpen}
            />
          )}
          {represent.includes('day') && (
            <DayPicker
              chosenYear={chosenYear}
              chosenMonth={chosenMonth}
              chosenDay={chosenDay}
              setChosenDay={setChosenDay}
              setIsOpen={setIsDayOpen}
              isOpen={isDayOpen}
            />
          )}
          {represent.includes('time') && (
            <HourPicker
              chosenDay={chosenDay}
              chosenMonth={chosenMonth}
              chosenYear={chosenYear}
              setChosenHour={setChosenHour}
              chosenHour={chosenHour !== 0 ? chosenHour : 24}
              setIsOpen={setIsHourOpen}
              isOpen={isHourOpen}
            />
          )}
        </div>
        {showError && (
          <span className='date-picker__error'>
            <FormattedMessage
              id='date-picker.choose-in-range'
              defaultMessage='Plase choose in range'
            />
            {getRange()}
          </span>
        )}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  data: PropTypes.object,
  setCurrentScreenAnswer: PropTypes.func,
  answerValue: PropTypes.object
};

export default DatePicker;
