export const AUTH_ERROR = {
  MULTI_FACTOR_REQUIRED: 'auth/multi-factor-auth-required',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  DEFAULT: 'auth/default-error'
  /**
   While creating user we can get next errros
   1. auth/email-already-in-use
   2. auth/invalid-email
   3. auth/operation-not-allowed
   4. auth/weak-password
   We check for all of this on frontend side, so this error should never happen
   https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
  */
};

// Those are errors that firebase is returning
export const AUTH_ERROR_CODE = {
  TOO_MANY_REQUEST: 'auth/too-many-requests',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  INVALID_VERIFICATION_CODE: 'auth/invalid-verification-code'
};

// Those are translations codes that represent our custom message for each firebase error
export const AUTH_ERROR_CUSTOM_MESSAGE = {
  DEFAULT: 'auth.default-error',
  [AUTH_ERROR_CODE.TOO_MANY_REQUEST]: 'auth.too-many-requests-error',
  [AUTH_ERROR_CODE.INVALID_ACTION_CODE]: 'auth.invalid-action-code',
  INVALID_VERIFICATION_CODE: 'auth.invalid-verification-code',
  VERIFICATION_CODE_TOO_SHORT: 'auth.verification-code-too-short',
  NO_OOB_CODE: 'auth.no-oob-code',
  EXPIRED_ACTION_CODE: 'auth.expired-action-code',
  CREATE_PASSWORD: 'auth.create-password-error',
  CREATE_USER: 'auth.create-user-error',
  LOGIN_TO_MANY_REQUESTS: 'auth.login-to-many-requests-error',
  WRONG_COMBINATION: 'auth.wrong-combination-error'
};
