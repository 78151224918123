import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import withWindow from '../../windowDecorators/withWindow';

import Button from '../Button';
import AccordionsWrapper from '../AccordionsWrapper';
import Accordion from '../Accordion';
import markdownFormater from '../../utils/markdownFormater';

import './index.scss';

const MedgateBlock = ({ data, breakpoint, className }) => {
  const isSmall = ['xsmall', 'small'].includes(breakpoint);
  const { title, text, accordionMobile, accordionDesktop } = data;
  const accordions = isSmall ? accordionMobile : accordionDesktop;
  return (
    <div className={cx('medgate', className)}>
      <div className='medgate__title'>{title}</div>
      <Markdown className='medgate__text' rehypePlugins={[rehypeRaw]}>
        {markdownFormater(text)}
      </Markdown>
      <AccordionsWrapper className='medgate__accordion-wrapper'>
        {accordions.map(button => {
          return (
            <Accordion
              key={button.title}
              data={button}
              contentClassName='medgate__accordion-content'
              className='medgate__accordion'
            >
              <Markdown
                className='medgate__accordion-text'
                rehypePlugins={[rehypeRaw]}
              >
                {markdownFormater(button.text)}
              </Markdown>
              {button.buttonLink && (
                <Button
                  className='medgate__button'
                  orange
                  href={button.buttonLink}
                >
                  {button.buttonText}
                </Button>
              )}
            </Accordion>
          );
        })}
      </AccordionsWrapper>
    </div>
  );
};

MedgateBlock.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string,
  className: PropTypes.string
};

export default withWindow(MedgateBlock);
