import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
  ELEVATED = 'elevated',
  TONAL = 'tonal'
}

export type ButtonProps = {
  type?: 'submit' | 'button';
  variant?: ButtonVariants;
  size?: 'small' | 'normal';
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLButtonElement>;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  size = 'normal',
  children,
  onClick,
  disabled,
  innerRef,
  className
}) => {
  const buttonClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  return (
    <button
      className={cx(
        {
          [buttonClassName]: true
        },
        className && { [className]: true }
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
      ref={innerRef}
    >
      {children}
    </button>
  );
};

export default Button;
