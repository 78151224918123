import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CheckIcon from '@material-ui/icons/Check';

import './index.scss';

const Step = ({ data }) => {
  const { titleText, status } = data;
  const classes = cx('step', `step--${status}`);

  return (
    <div className={classes}>
      <span className='step__circle'>
        <CheckIcon className='step__icon' />
      </span>
      <span className='step__title'>{titleText}</span>
    </div>
  );
};

Step.propTypes = {
  data: PropTypes.object
};

export default Step;
