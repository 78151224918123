import React from 'react';
import { Caption } from '../Typography';
import Popup, { PopUpType } from '../Popup';
import styles from './index.module.scss';

interface ShareLinkButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  popUpContent: PopUpType;
}

const ShareLinkButton: React.FC<ShareLinkButtonProps> = ({
  onClick,
  icon,
  text,
  popUpContent
}) => {
  return (
    <button onClick={onClick} className={styles.button}>
      {icon}
      <Caption element='span'>{text}</Caption>
      {popUpContent && <Popup data={popUpContent} />}
    </button>
  );
};

export default ShareLinkButton;
