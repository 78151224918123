import React from 'react';
import Accordion from '../../../../components/Accordion';
import { FormattedMessage } from 'react-intl';
import ContactByPhoneAccordionContent from '../ContactByPhoneAccordionContent';

const ContactByPhoneAccordion = () => {
  return (
    <Accordion
      title={
        <FormattedMessage
          id='test-result.partner-code.css-support.phone-accordion.title'
          defaultMessage='Contact by phone'
        />
      }
      content={<ContactByPhoneAccordionContent />}
    />
  );
};

export default ContactByPhoneAccordion;
