import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Body1, Heading4, Subtitle2 } from '../../../../components/Typography';
import { ReactComponent as ArrowMoreIcon } from '../../../../assets/svg/arrow-more.svg';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const HealthKnowledgeCardContent = ({ eyebrow, text, className }) => {
  return (
    <div
      className={cx(
        { 'health-knowledge-card': true },
        className && { [className]: true }
      )}
    >
      <div className='health-knowledge-card__eyebrow-wrapper'>
        <Subtitle2 className='health-knowledge-card__eyebrow'>
          {eyebrow}
        </Subtitle2>

        {<ArrowMoreIcon />}
      </div>

      <Heading4 className='health-knowledge-card__text'>{text}</Heading4>
      <Body1 className='health-knowledge-card__learn-more'>
        <FormattedMessage
          id='my-health.learn-more'
          defaultMessage='Learn more'
        />
      </Body1>
    </div>
  );
};

HealthKnowledgeCardContent.propTypes = {
  eyebrow: PropTypes.object,
  text: PropTypes.object,
  className: PropTypes.string
};

export default HealthKnowledgeCardContent;
