import React, { useEffect } from 'react';
import useQuery from '../../utils/use-query';
import { useHistory } from 'react-router-dom';

function Auth() {
  const query = useQuery();
  const history = useHistory();
  useEffect(() => {
    localStorage.setItem('token', query.get('token'));
    window.location.href = query.get('callback');
    return () => {};
  }, [history.location.pathname, query]);
  return <div>Loading!</div>;
}

export default Auth;
