import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Overline } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../assets/svg/home-icon.svg';
import { ReactComponent as FlaskIcon } from '../../../assets/svg/flask-icon.svg';
import { ReactComponent as BagIcon } from '../../../assets/svg/bag-icon.svg';
import { ReactComponent as HeartIcon } from '../../../assets/svg/heart-icon.svg';
import { LAB_TESTS_URL } from '../MyHealthHeaderNavbar';

import './index.scss';

const NavbarBottom = ({ data }) => {
  const [numberOfResultsNotViewed, setNumberOfResultsNotViewed] = useState(0);

  useEffect(() => {
    if (data && data.tests) {
      const getResultsViewedStatus = test => {
        if (test.resultsViewed === null || test.resultsViewed === undefined) {
          return true;
        }
        return test.resultsViewed;
      };
      const countResultsNotViewed = () => {
        return data.tests.filter(test => getResultsViewedStatus(test) === false)
          .length;
      };
      setNumberOfResultsNotViewed(countResultsNotViewed());
    } else {
      setNumberOfResultsNotViewed(0);
    }
  }, [data]);

  return (
    <div className='navbar-bottom'>
      <NavLink
        exact={true}
        to='/myhealth'
        className='navbar-bottom__link'
        activeClassName='navbar-bottom__active-link'
      >
        <HomeIcon />
        <Overline element='p'>
          <FormattedMessage id='dashboard.home' defaultMessage='Home' />
        </Overline>
      </NavLink>

      <NavLink
        exact={true}
        to='/myhealth/products'
        className='navbar-bottom__link'
        activeClassName='navbar-bottom__active-link'
      >
        <FlaskIcon />
        <Overline element='p'>
          <FormattedMessage
            id='dashboard.my-products'
            defaultMessage='My products'
          />
        </Overline>

        {numberOfResultsNotViewed > 0 && (
          <div className='navbar-bottom__bubble'>
            <Overline className='navbar-bottom__number-of-unviewed-results'>
              {numberOfResultsNotViewed}
            </Overline>
          </div>
        )}
      </NavLink>

      {data && data.employeeBenefits && (
        <NavLink
          exact={true}
          to='/myhealth/employee-health'
          className='navbar-bottom__link'
          activeClassName='navbar-bottom__active-link'
        >
          <HeartIcon className='navbar-bottom__heart-icon' />
          <Overline element='p'>
            <FormattedMessage
              id='dashboard.employee-health'
              defaultMessage='Employee Health'
            />
          </Overline>
        </NavLink>
      )}

      <a
        href={LAB_TESTS_URL}
        className='navbar-bottom__link'
        target='_blank'
        rel='noreferrer'
      >
        <BagIcon />
        <Overline element='p'>
          <FormattedMessage id='dashboard.shop' defaultMessage='Shop' />
        </Overline>
      </a>
    </div>
  );
};

NavbarBottom.propTypes = {
  data: PropTypes.object
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data')
});

export default connect(mapStateToProps)(NavbarBottom);
