import React from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DoctorIcon } from '../../../assets/svg/doctor-icon.svg';

import './index.scss';

const DoctorsReport = ({
  breakpoint,
  data,
  doctorsName,
  userData,
  language
}) => {
  const { firstName, genderPassport, biologicalGender } = userData;

  const getGreetingsString = () => {
    let greetingsString = 'test-result.dear-neutral';
    if (genderPassport === 'male' || biologicalGender === 'male') {
      greetingsString = 'test-result.dear-male';
    } else if (genderPassport === 'female' || biologicalGender === 'female') {
      greetingsString = 'test-result.dear-female';
    }

    return greetingsString;
  };

  // We are not using FormattedMessage here because 'react-read-more-read-less' is
  // expecting strings for readMore and readLess. FormattedMessage returns object.
  // We have translations in de.json and en.json files
  const readMore = language === 'de' ? 'Mehr anzeigen' : 'Read more';
  const readLess = language === 'de' ? 'Weniger anzeigen' : 'Read less';

  return (
    <div className='doctors-report'>
      <DoctorIcon className='doctor-report__icon' />
      <div className='doctors-report__title'>
        <FormattedMessage
          id='test-result.analysis-from-doctor'
          defaultMessage='Lab report analysis from doctor'
        />
      </div>
      <div className='doctors-report__text'>
        <FormattedMessage id={getGreetingsString()} defaultMessage='Dear ' />
        {firstName},
      </div>
      <ReactReadMoreReadLess
        readMoreClassName='doctors-report__read-more'
        readLessClassName='doctors-report__read-less'
        charLimit={breakpoint === 'small' ? 150 : 300}
        readMoreText={readMore}
        readLessText={readLess}
      >
        {data}
      </ReactReadMoreReadLess>
      {doctorsName && (
        <div className='doctors-report__bottom-text'>{doctorsName}</div>
      )}
    </div>
  );
};

DoctorsReport.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.string,
  userData: PropTypes.object,
  language: PropTypes.string,
  doctorsName: PropTypes.string
};

export default withWindow(DoctorsReport);
