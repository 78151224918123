import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const useCreateBlob = (data, report, resetData) => {
  const [downloadError, setDownloadError] = useState('');

  useEffect(() => {
    try {
      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', report);
        document.body.appendChild(link);
        link.click();
        link.parentNode && link.parentNode.removeChild(link);
        resetData();
      }
    } catch (error) {
      setDownloadError(
        <FormattedMessage
          id='test-result.try-again-later'
          defaultMessage='Something went wrong, please try again later'
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return downloadError;
};

export default useCreateBlob;