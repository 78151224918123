import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSwiperSlide } from 'swiper/react';
import withWindow from '../../../windowDecorators/withWindow';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Loader from '../../CircleLoader';

import './index.scss';

const Slide = ({ data, breakpoint, index }) => {
  const slideRef = React.useRef(null);
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  const swiperSlide = useSwiperSlide();
  const { title, text, image, gif, videoMobile } = data;

  const classes = cx({
    slide: true,
    'slide--active': swiperSlide.isActive,
    'slide--next': swiperSlide.isNext,
    'slide--prev': swiperSlide.isPrev
  });

  const loaderVisible = swiperSlide.isActive && !videoLoaded;

  const wrapperClasses = cx({
    slide__wrapper: true,
    'slide__wrapper--loading': loaderVisible
  });

  const isSmall = ['xsmall', 'small'].includes(breakpoint);
  const videoUrl = isSmall ? videoMobile : gif;

  const numberVisible =
    !swiperSlide.isActive || (swiperSlide.isActive && videoLoaded);

  return (
    <div className={wrapperClasses}>
      {numberVisible && <span className='slide__index'>{index}</span>}
      {loaderVisible && <Loader className='slide__loader' white />}
      <div className={classes}>
        <div className='slide__image'>
          {!swiperSlide.isActive || (swiperSlide.isActive && !videoLoaded) ? (
            <img src={image} alt={title} />
          ) : null}

          {/* Trying to preload gifs */}
          {(swiperSlide.isNext || swiperSlide.isPrev) && (
            <img
              ref={slideRef}
              src={videoUrl}
              alt={title}
              className='slide__video--hidden'
            />
          )}

          {swiperSlide.isActive && (
            <img
              ref={slideRef}
              src={videoUrl}
              alt={title}
              onLoad={() => {
                setVideoLoaded(true);
              }}
              className={cx({
                slide__video: true,
                'slide__video--visible': videoLoaded
              })}
            />
          )}
        </div>
        <div className='slide__content'>
          <span className='slide__title'>
            <b>{title}</b>
          </span>
          <span className='slide__text'>
            <Markdown rehypePlugins={[rehypeRaw]}>{text}</Markdown>
          </span>
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string,
  index: PropTypes.number
};

export default withWindow(Slide);
