import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { ReactComponent as User } from '../../../assets/svg/user.svg';
import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import './index.scss';
import useAuthorization from '../../hooks/useAuthorization';

const ProfileButton = ({ data, dispatch }) => {
  const { signOutUser } = useAuthorization(dispatch);
  const [isOpen, setIsOpen] = useState(false);
  let name = 'Jane Doe';
  if (data) {
    const { firstName, lastName, email } = data;
    if (email) {
      name = email;
    }
    if (firstName && lastName && firstName !== '' && lastName !== '') {
      name = `${firstName} ${lastName}`;
    }
  }

  const logout = () => {
    // Old
    // localStorage.removeItem('token');
    // window.location.href = `${process.env.REACT_APP_LOGIN_URL}${window.location.href}`;
    // New
    signOutUser();
  };

  return (
    <div className='profile-button'>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <button
          className='profile-button__button'
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className='profile-button__content'>
            <span className='profile-button__button-icon'>
              <User />
            </span>
            <span className='profile-button__text-wrapper'>
              <span className='profile-button__text'>
                <FormattedMessage id='app.hello' defaultMessage='Hello' />
              </span>
              <span
                className={cx('profile-button__name', {
                  'profile-button__name--blurred': !data
                })}
              >
                {name}
              </span>
            </span>
            <span className='profile-button__arrow' />
          </span>
        </button>
        {isOpen && (
          <div className='profile-buttom__container'>
            {/* <button className='profile-buttom__container-button'>
              <FormattedMessage id='app.account' defaultMessage='Account' />
            </button>  */}
            <span className='profile-button__name-mobile'>{name}</span>
            <button
              className='profile-buttom__container-button'
              onClick={logout}
            >
              <FormattedMessage id='app.logout' defaultMessage='Logout' />
            </button>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

ProfileButton.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default connect()(ProfileButton);
