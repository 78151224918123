import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Markdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';
import withWindow from '../../windowDecorators/withWindow';
import Bowser from 'bowser';

import { resetAnswerError } from '../../actions/data';

import './index.scss';

const MAIN_SLIDER_NAME = 'slide-content__children';

const handleOnChange = (
  setCurrentScreenAnswer,
  setValue,
  dispatch,
  error,
  autocapitalize
) => event => {
  if (error) {
    dispatch(resetAnswerError());
  }
  let value = '';
  if (autocapitalize) {
    value = event.target.value.toUpperCase();
  } else {
    value = event.target.value;
  }
  setValue(value);
  setCurrentScreenAnswer(value);
};

const getErrorValue = error => {
  let errorValue = (
    <FormattedMessage
      id='error.something-went-wrong'
      defaultMessage='Something went wrong. Please try again.'
    />
  );

  if (error.response && error.response.validationError) {
    errorValue = error.response.validationError.text;
  } else {
    errorValue = error.message;
  }

  return errorValue;
};

const TextQuestion = ({
  data,
  setCurrentScreenAnswer,
  error,
  dispatch,
  multiline,
  autocapitalize,
  switchboardTestCode,
  isSwitchboardScreen
}) => {
  const contentElement = useRef(null);
  const inputRef = useRef(null);
  const questionElement = useRef(null);
  const [value, setValue] = useState('');
  const [focusTimeout, setFocusTimeout] = useState(null);
  const [blurTimeout, setBlurTimeout] = useState(null);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isIOSSafari =
    browser.getBrowserName() === 'Safari' && browser.getOSName() === 'iOS';
  const isAndroidChrome =
    browser.getBrowserName() === 'Chrome' && browser.getOSName() === 'Android';

  useEffect(() => {
    const parentClass = questionElement.current.parentNode.classList[0];
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;

    if (
      data.question.answerValue &&
      data.question.answerValue.value &&
      isInMainSlide
    ) {
      setValue(data.question.answerValue.value);
      setCurrentScreenAnswer(data.question.answerValue.value);
    } else {
      setValue('');
      setCurrentScreenAnswer('');
    }

    if (
      switchboardTestCode &&
      switchboardTestCode.length > 0 &&
      isSwitchboardScreen
    ) {
      setValue(switchboardTestCode);
      setCurrentScreenAnswer(switchboardTestCode);
    }

    if (contentElement) {
      const links = contentElement.current.getElementsByTagName('a');

      if (links.length > 0) {
        for (var i = 0; i < links.length; i++) {
          links[i].setAttribute('target', '_blank');
          links[i].setAttribute('rel', 'noopener noreferrer');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // we need to wait because autofocus stops sliding animation
  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  }, []);

  useEffect(() => {
    const removeFocus = () => {
      if (document.activeElement === inputRef.current) {
        document.activeElement.blur();
      }
    };

    if (isIOSSafari) {
      window.addEventListener('touchstart', removeFocus);
    }

    return () => {
      window.removeEventListener('touchstart', removeFocus);
    };
  });

  const handleBlur = () => {
    clearTimeout(blurTimeout);
    inputRef.current.disabled = true;

    const timeout = setTimeout(() => {
      inputRef.current.disabled = false;
    }, 500);

    setBlurTimeout(timeout);
  };

  const handleOnFocus = () => {
    clearTimeout(focusTimeout);

    const timeout = setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);

    setFocusTimeout(timeout);
  };

  const { descriptionText, imageUrl, answerPlaceholder } = data.question;

  const inputClasses = cx({
    'text-question__input': true,
    'text-question__input--error': error
  });

  const textareaClasses = cx({
    'text-question__textarea': true,
    'text-question__textarea--error': error
  });

  const textWrapperClasses = cx({
    'text-question__text-wrapper': true,
    'text-question__text-wrapper--no-image': !imageUrl
  });

  const errorValue = error && getErrorValue(error);

  return (
    <div className='text-question' ref={questionElement}>
      <div ref={contentElement} className='text-question__content'>
        {imageUrl && (
          <div className='text-question__image-wrapper'>
            <img className='text-question__image' src={imageUrl} alt='alt' />
          </div>
        )}
        <div className={textWrapperClasses}>
          {descriptionText && (
            <div className='text-question__description-wrapper'>
              <Markdown className='text-question__text'>
                {descriptionText}
              </Markdown>
            </div>
          )}
          <div className='text-question__input-wrapper'>
            {multiline ? (
              <textarea
                ref={inputRef}
                placeholder={answerPlaceholder}
                className={textareaClasses}
                value={value}
                onChange={handleOnChange(
                  setCurrentScreenAnswer,
                  setValue,
                  dispatch,
                  error,
                  autocapitalize
                )}
                onBlur={isIOSSafari ? handleBlur : null}
                onClick={isAndroidChrome ? handleOnFocus : null}
              />
            ) : (
              <input
                ref={inputRef}
                placeholder={answerPlaceholder}
                className={inputClasses}
                type='text'
                value={value}
                onChange={handleOnChange(
                  setCurrentScreenAnswer,
                  setValue,
                  dispatch,
                  error,
                  autocapitalize
                )}
                onBlur={isIOSSafari ? handleBlur : null}
                onClick={isAndroidChrome ? handleOnFocus : null}
              />
            )}
            {errorValue && (
              <span className='text-question__input-error'>{errorValue}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TextQuestion.propTypes = {
  data: PropTypes.object,
  setCurrentScreenAnswer: PropTypes.func,
  error: PropTypes.object,
  dispatch: PropTypes.func,
  multiline: PropTypes.bool,
  autocapitalize: PropTypes.bool,
  switchboardTestCode: PropTypes.string,
  isSwitchboardScreen: PropTypes.bool
};

const mapStateToProps = state => ({
  error: state.data.get('error')
});

export default connect(mapStateToProps)(withWindow(TextQuestion));
