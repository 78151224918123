import React from 'react';
import Divider from '../../../components/Divider';
import HealthKnowledgeCard from './HealthKnowledgeCard';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const MyHealthHealthKnowledge = () => {
  return (
    <div className='my-health-health-knowledge'>
      <div className='my-health-health-knowledge__content-wrapper'>
        <Divider
          className='my-health-health-knowledge__divider'
          lineClassName='my-health-health-knowledge__divider-line'
          title={
            <FormattedMessage
              id='my-health.health-knowledge'
              defaultMessage='Health knowledge'
            />
          }
        />
        <a
          className='my-health-health-knowledge__content'
          href='https://yourself.health/de/pages/hay-fever-management-guide'
          target='_blank'
          rel='noreferrer'
        >
          <HealthKnowledgeCard
            eyebrow={
              <FormattedMessage
                id='my-health.allergies'
                defaultMessage='Allergies'
              />
            }
            text={
              <FormattedMessage
                id='my-health.fight-allergies'
                defaultMessage='Hay fever guide: Fight allergies in 3 steps.'
              />
            }
            className='my-health-health-knowledge__card-background'
          />
        </a>
      </div>
    </div>
  );
};

export default MyHealthHealthKnowledge;
