import React, { useRef } from 'react';
import cx from 'classnames';
import { ReactComponent as EclipseArrowLeft } from '../../assets/storybook-placeholders/svg/eclipse-with-arrow-left.svg';
import { ReactComponent as EclipseArrowRight } from '../../assets/storybook-placeholders/svg/eclipse-with-arrow-right.svg';
import CardsCarouselButton from '../CardsCarousel/CardsCarouselButton';
import MyHealthCard from './MyHealthCard';
import { MyHealthCardProps } from './MyHealthCard';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './index.module.scss';

export interface MyHealthCardsCarouselProps {
  cards: MyHealthCardProps[];
  handleInfo: (info: string) => void;
  className: string;
}

const MyHealthCardsCarousel: React.FC<MyHealthCardsCarouselProps> = ({
  cards,
  handleInfo,
  className
}) => {
  const numberOfCards = cards.length;
  const cardsPerView = numberOfCards > 1 ? 1.1 : 1;

  const swiperRef = React.useRef<SwiperClass | null>(null);
  const leftArrowButtonRef = useRef<HTMLButtonElement>(null);
  const rightArrowButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Swiper
      cssMode={true}
      className={cx(
        {
          [styles.carousel]: true
        },
        className && { [className]: true }
      )}
      slidesPerView={cardsPerView}
      spaceBetween={-12}
      breakpoints={{
        600: {
          slidesPerView: 1.8,
          spaceBetween: -16
        },
        900: {
          slidesPerView: 2,
          spaceBetween: 24
        }
      }}
      onSwiper={(swiper: SwiperClass) => {
        swiperRef.current = swiper;
      }}
      onSlideChange={() => {
        if (swiperRef.current) {
          const activeIndex = swiperRef.current.realIndex;

          if (leftArrowButtonRef.current) {
            leftArrowButtonRef.current.style.opacity =
              activeIndex === 0 ? '0' : '1';
          }

          if (rightArrowButtonRef.current) {
            rightArrowButtonRef.current.style.opacity =
              activeIndex === numberOfCards - 2 ? '0' : '1';
          }
        }
      }}
    >
      {cards.map((card, index) => (
        <SwiperSlide
          key={index}
          className={cx({
            [styles.carouselSlide]: true,
            [styles.onlyOneCard]: numberOfCards === 1
          })}
        >
          <MyHealthCard
            eyebrow={card.eyebrow}
            title={card.title}
            description={card.description}
            href={card.href}
            icon={card.icon}
            background={card.background}
            type={card.type}
            infoText={card.infoText}
            handleInfo={() => handleInfo(card.infoText)}
          />
        </SwiperSlide>
      ))}
      {numberOfCards > 2 && (
        <CardsCarouselButton
          icon={<EclipseArrowLeft />}
          slideDirection='prev'
          className={styles.arrowLeft}
          buttonRef={leftArrowButtonRef}
        />
      )}
      {numberOfCards > 2 && (
        <CardsCarouselButton
          icon={<EclipseArrowRight />}
          slideDirection='next'
          className={styles.arrowRight}
          buttonRef={rightArrowButtonRef}
        />
      )}
    </Swiper>
  );
};

export default MyHealthCardsCarousel;
