import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { connect } from 'react-redux';
import { setSliderViewed } from '../../actions/app';

import Slide from './Slide';
import SliderNextButton from './SliderNextButton';
import SliderPrevButton from './SliderPrevButton';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './index.scss';

const Slider = ({ breakpoint, slides, sliderViewed, dispatch }) => {
  const isSmall = ['xsmall', 'small'].includes(breakpoint);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const lastSlideViewed = activeSlideIndex + 1 === slides.length;

  if (lastSlideViewed && !sliderViewed) {
    dispatch(setSliderViewed());
  }

  return (
    <div>
      {slides && (
        <Swiper
          className='slider'
          wrapperClass={activeSlideIndex === 0 ? 'slider__wrapper' : ''}
          modules={[Navigation, Pagination, Keyboard]}
          spaceBetween={15}
          slidesPerView={isSmall ? 'auto' : 3}
          keyboard
          centeredSlides={!isSmall}
          pagination={{ clickable: true }}
          onSlideChange={swiper => setActiveSlideIndex(swiper.activeIndex)}
        >
          {slides.map((slide, index) => {
            return (
              <SwiperSlide
                key={index.toString()}
                virtualIndex={index}
                className='slider__slide'
              >
                <Slide data={slide} index={index + 1} />
              </SwiperSlide>
            );
          })}
          <SliderNextButton disabled={activeSlideIndex === slides.length + 1} />
          <SliderPrevButton disabled={activeSlideIndex === 0} />
        </Swiper>
      )}
    </div>
  );
};

Slider.propTypes = {
  breakpoint: PropTypes.string,
  slides: PropTypes.array,
  sliderViewed: PropTypes.bool,
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  sliderViewed: state.app.get('sliderViewed')
});

export default connect(mapStateToProps)(withWindow(Slider));
