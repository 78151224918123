import { combineReducers } from 'redux';
import app from './app';
import data from './data';
import dashboard from './dashboard';
import user from './user';
import testResult from './testResult';
import videoUpload from './videoUpload';
import auth from './auth';
import downloadReport from './downloadReport';

export default combineReducers({
  app,
  data,
  dashboard,
  user,
  testResult,
  videoUpload,
  auth,
  downloadReport
});
