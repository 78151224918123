import React from 'react';
import PropTypes from 'prop-types';
import VideoRecorder from 'react-video-recorder';
import Actions from './Actions';

import './index.scss';

const Recorder = ({ setVideo, setCurrentScreenAnswer }) => {
  return (
    <div>
      <div className='recorder__video-wrapper'>
        <VideoRecorder
          onRecordingComplete={videoBlob => {
            setVideo(videoBlob);
            // Setting to empty so we can upload new video, there is a check in actionBar
            setCurrentScreenAnswer('');
          }}
          renderDisconnectedView={() => <></>}
          renderActions={({
            t,
            isVideoInputSupported,
            isInlineRecordingSupported,
            thereWasAnError,
            isRecording,
            isCameraOn,
            streamIsReady,
            isConnecting,
            isRunningCountdown,
            isReplayingVideo,
            countdownTime,
            timeLimit,
            showReplayControls,
            replayVideoAutoplayAndLoopOff,
            useVideoInput,

            onTurnOnCamera,
            onTurnOffCamera,
            onOpenVideoInput,
            onStartRecording,
            onStopRecording,
            onPauseRecording,
            onResumeRecording,
            onStopReplaying,
            onConfirm
          }) => (
            <Actions
              t={t}
              isVideoInputSupported={isVideoInputSupported}
              isInlineRecordingSupported={isInlineRecordingSupported}
              thereWasAnError={thereWasAnError}
              isRecording={isRecording}
              isCameraOn={isCameraOn}
              streamIsReady={streamIsReady}
              isConnecting={isConnecting}
              isRunningCountdown={isRunningCountdown}
              isReplayingVideo={isReplayingVideo}
              countdownTime={countdownTime}
              timeLimit={timeLimit}
              showReplayControls={showReplayControls}
              replayVideoAutoplayAndLoopOff={replayVideoAutoplayAndLoopOff}
              useVideoInput={useVideoInput}
              onTurnOnCamera={onTurnOnCamera}
              onTurnOffCamera={onTurnOffCamera}
              onOpenVideoInput={onOpenVideoInput}
              onStartRecording={onStartRecording}
              onStopRecording={onStopRecording}
              onPauseRecording={onPauseRecording}
              onResumeRecording={onResumeRecording}
              onStopReplaying={onStopReplaying}
              onConfirm={onConfirm}
              setVideo={setVideo}
            />
          )}
        />
      </div>
    </div>
  );
};

Recorder.propTypes = {
  setVideo: PropTypes.func,
  setCurrentScreenAnswer: PropTypes.func
};

export default Recorder;
