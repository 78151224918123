import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const Checkbox = ({
  value,
  label,
  onChange,
  checked,
  disabled,
  className,
  isRadioButton,
  isOrange
}) => {
  const classes = cx('checkbox', className);
  const labelClasses = cx('checkbox__label', {
    'checkbox__label--disabled': disabled
  });

  const inputClasses = cx('checkbox__input', {
    'checkbox__input--radio': isRadioButton,
    'checkbox__input--orange': isOrange
  });

  const checkmarkClasses = cx('checkbox__checkmark', {
    'checkbox__checkmark--radio': isRadioButton
  });

  return (
    <div key={value} className={classes}>
      <label className={labelClasses}>
        <input
          type='checkbox'
          name={value}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={inputClasses}
        />
        <span className={checkmarkClasses} />
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isRadioButton: PropTypes.bool,
  isOrange: PropTypes.bool
};

export default Checkbox;
