import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Background from '../../components/Background';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import CircleLoader from '../../components/CircleLoader';
import useAuthorization from '../../hooks/useAuthorization';

import { ReactComponent as WarningSign } from '../../../assets/svg/warning-sign.svg';

import './index.scss';

const REDIRECT_TIMEOUT = 10000;

const Action = ({ dispatch }) => {
  const { verifyEmail, error, getNewPassword } = useAuthorization(dispatch);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oobCode = urlParams.get('oobCode');
    const mode = urlParams.get('mode');
    const continueUrl = urlParams.get('continueUrl');

    if (mode === 'verifyEmail') {
      verifyEmail(oobCode, continueUrl);
    } else if (mode === 'resetPassword') {
      getNewPassword(oobCode, continueUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.href = '/authorization';
      }, REDIRECT_TIMEOUT);
    }
  }, [error]);

  return (
    <Background className='action'>
      <div className='action__content'>
        <FormWrapper contentClassName='action__inner-content'>
          {!error && <CircleLoader />}
          {error && (
            <div className='action__error'>
              <WarningSign className='action__icon' />
              <span className='action__error-message action__error-message--black'>
                <FormattedMessage
                  id={error}
                  defaultMessage="We're having a technical issue. Please try again later."
                />
              </span>
              <span className='action__error-message action__error-message--bold'>
                <FormattedMessage
                  id='auth.redirected-to-login'
                  defaultMessage='In a few seconds you will be redirected to log in page ...'
                />
              </span>
              <Button
                className='action__button '
                orange
                href='/authorization'
                noNewTab
              >
                <FormattedMessage
                  id='auth.go-to-login-page'
                  defaultMessage='Go to login page'
                />
              </Button>
            </div>
          )}
        </FormWrapper>
      </div>
    </Background>
  );
};

Action.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(Action);
