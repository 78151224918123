import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';

import Modal from '../Modal';
import HayfeverModalProduct from '../HayfeverModalProduct';

import './index.scss';

const renderProducts = (products, flowCountry) => {
  const filteredProducts = products.filter(
    product => product.type !== 'doctorCall'
  );
  return filteredProducts.map(product => {
    return (
      <HayfeverModalProduct
        key={product.name}
        data={product}
        flowCountry={flowCountry}
      />
    );
  });
};

const HayfeverCheckoutModal = ({
  recommendedModalData,
  onCloseButtonClick,
  selectedTest,
  isModalClosing,
  flowCountry
}) => {
  const selectedTestData = recommendedModalData.find(
    item => item.key === selectedTest
  );
  const doctorCallProduct = selectedTestData.products.find(
    product => product.type === 'doctorCall'
  );
  return (
    <Modal
      className='recommendation-modal'
      onCloseButtonClick={onCloseButtonClick}
      closeButtonRight
      isModalClosing={isModalClosing}
    >
      <div className='recommendation-modal__content'>
        <div className='recommendation-modal__header'>
          <span className='recommendation-modal__title'>
            {selectedTestData.modalTitle}
          </span>
          <span className='recommendation-modal__header-text'>
            <FormattedMessage
              id='recommend.package-included'
              defaultMessage='This package includes'
            />
            :
          </span>
        </div>
        {renderProducts(selectedTestData.products, flowCountry)}
      </div>
      {selectedTestData.extensionText && (
        <div className='recommendation-modal__text-wrapper'>
          <Markdown>{selectedTestData.extensionText}</Markdown>
        </div>
      )}
      {doctorCallProduct && (
        <div className='recommendation-modal__text-wrapper'>
          <Markdown>{doctorCallProduct.modalText}</Markdown>
        </div>
      )}
    </Modal>
  );
};

HayfeverCheckoutModal.propTypes = {
  recommendedModalData: PropTypes.array,
  onCloseButtonClick: PropTypes.func,
  selectedTest: PropTypes.string,
  isModalClosing: PropTypes.bool,
  flowCountry: PropTypes.string
};

export default HayfeverCheckoutModal;
