import { Map } from 'immutable';

import {
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  UPDATE_DASHBOARD_DATA_START,
  UPDATE_DASHBOARD_DATA_SUCCESS,
  UPDATE_DASHBOARD_DATA_ERROR,
  CREATE_TEST_ORDER_START,
  CREATE_TEST_ORDER_SUCCESS,
  CREATE_TEST_ORDER_ERROR,
  RESET_TEST_ORDER_DATA
} from '../actions/dashboard';

const initialState = Map({
  loading: false,
  error: null,
  data: null,
  orderData: false,
  orderLoading: false,
  orderError: null
});

const actionsMap = {
  [GET_DASHBOARD_DATA_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [GET_DASHBOARD_DATA_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [GET_DASHBOARD_DATA_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CREATE_TEST_ORDER_START]: state => {
    return state.merge(
      Map({
        orderLoading: true,
        orderError: null,
        orderData: false
      })
    );
  },
  [CREATE_TEST_ORDER_SUCCESS]: (state, action) => {
    const currentData = state.get('data');
    const { employeeBenefits } = currentData;
    const newData = {
      ...currentData,
      employeeBenefits: {
        ...employeeBenefits,
        benefitTests: action.data
      }
    };
    return state.merge(
      Map({
        orderLoading: false,
        orderError: null,
        data: newData,
        orderData: true
      })
    );
  },
  [CREATE_TEST_ORDER_ERROR]: (state, action) => {
    return state.merge(
      Map({
        orderLoading: false,
        orderError: action.error,
        orderData: false
      })
    );
  },
  [RESET_TEST_ORDER_DATA]: state => {
    return state.merge(
      Map({
        orderLoading: false,
        orderError: null,
        orderData: false
      })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
