import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormTitle from '../FormWrapper/FormTitle';
import FormButton from '../FormWrapper/FormButton';
import FormTextWithChildren from '../FormWrapper/FormTextWithChildren';
import { AUTH_ERROR_CUSTOM_MESSAGE } from '../../constants/errorCodes';

import Input from '../Input';

import lockIcon from '../../../assets/svg/lock-icon.svg';

import './index.scss';

const checkIfPasswordIsValid = password => {
  return /((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*\W)|(?=.*[A-Z])(?=.*\d)(?=.*\W)|(?=.*[A-Z])(?=.*[a-z])(?=.*\W)).{13,}/.test(
    password
  );
};

const ResetPasswordForm = ({ resetPassword, isLoading, hookError }) => {
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(null);
  const [oobCode, setOobCode] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oobCode = urlParams.get('oobCode');
    setOobCode(oobCode);
  }, []);

  useEffect(() => {
    setFormError(hookError);
  }, [hookError]);

  const handleOnChange = value => {
    if (formError) {
      setFormError(null);
    }
    setPassword(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isPasswordValid = checkIfPasswordIsValid(password);

    if (isPasswordValid && oobCode) {
      resetPassword(oobCode, password);
    } else {
      if (!isPasswordValid) {
        setFormError(AUTH_ERROR_CUSTOM_MESSAGE.CREATE_PASSWORD);
      }
      if (!oobCode) {
        setFormError(AUTH_ERROR_CUSTOM_MESSAGE.NO_OOB_CODE);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle
        title='auth.create-new-password'
        defaultTitle='Create new password'
      />
      <FormTextWithChildren>
        <FormattedMessage
          id='auth.create-password-explanation'
          defaultMessage='Choose a password with 13 or more characters. Include characters from at least 3 of the following categories:'
        />
        <ul>
          <li>
            <FormattedMessage
              id='auth.password-category-one'
              defaultMessage='Lower case (e.g. l, d, v)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-two'
              defaultMessage='Uppercase (e.g. L, D, V)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-three'
              defaultMessage='Special characters (for example: !, @, #, %, +, etc.)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-four'
              defaultMessage='Numbers (e.g. 7, 4, 9)'
            />
          </li>
        </ul>
      </FormTextWithChildren>
      <Input
        type='password'
        name='new-password'
        autoComplete='new-password'
        label='Password'
        placeholder='Choose a password'
        value={password}
        onChange={e => handleOnChange(e.target.value)}
        icon={lockIcon}
        error={formError}
        autoFocus
      />
      <div className='reset-password-form__buttons-wrapper'>
        <FormButton
          text='auth.set-new-password'
          defaultText='Set new password'
          submit
          isLoading={isLoading}
          disabled={!!formError}
        />
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  password: PropTypes.string,
  setPassword: PropTypes.func,
  resetPassword: PropTypes.func,
  isLoading: PropTypes.bool,
  hookError: PropTypes.string,
  setUserState: PropTypes.func
};

export default ResetPasswordForm;
