import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../components/Divider';
import { FormattedMessage } from 'react-intl';
import TreatmentMonths from '../TreatmentMonths';
import { Subtitle2, Body2 } from '../../../components/Typography';

import './index.scss';

const RecommendedMedication = ({ recommendedMedication }) => {
  return (
    <div className='recommended-medication'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.recommended-medication'
            defaultMessage='Recommended medication'
          />
        }
      />
      {recommendedMedication.map(medication => (
        <div
          key={medication.name}
          className='recommended-medication__content-wrapper'
        >
          <div className='recommended-medication__text-wrapper'>
            <Subtitle2 className='recommended-medication__text'>
              {medication.name}
            </Subtitle2>
            <Body2>{medication.description}</Body2>
          </div>
          <TreatmentMonths
            title={
              <FormattedMessage
                id='test-result.treatment.recommended-months'
                defaultMessage='Recommended months to start treatment'
              />
            }
            checkboxList={medication.recommendedMonths}
          />
        </div>
      ))}
    </div>
  );
};

RecommendedMedication.propTypes = {
  recommendedMedication: PropTypes.array
};

export default RecommendedMedication;
