import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Price from '../Price';

import './index.scss';

const renderIllnesses = (illnesses, flowCountry) => {
  return (
    <ul className='recommendation-modal-product__list'>
      {illnesses.map(illness => {
        return (
          <li key={illness.key} className='recommendation-modal-product__list-item'>
            <span className='recommendation-modal-product__illness-title'>{illness.titleText[flowCountry]}</span>
            <span
              className={cx(
                'recommendation-modal-product__illness-risk',
                `recommendation-modal-product__illness-risk--${illness.riskLevel}`
              )}
            >
              {illness.riskLevelText}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

const RecommendationModalProduct = (data, flowCountry) => {
  const { imageUrl, price, priceCurrency, titleText, descriptionText, properties } = data.data;

  return (
    <div className='recommendation-modal-product'>
      <div className='recommendation-modal-product__divider' />
      <div className='recommendation-modal-product__content'>
        <div className='recommendation-modal-product__left-block'>
          <div className='recommendation-modal-product__image-wrapper'>
            <img src={imageUrl} alt='product' />
            <Price price={price} priceCurrency={priceCurrency} className='recommendation-modal-product__price' />
          </div>
        </div>
        <div className='recommendation-modal-product__right-block'>
          <div className='recommendation-modal-product__text-wrapper'>
            <span className='recommendation-modal-product__title'>{titleText}</span>
            <span className='recommendation-modal-product__text'>{descriptionText}</span>
            {renderIllnesses(properties.risks, flowCountry)}
          </div>
        </div>
      </div>
      <div className='recommendation-modal-product__divider recommendation-modal-product__divider--bottom' />
    </div>
  );
};

RecommendationModalProduct.propTypes = {
  data: PropTypes.object,
  flowCountry: PropTypes.string
};

export default RecommendationModalProduct;
