import React from 'react';
import PropTypes from 'prop-types';
import TestResultsShareLink from '../../TestResultsShareLink';

const FOOTER_CTA_TYPES = {
  SHARE: 'share',
  SCHEDULE_REMINDER: 'scheduleReminder'
};

const RecommendedActionsFooterCTA = ({ footerCTA }) => {
  if (footerCTA.type === FOOTER_CTA_TYPES.SHARE) {
    return (
      <TestResultsShareLink
        iconType={FOOTER_CTA_TYPES.SHARE}
        text={footerCTA.text}
        url={footerCTA.url}
      />
    );
  }

  // TODO: Implement SCHEDULE_REMINDER type when backend is done
  return null;
};

RecommendedActionsFooterCTA.propTypes = {
  footerCTA: PropTypes.object
};

export default RecommendedActionsFooterCTA;
