import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../components/Divider';
import { FormattedMessage } from 'react-intl';
import { Heading4, Body2 } from '../../../components/Typography';
import CssSupportAccordions from './CssSupportAccordions';

import './index.scss';

const CssSupport = ({ language }) => {
  const cssInfoUrl =
    language === 'en'
      ? 'https://css.ch/health-coach'
      : 'https://css.ch/gesundheitscoach';

  return (
    <div className='css-support'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.partner-code.css-support'
            defaultMessage='CSS support'
          />
        }
      />
      <div className='css-support__content'>
        <Heading4>
          <FormattedMessage
            id='test-result.partner-code.css-support.title'
            defaultMessage='Do you have additional questions?'
          />
        </Heading4>

        <Body2 className='css-support__text'>
          <FormattedMessage
            id='test-result.partner-code.css-support.text-one'
            defaultMessage='Then contact a CSS health coach. For you as a CSS customer the first consultation is free.'
          />
        </Body2>

        <Body2 className='css-support__text'>
          <FormattedMessage
            id='test-result.partner-code.css-support.text-two'
            defaultMessage='Please note: A consult with a health coach does not replace a diagnosis by an accredited doctor.'
          />
        </Body2>

        <Body2 className='css-support__text'>
          <FormattedMessage
            id='test-result.partner-code.css-support.text-three'
            defaultMessage='Find more information about this service under:'
          />
          <a className='css-support__url' href={cssInfoUrl}>
            {cssInfoUrl}
          </a>
        </Body2>

        <CssSupportAccordions language={language} />
      </div>
    </div>
  );
};

CssSupport.propTypes = {
  language: PropTypes.string
};
export default CssSupport;
