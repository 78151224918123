import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { testTypes } from '../../../components/TestCard/testTypes/index';
import { Heading3 } from '../../../components/Typography';
import MyHealthContainer from '../../components/MyHealthContainer';
import MyHealthCardsCarousel from '../../../components/MyHealthCardsCarousel';
import MyHealthQuickActions from '../../components/MyHealthQuickActions';
import MyHealthYourProducts from '../../components/MyHealthYourProducts';
import BottomDrawer from '../../../components/BottomDrawer';
import BottomDrawerContent from '../../../components/BottomDrawerContent';
import MyHealthHealthKnowledge from '../../components/MyHealthHealthKnowledge';
import {
  cardsWithEmployeeBenefits,
  cardsWithoutEmployeeBenefits
} from './cards';

import './index.scss';

const MyHealth = ({
  dispatch,
  data,
  loading,
  userLoading,
  lang,
  changeLanguage
}) => {
  //TODO:Refactor this: Had to leave state isBottomsModalOpen because if I try and do it same as sideModalContent, then modal is not closing properly
  const [isBottomsModalOpen, setIsBottomModalOpen] = useState(false);
  const [bottomModalContent, setBottomModalContent] = useState(null);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data && data.user && data.user.firstName) {
      setFirstName(data.user.firstName);
    }
  }, [data]);

  const handleOpenBottomModal = test => {
    const testType = testTypes[test.type];
    setBottomModalContent(
      <BottomDrawerContent
        status={test.status}
        type={test.type}
        statusColor={test.status}
        testTitle={testType}
        prescriptionTitle={test.name}
        testCodeText={test.code}
        updateSamplingTimeUrl={test.updateSamplingTimeUrl}
        samplingInstructionsUrl={test.samplingInstructionsUrl}
        lastUpdateText={test.lastUpdate}
      />
    );
    setIsBottomModalOpen(true);
  };

  // TODO: Rework this
  const cards =
    data && data.employeeBenefits
      ? cardsWithEmployeeBenefits
      : cardsWithoutEmployeeBenefits;

  // Adding company logo to the first card
  if (data && data.employeeBenefits) {
    cards[0].icon = data.employeeBenefits.companyData.logo;
  }

  return (
    <MyHealthContainer
      dispatch={dispatch}
      lang={lang}
      changeLanguage={changeLanguage}
    >
      <div className='my-health'>
        <div className='my-health__left-block'>
          <div className='my-health__header'>
            <Heading3>
              <FormattedMessage id='my-health.hello' defaultMessage='Hello' />{' '}
              {firstName}
            </Heading3>
          </div>

          <MyHealthCardsCarousel
            cards={cards}
            className='my-health__carousel'
          />

          {data &&
            data.tests &&
            data.tests.length > 0 &&
            !loading &&
            !userLoading && (
              <div className='my-health__your-products'>
                <MyHealthYourProducts
                  tests={data.tests}
                  handleOpenBottomModal={test => handleOpenBottomModal(test)}
                />
              </div>
            )}

          <div className='my-health__quick-actions'>
            <MyHealthQuickActions />
          </div>

          <div className='my-health__health-knowledge-mobile'>
            <MyHealthHealthKnowledge />
          </div>
        </div>
        <div className='my-health__right-block'>
          <MyHealthHealthKnowledge />
        </div>
        <BottomDrawer
          isOpen={isBottomsModalOpen}
          onClick={() => {
            setIsBottomModalOpen(false);
          }}
        >
          {bottomModalContent}
        </BottomDrawer>
      </div>
    </MyHealthContainer>
  );
};

MyHealth.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data'),
  loading: state.dashboard.get('loading'),
  userLoading: state.user.get('loading'),
  userData: state.user.get('user')
});

export default connect(mapStateToProps)(MyHealth);
