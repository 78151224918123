import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MyHealthContainer from '../../components/MyHealthContainer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyHealthEmployeeHealthHero from '../../components/MyHealthEmployeeHealthHero';
import MyHealthEmployeeHealthProgram from '../../components/MyHealthEmployeeHealthProgram';
import Modal from '../../../components/Modal';
import OrderTestModalContent from '../../components/OrderTestModalContent';
import OrderTestModalContentCompleted from '../../components/OrderTestModalContentCompleted';
import EmployeeHealthProgramQuickActions from '../../components/EmployeeHealthProgramQuickActions';
import SideDrawer from '../../../components/SideDrawer';
import { useIntl } from 'react-intl';

import './index.scss';

const LEARN_ABOUT_HEALTH_BENEFITS_INFO_ID =
  'my-health.learn-about-health-benefits-info';

const MyHealthEmployeeHealth = ({ dispatch, data, lang, changeLanguage }) => {
  const intl = useIntl();

  const [sideModalContent, setSideModalContent] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSideModal = info => {
    const translation = intl.formatMessage({
      id: info
    });

    setSideModalContent(
      <Markdown rehypePlugins={[rehypeRaw]}>{translation}</Markdown>
    );
  };

  const handleOpenModal = card => {
    setModalContent(
      <OrderTestModalContent
        testData={card}
        benefitData={data.employeeBenefits}
        handleProceed={handleProceed}
        onClose={() => setModalContent(null)}
      />
    );
  };

  const handleProceed = () => {
    setModalContent(
      <OrderTestModalContentCompleted onClose={() => setModalContent(null)} />
    );
    setTimeout(() => {
      setModalContent(null);
    }, 5000);
  };

  return (
    <MyHealthContainer
      dispatch={dispatch}
      lang={lang}
      changeLanguage={changeLanguage}
    >
      <div className='my-health-employee-health'>
        <div className='my-health-employee-health__content'>
          {data && data.employeeBenefits && (
            <>
              <div className='my-health-employee-health__employee-health-hero'>
                <MyHealthEmployeeHealthHero data={data.employeeBenefits} />
              </div>

              <div className='my-health-employee-health__employee-health-program'>
                <MyHealthEmployeeHealthProgram
                  employeeBenefits={data.employeeBenefits}
                  handleInfo={info => handleOpenSideModal(info)}
                  handleModal={handleOpenModal}
                />
              </div>
            </>
          )}

          <div className='my-health-employee-health__quick-actions'>
            <EmployeeHealthProgramQuickActions
              onClick={() =>
                handleOpenSideModal(LEARN_ABOUT_HEALTH_BENEFITS_INFO_ID)
              }
            />
          </div>
        </div>
        <SideDrawer
          onClick={() => {
            setSideModalContent(null);
          }}
          isOpen={!!sideModalContent}
        >
          <div>{sideModalContent}</div>
        </SideDrawer>
        <Modal isOpen={!!modalContent}>{modalContent}</Modal>
      </div>
    </MyHealthContainer>
  );
};

MyHealthEmployeeHealth.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  userError: PropTypes.object,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data')
});

export default connect(mapStateToProps)(MyHealthEmployeeHealth);
