import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../components/Divider';
import EligibilityPanel from '../EligibilityPanel';
import TextWithInfoButton from '../../../components/TextWithInfoButton';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const EligibilityForImmunotherapy = ({
  immunotherapyEligibilityData,
  handleInfo
}) => {
  const {
    description,
    info,
    eligibilityResultExplanation,
    eligibilityResultInfo,
    SCITPossible,
    SLITPossible,
    eligibleForImmunoTherapy
  } = immunotherapyEligibilityData;

  return (
    <div className='eligibility-for-immunotherapy'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.eligibility-for-immunotherapy'
            defaultMessage='Eligibility for immunotherapy'
          />
        }
      />

      <TextWithInfoButton
        text={description}
        info={info}
        handleInfo={() => handleInfo(info)}
      />

      <div className='eligibility-for-immunotherapy__eligibility-panel-wrapper'>
        {eligibleForImmunoTherapy !== null && (
          <div className='eligibility-for-immunotherapy__immunotherapy'>
            <EligibilityPanel
              text={
                <FormattedMessage
                  id='test-result.eligibility-for-immunotherapy'
                  defaultMessage='Eligibility for immunotherapy'
                />
              }
              eligible={eligibleForImmunoTherapy}
            />
          </div>
        )}

        {SLITPossible !== null && (
          <EligibilityPanel
            text={
              <FormattedMessage
                id='test-result.eligibility-for-immunotherapy-slit'
                defaultMessage='Eligibility for immunotherapy with tablets or drops'
              />
            }
            eligible={SLITPossible}
          />
        )}

        {SCITPossible !== null && (
          <div className='eligibility-for-immunotherapy__immunotherapy'>
            <EligibilityPanel
              text={
                <FormattedMessage
                  id='test-result.eligibility-for-immunotherapy-scit'
                  defaultMessage='Eligibility for immunotherapy with injections'
                />
              }
              eligible={SCITPossible}
            />
          </div>
        )}
      </div>

      <TextWithInfoButton
        text={eligibilityResultExplanation}
        info={eligibilityResultInfo}
        handleInfo={() => handleInfo(eligibilityResultInfo)}
      />
    </div>
  );
};

EligibilityForImmunotherapy.propTypes = {
  immunotherapyEligibilityData: PropTypes.object,
  handleInfo: PropTypes.func
};

export default EligibilityForImmunotherapy;
