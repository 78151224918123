import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';

import Button from '../Button';
import Price from '../Price';

import './index.scss';

const getProductTitles = products => {
  const productTitles = products && products.map(product => product.titleText);

  if (productTitles) {
    return productTitles.map(product => {
      return (
        <li key={product} className='recommendation-card__product'>
          {product}
        </li>
      );
    });
  }
};

const handleButtonClick = (cardClick, key, isChecked, buttonRef) => () => {
  if (isChecked) {
    cardClick(null);
  } else {
    cardClick(key);
  }

  buttonRef.current.blur();
};

const handleInfoButtonClick = (key, openModal) => () => {
  openModal(key);
};

const RecommendationCard = ({
  data,
  cardClick,
  chosenTest,
  breakpoint,
  isNotButton,
  openModal,
  numberOfCards,
  kind
}) => {
  const {
    key,
    price,
    priceCurrency,
    discount,
    discountCurrency,
    titleText,
    subTitleText,
    products,
    extensionText,
    noReadMoreButton
  } = data;
  const buttonRef = useRef(null);

  const isSmall = ['xsmall', 'small'].includes(breakpoint);
  const isChecked = chosenTest === key;

  const cardClasses = cx({
    'recommendation-card': true,
    'recommendation-card--two-in-row': numberOfCards === 2,
    'recommendation-card--four-in-row': numberOfCards === 4,
    checked: isChecked
  });

  const cardIconClasses = cx({
    'recommendation-card__icon': true,
    'recommendation-card__icon--visible': isChecked
  });

  const selectButtonClasses = cx({
    'recommendation-card__select-button': true,
    'recommendation-card__select-button--selected': isChecked
  });

  const buttonsWrapperClasses = cx({
    'recommendation-card__button-wrapper': true,
    'recommendation-card__button-wrapper--one-button': noReadMoreButton
  });

  return (
    <div className={cardClasses} style={isSmall ? { width: '58vw' } : {}}>
      {key === 'recommended' && (
        <div className='recommendation-card__badge'>
          <FormattedMessage
            id='recommend.recommended'
            defaultMessage='Recommended'
          />
        </div>
      )}
      {!isNotButton ? (
        <div className='recommendation-card__button'>
          <span className='recommendation-card__wrapper'>
            <span className='recommendation-card__content'>
              <span className='recommendation-card__icon-wrapper'>
                <img src={data.iconUrl} alt='card icon' />
                <img
                  src={data.selectedIconUrl}
                  alt='card icon chosen'
                  className={cardIconClasses}
                />
                <span className='recommendation-card__price-wrapper'>
                  <Price
                    price={price}
                    priceCurrency={priceCurrency}
                    discount={discount}
                    discountCurrency={discountCurrency}
                  />
                </span>
              </span>
              <div className='recommendation-card__text'>
                <span className='recommendation-card__title'>{titleText}</span>
                <span className='recommendation-card__subtitle'>
                  {subTitleText}
                </span>
                <ul className='recommendation-card__products'>
                  {getProductTitles(products)}
                </ul>
              </div>
            </span>
            <div className={buttonsWrapperClasses}>
              {!noReadMoreButton && (
                <Button
                  className='recommendation-card__read-more-button'
                  onClick={handleInfoButtonClick(key, openModal)}
                >
                  <FormattedMessage
                    id='app.read-more'
                    defaultMessage='Read More'
                  />
                </Button>
              )}
              <Button
                buttonRef={buttonRef}
                purple
                className={selectButtonClasses}
                onClick={handleButtonClick(
                  cardClick,
                  key,
                  isChecked,
                  buttonRef
                )}
              >
                {isChecked ? (
                  <FormattedMessage
                    id='recommend.selected'
                    defaultMessage='Selected'
                  />
                ) : (
                  <FormattedMessage
                    id='recommend.select'
                    defaultMessage='Select'
                  />
                )}
              </Button>
            </div>
          </span>
        </div>
      ) : (
        <div className='recommendation-card__text-wrapper'>
          <Markdown>{extensionText}</Markdown>
        </div>
      )}
    </div>
  );
};

RecommendationCard.propTypes = {
  data: PropTypes.object,
  chosenTest: PropTypes.string,
  cardClick: PropTypes.func,
  breakpoint: PropTypes.string,
  isNotButton: PropTypes.bool,
  openModal: PropTypes.func,
  numberOfCards: PropTypes.number,
  kind: PropTypes.string
};

export default withWindow(RecommendationCard);
