import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withWindow from '../../windowDecorators/withWindow';

import './index.scss';

const PriceTag = ({ data, breakpoint }) => {
  const { priceSixMonths, price } = data;
  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <div className='price-tag'>
      <div className='price-tag__text-wrapper'>
        <div className='price-tag__small-text'>
          <FormattedMessage id='app.from' defaultMessage='from' />
        </div>
        <div className='price-tag__big-text'>
          {price && price.package && (
            <FormattedMessage
              id='app.price'
              defaultMessage={`CHF ${Math.ceil(price.package)}`}
              values={{ price: Math.ceil(price.package) }}
            />
          )}
          {priceSixMonths && (
            <FormattedMessage
              id='app.price'
              defaultMessage={`CHF ${Math.ceil(priceSixMonths)}`}
              values={{ price: Math.ceil(priceSixMonths) }}
            />
          )}
        </div>
        {priceSixMonths && (
          <div className='price-tag__small-text'>
            <FormattedMessage
              id={isSmallOrMedium ? 'app.six-months-short' : 'app.six-months'}
              defaultMessage='6 months'
            />
          </div>
        )}
      </div>
    </div>
  );
};

PriceTag.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string
};

export default withWindow(PriceTag);
