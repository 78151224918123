import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Body2, Subtitle1 } from '../Typography';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download.svg';
import cx from 'classnames';

import styles from './index.module.scss';

export interface DownloadButtonProps {
  text: string;
  description: string;
  onClick: () => void;
  error?: string;
  downloadError?: string;
  className?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  text,
  description,
  onClick,
  error,
  downloadError,
  className
}) => {
  return (
    <div className={styles.wrapper}>
      <button
        onClick={onClick}
        className={cx({ [styles.button]: true }, className)}
      >
        <div className={styles.textWrapper}>
          <Subtitle1 className={styles.text}>{text}</Subtitle1>
          <Body2 className={styles.description}>{description}</Body2>
        </div>
        <DownloadIcon />
      </button>
      {error ||
        (downloadError && (
          <p className={styles.error}>
            <FormattedMessage
              id='test-result.try-again-later'
              defaultMessage='Something went wrong, please try again later'
            />
          </p>
        ))}
    </div>
  );
};

export default DownloadButton;
