import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from '../../../components/Typography';
import { ReactComponent as InfoIcon } from '../../../assets/storybook-placeholders/svg/info-gradient.svg';

import './index.scss';

const EmployeeHealthProgramFooterCTA = ({ footerCTA, handleInfo }) => {
  return (
    <button
      onClick={() => handleInfo(footerCTA.infoText)}
      className='employee-health-program-footer-cta'
    >
      <div className='employee-health-program-footer-cta__text-wrapper'>
        <Caption element='span'>{footerCTA.text}</Caption>
        <InfoIcon className='employee-health-program-footer-cta__info-icon' />
      </div>
    </button>
  );
};

EmployeeHealthProgramFooterCTA.propTypes = {
  footerCTA: PropTypes.object,
  handleInfo: PropTypes.func
};

export default EmployeeHealthProgramFooterCTA;
