import React from 'react';
import Divider from '../../../components/Divider';
import QuickActionCard from '../../../components/QuickActionCard';
import { ReactComponent as BoxIcon } from '../../../assets/svg/box-with-plus.svg';
import { ReactComponent as SupportIcon } from '../../../assets/svg/user-with-heart.svg';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const SWITCHBOARD_URL = '/?conversationFlowKey=switchboard';
const SUPPORT_URL = 'https://service.yourself.health/';

const MyHealthQuickActions = () => {
  return (
    <div className='my-health-quick-actions'>
      <Divider
        title={
          <FormattedMessage
            id='my-health.quick-access'
            defaultMessage='Quick access'
          />
        }
      />
      <div className='my-health-quick-actions__cards'>
        <QuickActionCard
          icon={<BoxIcon />}
          title={
            <FormattedMessage
              id='my-health.activate-test'
              defaultMessage='Activate test'
            />
          }
          url={SWITCHBOARD_URL}
          className='my-health-quick-actions__activate-test'
        />
        <QuickActionCard
          icon={<SupportIcon />}
          title={
            <FormattedMessage
              id='my-health.contact-support'
              defaultMessage='Contact Support'
            />
          }
          url={SUPPORT_URL}
          className='my-health-quick-actions__contact-support'
        />
      </div>
    </div>
  );
};

export default MyHealthQuickActions;
