import React from 'react';
import { Caption } from '../../Typography';

import styles from './index.module.scss';

export interface ProgressBarSectionProps {
  color: string;
  maxUpperLimit: number;
  lowerLimit: number;
  upperLimit: number;
  isFirstSection: boolean;
}

const ProgressBarSection: React.FC<ProgressBarSectionProps> = ({
  color,
  maxUpperLimit,
  lowerLimit,
  upperLimit,
  isFirstSection
}) => {
  const calculateSectionWidth = () => {
    const value = (100 * (upperLimit - lowerLimit)) / maxUpperLimit;
    return value + '%';
  };

  const sectionWidth = calculateSectionWidth();

  const sectionTitleStyle = isFirstSection
    ? styles.sectionTitleFirst
    : styles.sectionTitle;

  return (
    <div
      className={styles.section}
      style={{ backgroundColor: color, width: sectionWidth }}
    >
      <Caption className={sectionTitleStyle} element='span'>
        {lowerLimit}
      </Caption>
    </div>
  );
};

export default ProgressBarSection;
