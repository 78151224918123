import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { Subtitle1, Caption } from '../Typography';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrow-down-icon.svg';
import { ReactComponent as InfoGradientIcon } from '../../assets/storybook-placeholders/svg/info-gradient.svg';

import styles from './index.module.scss';

interface AccordionProps {
  title: string;
  content: React.ReactNode;
  info: string;
  handleInfo: () => void;
  isFirstAccordionOpen?: boolean;
  onToggle: () => void;
  type?: string;
}
const Accordion: React.FC<AccordionProps> = ({
  title,
  type,
  content,
  info,
  handleInfo,
  isFirstAccordionOpen,
  onToggle
}) => {
  const [isOpen, setIsOpen] = useState(isFirstAccordionOpen);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // added timeout here also just in case, for more details why, look into accordion component
    setTimeout(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, 500);
  }, [content]);

  const handleAccordionClick = () => {
    setIsOpen(!isOpen);
    onToggle();
  };

  return (
    <div className={styles.accordion}>
      <div onClick={handleAccordionClick} className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.textWrapper}>
            <Caption className={styles.accordionType} element='p'>
              {type}
            </Caption>
            <Subtitle1 className={styles.title}>{title}</Subtitle1>
          </div>

          {info && (
            <button
              className={styles.infoIcon}
              onClick={event => {
                event.stopPropagation();
                handleInfo();
              }}
            >
              <InfoGradientIcon />
            </button>
          )}
        </div>
        <ArrowDownIcon
          className={cx({
            [styles.arrowDown]: true,
            [styles.arrowUp]: isOpen
          })}
        />
      </div>
      <div
        ref={contentRef}
        className={styles.content}
        style={{ maxHeight: isOpen ? contentHeight : 0 }}
      >
        {content}
      </div>
    </div>
  );
};
export default Accordion;
