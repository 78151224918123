import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const IconButton = ({ href, onClick, children, className, noNewTab }) => {
  const classes = cx('icon-button', className);

  return href ? (
    <a href={href} className={classes} target={noNewTab ? '' : '_blank'} rel='noopener noreferrer'>
      {children}
    </a>
  ) : (
    <button type='button' className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

IconButton.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  noNewTab: PropTypes.bool
};

export default IconButton;
