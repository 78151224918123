import React from 'react';
import MyHealthCardContent from '../MyHealthCardContent';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

enum CardType {
  INFO = 'info',
  INNER_LINK = 'inner-link',
  OUTER_LINK = 'outer-link'
}

export interface MyHealthCardProps {
  eyebrow: string;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  href: string;
  background: string;
  type: CardType;
  handleInfo: (info: string) => void;
  infoText: string;
}

const MyHealthCard: React.FC<MyHealthCardProps> = ({
  eyebrow,
  icon,
  title,
  description,
  href,
  background,
  type,
  handleInfo,
  infoText
}) => {
  //TODO: refactor this when Djordje returns
  const cardType = {
    [CardType.INFO]: (
      <button onClick={() => handleInfo(infoText)} className={styles.wrapper}>
        <MyHealthCardContent
          eyebrow={eyebrow}
          title={title}
          background={background}
          icon={icon}
          description={description}
        />
      </button>
    ),
    [CardType.OUTER_LINK]: (
      <a
        href={href}
        className={styles.wrapper}
        target='_blank'
        rel='noopener noreferrer'
      >
        <MyHealthCardContent
          eyebrow={eyebrow}
          title={title}
          background={background}
          icon={icon}
          description={description}
        />
      </a>
    ),
    [CardType.INNER_LINK]: (
      <Link to={href} className={styles.wrapper}>
        <MyHealthCardContent
          eyebrow={eyebrow}
          title={title}
          background={background}
          icon={icon}
          description={description}
        />
      </Link>
    )
  };

  return <>{cardType[type]}</>;
};

export default MyHealthCard;
