import React from 'react';
import Link from '../Link';
import Button from '../Button';
import { Body2, Subtitle1 } from '../Typography';
import Placeholder from '../../assets/images/placeholder.png';

import styles from './index.module.scss';

export type ProductCard = {
  title: string;
  testType: string;
  price: string;
  iconUrl: string;
  cta: { url: string; text: string; type: string; disabled?: boolean };
};

export interface OrderProductCardProps {
  productCard: ProductCard;
}

const OrderProductCard: React.FC<OrderProductCardProps> = ({ productCard }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={productCard.iconUrl ? productCard.iconUrl : Placeholder}
          alt={productCard.title}
        />
      </div>
      <div className={styles.rightSide}>
        <Subtitle1>{productCard.title}</Subtitle1>
        <Body2 className={styles.text}>{productCard.price}</Body2>
        {/* In case button is disabled, we are rendering disabled button
          because we don't want to change or add disable state to 
          the Link component.
        */}
        {productCard.cta.disabled ? (
          <Button onClick={() => {}} size='small' disabled>
            {productCard.cta.text}
          </Button>
        ) : (
          <Link size='small' url={productCard.cta.url}>
            {productCard.cta.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default OrderProductCard;
