import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const FormTitle = ({ title, defaultTitle = '' }) => {
  return (
    <div className='form-title'>
      <FormattedMessage id={title} defaultMessage={defaultTitle} />
    </div>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string,
  defaultTitle: PropTypes.string
};

export default FormTitle;
