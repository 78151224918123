import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as ArrowDown } from '../../../assets/svg/down-arrow.svg';

import './index.scss';

const handleChange = (onChange, e) => {
  onChange(e.target.value);
};

const LanguageDropdown = ({
  onChange,
  value,
  className,
  wrapperClassName,
  availableLanguages
}) => {
  const langMap = {
    en: 'EN',
    de: 'DE',
    fr: 'FR',
    it: 'IT'
  };
  let languages;
  if (
    !availableLanguages ||
    availableLanguages === undefined ||
    availableLanguages.length === 0
  ) {
    languages = Object.keys(langMap);
  } else {
    languages = availableLanguages;
  }
  return (
    <div className={cx('dropdown__wrapper', wrapperClassName)}>
      <select
        className={cx('dropdown', className)}
        value={value}
        onChange={e => handleChange(onChange, e)}
      >
        {languages.map(lang => (
          <option key={lang} value={lang}>
            {langMap[lang]}
          </option>
        ))}
      </select>
      <ArrowDown className='dropdown__icon' />
    </div>
  );
};

LanguageDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(PropTypes.string)
};

export default LanguageDropdown;
