import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const FormTextWithChildren = ({ children, className }) => {
  return (
    <div className={cx('form-text', className)}>
      <span className='form-text__text'>{children}</span>
    </div>
  );
};

FormTextWithChildren.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default FormTextWithChildren;
