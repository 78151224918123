import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useCountDown from 'react-countdown-hook';
import { FormattedMessage } from 'react-intl';

import FormTitle from '../FormWrapper/FormTitle';
import FormText from '../FormWrapper/FormText';

import './index.scss';

const countdownTime = 90 * 1000; // how much we have to wait before enabling user to send new email
const interval = 1000; // interval to change remaining time amount, defaults to 1000

const ConfirmEmail = ({ email, resendVerificationEmail, error, setError }) => {
  const [disabled, setDisabled] = useState(true);
  const [timeLeft, { start }] = useCountDown(null, interval);

  useEffect(() => {
    if (timeLeft <= 0 && disabled) {
      setDisabled(false);
      if (error) {
        setError(null);
      }
    }

    if (timeLeft > 0 && !disabled) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, disabled]);

  useEffect(() => {
    const now = new Date();
    const emailResendTimeout = localStorage.getItem('emailResendTimeout');
    let countdownStart;
    // If we have time stored, we are checking that value and using it for countdown
    if (emailResendTimeout && emailResendTimeout > now.getTime()) {
      countdownStart = emailResendTimeout - now.getTime();
      start(countdownStart);
    }

    // If we don't have time stored, we are starting countdown of 90 seconds and adding it to the localstorage
    if (
      !emailResendTimeout ||
      (emailResendTimeout && emailResendTimeout < now.getTime())
    ) {
      // One minute and a half
      countdownStart = countdownTime;
      localStorage.setItem(
        'emailResendTimeout',
        now.getTime() + countdownStart
      );
      start(countdownStart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    resendVerificationEmail();
    const now = new Date();
    const countdownStart = countdownTime;
    localStorage.setItem('emailResendTimeout', now.getTime() + countdownStart);
    start(countdownStart);
    setDisabled(true);
  };

  return (
    <div>
      <FormTitle
        title='auth.confirm-email'
        defaultTitle='Confirm your email address'
      />
      <FormText
        text='auth.confirm-email-explanation'
        defaultText='We have sent a verification email to {email}. Please click on the link in that email to continue.'
        values={{ email: email }}
      />
      <div className='confirm-email__text'>
        <span className='confirm-email__text-text'>
          <FormattedMessage
            id='auth.email-not-received'
            defaultMessage='No email received?'
          />
        </span>
        <button
          className='confirm-email__button'
          disabled={disabled}
          onClick={() => handleButtonClick()}
        >
          {disabled ? (
            <>
              <FormattedMessage
                id='auth.resend-in'
                defaultMessage='Resend in '
              />
              {Math.floor(timeLeft / 1000)}
            </>
          ) : (
            <FormattedMessage
              id='auth.resend-email'
              defaultMessage='Resend now.'
            />
          )}
        </button>
      </div>
      {error && (
        <span className='confirm-email__error'>
          <FormattedMessage
            id={error}
            defaultMessage="We're having a technical issue. Please try again later."
          />
        </span>
      )}
    </div>
  );
};

ConfirmEmail.propTypes = {
  email: PropTypes.string,
  resendVerificationEmail: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func
};

export default ConfirmEmail;
