export const GET_TEST_RESULT_START = 'GET_TEST_RESULT_START';
export const GET_TEST_RESULT_SUCCESS = 'GET_TEST_RESULT_SUCCESS';
export const GET_TEST_RESULT_ERROR = 'GET_TEST_RESULT_ERROR';
export const MARK_RECOMMENDED_ACTION_AS_DONE_START = 'MARK_RECOMMENDED_ACTION_AS_DONE_START';
export const MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS = 'MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS';
export const MARK_RECOMMENDED_ACTION_AS_DONE_ERROR = 'MARK_RECOMMENDED_ACTION_AS_DONE_ERROR';

export const getTestResults = (testCode, language) => ({
  type: GET_TEST_RESULT_START,
  payload: {
    testCode,
    language
  }
});

export const markRecommendedActionAsDone = (testCode, timingKey, contentKey, language) => ({
  type: MARK_RECOMMENDED_ACTION_AS_DONE_START,
  payload: {
    testCode,
    timingKey,
    contentKey,
    language
  }
});
