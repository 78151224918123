import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withWindow from '../../windowDecorators/withWindow';

import useAuthorization from '../../hooks/useAuthorization';

import Background from '../../components/Background';
import FormWrapper from '../../components/FormWrapper';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import PasswordUpdatedContent from '../../components/PasswordUpdatedContent';

import backgroundMobile from '../../../assets/svg/reset-password-mobile.svg';
import backgroundLarge from '../../../assets/svg/reset-password-desktop.svg';

import './index.scss';

const ResetPassword = ({ breakpoint, dispatch, lang, changeLanguage }) => {
  const {
    resetPassword,
    error,
    isLoading,
    userState,
    userStateValues
  } = useAuthorization(dispatch);

  const smallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <Background className='reset-password__background'>
      <div className='reset-password'>
        <FormWrapper
          lang={lang}
          changeLanguage={changeLanguage}
          imageUrl={smallOrMedium ? backgroundMobile : backgroundLarge}
        >
          {userState !== userStateValues.PASSWORD_UPDATED ? (
            <ResetPasswordForm
              resetPassword={resetPassword}
              hookError={error}
              isLoading={isLoading}
            />
          ) : (
            <PasswordUpdatedContent />
          )}
        </FormWrapper>
      </div>
    </Background>
  );
};

ResetPassword.propTypes = {
  breakpoint: PropTypes.string,
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default connect()(withWindow(ResetPassword));
