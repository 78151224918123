import React from 'react';
import cx from 'classnames';
import { Subtitle2 } from '../Typography';

import styles from './index.module.scss';

interface QuickActionButtonCardProps {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
  className: string;
}

const QuickActionButtonCard: React.FC<QuickActionButtonCardProps> = ({
  icon,
  title,
  onClick,
  className
}) => {
  return (
    <button className={styles.wrapper} onClick={onClick}>
      <div className={styles.content}>
        {icon}
        <Subtitle2 className={styles.title}>{title}</Subtitle2>
        <div
          className={cx(
            {
              [styles.line]: true
            },
            className && { [className]: true }
          )}
        ></div>
        <div
          className={cx(
            {
              [styles.cardShadow]: true
            },
            className && { [className]: true }
          )}
        ></div>
      </div>
    </button>
  );
};

export default QuickActionButtonCard;
