import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import countriesData from 'countries-list';
import { FormattedMessage } from 'react-intl';
import 'react-dropdown/style.css';

import './index.scss';

const getCountries = countries => {
  const countriesList = [];
  Object.keys(countries).map(key => {
    return countriesList.push({
      value: key,
      label: countries[key].name
    });
  });

  return countriesList;
};

const QuestionDropdown = ({ answerValue, setCurrentScreenAnswer }) => {
  const [selectedItem, setSelectedItem] = useState();
  const options = getCountries(countriesData.countries);

  options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

  useEffect(() => {
    if (answerValue) {
      const findSelectedItem = options.findIndex(option => option.value === answerValue);
      setSelectedItem(options[findSelectedItem]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = country => {
    setSelectedItem(country);
    setCurrentScreenAnswer(country.value);
  };

  return (
    <div className='question-dropdown'>
      <div className='question-dropdown__content'>
        <Dropdown
          className='question-dropdown__element'
          controlClassName='question-dropdown__control'
          arrowClassName='question-dropdown__arrow'
          placeholderClassName={cx({
            'question-dropdown__placeholder': true,
            'question-dropdown__placeholder--selected': selectedItem
          })}
          menuClassName='question-dropdown__menu'
          value={selectedItem}
          options={options}
          onChange={e => handleOnChange(e)}
          placeholder={<FormattedMessage id='countries.select' defaultMessage='Select country' />}
        />
      </div>
    </div>
  );
};

QuestionDropdown.propTypes = {
  answerValue: PropTypes.string,
  setCurrentScreenAnswer: PropTypes.func
};

export default QuestionDropdown;
