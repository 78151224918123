import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Caption, Subtitle1 } from '../Typography';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrow-down-icon.svg';

import styles from './index.module.scss';

interface AccordionProps {
  contentKey: string;
  type: string;
  title: string;
  content: React.ReactNode;
  done: boolean;
}

const RecommendedActionsAccordion: React.FC<AccordionProps> = ({
  type,
  title,
  content,
  contentKey,
  done = true
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!done);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //in recommended actions, we had a problem where ctas would be cut off a bit, this way we set timeout so the content loads properly
    setTimeout(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, 500);
  }, [content]);

  const handleAccordionClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(!done);
  }, [done]);

  return (
    <div
      className={cx({
        [styles.accordionContentWrapper]: true,
        // TODO: This should be implemented in a better way
        [styles.gradientWrapper]: contentKey === 'preparatoryConsultation'
      })}
    >
      <div
        onClick={handleAccordionClick}
        className={styles.accordionTitleWrapper}
      >
        <div className={styles.accordionTextWrapper}>
          <Caption className={styles.accordionTitle} element='p'>
            {type}
          </Caption>
          <Subtitle1>{title}</Subtitle1>
        </div>
        <ArrowDownIcon
          className={cx({
            [styles.arrowDown]: true,
            [styles.arrowUp]: isOpen
          })}
        />
      </div>

      <div
        ref={contentRef}
        className={styles.accordionContent}
        style={{ maxHeight: isOpen ? contentHeight : 0 }}
      >
        {content}
      </div>
    </div>
  );
};

export default RecommendedActionsAccordion;
