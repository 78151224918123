/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import WindowManager from './windowDecorators/WindowManager';
import { PATHS } from '../app/constants/paths';

import store from './store';
import breakpoints from './constants/breakpoints';
import ImportScript from './hooks/importScript';
import Action from './views/Action';
import Home from './views/Home';
import TestHome from './views/TestHome';
import Error from './views/Error';
import Auth from './views/Auth';
import Authorization from './views/Authorization';
import PhoneRegistration from './views/PhoneRegistration';
import TestResults from './views/TestResults';
import MyHealth from './views/MyHealth';
import MyHealthProducts from './views/MyHealthProducts';
import MyHealthEmployeeHealth from './views/MyHealthEmployeeHealth';
import MyHealthShop from './views/MyHealthShop';
import ResetPassword from './views/ResetPassword';
import Playground from './views/Playground';
import { AuthContextProvider, useAuthState } from './context/firebase';

import './utils/modernizr';
import '../scss/index.scss';

new WindowManager(breakpoints.formatted, 500);

const AuthenticatedRoute = ({ component: C, ...props }) => {
  const { isAuthenticated, isLoading } = useAuthState();
  if (isLoading) {
    return <div className='home__loader'></div>;
  }

  if (!isAuthenticated) {
    window.location.href = `${process.env.REACT_APP_SECURE_APP_URL}${PATHS.AUTHORIZATION}`;
  }

  return (
    isAuthenticated && (
      <Route
        {...props}
        render={routeProps => {
          return <C {...routeProps} {...props} />;
        }}
      />
    )
  );
};

// Routes that has to be redirected to secure url, for example /authrization
// Because when user login, we are setting items in localstorage and it has to be
// on same url origin
const SecureRoute = ({ component: C, ...props }) => {
  const origin = window.location.origin;
  const href = window.location.href;

  if (origin !== process.env.REACT_APP_SECURE_APP_URL) {
    window.location.href = href.replace(
      origin,
      process.env.REACT_APP_SECURE_APP_URL
    );
  } else {
    return <Route render={routeProps => <C {...routeProps} {...props} />} />;
  }
};

// const UnauthenticatedRoute = ({ component: C, ...props }) => {
//   const { isAuthenticated, isLoading } = useAuthState();
//   if (isLoading) {
//     return (
//       <Background className='home__background-wrapper'>
//         <div className='home__loader-wrapper'>
//           <CircleLoader />
//         </div>
//       </Background>
//     );
//   }
//   return (
//     <Route
//       {...props}
//       render={routeProps =>
//         !isAuthenticated ? <C {...routeProps} /> : <Redirect to='/test-home' />
//       }
//     />
//   );
// };

function loadMessages(locale) {
  switch (locale) {
    case 'de':
      return import('../translations/de.json');
    case 'en':
      return import('../translations/en.json');
    case 'it':
      return import('../translations/it.json');
    case 'fr':
      return import('../translations/fr.json');
    default:
      return import('../translations/en.json');
  }
}

const App = () => {
  ImportScript('https://www.google.com/recaptcha/api.js');
  const language = localStorage.getItem('hy-language');

  const [emulatorStarted, setEmulatorStarted] = useState(false);
  const [locale, setLocale] = useState(
    language && language !== 'null' && language !== 'undefined'
      ? language
      : 'en'
  );
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    loadMessages(locale).then(setMessages);
  }, [locale]);

  const changeLanguage = value => {
    setLocale(value);
    localStorage.setItem('hy-language', value);
  };

  return (
    <AuthContextProvider>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <Router>
            {/* {showMyHealthHeader && <MyHealthHeader />} */}
            <Switch>
              <Route exact={true} path='/test/playground'>
                <Playground />
              </Route>
              <Route exact={true} path='/action'>
                <Action />
              </Route>
              <Route exact={true} path='/auth'>
                <Auth />
              </Route>
              <AuthenticatedRoute
                exact={true}
                path='/test-home'
                component={TestHome}
              />
              <Route exact={true} path='/reset-password'>
                <ResetPassword lang={locale} changeLanguage={changeLanguage} />
              </Route>
              <Route exact={true} path='/error'>
                <Error />
              </Route>
              <Route exact={true} path='/phone-registration'>
                <PhoneRegistration
                  lang={locale}
                  changeLanguage={changeLanguage}
                />
              </Route>
              <SecureRoute
                exact={true}
                path='/authorization'
                emulatorStarted={emulatorStarted}
                setEmulatorStarted={setEmulatorStarted}
                component={Authorization}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              <SecureRoute
                exact={true}
                path='/log-in'
                emulatorStarted={emulatorStarted}
                setEmulatorStarted={setEmulatorStarted}
                component={Authorization}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              <Route exact={true} path='/mytests'>
                <Redirect to='/myhealth' />
              </Route>
              <AuthenticatedRoute
                exact={true}
                path='/myhealth'
                component={MyHealth}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              <AuthenticatedRoute
                exact={true}
                path='/myhealth/products'
                component={MyHealthProducts}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              <AuthenticatedRoute
                exact={true}
                path='/myhealth/employee-health'
                component={MyHealthEmployeeHealth}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              <AuthenticatedRoute
                exact={true}
                path='/myhealth/shop'
                component={MyHealthShop}
                lang={locale}
                changeLanguage={changeLanguage}
              />
              {/* <AuthenticatedRoute
                exact={true}
                path='/myhealth'
                component={Dashboard}
              /> */}
              <AuthenticatedRoute
                exact={true}
                path='/test-results/:testCode'
                component={TestResults}
              />
              <Route exact={true} path='/test-results'>
                <Redirect to='/error' />
              </Route>
              <Route exact={true} path='/:conversationId?'>
                <Home />
              </Route>
              <Route exact={true} path='/'>
                <Redirect to='/myhealth' />
              </Route>
            </Switch>
          </Router>
        </IntlProvider>
      </Provider>
    </AuthContextProvider>
  );
};

export default App;
