import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import { Typography } from '../Typography';

export interface DividerProps {
  title: React.ReactNode;
  className: string;
  lineClassName: string;
}

const Divider: React.FC<DividerProps> = ({
  title,
  className,
  lineClassName
}) => {
  return (
    <div
      className={cx(
        { [styles.wrapper]: true },
        className && { [className]: true }
      )}
    >
      <Typography element='p' variant='overline' className={styles.title}>
        {title}
      </Typography>
      <div
        className={cx(
          { [styles.line]: true },
          lineClassName && { [lineClassName]: true }
        )}
      ></div>
    </div>
  );
};

export default Divider;
