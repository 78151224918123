import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Background = ({ children, className }) => {
  return (
    <div className={cx('background', className)}>
      <div className='background__background'>
        <div className='background__elipse-wrapper'>
          <div className='background__elipse' />
        </div>
        {children}
      </div>
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default Background;
