import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as HighRisk } from '../../../assets/svg/high-risk.svg';
import { ReactComponent as MediumRisk } from '../../../assets/svg/medium-risk.svg';
import { ReactComponent as LowRisk } from '../../../assets/svg/low-risk.svg';
import InfoIcon from '@material-ui/icons/Info';

import { riskLevel } from '../../constants/data';
import { firstLetterCapital } from '../../utils/helpers';

import './index.scss';

const CARD_IMAGE = {
  [riskLevel.LOW]: LowRisk,
  [riskLevel.HIGH]: HighRisk,
  [riskLevel.MEDIUM]: MediumRisk
};

const renderRiskList = data => {
  const { level, risks } = data;
  const title = `${firstLetterCapital(level)} risk`;
  const classes = cx('risk-card__list-wrapper', `risk-card__list-wrapper--${level}`);

  return (
    <div className={classes}>
      <span className='risk-card__list-title'>{title}</span>
      <ul className='risk-card__list'>
        {risks.map(risk => {
          return (
            <li className='risk-card__list-item' key={risk.titleText}>
              {risk.titleText}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const RiskCard = ({ data, aggregated }) => {
  const { level, titleText, iconUrl, link } = data;
  const Image = CARD_IMAGE[level];

  return (
    <div className='risk-card'>
      {aggregated ? (
        <Fragment>
          <Image className='risk-card__icon' />
          {renderRiskList(data)}
        </Fragment>
      ) : (
        <div className='risk-card__content'>
          <div className='risk-card__image-wrapper'>
            <img src={iconUrl} alt='risk' className='risk-card__image' />
          </div>
          <span className='risk-card__title'>
            {titleText}
            <a href={link} className='risk-card__info' target='_blank' rel='noopener noreferrer'>
              <InfoIcon className='risk-card__icon' />
            </a>
          </span>
        </div>
      )}
    </div>
  );
};

RiskCard.propTypes = {
  data: PropTypes.object,
  aggregated: PropTypes.bool
};

export default RiskCard;
