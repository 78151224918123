import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const FormText = ({ text, defaultText = '', values, className }) => {
  return (
    <div className={cx('form-text', className)}>
      <span className='form-text__text'>
        <FormattedMessage
          id={text}
          defaultMessage={defaultText}
          values={values}
        />
      </span>
    </div>
  );
};

FormText.propTypes = {
  text: PropTypes.string,
  defaultText: PropTypes.string,
  values: PropTypes.any,
  className: PropTypes.string
};

export default FormText;
