import { useEffect, useState } from 'react';

export default function useSetSticky(scrollPositionY, stickyPosition) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!isSticky && scrollPositionY > stickyPosition) {
      setIsSticky(true);
    }

    if (isSticky && scrollPositionY <= stickyPosition) {
      setIsSticky(false);
    }
  }, [isSticky, scrollPositionY, stickyPosition]);

  return isSticky;
}
