import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import FormTitle from '../FormWrapper/FormTitle';
import FormTextWithChildren from '../FormWrapper/FormTextWithChildren';
import { FormattedMessage } from 'react-intl';
import FormButton from '../FormWrapper/FormButton';
import Input from '../Input';
import { PATHS } from '../../constants/paths';

import emailIcon from '../../../assets/svg/simple-email-icon.svg';

import './index.scss';

const checkIfEmailIsValid = email => {
  return /\S+@\S+\.\S+/.test(email);
};

const EmailForm = ({ email, setEmail, checkIfUserExist, isLoading, intl }) => {
  const location = useLocation();
  const [error, setError] = useState(false);
  const emailInputLabel = intl.formatMessage({ id: 'auth.email-input-label' });
  const isJustLogin = location.pathname === PATHS.LOGIN;

  const handleEmailChange = value => {
    if (error) {
      setError(false);
    }
    setEmail(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isEmailValid = checkIfEmailIsValid(email);

    if (isEmailValid) {
      checkIfUserExist(email);
    } else {
      setError(true);
    }
  };

  const getErrorMessage = () => {
    if (error) {
      return 'auth.invalid-email';
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle
        title='auth.sign-in-or-create-an-account'
        defaultTitle='Sign in or create an HealthYourself account'
      />
      <FormTextWithChildren>
        {isJustLogin ? (
          <FormattedMessage
            id='auth.enter-email-explanation-just-login'
            defaultMessage='Please enter email that you are using or you will use'
          />
        ) : (
          <FormattedMessage
            id='auth.enter-email-explanation'
            defaultMessage='Please enter email that you are using or you will use'
          />
        )}
      </FormTextWithChildren>
      <Input
        type='email'
        placeholder='name@mail.com'
        value={email}
        onChange={e => handleEmailChange(e.target.value)}
        icon={emailIcon}
        name='email'
        label={emailInputLabel}
        error={error ? getErrorMessage() : null}
        autoFocus
      />
      <div className='email-form__buttons-wrapper'>
        <FormButton
          text='auth.continue-with-email'
          defaultTitle='Continue'
          isLoading={isLoading}
          submit
        />
      </div>
    </form>
  );
};

EmailForm.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  checkIfUserExist: PropTypes.func,
  isLoading: PropTypes.bool,
  intl: PropTypes.any,
  uknownUserError: PropTypes.bool
};

export default EmailForm;
