import React from 'react';
import cx from 'classnames';
import { Body2, Heading5, Subtitle2 } from '../../Typography';
import { ReactComponent as ArrowMoreIcon } from '../../../assets/svg/arrow-more.svg';

import styles from './index.module.scss';

interface MyHealthCardContentProps {
  eyebrow: string;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  background?: string;
  className?: string;
}

const MyHealthCardContent: React.FC<MyHealthCardContentProps> = ({
  eyebrow,
  icon,
  title,
  description,
  background,
  className
}) => {
  // Checking if icon is URL or React component
  const isIconUrl = typeof icon === 'string';
  return (
    <div
      className={cx(
        { [styles.content]: true },
        className && { [className]: true }
      )}
      style={{ background: background }}
    >
      <div className={styles.eyebrowWrapper}>
        <Subtitle2 className={styles.eyebrow}>{eyebrow}</Subtitle2>

        <div className={styles.arrow}>{<ArrowMoreIcon />}</div>
      </div>

      <div className={styles.titleWrapper}>
        {icon && !isIconUrl && <div className={styles.icon}>{icon}</div>}
        {icon && isIconUrl && (
          <img src={icon} alt='company logo' className={styles.icon} />
        )}
        <Heading5>{title}</Heading5>
        {description && <Body2>{description}</Body2>}
      </div>
    </div>
  );
};

export default MyHealthCardContent;
