export const DOWNLOAD_REPORT_START = 'DOWNLOAD_REPORT_START';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';
export const DOWNLOAD_REPORT_CLEAR = 'DOWNLOAD_REPORT_CLEAR'

export const downloadReport = (documentType, documentId) => ({
  type: DOWNLOAD_REPORT_START,
  payload: {
    documentType,
    documentId
  }
});

export const clearDownloadReport = () => ({
  type: DOWNLOAD_REPORT_CLEAR
});

