import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/checked-3D.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close-icon.svg';
import { Heading3, Heading5 } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const OrderTestModalContentCompleted = ({ onClose }) => {
  return (
    <div className='order-test-modal-content-completed'>
      <div className='order-test-modal-content-completed__text-wrapper'>
        <CheckedIcon />
        <Heading3>
          <FormattedMessage
            id='my-health.all-done'
            defaultMessage='All done!'
          />
        </Heading3>
        <Heading5 className='order-test-modal-content-completed__description'>
          <FormattedMessage
            id='my-health.test-has-been-ordered'
            defaultMessage='Your test has been ordered and will usually be delivered to you within 1-2 working days.'
          />
        </Heading5>
      </div>
      <button
        onClick={onClose}
        className='order-test-modal-content-completed__close-button'
      >
        <CloseIcon />
      </button>
    </div>
  );
};

OrderTestModalContentCompleted.propTypes = {
  onClose: PropTypes.func
};

export default OrderTestModalContentCompleted;
