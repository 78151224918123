import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../CircleLoader';
import { submitAnswer } from '../../actions/data';

import './index.scss';
import { directions } from '../../constants/data';

const LoaderScreen = ({ 
  data, 
  userLoading, 
  userData, 
  language, 
  conversationId, 
  conversationFlowStateKey,
  dispatch,
  setAnimationDirection
}) => {
  const { text, image } = data.loader;
  const { userId } = userData;

  useEffect(() => {
    const answerData = {
      action: "next",
      conversationFlowStateKey,
      conversationId,
      userId,
    };

    const timeout = setTimeout(() => {
      setAnimationDirection(directions.FORWARD);
      dispatch(submitAnswer(answerData));
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [conversationFlowStateKey, conversationId, dispatch, setAnimationDirection, userId]);

  if (userLoading) {
    return (
      <div className='loader-screen'>
        <div className='loader-screen__content-wrapper'>
          <div className='loader-screen__loader'><CircleLoader /></div>
        </div>
      </div>
    );
  }

  return (
    <div className='loader-screen'>
      <div className='loader-screen__content-wrapper'>
        <div className='loader-screen__content'>
          <div className='loader-screen__image-wrapper'>
            <img className='loader-screen__image' src={image} alt='supplements loader' />
          </div>
          <span className='loader-screen__text'>{text}</span>
        </div>
      </div>
    </div>
  );
};

LoaderScreen.propTypes = {
  data: PropTypes.object,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  language: PropTypes.string,
  conversationId: PropTypes.string,
  conversationFlowStateKey: PropTypes.string,
  dispatch: PropTypes.func,
  setAnimationDirection: PropTypes.func
};

export default LoaderScreen;
