import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';
import cx from 'classnames';

import RecommendationCard from '../RecommendationCard';
import Carousel from '../Carousel';

import './index.scss';
import {getSiteHref} from "../../utils/helpers";

/**
 * Function for generating href for "Add to basket" button
 * Example: https://yourself.health/cart/add?id[]=29227106861142&id[]=29227105222742
 * @param {array} items
 * @param {string} chosenTest
 * @param cartUrl root of cart url, in the example above https://yourself.health/
 */
const getBasketButtonHref = (items, chosenTest, cartUrl) => {
  const chosenTestData = items.find(item => item.key === chosenTest);
  let redirectUrl = `${cartUrl}cart/add?`;

  if (chosenTest) {
    for (let i = 0; chosenTestData.products.length > i; i++) {
      const productID = chosenTestData.products[i].id;
      const productString = i === 0 ? `id[]=${productID}` : `&id[]=${productID}`;
      redirectUrl += productString;
    }
    // redirectUrl += "&gift_card=1234"
  } else {
    redirectUrl = null;
  }

  return redirectUrl;
};

const getCheckoutButtonHref = (items, chosenTest, cartUrl) => {
  const chosenTestData = items.find(item => item.key === chosenTest);
  const prescriptionId = localStorage.getItem('prescriptionId');
  let redirectUrl = `${cartUrl}cart/add?`;

  if (chosenTest) {
    if (chosenTestData.type === 'hayfever') { 
      for(let i = 0; i < chosenTestData.products.length; i++) {
        const productString = i === 0 ? `id[]=${chosenTestData.products[i].id}` :
        `&id[]=${chosenTestData.products[i].id}`;
        redirectUrl += productString; 
      }

      return `${redirectUrl}&attributes[prescription-id]=${prescriptionId}&attributes[prescription-type]=hayfever`;
    } else {
      const productString = `id[]=${chosenTestData.id}`;
      redirectUrl += productString;

      if (chosenTestData.addDoctorsCall) {
        redirectUrl += '&id[]=39541168635990';
      }

      return `${redirectUrl}&attributes[prescription-id]=${prescriptionId}&&attributes[prescription-type]=contraception&attributes[subscription-size-in-months]=${chosenTestData.subscriptionSizeInMonths}`;
    }
  } else {
    redirectUrl = null;
    return redirectUrl;
  }
}

const renderRecommendationCards = (items, chosenTest, cardClick, openModal, kind) => {
  return items.map((item, index) => {
    return (
      <RecommendationCard
        key={`${index}-${item.key}`}
        data={item}
        chosenTest={chosenTest}
        cardClick={cardClick}
        isNotButton={item.key === 'unsupported'}
        openModal={openModal}
        numberOfCards={items.length}
        kind={kind}
      />
    );
  });
};
const handleInfoButtonClick = (setIsModalOpen, setSelectedTest) => selectedTest => {
  setIsModalOpen(true);
  setSelectedTest(selectedTest);
};

const getRecommendedCardIndex = cardsList => {
  const recommendedCardIndex = cardsList.findIndex(card => card.key === 'recommended');
  // In case when we don't have recommended card we are returning 0
  return recommendedCardIndex === -1 ? 0 : recommendedCardIndex;
};

const Recommendation = ({
  data,
  breakpoint,
  setIsModalOpen,
  setRecommendedModalData,
  setSelectedTest,
  setBasketButtonHref,
  flowCountry
}) => {
  const [chosenTest, setChosenTest] = useState(null);
  const items = data.productBundleRecommendation.productBundles;
  const kind = data.kind;

  setRecommendedModalData(items);
  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);
  const isSmall = ['xsmall', 'small'].includes(breakpoint);

  const handleCardClick = (setChosenTest, setBasketButtonHref, items, cartUrl) => chosenTest => {
    setChosenTest(chosenTest);
    const href = data.kind === 'checkout' ? getCheckoutButtonHref(items, chosenTest, cartUrl) : getBasketButtonHref(items, chosenTest, cartUrl);
    setBasketButtonHref(href);
  };

  const contentClasses = cx({
    recommendation__content: true,
    'recommendation__content--less-then-three': items.length < 3
  });
  const siteHref = getSiteHref(flowCountry);

  return (
    <div className='recommendation'>
      {isSmallOrMedium ? (
        <Carousel
          centerPadding={15}
          initialSlide={getRecommendedCardIndex(items)}
          breakpoint={breakpoint}
          key='recommended tests'
          variableWidth={isSmall}
        >
          {renderRecommendationCards(
            items,
            chosenTest,
            handleCardClick(setChosenTest, setBasketButtonHref, items, siteHref),
            handleInfoButtonClick(setIsModalOpen, setSelectedTest),
            kind
          )}
        </Carousel>
      ) : (
        <div className={contentClasses}>
          {renderRecommendationCards(
            items,
            chosenTest,
            handleCardClick(setChosenTest, setBasketButtonHref, items, siteHref),
            handleInfoButtonClick(setIsModalOpen, setSelectedTest),
            kind
          )}
        </div>
      )}
    </div>
  );
};

Recommendation.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string,
  setIsModalOpen: PropTypes.func,
  setRecommendedModalData: PropTypes.func,
  setSelectedTest: PropTypes.func,
  setBasketButtonHref: PropTypes.func,
  flowCountry: PropTypes.string
};

export default withWindow(Recommendation);
