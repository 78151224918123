import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import Background from '../../components/Background';

import './index.scss';

import messages_de from '../../../translations/de.json';
import messages_en from '../../../translations/en.json';
import messages_it from '../../../translations/it.json';
import messages_fr from '../../../translations/fr.json';

const messages = {
  de: messages_de,
  en: messages_en,
  it: messages_it,
  fr: messages_fr
};

const Error = () => {
  const language = localStorage.getItem('hy-language') || 'en';
  const siteHref = 'https://yourself.health/';

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className='error'>
        <Background>
          <div className='error__header'>
            <a className='error__logo-wrapper' href={siteHref}>
              <Logo className='error__logo' />
            </a>
          </div>
          <div className='error__text'>
            <FormattedMessage id='error.contact-text' defaultMessage="It's not you, it's us. Please contact our " />{' '}
            <a href='https://yourself.health/pages/service'>
              <FormattedMessage id='error.customer-service' defaultMessage='Customer service' />
            </a>
          </div>
        </Background>
      </div>
    </IntlProvider>
  );
};

Error.propTypes = {
  language: PropTypes.string
};

const mapStateToProps = state => ({
  language: state.app.get('language')
});

export default connect(mapStateToProps)(Error);
