import React from 'react';
import PropTypes from 'prop-types';
import ContentCard from '../../../components/ContentCard';

import './index.scss';

const EmployeeHealthProgramContentCards = ({ contentCards, handleInfo }) => {
  return (
    <div className='employee-health-program-content-cards'>
      {contentCards.map((card, index) => {
        return <ContentCard key={index} data={card} handleInfo={handleInfo} />;
      })}
    </div>
  );
};

EmployeeHealthProgramContentCards.propTypes = {
  contentCards: PropTypes.array,
  handleInfo: PropTypes.func
};

export default EmployeeHealthProgramContentCards;
