import React from 'react';
import { Body2 } from '../Typography';

import styles from './index.module.scss';

export interface BottomDrawerTextProps {
  title: React.ReactNode;
  text: string;
  icon: React.ReactNode;
}

const BottomDrawerText: React.FC<BottomDrawerTextProps> = ({
  title,
  text,
  icon
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        {icon}
        <Body2 className={styles.text}>{title}</Body2>
      </div>
      <Body2 className={styles.text}>{text}</Body2>
    </div>
  );
};

export default BottomDrawerText;
