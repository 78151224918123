import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import Markdown from 'react-markdown';

import SelectIndicator from '../SelectIndicator';
import PriceTag from '../PriceTag';
import { questionsType } from '../../constants/data';

import './index.scss';

const handleClick = (handleAnswerClick, answerData, buttonRef) => () => {
  handleAnswerClick(answerData.key);
  // We are removing focus from button so our enter click works (defined in actionsBar)
  buttonRef.current.blur();
};

const renderModalContent = answerData => {
  return (
    <div className='answer-card__modal-content'>
      {answerData.selectedIconUrl && (
        <div className='answer-card__modal-image'>
          <img src={answerData.selectedIconUrl} alt='card icon chosen' />
        </div>
      )}
      <span className='answer-card__modal-title'>{answerData.titleText}</span>
      <span className='asnwer-card__modal-description'>
        <Markdown>{answerData.description}</Markdown> 
      </span>
    </div>
  );
};

const AnswerCard = ({
  handleAnswerClick,
  answerData,
  currentAnswer,
  questionType,
  numberOfQuestions,
  handleInfoIconClick
}) => {
  const { priceSixMonths, price } = answerData;
  const cardHasTag = priceSixMonths || price;
  const buttonRef = useRef(null);
  const isChecked = currentAnswer.includes(answerData.key);
  const isSingleSelect = questionType === questionsType.SINGLE_SELECT;
  const modalContent = renderModalContent(answerData);
  const classes = cx({
    'answer-card': true,
    // TODO: merge with answer-card
    'asnwer-card--in-carousel': true,
    checked: isChecked
  });

  const cardIconClasses = cx({
    'answer-card__icon': true,
    'answer-card__icon--visible': isChecked
  });

  const iconWrapperClasses = cx({
    'answer-card__icon-wrapper': true,
    'answer-card__icon-wrapper--no-icon':
      !answerData.iconUrl || !answerData.selectedIconUrl
  });

  const answerCardContent = cx({
    'answer-card__content': true,
    'answer-card__content--has-tag': cardHasTag
  });

  return (
    <div className={classes}>
      {cardHasTag && <PriceTag data={answerData} />}
      {answerData.description && (
        <button
          onClick={() => {
            handleInfoIconClick(modalContent);
          }}
          className='answer-card__info'
          target='_blank'
          rel='noopener noreferrer'
        >
          <InfoIcon className='answer-card__info-icon' />
        </button>
      )}
      <button
        ref={buttonRef}
        className='answer-card__wrapper'
        type='button'
        onClick={handleClick(handleAnswerClick, answerData, buttonRef)}
      >
        <span className={answerCardContent}>
          <span className={iconWrapperClasses}>
            {answerData.iconUrl && answerData.selectedIconUrl && (
              <>
                <img src={answerData.iconUrl} alt='card icon' />
                <img
                  src={answerData.selectedIconUrl}
                  alt='card icon chosen'
                  className={cardIconClasses}
                />
              </>
            )}
          </span>
          <span className='answer-card__title'>
            <Markdown>{answerData.titleText}</Markdown>
          </span>
          <SelectIndicator isChecked={isChecked} isSquare={!isSingleSelect} />
        </span>
      </button>
    </div>
  );
};

AnswerCard.propTypes = {
  handleAnswerClick: PropTypes.func,
  answerData: PropTypes.object,
  currentAnswer: PropTypes.any,
  questionType: PropTypes.string,
  numberOfQuestions: PropTypes.number,
  handleInfoIconClick: PropTypes.func
};

export default AnswerCard;
