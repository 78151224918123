import { Map } from 'immutable';

import {
  GET_TEST_RESULT_SUCCESS,
  GET_TEST_RESULT_ERROR,
  GET_TEST_RESULT_START,
  MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS,
  MARK_RECOMMENDED_ACTION_AS_DONE_ERROR,
  MARK_RECOMMENDED_ACTION_AS_DONE_START
} from '../actions/testResult';

const initialState = Map({
  data: null,
  error: null,
  loading: null,
  markAsCompleteButtonLoading: null
});

const actionsMap = {
  [GET_TEST_RESULT_START]: state => {
    return state.merge(
      Map({
        error: null,
        loading: true
      })
    );
  },
  [GET_TEST_RESULT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        data: action.data,
        loading: false
      })
    );
  },
  [GET_TEST_RESULT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        error: action.error,
        loading: false
      })
    );
  },
  [MARK_RECOMMENDED_ACTION_AS_DONE_START]: state => {
    return state.merge(
      Map({
        error: null,
        markAsCompleteButtonLoading: true
      })
    );
  },
  [MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        data: action.data,
        markAsCompleteButtonLoading: false
      })
    );
  },
  [MARK_RECOMMENDED_ACTION_AS_DONE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        error: action.error,
        markAsCompleteButtonLoading: false
      })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
