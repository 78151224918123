import React from 'react';
import PropTypes from 'prop-types';

import TextWithBadge from '../../../components/TextWithBadge';
import { FormattedMessage } from 'react-intl';

const eligibilityStatuses = {
  true: (
    <FormattedMessage id='test-result.eligibility.yes' defaultMessage='Yes' />
  ),
  false: (
    <FormattedMessage id='test-result.eligibility.no' defaultMessage='No' />
  )
};

const eligibilityStatusColors = {
  true: '#7C9179',
  false: '#C8585F'
};

const EligibilityPanel = ({ text, eligible }) => {
  const eligibilityStatus = eligibilityStatuses[eligible];
  const eligibilityColor = eligibilityStatusColors[eligible];
  return (
    <TextWithBadge
      text={text}
      badgeText={eligibilityStatus}
      badgeBackgroundColor={eligibilityColor}
    />
  );
};

EligibilityPanel.propTypes = {
  text: PropTypes.object,
  eligible: PropTypes.bool
};

export default EligibilityPanel;
