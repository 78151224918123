import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import FormTitle from '../FormWrapper/FormTitle';
import FormText from '../FormWrapper/FormText';
import FormButton from '../FormWrapper/FormButton';

import Input from '../Input';

import phoneIcon from '../../../assets/svg/phone-icon.svg';

import './index.scss';

const formatPhoneNumber = phone => {
  let result = [];

  // Extract only supported characters
  for (const letter of phone) {
    if ((letter <= '9' && letter >= '0') || letter === '+') {
      result.push(letter);
    }
  }

  // Replace 00 prefix by the standard + prefix
  if (result.slice(0, 2) === '00') {
    result = ['+', ...result.slice(2)];
  }

  // Add country code to local Swiss phone numbers. This functionality needs to be extended if we roll out to additional countries.
  if (result[0] === '0') {
    result = ['+', '4', '1', ...result.slice(1)];
  }

  // Validate and format the result based on standardized international phone number formatting
  if (isValidPhoneNumber(result.join(''))) {
    return parsePhoneNumber(result.join(''))
      .formatInternational()
      .split(' ')
      .join('');
  }

  // When no valid phone number was found, return null
  return null;
};

const PhoneNumberForm = ({ phone, setPhone, submitPhone, isLoading, intl }) => {
  const phoneNumberInputLabel = intl.formatMessage({
    id: 'auth.phone-number-input-label'
  });

  const [error, setError] = useState(false);

  const handleOnChange = value => {
    if (error) {
      setError(false);
    }
    setPhone(value);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const formatedPhoneNumber = formatPhoneNumber(phone);

    if (formatedPhoneNumber) {
      submitPhone(formatedPhoneNumber);
    } else {
      setError(true);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <FormTitle
        title='auth.enter-mobile-number'
        defaultTitle='Enter your mobile number'
      />
      <FormText
        text='auth.enter-mobile-explanation'
        defaultText='We will use this phone number to send a verification code every time you login. It will also be used to contact you when necessary. We will not send you spam or other unsolicited communication.'
      />
      <Input
        type='tel'
        placeholder='+41791234567'
        value={phone}
        label={phoneNumberInputLabel}
        onChange={e => handleOnChange(e.target.value)}
        icon={phoneIcon}
        name='Phone'
        error={error ? 'auth.enter-mobile-error' : null}
      />
      <div className='phone-number-form__buttons-wrapper'>
        <FormButton
          text='auth.send-code'
          defaultText='Send code'
          submit
          isLoading={isLoading}
          disabled={error}
        />
      </div>
    </form>
  );
};

PhoneNumberForm.propTypes = {
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  submitPhone: PropTypes.func,
  isLoading: PropTypes.bool,
  intl: PropTypes.any
};

export default PhoneNumberForm;
