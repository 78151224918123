import React from 'react';
import cx from 'classnames';
import PrescriptionCard, { PrescriptionCardStatus } from '../PrescriptionCard';
import TestCard, { TestCardStatus } from '../TestCard';

import styles from './index.module.scss';

interface Prescription {
  activationFlowUrl: string;
  iconUrl: string;
  lastUpdate: string;
  name: string;
  nextReorderDate: string;
  reorderUrl: string;
  status: string;
  type: string;
  customerName: string;
}

interface Test {
  activationFlowUrl: string;
  code: string;
  iconUrl: string;
  lastUpdate: string;
  personName: string;
  reorderDiscount: string;
  reorderUrl: string;
  samplingInstructionsUrl: string;
  status: string;
  testResultsUrl: string;
  type: string;
  testedPersonName: string;
  updateSamplingTimeUrl: string;
  testedPersonInitials: string;
  resultsViewed: boolean | null;
}

interface DashboardCardsListProps {
  prescriptions: Prescription[];
  tests: Test[];
  arePrescriptionsVisible: boolean;
  areTestsVisible: boolean;
}

const DashboardCardsList: React.FC<DashboardCardsListProps> = ({
  prescriptions,
  tests,
  arePrescriptionsVisible,
  areTestsVisible
}) => {
  return (
    <div className={styles.wrapper}>
      {areTestsVisible && (
        <div className={styles.cards}>
          {tests.map((test, index) => (
            <TestCard
              key={`t-${index}`}
              iconUrl={test.iconUrl}
              type={test.type}
              status={test.status as TestCardStatus}
              activationFlowUrl={test.activationFlowUrl}
              code={test.code}
              lastUpdate={test.lastUpdate}
              personName={test.personName}
              reorderUrl={test.reorderUrl}
              reorderDiscount={test.reorderDiscount}
              testResultsUrl={test.testResultsUrl}
              samplingInstructionsUrl={test.samplingInstructionsUrl}
              updateSamplingTimeUrl={test.updateSamplingTimeUrl}
              testedPersonName={test.testedPersonName}
              testedPersonInitials={test.testedPersonInitials}
              resultsViewed={test.resultsViewed}
            />
          ))}
        </div>
      )}

      {arePrescriptionsVisible && (
        <div
          className={cx({
            [styles.cards]: true,
            [styles.prescriptions]: true
          })}
        >
          {prescriptions.map((prescription, index) => (
            <PrescriptionCard
              key={`p-${index}`}
              iconUrl={prescription.iconUrl}
              name={prescription.name}
              status={prescription.status as PrescriptionCardStatus}
              activationFlowUrl={prescription.activationFlowUrl}
              reorderUrl={prescription.reorderUrl}
              lastUpdate={prescription.lastUpdate}
              nextReorderDate={prescription.nextReorderDate}
              customerName={prescription.customerName}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default DashboardCardsList;
