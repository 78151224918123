import React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '../../../components/Divider';
import InfoBlock from '../../../components/InfoBlock';

import './index.scss';

const StauffacherSupport = () => {
  return (
    <div className='stauffacher-support'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.partner-code.stauffacher-support'
            defaultMessage='Stauffacher support'
          />
        }
      />
      <div className='stauffacher-support__content'>
        <InfoBlock
          className='stauffacher-support__block'
          text={
            <FormattedMessage
              id='test-result.partner-code.stauffacher-support.text'
              defaultMessage='Supplements or additional advice needed?'
            />
          }
          description={
            <FormattedMessage
              id='test-result.partner-code.stauffacher-support.description'
              defaultMessage='The Stauffacher Apotheke is happy to provide you with additional advice on how to support your wellbeing with dietary changes or personalised supplements.{br}{br}Take a screenshot of this screen and show it at the pharmacy to receive an exclusive 10% discount for your personalised supplements.'
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </div>
  );
};

export default StauffacherSupport;
