import React, { useState } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonVariants } from '../../components/Button';

import { ReactComponent as CopyableIcon } from '../../assets/svg/copy-icon.svg';

import styles from './index.module.scss';

const TOOLTIP_TIMEOUT = 2000;

interface CopyToClipboardButtonProps {
  value: string;
  className?: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  value,
  className
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, TOOLTIP_TIMEOUT);
    });
  };

  return (
    <div className={cx(styles.wrapper, className)}>
      <Button
        onClick={handleCopy}
        variant={ButtonVariants.OUTLINED}
        className={styles.button}
      >
        <span className={styles.iconWrapper}>
          <CopyableIcon />
        </span>
        <span>
          <FormattedMessage
            id='my-health.copy-my-code'
            defaultMessage='Copy my code'
          />
        </span>
        <span>({value})</span>
      </Button>
      {tooltipOpen && (
        <div className={styles.tooltip}>
          <FormattedMessage id='my-health.copied' defaultMessage='Copied' />
        </div>
      )}
    </div>
  );
};
export default CopyToClipboardButton;
