import React from 'react';
import cx from 'classnames';
import Link from '../Link';
import { Caption, Subtitle2 } from '../Typography';
import { FormattedMessage } from 'react-intl';
import formatLastUpdateDate from '../../app/helpers/dateFormat';

import styles from './index.module.scss';

export type PrescriptionCardStatus =
  | 'in-progress'
  | 'doctor-review'
  | 'approved'
  | 'rejected'
  | 'unknown';

export const prescriptionStatusColors: Record<
  PrescriptionCardStatus,
  string
> = {
  'in-progress': '#FF6145',
  'doctor-review': '#FF6145',
  approved: '#7C9179',
  rejected: '#C8585F',
  unknown: '#6F6B72'
};

export interface PrescriptionCardProps {
  iconUrl: string;
  name: string;
  status: PrescriptionCardStatus;
  activationFlowUrl: string;
  lastUpdate: string;
  reorderUrl: string;
  nextReorderDate: string;
  customerName: string;
}

const PrescriptionCard: React.FC<PrescriptionCardProps> = ({
  iconUrl,
  name,
  status,
  activationFlowUrl,
  customerName,
  reorderUrl,
  nextReorderDate,
  lastUpdate
}) => {
  const statusColor = prescriptionStatusColors[status];

  const nextReorderDateSection = nextReorderDate ? (
    <>
      <Caption element='p' className={styles.prescriptionInfoLabel}>
        <FormattedMessage
          id='dashboard.reorder-date'
          defaultMessage='Reorder date: '
        />
      </Caption>
      <Caption element='p' className={styles.prescriptionInfoData}>
        {formatLastUpdateDate(nextReorderDate)}
      </Caption>
    </>
  ) : null;

  const lastUpdateSection = lastUpdate ? (
    <>
      <Caption element='p' className={styles.prescriptionInfoLabel}>
        <FormattedMessage
          id='dashboard.last-update'
          defaultMessage='Last update: '
        />
      </Caption>
      <Caption element='p' className={styles.prescriptionInfoData}>
        {formatLastUpdateDate(lastUpdate)}
      </Caption>
    </>
  ) : null;

  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [styles.reorderWrapper]: reorderUrl
      })}
    >
      <div
        className={cx({
          [styles.cardWrapper]: true,
          [styles.cardWrapperWithReorder]: reorderUrl
        })}
      >
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={iconUrl} alt='prescription' />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.textContentWrapper}>
            <div className={styles.titleWrapper}>
              <Subtitle2 className={styles.title}>{name}</Subtitle2>

              <div style={{ color: statusColor }}>
                <Caption element='p' className={styles.description}>
                  <FormattedMessage
                    id={`dashboard.prescription.status.${status}`}
                    defaultMessage='Undefined'
                  />
                </Caption>
              </div>
            </div>

            <div>
              {customerName && (
                <div className={styles.prescriptionInfoName}>
                  <Caption element='p' className={styles.prescriptionInfoLabel}>
                    <FormattedMessage
                      id='dashboard.customer'
                      defaultMessage='Customer: '
                    />
                  </Caption>
                  <Caption element='p' className={styles.prescriptionInfoData}>
                    {customerName}
                  </Caption>
                </div>
              )}

              {nextReorderDate ? nextReorderDateSection : lastUpdateSection}
            </div>
          </div>
          {reorderUrl && (
            <Link size='small' url={reorderUrl} className={styles.button}>
              <FormattedMessage
                id='dashboard.reorder'
                defaultMessage='Reorder'
              />
            </Link>
          )}
          {activationFlowUrl && (
            <Link
              size='small'
              variant='elevated'
              url={activationFlowUrl}
              className={styles.button}
            >
              <Caption element='p'>
                <FormattedMessage
                  id='dashboard.continue-activation'
                  defaultMessage='Continue activation'
                />
              </Caption>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCard;
