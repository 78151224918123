import React from 'react';
import { Caption } from '../Typography';
import styles from './index.module.scss';

export type PopUpType = {
  text: string;
  type: 'success' | 'error';
};

interface PopupProps {
  data: PopUpType;
}

const Popup: React.FC<PopupProps> = ({ data }) => {
  const { text, type } = data;

  const popupBackground = type === 'success' ? '#564494' : '#b3261e';

  return (
    <div style={{ background: popupBackground }} className={styles.text}>
      <Caption element='span'>{text}</Caption>
    </div>
  );
};

export default Popup;
