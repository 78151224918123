import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import styles from './index.module.scss';

export type OuterLinkProps = {
  variant?: 'primary' | 'outlined' | 'text' | 'elevated' | 'tonal';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  href: string;
  innerRef?: React.RefObject<HTMLAnchorElement>;
  className?: string;
};

const OuterLink: React.FC<OuterLinkProps> = ({
  variant = 'primary',
  size = 'normal',
  children,
  href,
  innerRef,
  className
}) => {
  const linkClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  return (
    <a
      className={cx(
        {
          [linkClassName]: true
        },
        className && { [className]: true }
      )}
      href={href}
      ref={innerRef}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
};

export default OuterLink;
