import React from 'react';
import PropTypes from 'prop-types';
import { useSwiper } from 'swiper/react';

import { NavigateBefore } from '@material-ui/icons';

import './index.scss';

const SliderPrevButton = ({ disabled }) => {
  const swiper = useSwiper();

  return (
    <button
      className='slider-prev-button'
      disabled={disabled}
      onClick={() => swiper.slidePrev()}
    >
      <NavigateBefore />
    </button>
  );
};

SliderPrevButton.propTypes = {
  disabled: PropTypes.bool
};

export default SliderPrevButton;
