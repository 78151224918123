import React, { useEffect, useRef, useState } from 'react';
import {
  addNoScroll,
  removeNoScroll
} from '../../app/helpers/disableScrolling';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-icon.svg';
import cx from 'classnames';

import styles from './index.module.scss';
import useClickOutside from '../../app/hooks/useClickOutside';

export interface SideDrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  className: string;
}

const SideDrawer: React.FC<SideDrawerProps> = ({
  isOpen = false,
  children,
  onClick,
  className
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useClickOutside(drawerRef, () => {
    if (isModalOpen) {
      setIsModalVisible(false);
      setTimeout(() => {
        onClick(); // Update the parent state to close the modal
      }, 200);
    }
  });

  useEffect(() => {
    if (isOpen && !isModalOpen) {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalVisible(true);
      }, 50);
      addNoScroll();
    }

    if (!isOpen && isModalOpen) {
      setIsModalVisible(false);
      setTimeout(() => {
        setIsModalOpen(false);
        removeNoScroll();
      }, 200);
    }

    return () => {
      if (isModalOpen) {
        removeNoScroll();
      }
    };
  }, [isOpen, isModalOpen]);

  return (
    <div
      className={cx(
        {
          [styles.container]: true,
          [styles.open]: isModalOpen
        },
        className && { [className]: true }
      )}
    >
      <div
        ref={drawerRef}
        className={cx({
          [styles.wrapper]: true,
          [styles.transform]: isModalVisible
        })}
      >
        <div className={styles.drawerContent}>{children}</div>
      </div>
      <CloseIcon
        className={cx({
          [styles.icon]: true,
          [styles.transform]: isModalVisible
        })}
        onClick={onClick}
      />
    </div>
  );
};

export default SideDrawer;
