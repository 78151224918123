import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTestResults } from '../../actions/testResult';
import { getDashboardData } from '../../actions/dashboard';

import TestResultsContent from '../../components/TestResultsContent';
import TestResultsImmunotherapy from '../../components/TestResultsImmunotherapy';
import TestResultsMainHeader from '../../../components/TestResultsMainHeader';

import './index.scss';

import messages_de from '../../../translations/de.json';
import messages_en from '../../../translations/en.json';
import messages_it from '../../../translations/it.json';
import messages_fr from '../../../translations/fr.json';
import useLanguage from '../../hooks/useLanguage';

const messages = {
  de: messages_de,
  en: messages_en,
  it: messages_it,
  fr: messages_fr
};

const TestResults = ({ dispatch, data, error, loading }) => {
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  const { testCode } = useParams();

  useEffect(() => {
    if (currentLanguage === 'it' || currentLanguage === 'fr') {
      changeLanguage('en');
    } else {
      dispatch(getTestResults(testCode, currentLanguage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testCode]);

  // If this test results were never viewed, we need to get the fresh dashboard data
  // because we need number of not viewed results for bubble in the header
  useEffect(() => {
    if (data && !data.resultsViewed) {
      dispatch(getDashboardData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const changeLanguage = language => {
    localStorage.setItem('hy-language', language);
    setCurrentLanguage(language);
    dispatch(getTestResults(testCode, language));
  };

  if (loading) return <div className='test-results__loader'></div>;

  return (
    <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
      <div className='test-results'>
        <TestResultsMainHeader
          availableLanguages={['en', 'de']}
          defaultLanguage={currentLanguage}
          onChangeLanguage={changeLanguage}
          dispatch={dispatch}
          className='test-results__header'
          logoClassName='test-results__logo'
        />
        {data && data.type !== 'allergyCheckForImmunotherapy' && !error && (
          <TestResultsContent data={data} language={currentLanguage} />
        )}

        {data && data.type === 'allergyCheckForImmunotherapy' && !error && (
          <TestResultsImmunotherapy data={data} language={currentLanguage} />
        )}

        {/*We have older version, so I commented this new one out, so it won't overlap with older version */}
        {/* {error && <ContactService />} */}
      </div>
    </IntlProvider>
  );
};

TestResults.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = state => ({
  data: state.testResult.get('data'),
  error: state.testResult.get('error'),
  loading: state.testResult.get('loading')
});

export default connect(mapStateToProps)(TestResults);
