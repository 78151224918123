import React, { ReactNode } from 'react';
import Input, { InputProps } from '../RegularInput/index';
import styles from './InputWithIcon.module.scss';

export interface InputWithIconProps extends InputProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const InputWithIcon: React.FC<InputWithIconProps> = ({
  leftIcon,
  rightIcon,
  disabled,
  label,
  error,
  ...inputProps
}) => {
  const hasLeftIcon = !!leftIcon;
  const hasRightIcon = !!rightIcon;

  return (
    <div
      className={`${styles.wrapper} ${
        hasRightIcon ? styles.padding_right : ''
      } ${hasLeftIcon ? styles.padding_left : ''}`}
    >
      <Input
        {...inputProps}
        className={styles.input}
        disabled={disabled}
        label={label}
        error={error}
      />

      {leftIcon && (
        <div
          className={`${styles.icon} ${styles.left_icon} ${
            disabled ? styles.disabled : ''
          } ${label ? styles.with_label : ''} ${
            error ? styles.with_error : ''
          }`}
        >
          {leftIcon}
        </div>
      )}
      {rightIcon && (
        <div
          className={`${styles.icon} ${styles.right_icon} ${
            disabled ? styles.disabled : ''
          } ${label ? styles.with_label : ''} ${
            error ? styles.with_error : ''
          }`}
        >
          {rightIcon}
        </div>
      )}
    </div>
  );
};

export default InputWithIcon;
