import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const RecommendedActionsCheckbox = ({ done }) => {
  return (
    <input
      className='recommended-actions-checkbox'
      type='checkbox'
      checked={done}
      readOnly={true}
    />
  );
};

RecommendedActionsCheckbox.propTypes = {
  done: PropTypes.bool
};

export default RecommendedActionsCheckbox;
