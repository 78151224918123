import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const TestResultInfo = ({ data }) => {
  const { colour, measuredValue, scoreTitleText, text, titleText } = data;
  const circleClasses = cx({
    'test-result-info__circle': true,
    'test-result-info__circle--white': colour.toLowerCase() === '#ffffff'
  });

  const numberClasses = cx({
    'test-result-info__top-text': true,
    'test-result-info__top-text--three': measuredValue.toString().length === 3,
    'test-result-info__top-text--four': measuredValue.toString().length === 4,
    'test-result-info__top-text--five': measuredValue.toString().length === 5,
    'test-result-info__top-text--six': measuredValue.toString().length === 6,
    'test-result-info__top-text--seven': measuredValue.toString().length === 7,
    'test-result-info__top-text--eight': measuredValue.toString().length === 8
  });

  return (
    <div className='test-result-info'>
      <span className='test-result-info__title'>{titleText}</span>
      <div className='test-result-info__content'>
        <div className='test-result-info__text'>{text}</div>
        <div className={circleClasses} style={{ backgroundColor: colour }}>
          <span className={numberClasses}>{measuredValue}</span>
          <span className='test-result-info__bottom-text'>{scoreTitleText}</span>
        </div>
      </div>
    </div>
  );
};

TestResultInfo.propTypes = {
  data: PropTypes.object
};

export default TestResultInfo;
