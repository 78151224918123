import React from 'react';
import InnerLink from '../InnerLink';
import OuterLink from '../OuterLink';
import extractPathFromUrl from '../../app/helpers/extractPathFromUrl.js';

const HEALTH_YOURSELF_URL = process.env.REACT_APP_SECURE_APP_URL;

interface LinkProps {
  url: string;
  variant?: 'primary' | 'outlined' | 'text' | 'elevated' | 'tonal';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  className?: string;
}

const Link: React.FC<LinkProps> = ({
  url,
  variant,
  size,
  children,
  className
}) => {
  const isInnerUrl =
    (HEALTH_YOURSELF_URL && url.includes(HEALTH_YOURSELF_URL)) ||
    url.startsWith('/');

  const path = extractPathFromUrl(url);

  return (
    <>
      {isInnerUrl ? (
        <InnerLink
          to={path}
          variant={variant}
          size={size}
          className={className}
        >
          {children}
        </InnerLink>
      ) : (
        <OuterLink
          href={url}
          variant={variant}
          size={size}
          className={className}
        >
          {children}
        </OuterLink>
      )}
    </>
  );
};

export default Link;
