import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TestResultInfo from '../TestResultInfo';
import AccordionsWrapper from '../../AccordionsWrapper';
import Accordion from '../../Accordion';

import './index.scss';

const ResultsBlock = ({ areResultsGrouped, results }) => {
  let resultsList = results;
  if (areResultsGrouped) {
    const groupsWithDetectedReaction = results.filter(
      ({ isReactionDetected }) => isReactionDetected
    );
    const groupsWithoutDetectedReaction = results.filter(
      ({ isReactionDetected }) => !isReactionDetected
    );
    resultsList = [
      ...groupsWithDetectedReaction,
      ...groupsWithoutDetectedReaction
    ];
  }
  return (
    <>
      <div className='results-block__lab-results'>
        <FormattedMessage
          id='test-result.lab-results'
          defaultMessage='Lab results'
        />
      </div>
      {areResultsGrouped && (
        <AccordionsWrapper>
          {resultsList.map((resultsGroup, index) => {
            const { isReactionDetected } = resultsGroup;
            const subtitle = isReactionDetected && (
              <FormattedMessage
                id='test-result.reaction-detected'
                defaultMessage='(Reaction detected)'
              />
            );

            return (
              <Accordion
                key={index}
                className='test-result__results-group'
                contentClassNameOpen='test-result__results-group-content--open'
                data={resultsGroup}
                isFirstOpen={index === 0}
                subtitle={subtitle}
              >
                {resultsGroup.items.map((test, index) => {
                  return <TestResultInfo key={index} data={test} />;
                })}
              </Accordion>
            );
          })}
        </AccordionsWrapper>
      )}
      {!areResultsGrouped &&
        results.map((test, index) => (
          <TestResultInfo key={index} data={test} />
        ))}
    </>
  );
};

ResultsBlock.propTypes = {
  areResultsGrouped: PropTypes.bool,
  results: PropTypes.array
};

export default ResultsBlock;
