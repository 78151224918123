import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

import '../YearPicker/index.scss';

const MONTHS = [
  { name: 'Jan', value: 1 },
  { name: 'Feb', value: 2 },
  { name: 'Mar', value: 3 },
  { name: 'Apr', value: 4 },
  { name: 'May', value: 5 },
  { name: 'Jun', value: 6 },
  { name: 'July', value: 7 },
  { name: 'Aug', value: 8 },
  { name: 'Sep', value: 9 },
  { name: 'Oct', value: 10 },
  { name: 'Nov', value: 11 },
  { name: 'Dec', value: 12 }
];

const MonthPicker = ({
  chosenYear,
  chosenMonth,
  setChosenMonth,
  setIsOpen,
  isOpen
}) => {
  useEffect(() => {
    setIsOpen(false);
  }, [chosenMonth, setIsOpen]);

  const getCurrentItems = () => {
    return MONTHS.map(item => {
      const buttonSelectorClass = cx({
        'picker__button-selector': true,
        'picker__button-selector--selected': chosenMonth.value === item.value
      });

      return (
        <button
          className='picker__button'
          key={item.name}
          onClick={() => setChosenMonth(item)}
        >
          <span className={buttonSelectorClass}>{item.name}</span>
        </button>
      );
    });
  };

  const pickerTextClasses = cx({
    picker__text: true,
    'picker__text--black': chosenMonth.value
  });

  const arrowDownClasses = cx({
    'picker__arrow-down': true,
    'picker__arrow-down--disabled': !chosenYear
  });

  const pickerClasses = cx({
    picker: true,
    'picker--disabled': !chosenYear
  });

  return (
    <div
      className={pickerClasses}
      onClick={chosenYear ? () => setIsOpen(true) : null}
    >
      <span className={pickerTextClasses}>
        {chosenMonth.name ?? (
          <FormattedMessage
            id='date-picker.enter-month'
            defaultMessage='Enter Month'
          />
        )}
      </span>
      <div className={arrowDownClasses} />
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div className='picker__list'>
            <div className='picker__month-list-header'>
              <span className='picker__current-values'>{chosenYear}</span>
            </div>
            <div className='picker__list-items'>{getCurrentItems()}</div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

MonthPicker.propTypes = {
  chosenYear: PropTypes.number,
  chosenMonth: PropTypes.object,
  setChosenMonth: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool
};

export default MonthPicker;
