import React from 'react';
import PropTypes from 'prop-types';
import { Body2 } from '../../../../components/Typography';
import EmployeeHealthProgramContentCards from '../../EmployeeHealthProgramContentCards';
import EmployeeHealthProgramProductCards from '../../EmployeeHealthProgramProductCards';
import EmployeeHealthProgramFooterCTA from '../../EmployeeHealthProgramFooterCTA';
// TODO: remove it from here
import InfoButton from '../../InfoButton';
import LearnMoreButton from '../../LearnMoreButton';

import './index.scss';

const MyHealthEmployeeHealthProgramContent = ({
  description,
  contentCards,
  productCards,
  footerCTA,
  handleInfo,
  handleModal,
  descriptionCTA
}) => {
  return (
    <div>
      {description && (
        <Body2 className='my-health-employe-health-program-content__description'>
          {description}
        </Body2>
      )}
      {/* TODO: Handle any button case */}
      {descriptionCTA && descriptionCTA.type === 'info' && (
        <InfoButton
          cta={descriptionCTA}
          handleInfo={handleInfo}
          className='my-health-employe-health-program-content__description-button'
        />
      )}
      {descriptionCTA && descriptionCTA.type === 'link' && (
        <LearnMoreButton
          cta={descriptionCTA}
          className='my-health-employe-health-program-content__description-button'
        />
      )}
      {contentCards && (
        <EmployeeHealthProgramContentCards
          contentCards={contentCards}
          handleInfo={handleInfo}
        />
      )}
      {productCards && (
        <EmployeeHealthProgramProductCards
          productCards={productCards}
          handleModal={handleModal}
        />
      )}
      {footerCTA && (
        <EmployeeHealthProgramFooterCTA
          footerCTA={footerCTA}
          handleInfo={handleInfo}
        />
      )}
    </div>
  );
};

MyHealthEmployeeHealthProgramContent.propTypes = {
  description: PropTypes.object,
  contentCards: PropTypes.array,
  productCards: PropTypes.array,
  footerCTA: PropTypes.object,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  descriptionCTA: PropTypes.object
};

export default MyHealthEmployeeHealthProgramContent;
