import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Accordion from '../../../../components/Accordion';
import MyHealthEmployeeHealthProgramContent from '../MyHealthEmployeeHealthProgramContent';

const MyHealthEmployeeInformationAccordion = ({
  toggle,
  handleInfo,
  handleModal,
  isOpen
}) => {
  return (
    <Accordion
      type={
        <FormattedMessage
          id='my-health.information'
          defaultMessage='Information'
        />
      }
      title={
        <FormattedMessage
          id='my-health.get-to-know-benefits'
          defaultMessage='Get to know your employee health benefits'
        />
      }
      isFirstAccordionOpen={isOpen}
      onToggle={toggle}
      content={
        <MyHealthEmployeeHealthProgramContent
          description={
            <FormattedMessage
              id='my-health.learn-about-health-benefits'
              defaultMessage='Learn about your employee health benefits and how to access them.'
            />
          }
          descriptionCTA={{
            text: (
              <FormattedMessage
                id='my-health.learn-more'
                defaultMessage='Learn more'
              />
            ),
            type: 'info',
            infoText: 'my-health.learn-about-health-benefits-info'
          }}
          handleInfo={handleInfo}
          handleModal={handleModal}
        />
      }
    />
  );
};

MyHealthEmployeeInformationAccordion.propTypes = {
  toggle: PropTypes.func,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool
};

export default MyHealthEmployeeInformationAccordion;
