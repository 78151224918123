import React, { ReactNode, useEffect } from 'react';
import {
  addNoScroll,
  removeNoScroll
} from '../../app/helpers/disableScrolling';
import styles from './index.module.scss';

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, children }) => {
  useEffect(() => {
    if (isOpen) {
      addNoScroll();
    } else {
      removeNoScroll();
    }

    return () => {
      removeNoScroll();
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
