import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import TestResultsHeader from '../../../components/TestResultsHeader';
import NegativeAllergyDiagnosisBlock from './NegativeAllergyDiagnosisBlock';
import ReportBox from '../../../components/ReportBox';
import Divider from '../../../components/Divider';
import TestResultsNavigation from '../TestResultsNavigation';
import RecommendedActions from '../RecommendedActions';
import TestResultsDocuments from '../TestResultsDocuments';
import SideDrawer from '../../../components/SideDrawer';
import AllergyDiagnosisBlock from './AllergyDiagnosisBlock';
import EligibilityForImmunotherapy from '../EligibilityForImmunotherapy';
import RecommendedMedication from '../RecommendedMedication';
import StauffacherSupport from '../StauffacherSupport';
import CssSupport from '../CssSupport';
import InfoBanner from '../../../components/InfoBanner';
import useScrollHandlerForImmunotherapy from '../TestResultsContent/useScrollHandlerForImmunotherapy.hook';
import { FormattedMessage } from 'react-intl';
import { overallResults } from '../../constants/overallResults';

import styles from './index.module.scss';

const TestResultsImmunotherapy = ({ data, language }) => {
  const {
    title,
    allergensData,
    immunotherapyEligibilityData,
    recommendedMedication,
    reports,
    overallResult,
    documents,
    partnerBlocks,
    infoBlock,
    recommendedActions
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const {
    scrollableElementRef,
    linksData,
    activeSection
  } = useScrollHandlerForImmunotherapy({
    data
  });

  const handleOpenModal = info => {
    setModalContent(<Markdown rehypePlugins={[rehypeRaw]}>{info}</Markdown>);
    setIsModalOpen(true);
  };

  const eligibleForRecommendedMedication =
    immunotherapyEligibilityData.eligibleForImmunoTherapy &&
    immunotherapyEligibilityData.SCITPossible &&
    immunotherapyEligibilityData.SLITPossible;

  const isOverallResultNegative = overallResult === overallResults.NEGATIVE;
  const isOverallResultPositive = overallResult === overallResults.POSITIVE;

  return (
    <div className={styles.contentWrapperScroll} ref={scrollableElementRef}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <TestResultsHeader
            className={styles.testResultsHeader}
            title={title}
            overallResult={overallResult}
          />

          <div className={styles.navigationMobileWrapper}>
            <TestResultsNavigation
              className={styles.navigationMobile}
              linksData={linksData}
              activeSection={activeSection}
            />
          </div>

          {reports.doctorReport && (
            <div id='doctor' className={styles.doctorReport}>
              <Divider
                title={
                  <FormattedMessage
                    id='app.doctors-report'
                    defaultMessage="Doctor's report"
                  />
                }
              />
              <ReportBox
                className={styles.reportBox}
                text={reports.doctorReport.reportText}
                name={reports.doctorReport.writtenBy}
              />
            </div>
          )}

          {isOverallResultNegative && (
            <div id='allergy' className={styles.allergyDiagnosis}>
              <NegativeAllergyDiagnosisBlock text={allergensData.description} />
            </div>
          )}

          {isOverallResultPositive && allergensData.allergens.length > 0 && (
            <div id='allergy' className={styles.allergyDiagnosis}>
              <AllergyDiagnosisBlock
                allergensData={allergensData}
                handleInfo={info => handleOpenModal(info)}
              />
            </div>
          )}

          <div className={styles.documentsWrapperMobile}>
            <TestResultsDocuments documents={documents} />
          </div>

          {isOverallResultPositive && (
            <div id='immunotherapy' className={styles.immunotherapyEligibility}>
              <EligibilityForImmunotherapy
                immunotherapyEligibilityData={immunotherapyEligibilityData}
                handleInfo={info => handleOpenModal(info)}
              />
            </div>
          )}

          {eligibleForRecommendedMedication && (
            <div id='medication' className={styles.recommendedMedication}>
              <RecommendedMedication
                recommendedMedication={recommendedMedication}
              />
            </div>
          )}

          {infoBlock && (
            <InfoBanner
              title={infoBlock.title}
              description={infoBlock.description}
              type={infoBlock.type}
              backgroundColor={infoBlock.color}
              url={infoBlock.url}
              handleInfo={() => handleOpenModal(infoBlock.infoText)}
            />
          )}

          {partnerBlocks && partnerBlocks.stauffacherBlock && (
            <div id='stauffacher'>
              <StauffacherSupport />
            </div>
          )}

          {partnerBlocks && partnerBlocks.CSSBlock && (
            <div id='css'>
              <CssSupport language={language} />
            </div>
          )}

          {recommendedActions && (
            <div id='actions' className={styles.actions}>
              <Divider
                title={
                  <FormattedMessage
                    id='app.recommended-actions'
                    defaultMessage='Recommended actions'
                  />
                }
              />
              <div className={styles.recommendedActions}>
                {recommendedActions.map((action, index) => {
                  const isLastRecommendedActionsBlock =
                    index === recommendedActions.length - 1;

                  return (
                    <RecommendedActions
                      key={index}
                      timing={action.timing}
                      actions={action.actions}
                      labReport={documents.labReport}
                      isLastRecommendedActionsBlock={
                        isLastRecommendedActionsBlock
                      }
                      handleInfo={infoData => handleOpenModal(infoData)}
                      language={language}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className={styles.sidebarWrapper}>
          <div className={styles.sidebar}>
            <TestResultsNavigation
              className={styles.navigationDesktop}
              linksData={linksData}
              activeSection={activeSection}
            />

            <TestResultsDocuments documents={documents} />
          </div>
        </div>
      </div>
      <SideDrawer onClick={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <div>{modalContent}</div>
      </SideDrawer>
    </div>
  );
};

TestResultsImmunotherapy.propTypes = {
  data: PropTypes.object,
  language: PropTypes.string
};

export default TestResultsImmunotherapy;
