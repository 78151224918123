import { useState } from "react";

const useLanguage = () => {
  const language = localStorage.getItem('hy-language');
  const [currentLanguage, setCurrentLanguage] = useState(
    language && language !== 'null' && language !== 'undefined' ? language : 'en'
  );

  if (language === 'null' || language === 'undefined') {
    localStorage.setItem('hy-language', currentLanguage);
  }

  return {
    currentLanguage,
    setCurrentLanguage
  };
}

export default useLanguage;