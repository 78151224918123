// https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
// true on IE11
// false on Edge and other IEs/browsers.
const isIe11 = typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode;

function getBrowserLanguage() {
  const browserLanguage = (navigator.language || navigator.userLanguage).substring(0, 2);

  // If language is not german, italian, we are returning english (for now)
  return ['en', 'de', 'fr', 'it'].includes(browserLanguage) ? browserLanguage : 'en';
}
export { isIe11, getBrowserLanguage };
