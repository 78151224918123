import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BottomDrawer from '../../../components/BottomDrawer';
import BottomDrawerContent from '../../../components/BottomDrawerContent';
import DashboardTabs, { TABS } from '../../../components/DashboardTabs';
import DashboardTitle from '../../../components/DashboardTitle';
import DashboardWithNoCards from '../../../components/DashboardWithNoCards';
import DashboardCardsList from '../../../components/DashboardCardsList';
import MyHealthHealthKnowledge from '../MyHealthHealthKnowledge';
import { prescriptionStatusColors } from '../../../components/PrescriptionCard';
import { testStatusColors } from '../../../components/TestCard';
import { testTypeNames } from '../../../components/TestCard/testTypeNames';

import './index.scss';

const DashboardContent = ({ data, error }) => {
  const { prescriptions, tests } = data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  //TODO; check what we want with Prescription Card
  const handleOpenModal = data => {
    const statusColor =
      testStatusColors[data.status] || prescriptionStatusColors[data.status];

    const testType = testTypeNames[data.type];
    setModalContent(
      <BottomDrawerContent
        status={data.status}
        type={data.type}
        statusColor={statusColor}
        testTitle={testType}
        prescriptionTitle={data.name}
        testCodeText={data.code}
        samplingInstructionsUrl={data.samplingInstructionsUrl}
        lastUpdateText={data.lastUpdate}
      />
    );
    setIsModalOpen(true);
  };

  const getInitialActiveTab = () => {
    const havePrescription = prescriptions && prescriptions.length > 0;
    const haveTests = tests && tests.length > 0;
    const savedTab = localStorage.getItem('activeTab');

    if (savedTab) {
      if (
        (savedTab === TABS.PRESCRIPTIONS && havePrescription) ||
        (savedTab === TABS.TESTS && haveTests) ||
        (savedTab === TABS.ALL && havePrescription && haveTests)
      ) {
        return savedTab;
      }
    }

    let initalActiveTab = null;

    if (havePrescription) {
      initalActiveTab = TABS.PRESCRIPTIONS;
    }

    if (haveTests) {
      initalActiveTab = TABS.TESTS;
    }

    if (havePrescription && haveTests) {
      initalActiveTab = TABS.ALL;
    }

    return initalActiveTab;
  };

  const [activeTab, setActiveTab] = useState(getInitialActiveTab());

  const isActiveTabAllOrPrescriptions = [TABS.ALL, TABS.PRESCRIPTIONS].includes(
    activeTab
  );
  const isActiveTabAllOrTests = [TABS.ALL, TABS.TESTS].includes(activeTab);

  const arePrescriptionsVisible =
    prescriptions && prescriptions.length > 0 && isActiveTabAllOrPrescriptions;

  const areTestsVisible = tests && tests.length > 0 && isActiveTabAllOrTests;

  const areTestsOrPrescriptionsAvailable =
    (tests && tests.length > 0) || (prescriptions && prescriptions.length > 0);

  const sortedPrescriptionsByLastUpdate = prescriptions.sort(
    (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
  );

  const sortedTestsByResultsViewedAndLastUpdate = tests.sort((a, b) => {
    const aResultsViewed = a.resultsViewed === null ? true : a.resultsViewed;
    const bResultsViewed = b.resultsViewed === null ? true : b.resultsViewed;
    if (aResultsViewed === bResultsViewed) {
      return new Date(b.lastUpdate) - new Date(a.lastUpdate);
    }
    return aResultsViewed ? 1 : -1;
  });

  if (error) {
    return (
      <div className='dashboard__error-wrapper'>
        <FormattedMessage
          id='error.something-went-wrong'
          defaultMessage='Something went wrong. Please try again.'
        />
      </div>
    );
  }

  return (
    <div className='dashboard__content'>
      <div className='dashboard__left-block'>
        <div className='dashboard__title'>
          <DashboardTitle
            title={
              <FormattedMessage
                id='dashboard.my-products'
                defaultMessage='My Products'
              />
            }
          />
        </div>
        {areTestsOrPrescriptionsAvailable ? (
          <>
            <div className='dashboard__navigation'>
              <DashboardTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isPrescriptionsTabVisible={
                  prescriptions && prescriptions.length > 0
                }
                isTestsTabVisible={tests && tests.length > 0}
              />
            </div>
            <div className='dashboard__products-list'>
              <DashboardCardsList
                prescriptions={sortedPrescriptionsByLastUpdate}
                tests={sortedTestsByResultsViewedAndLastUpdate}
                handleOpenModal={handleOpenModal}
                arePrescriptionsVisible={arePrescriptionsVisible}
                areTestsVisible={areTestsVisible}
              />
            </div>

            <div className='dashboard__health-knowledge-mobile'>
              <MyHealthHealthKnowledge />
            </div>
          </>
        ) : (
          <div>
            <div className='dashboard__no-cards'>
              <DashboardWithNoCards />
            </div>
            <div className='dashboard__health-knowledge-mobile'>
              <MyHealthHealthKnowledge />
            </div>
          </div>
        )}
      </div>

      <div className='dashboard__right-block'>
        <MyHealthHealthKnowledge />
      </div>

      <BottomDrawer isOpen={isModalOpen} onClick={() => setIsModalOpen(false)}>
        {modalContent}
      </BottomDrawer>
    </div>
  );
};

DashboardContent.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object
};

export default DashboardContent;
