import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingCircle from '../LoadingCircle';
import Button from '../../../components/Button';
import { markRecommendedActionAsDone } from '../../actions/testResult';

const MarkAsCompleteButton = ({
  dispatch,
  cta,
  timingKey,
  contentKey,
  markAsCompleteButtonLoading,
  language,
  done
}) => {
  const { testCode } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (isLoading && !markAsCompleteButtonLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsCompleteButtonLoading]);

  const handleOnClick = () => {
    setIsLoading(true);
    dispatch(
      markRecommendedActionAsDone(testCode, timingKey, contentKey, language)
    );
  };

  return (
    <>
      {!done && (
        <Button
          key={cta.text}
          variant={cta.variant}
          disabled={cta.disabled}
          size='small'
          onClick={handleOnClick}
        >
          {isLoading ? <LoadingCircle /> : cta.text}
        </Button>
      )}
    </>
  );
};

MarkAsCompleteButton.propTypes = {
  cta: PropTypes.object,
  timing: PropTypes.string,
  dispatch: PropTypes.func,
  markAsCompleteButtonLoading: PropTypes.bool,
  timingKey: PropTypes.string,
  contentKey: PropTypes.string,
  language: PropTypes.string,
  done: PropTypes.bool
};

const mapStateToProps = state => ({
  markAsCompleteButtonLoading: state.testResult.get(
    'markAsCompleteButtonLoading'
  )
});

export default connect(mapStateToProps)(MarkAsCompleteButton);
