import React from 'react';
import TabButton from '../TabButton';
import styles from './index.module.scss';

export const TABS = {
  ALL: 'all',
  TESTS: 'tests',
  PRESCRIPTIONS: 'prescriptions'
};

interface Tab {
  id: string;
}

const TabsData = [
  {
    id: TABS.ALL
  },
  {
    id: TABS.TESTS
  },
  {
    id: TABS.PRESCRIPTIONS
  }
];

interface DashboardTabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isTestsTabVisible: boolean;
  isPrescriptionsTabVisible: boolean;
}

const DashboardTabs: React.FC<DashboardTabsProps> = ({
  activeTab,
  setActiveTab,
  isTestsTabVisible,
  isPrescriptionsTabVisible
}) => {
  const getTabsData = (
    isTestsTabVisible: boolean,
    isPrescriptionsTabVisible: boolean
  ) => {
    let tabs = [] as Tab[];
    if (isTestsTabVisible && isPrescriptionsTabVisible) {
      tabs = [...TabsData];
    }

    if (isTestsTabVisible && !isPrescriptionsTabVisible) {
      tabs.push({ id: TABS.TESTS });
    }

    if (isPrescriptionsTabVisible && !isTestsTabVisible) {
      tabs.push({ id: TABS.PRESCRIPTIONS });
    }

    return tabs;
  };

  const tabs = getTabsData(isTestsTabVisible, isPrescriptionsTabVisible);
  return (
    <div className={styles.wrapper}>
      {tabs.map(tab => {
        return (
          <TabButton
            key={tab.id}
            tab={tab.id}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        );
      })}
    </div>
  );
};

export default DashboardTabs;
