import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { directions } from '../../constants/data';

import './index.scss';

const ANIMATION_DURATION = 300;

const SlideContent = ({ children, animationDirection, changingData }) => {
  const prevChildren = useRef(null);
  const [animate, setAnimate] = useState(false);
  const [previousChildren, setPreviousChildren] = useState(false);
  const [previousChildrenLeft, setPreviousChildrenLeft] = useState(0);
  const [previousChildrenOpacity, setPreviousChildrenOpacity] = useState(0);
  const [childrenLeft, setChildrenLeft] = useState(0);
  const [childrenOpacity, setChildrenOpacity] = useState(1);

  useEffect(() => {
    let animationStartRequestID;
    let animationEndTimeoutID;

    if (prevChildren.current) {
      setPreviousChildren(prevChildren.current);
      setPreviousChildrenLeft(0);
      setChildrenLeft(animationDirection === directions.FORWARD ? '50%' : '-50%');
      setPreviousChildrenOpacity(1);
      setChildrenOpacity(0);

      animationStartRequestID = requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setAnimate(true);
          setPreviousChildrenLeft(animationDirection === directions.FORWARD ? '-50%' : '50%');
          setChildrenLeft(0);
          setPreviousChildrenOpacity(0);
          setChildrenOpacity(1);
        });
      });

      animationEndTimeoutID = setTimeout(() => {
        setAnimate(false);
        setPreviousChildrenLeft(0);
        setPreviousChildrenOpacity(1);
        setPreviousChildren(null);
        prevChildren.current = children;
      }, ANIMATION_DURATION);
    } else {
      prevChildren.current = children;
    }

    return () => {
      cancelAnimationFrame(animationStartRequestID);
      clearTimeout(animationEndTimeoutID);
    };
    // Need to be triggered only if the whole data is changed.
    // with only children, because of the state change it will be triggered.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingData]);

  const childrenStyle = {
    transition: animate ? `all ${ANIMATION_DURATION}ms` : null,
    transform: `translateX(${childrenLeft})`,
    opacity: childrenOpacity
  };

  const previousChildrenStyle = {
    transition: animate ? `all ${ANIMATION_DURATION}ms` : null,
    transform: `translateX(${previousChildrenLeft})`,
    opacity: previousChildrenOpacity
  };

  return (
    <div className='slide-content'>
      <div style={previousChildrenStyle} className='slide-content__prev-children'>
        {previousChildren}
      </div>
      <div style={childrenStyle} className='slide-content__children'>
        {children}
      </div>
    </div>
  );
};

SlideContent.propTypes = {
  children: PropTypes.any,
  animationDirection: PropTypes.string,
  changingData: PropTypes.string
};

export default SlideContent;
