import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import withWindow from '../../windowDecorators/withWindow';

import useAuthorization from '../../hooks/useAuthorization';
import Background from '../../components/Background';
import FormWrapper from '../../components/FormWrapper';
import EmailForm from '../../components/EmailForm';
import ConfirmEmail from '../../components/ConfirmEmail';
import CreatePasswordForm from '../../components/CreatePasswordForm';
import EnterPasswordForm from '../../components/EnterPasswordForm';
import PhoneCodeForm from '../../components/PhoneCodeForm';
import CheckInboxContent from '../../components/CheckInboxContent';

import backgroundMobile from '../../../assets/svg/sign-up-mobile.svg';
import backgroundLarge from '../../../assets/svg/sign-up-large.svg';
import resetPasswordBackgroundMobile from '../../../assets/svg/reset-password-mobile.svg';
import resetPasswordBackgroundDesktop from '../../../assets/svg/reset-password-desktop.svg';

import './index.scss';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';

const Authorization = ({
  breakpoint,
  dispatch,
  intl,
  lang,
  changeLanguage
}) => {
  const {
    registerUser,
    signInUser,
    checkIfUserExist,
    userState,
    userStateValues,
    isLoading,
    error,
    setError,
    resendVerificationEmail,
    finishLogin,
    setUserState,
    MFANumber,
    showForgotPasswordForm,
    sendResetPasswordEmail,
    recaptchaRef,
    recaptchaResendRef,
    resendVerificationCodeForSignIn
  } = useAuthorization(dispatch);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const smallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  useEffect(() => {
    const searchString = history.location.search;
    const searchParams = new URLSearchParams(searchString);
    const langFromUrl = searchParams.get('lang');
    if (langFromUrl && langFromUrl !== 'null' && lang !== langFromUrl) {
      if (langFromUrl === 'fr' || langFromUrl === 'it') {
        changeLanguage('en');
      } else {
        changeLanguage(langFromUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userState === userStateValues.FORGOT_PASSWORD ||
      userState === userStateValues.CHECK_INBOX
    ) {
      if (smallOrMedium) {
        setBackgroundImage(resetPasswordBackgroundMobile);
      } else {
        setBackgroundImage(resetPasswordBackgroundDesktop);
      }
    } else {
      if (smallOrMedium) {
        setBackgroundImage(backgroundMobile);
      } else {
        setBackgroundImage(backgroundLarge);
      }
    }
  }, [
    breakpoint,
    smallOrMedium,
    userState,
    userStateValues.CHECK_INBOX,
    userStateValues.FORGOT_PASSWORD
  ]);

  return (
    <Background className='sign-up__background'>
      <div ref={recaptchaRef}>
        <div id='recaptcha' />
      </div>
      <div ref={recaptchaResendRef}>
        <div id='recaptcha-resend' />
      </div>
      <div id='recaptcha-container' />
      <div className='sign-up'>
        <FormWrapper
          imageUrl={backgroundImage}
          lang={lang}
          changeLanguage={changeLanguage}
        >
          {!userState && (
            <EmailForm
              email={email}
              setEmail={setEmail}
              checkIfUserExist={checkIfUserExist}
              isLoading={isLoading}
              intl={intl}
            />
          )}
          {userState === userStateValues.NEW_USER && (
            <CreatePasswordForm
              password={password}
              setPassword={setPassword}
              createAccount={registerUser}
              isLoading={isLoading}
              email={email}
              hookError={error}
              setUserState={setUserState}
              intl={intl}
            />
          )}
          {userState === userStateValues.EXISTING_USER && (
            <EnterPasswordForm
              password={password}
              setPassword={setPassword}
              email={email}
              signInUser={signInUser}
              isLoading={isLoading}
              error={error}
              setError={setError}
              setUserState={setUserState}
              onForgotPasswordButtonClick={showForgotPasswordForm}
              intl={intl}
            />
          )}
          {userState === userStateValues.FORGOT_PASSWORD && (
            <ForgotPasswordForm
              email={email}
              isLoading={isLoading}
              hookError={error}
              setEmail={setEmail}
              setHookError={setError}
              sendResetPasswordEmail={sendResetPasswordEmail}
              setUserState={setUserState}
              intl={intl}
            />
          )}
          {userState === userStateValues.CHECK_INBOX && (
            <CheckInboxContent email={email} setUserState={setUserState} />
          )}
          {userState === userStateValues.EMAIL_VERIFICATION && (
            <ConfirmEmail
              email={email}
              resendVerificationEmail={resendVerificationEmail}
              error={error}
              setError={setError}
            />
          )}
          {userState === userStateValues.PHONE_CODE && (
            <PhoneCodeForm
              isLoading={isLoading}
              hookError={error}
              code={code}
              setCode={setCode}
              phone={MFANumber}
              email={email}
              onClick={finishLogin}
              resendVerificationCode={resendVerificationCodeForSignIn}
            />
          )}
        </FormWrapper>
      </div>
    </Background>
  );
};

Authorization.propTypes = {
  breakpoint: PropTypes.string,
  dispatch: PropTypes.func,
  emulatorStarted: PropTypes.bool,
  setEmulatorStarted: PropTypes.func,
  intl: PropTypes.any,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default connect()(withWindow(injectIntl(Authorization)));
