// Custom API error to throw
import { authHeader } from '../utils/helpers';

function ApiError(message, data, status) {
  let response = null;
  let isObject = false;

  // We are trying to parse response
  try {
    response = JSON.parse(data);
    isObject = true;
  } catch (e) {
    response = data;
  }

  return {
    response,
    message,
    status,
    toString: () => {
      return `${message}\nResponse:\n${
        isObject ? JSON.stringify(response, null, 2) : response
      }`;
    }
  };
}

// API wrapper function
const fetchResource = async (path, userOptions = {}) => {
  // Define default options
  const defaultOptions = {
    // credentials: 'include',
  };
  // Define default headers
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  const options = {
    // Merge options
    ...defaultOptions,
    ...userOptions,
    // Merge headers
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
      ...authHeader()
    }
  };

  const fetchPath = `${process.env.REACT_APP_API_URL}/${path}`;
  // Detect is we are uploading a file
  const isFile = typeof window !== 'undefined' && options.body instanceof File;

  // Stringify JSON data
  // If body is not a file
  if (options.body && typeof options.body === 'object' && !isFile) {
    options.body = JSON.stringify(options.body);
  }

  // Variable which will be used for storing response
  let response = null;
  return (
    fetch(fetchPath, options)
      .then(responseObject => {
        // Saving response for later use in lower scopes
        response = responseObject;

        // HTTP unauthorized
        if (response.status === 401 || response.status === 403) {
          window.location.href = `${process.env.REACT_APP_LOGIN_URL}${window.location.href}`;
        }

        if (response.status >= 500) {
          window.location.href = `${window.location.origin}/error`;
        }

        // Check for error HTTP error codes
        if (response.status < 200 || response.status >= 300) {
          // Get response as text or blob
          return options.responseType === 'blob'
            ? response.blob()
            : response.text();
        }
        // Get response as json or blob
        return options.responseType === 'blob'
          ? response.blob()
          : response.json();
      })
      // "parsedResponse" will be either text or javascript object depending if
      // "response.text()" or "response.json()" got called in the upper scope
      .then(parsedResponse => {
        // Check for HTTP error codes
        if (response.status < 200 || response.status >= 300) {
          // Throw error
          throw parsedResponse;
        }
        // Request succeeded
        return parsedResponse;
      })
      .catch(error => {
        // Throw custom API error
        // If response exists it means HTTP error occured
        if (response) {
          throw ApiError(
            `Request failed with status ${response.status}.`,
            error,
            response.status
          );
        } else {
          throw ApiError(error.toString(), null, 'REQUEST_FAILED');
        }
      })
  );
};

function startConversation(conversationFlowKey, lang) {
  return fetchResource('conversations', {
    method: 'POST',
    body: {
      conversationFlowKey,
      lang
    }
  });
}

function startConversationWithTestCode(testCode, conversationFlowKey, lang) {
  return fetchResource(`conversations/testCode/${testCode}`, {
    method: 'POST',
    body: {
      testCode,
      conversationFlowKey,
      lang
    }
  });
}

function startConversationWithBenefitCode(
  benefitCode,
  conversationFlowKey,
  lang
) {
  return fetchResource(`conversations/benefitCode/${benefitCode}`, {
    method: 'POST',
    body: {
      benefitCode,
      conversationFlowKey,
      lang
    }
  });
}

function createPrescriptionConversationWithId(prescriptionId, lang) {
  return fetchResource(`conversations/prescription/${prescriptionId}`, {
    method: 'POST',
    body: {
      prescriptionId,
      lang
    }
  });
}

function updateConversation(lang, conversationId) {
  const userId = localStorage.getItem('userId');
  return fetchResource(`conversations/${conversationId}`, {
    method: 'PATCH',
    body: {
      lang,
      userId
    }
  });
}

function continueConversation(conversationId) {
  // We want to send userId for continuing conversation if we have, so we can
  // continue from saved point
  // We are sending this as query because this is GET endpoint
  const userId = localStorage.getItem('userId');
  const url = `conversations/${conversationId}${
    userId ? `?userId=${userId}` : ''
  }`;

  return fetchResource(url);
}

// Sending slack message to slack debug-error channel
function sendSlackDebugError(message) {
  return fetchResource('notification/slack-debug-error', {
    method: 'POST',
    body: {
      message
    }
  });
}

function submitAnswer(answer) {
  return fetchResource(`conversations/${answer.conversationId}/next`, {
    method: 'POST',
    body: answer
  });
}

function goBack(body) {
  const userId = localStorage.getItem('userId');
  return fetchResource(`conversations/${body.conversationId}/back`, {
    method: 'POST',
    body: {
      ...body,
      userId
    }
  });
}

function updateUser(value) {
  return fetchResource(`user`, {
    method: 'PATCH',
    body: {
      ...value
    }
  });
}

function updateDashboard(lang) {
  return fetchResource('dashboard', {
    method: 'PATCH',
    body: {
      lang
    }
  });
}

function getDashboardData() {
  return fetchResource('dashboard');
}

function getUserData() {
  return fetchResource('user');
}

function checkIfUserExist(email) {
  return fetchResource('auth/check-if-user-exist', {
    method: 'POST',
    body: {
      email
    }
  });
}

function getUserPhoneNumber(email) {
  return fetchResource('auth/get-user-phone', {
    method: 'POST',
    body: {
      email
    }
  });
}

function sendSlackAuthError(message) {
  return fetchResource('notification/slack-auth-error', {
    method: 'POST',
    body: {
      message
    }
  });
}

// Creating user in DB (Firestore)
function createUser(email, lang) {
  return fetchResource('user/create', {
    method: 'POST',
    body: {
      email,
      lang
    }
  });
}

function getTestResults(testCode, language) {
  return fetchResource(`test-results/${testCode}?language=${language}`);
}

function markRecommendedActionAsDone(
  testCode,
  timingKey,
  contentKey,
  language
) {
  return fetchResource(`test-results/${testCode}?language=${language}`, {
    method: 'PATCH',
    body: {
      timingKey,
      key: contentKey
    }
  });
}

function createOrder(orderData) {
  const { benefitCode } = orderData;
  return fetchResource(`benefits/benefit/${benefitCode}/use`, {
    method: 'PUT',
    body: {
      orderData
    }
  });
}

export default {
  startConversation,
  submitAnswer,
  goBack,
  continueConversation,
  updateConversation,
  updateDashboard,
  startConversationWithTestCode,
  updateUser,
  fetchResource,
  getDashboardData,
  getUserData,
  createPrescriptionConversationWithId,
  checkIfUserExist,
  getUserPhoneNumber,
  sendSlackAuthError,
  createUser,
  sendSlackDebugError,
  getTestResults,
  markRecommendedActionAsDone,
  createOrder,
  startConversationWithBenefitCode
};
