import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as Info } from '../../../assets/svg/info.svg';

import './index.scss';

const PersonalHayfever = ({ data }) => {
  const { footnote, image, includedTests, text } = data.info;

  return (
    <div className='personal-hayfever'>
      <div className='personal-hayfever__content'>
        <div className='personal-hayfever__image-wrapper'>
          <img src={image} alt='personal hayfever' />
        </div>
        <div className='personal-hayfever__text-content'>
          {text && (
            <div className='personal-hayfever__top-text'>
              <Markdown
                className='personal-hayfever__markdown'
                rehypePlugins={[rehypeRaw]}
              >
                {text}
              </Markdown>
            </div>
          )}
          <div className='personal-hayfever__list'>
            {includedTests.map(test => {
              return (
                <div className='personal-hayfever__list-item' key={test.title}>
                  <div className='personal-hayfever__name-wrapper'>
                    <div className='personal-hayfever__list-item-image'>
                      <img src={test.image} alt={test.title} />
                    </div>
                    <div className='personal-hayfever__list-item-name'>
                      {test.title}
                    </div>
                  </div>
                  <div className='personal-hayfever__date-wrapper'>
                    <Calendar className='personal-hayfever__calendar' />
                    <span className='personal-hayfever__date-text'>{`${test.period.start} - ${test.period.end}`}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='personal-hayfever__info-wrapper'>
            <div className='personal-hayfever__info-icon'>
              <Info />
            </div>
            <div className='personal-hayfever__info-text'>
              <Markdown
                className='personal-hayfever__markdown'
                rehypePlugins={[rehypeRaw]}
              >
                {footnote}
              </Markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PersonalHayfever.propTypes = {
  data: PropTypes.object
};

export default PersonalHayfever;
