import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Body2 } from '../Typography';
import cx from 'classnames';
import styles from './index.module.scss';

export interface TabButtonProps {
  tab: string;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabButton: React.FC<TabButtonProps> = ({
  tab,
  activeTab,
  setActiveTab
}) => {
  const capitalizeFirstLetter = (tab: string) => {
    return tab.charAt(0).toUpperCase() + tab.slice(1);
  };

  const handleTabClick = (tab: string) => {
    localStorage.setItem('activeTab', tab);
    setActiveTab(tab);
  };

  return (
    <button
      className={cx({
        [styles.tab]: true,
        [styles.activeTab]: activeTab === tab
      })}
      onClick={() => handleTabClick(tab)}
    >
      <Body2 element='span'>
        <FormattedMessage
          id={`app.${tab}`}
          defaultMessage={capitalizeFirstLetter(tab)}
        />
      </Body2>
    </button>
  );
};

export default TabButton;
