import React from 'react';
import PropTypes from 'prop-types';
import { PATHS } from '../../constants/paths';

import FormTitle from '../FormWrapper/FormTitle';
import FormButton from '../FormWrapper/FormButton';
import FormText from '../FormWrapper/FormText';
import { getCallbackFromURL } from '../../helpers/authorizationHelpers';

const PasswordUpdatedContent = () => {
  const handleOnSubmit = e => {
    const continueUrl = getCallbackFromURL();
    e.preventDefault();

    let buttonClickPath = PATHS.AUTHORIZATION;
    if (continueUrl) {
      buttonClickPath = continueUrl;
    }

    window.location.href = buttonClickPath;
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <FormTitle
        title='auth.password-updated'
        defaultTitle='Password updated!'
      />
      <FormText
        text='auth.password-updated-message'
        defaultText='Your password has beed changed successfully. Use your new password to log in.'
      />
      <FormButton
        className='check-inbox-content__button'
        text='auth.back-to-log-in'
        defaultText='Back to Log in'
        submit
      />
    </form>
  );
};

PasswordUpdatedContent.propTypes = {
  email: PropTypes.string,
  setUserState: PropTypes.func
};

export default PasswordUpdatedContent;
