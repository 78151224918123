import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withWindow from '../../windowDecorators/withWindow';

import Button from '../Button';
import Scorebar from '../Scorebar';

import { ReactComponent as Share } from '../../../assets/svg/share.svg';

import InfoIcon from '@material-ui/icons/Info';

import './index.scss';

const renderIlnessesNames = illnesses => {
  return illnesses.map(illness => {
    return (
      <span key={illness.key} className='scorecard__illness'>
        <span className='scorecard__illness-name'>
          {illness.titleText}
          {illness.link &&<a href={illness.link} className='scorecard__illness-info' target='_blank' rel='noopener noreferrer'>
            <InfoIcon className='scorecard__illness-info-icon'/>
          </a>}
        </span>
      </span>
    );
  });
};

const renderScrollBars = (illnesses, scores) => {
  return illnesses.map(illness => (
    <div key={illness.key} className='scorecard__score-bar-wrapper'>
      <Scorebar data={illness} scores={scores} />{' '}
    </div>
  ));
};

const generateModalData = (setModalData, data) => {
  const modalData = data.illnesses.map(illness => {
    const primaryScore = data.scores.primary.values.find(score => score.key === illness.key);
    const secondaryScore = data.scores.secondary.values.find(score => score.key === illness.key);

    return {
      ...illness,
      primaryScore: primaryScore,
      secondaryScore: secondaryScore,
      youValue: primaryScore.value,
      reasonText: primaryScore.reasonText,
      instructionText: primaryScore.instructionText,
      swissValue: secondaryScore.value
    };
  });
  setModalData(modalData);
};

const Scorecard = ({ data, breakpoint, setModalData, setIsModalOpen }) => {
  const { scorecard } = data.info;
  const isLarge = ['large', 'xlarge'].includes(breakpoint);

  useEffect(() => {
    generateModalData(setModalData, scorecard);
  }, [scorecard, setModalData]);

  return (
    <div className='scorecard'>
      <div className='scorecard__left-side'>
        <div className='scorecard__score-bar-labels'>
          <span>
            <FormattedMessage id='scorecard.few-cases' defaultMessage='Few cases' />
          </span>
          <span>
            <FormattedMessage id='scorecard.many-cases' defaultMessage='Many cases' />
          </span>
        </div>
        <div className='scorecard__illnesses'>{renderIlnessesNames(scorecard.illnesses)}</div>
        <div className='scorecard__score-bars'>{renderScrollBars(scorecard.illnesses, scorecard.scores)}</div>
      </div>
      <div className='scorecard__right-side'>
        <div className='scorecard__icon-explain'>
          <div className='scorecard__icon'>
            <img src={scorecard.scores.secondary.iconUrl} alt={scorecard.scores.secondary.titleText} />
          </div>
          <span className='scorecard__text'>
            {scorecard.scores.secondary.titleText}
            {isLarge && <span className='scorecard__source'>{scorecard.scores.secondary.descriptionText}</span>}
          </span>
        </div>
        <div className='scorecard__icon-explain'>
          <div className='scorecard__icon'>
            <img src={scorecard.scores.primary.iconUrl} alt={scorecard.scores.primary.titleText} />
          </div>
          <span className='scorecard__text'>
            {scorecard.scores.primary.titleText}
            <button onClick={() => setIsModalOpen(true)} className='scorecard__read-more'>
              <FormattedMessage id='app.read-more' defaultMessage='Read more' />
            </button>
          </span>
        </div>
        <Button onClick={() => {}} className='scorecard__download-button'>
          <Share />
          <FormattedMessage id='scorecard.download' defaultMessage='Download' /> /
          <FormattedMessage id='scorecard.share' defaultMessage='Share' />
        </Button>
      </div>
    </div>
  );
};

Scorecard.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string,
  setModalData: PropTypes.func,
  setIsModalOpen: PropTypes.func
};

export default withWindow(Scorecard);
