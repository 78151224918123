import React, { ReactNode } from 'react';
import { useSwiper } from 'swiper/react';
import styles from './index.module.scss';

interface CardsCarouselButtonProps {
  icon: ReactNode;
  className?: string;
  slideDirection?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

const CardsCarouselButton: React.FC<CardsCarouselButtonProps> = ({
  icon,
  className,
  slideDirection,
  buttonRef
}) => {
  const swiper = useSwiper();

  const handleButtonClick = () => {
    if (slideDirection === 'next') {
      swiper.slideNext();
    }

    if (slideDirection === 'prev') {
      swiper.slidePrev();
    }
  };

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={handleButtonClick}
      ref={buttonRef}
    >
      {icon}
    </button>
  );
};

export default CardsCarouselButton;
