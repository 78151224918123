import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const HayfeverModalProduct = ({ data }, flowCountry) => {
  const { icon, name, packageNumber, type, unitsNumber } = data;
  return (
    <div className='hayfever-modal-product'>
      <div className='hayfever-modal-product__divider' />
      <div className='hayfever-modal-product__content'>
        <div className='hayfever-modal-product__left-block'>
          <div className='hayfever-modal-product__image-wrapper'>
            <img src={icon} alt='product' />
          </div>
        </div>
        <div className='hayfever-modal-product__right-block'>
          <div className='hayfever-modal-product__text-wrapper'>
            <span className='hayfever-modal-product__title'>{name}</span>
            <span className='hayfever-modal-product__text'>{type}</span>
            <span className='hayfever-modal-product__text'>{unitsNumber}</span>
            <span className='hayfever-modal-product__text'>{packageNumber}</span>
          </div>
        </div>
      </div>
      <div className='hayfever-modal-product__divider hayfever-modal-product__divider--bottom' />
    </div>
  );
};

HayfeverModalProduct.propTypes = {
  data: PropTypes.object,
  flowCountry: PropTypes.string
};

export default HayfeverModalProduct;
