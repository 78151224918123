import React from 'react';
import PropTypes from 'prop-types';
import LanguageDropdown from '../LanguageDropdown';
import cx from 'classnames';

import './index.scss';

const FormWrapper = ({
  children,
  className,
  imageUrl,
  contentClassName,
  lang,
  changeLanguage
}) => {
  return (
    <div className={cx('form-wrapper', className)}>
      {imageUrl && (
        <div className='form-wrapper__image-wrapper'>
          <img src={imageUrl} alt='title' className='form-wrapper__image' />
        </div>
      )}
      <div
        className={cx('form-wrapper__content-wrapper', contentClassName, {
          'form-wrapper__content-wrapper--full-width': !imageUrl
        })}
      >
        <LanguageDropdown
          wrapperClassName='form-wrapper__language-dropdown'
          onChange={changeLanguage}
          value={lang}
          availableLanguages={['en', 'de', 'fr', 'it']}
        />
        <>{children}</>
      </div>
    </div>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  contentClassName: PropTypes.string,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default FormWrapper;
