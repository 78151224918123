import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

type TypographyElement =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'div'
  | 'caption'
  | 'button';

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

const TypographyVariantElements: Record<
  TypographyElement,
  React.ElementType
> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
  div: 'div',
  caption: 'caption',
  button: 'button'
};

interface TypographyProps {
  element?: TypographyElement;
  variant?: TypographyVariant;
  children: React.ReactNode;
  className?: string;
}

const Typography: React.FC<TypographyProps> = ({
  element = 'p',
  variant = 'body1',
  children,
  className
}) => {
  const Element = TypographyVariantElements[element];

  return (
    <Element
      className={cx(
        {
          [styles[variant]]: true
        },
        className && { [className]: true }
      )}
    >
      {children}
    </Element>
  );
};

const Heading1: React.FC<TypographyProps> = ({
  element = 'h1',
  variant = 'h1',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Heading2: React.FC<TypographyProps> = ({
  element = 'h2',
  variant = 'h2',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Heading3: React.FC<TypographyProps> = ({
  element = 'h3',
  variant = 'h3',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Heading4: React.FC<TypographyProps> = ({
  element = 'h4',
  variant = 'h4',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Heading5: React.FC<TypographyProps> = ({
  element = 'h5',
  variant = 'h5',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Heading6: React.FC<TypographyProps> = ({
  element = 'h6',
  variant = 'h6',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Subtitle1: React.FC<TypographyProps> = ({
  element = 'p',
  variant = 'subtitle1',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Subtitle2: React.FC<TypographyProps> = ({
  element = 'p',
  variant = 'subtitle2',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Body1: React.FC<TypographyProps> = ({
  element = 'p',
  variant = 'body1',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Body2: React.FC<TypographyProps> = ({
  element = 'p',
  variant = 'body2',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const ButtonText: React.FC<TypographyProps> = ({
  element = 'span',
  variant = 'button',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Caption: React.FC<TypographyProps> = ({
  element = 'caption',
  variant = 'caption',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

const Overline: React.FC<TypographyProps> = ({
  element = 'span',
  variant = 'overline',
  children,
  className
}) => (
  <Typography element={element} variant={variant} className={className}>
    {children}
  </Typography>
);

export {
  Typography,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  ButtonText,
  Caption,
  Overline
};
