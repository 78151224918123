// States that are used through authorization process
export const USER_STATE = {
  EXISTING_USER: 'existingUser',
  NEW_USER: 'newUser',
  ERROR: 'error',
  EMAIL_VERIFICATION: 'emailVerification',
  PHONE_CODE: 'phoneCode',
  RESET_PASSWORD: 'resetPassword',
  FORGOT_PASSWORD: 'forgotPassword'
};
