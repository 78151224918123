import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Step from './Step';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './index.scss';

const renderSteps = steps => {
  return steps.map((step, index) => {
    return (
      <Fragment key={step.key}>
        <Step data={step} />
        {index !== steps.length - 1 && <ChevronRightIcon className='progress-bar__arrow-icon' />}
      </Fragment>
    );
  });
};

const ProgressBar = ({ data, isTitleSticky }) => {
  const { progressValue, steps, conversationFlowTitleText } = data;
  const progressBarClasses = cx({
    'progress-bar': true,
    'progress-bar--with-sticky-title': isTitleSticky
  });

  return (
    <div className={progressBarClasses}>
      <div className='progress-bar__content'>
        <span className='progress-bar__title'>{conversationFlowTitleText}</span>
        {
          steps && steps.length > 0 && (
            <>
              <div className='progress-bar__steps'>{renderSteps(steps)}</div>
              <div className='progress-bar__bar-wrapper'>
                <div className='progress-bar__bar' style={{ width: `${progressValue}%` }} />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  data: PropTypes.object,
  isTitleSticky: PropTypes.bool
};

export default ProgressBar;
