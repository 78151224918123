import { Map } from 'immutable';
import { getBrowserLanguage } from '../utils/browsers';

import {SET_LANGUAGE, SET_BACK_URL, SET_FLOW_COUNTRY, SET_SLIDER_VIEWED} from '../actions/app';

const initialState = Map({
  language: getBrowserLanguage(),
  backUrl: 'https://yourself.health/',
  flowCountry: 'switzerland',
  token: '!!!!',
  sliderViewed: false
});

const actionsMap = {
  [SET_LANGUAGE]: (state, action) => {
    return state.merge(
      Map({
        language: action.payload
      })
    );
  },
  [SET_BACK_URL]: (state, action) => {
    return state.merge(
      Map({
        backUrl: action.payload
      })
    );
  },
  [SET_FLOW_COUNTRY]: (state, action) => {
    return state.merge(
        Map({
          flowCountry: action.payload
        })
    );
  },
  [SET_SLIDER_VIEWED]: (state) => {
    return state.merge(
        Map({
          sliderViewed: true
        })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
