import { takeLatest, call, put } from 'redux-saga/effects';

import {
  START_CONVERSATION_WITH_TEST_CODE_START,
  START_CONVERSATION_WITH_TEST_CODE_SUCCESS,
  START_CONVERSATION_WITH_TEST_CODE_ERROR,
  START_CONVERSATION_WITH_BENEFIT_CODE_START,
  START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS,
  START_CONVERSATION_WITH_BENEFIT_CODE_ERROR,
  START_CONVERSATION_START,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_ERROR,
  UPDATE_CONVERSATION_START,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_ERROR,
  UPDATE_DASHBOARD_DATA_START,
  UPDATE_DASHBOARD_DATA_SUCCESS,
  UPDATE_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  SUBMIT_ANSWER_START,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_ERROR,
  GO_BACK_START,
  GO_BACK_SUCCESS,
  GO_BACK_ERROR,
  CONTINUE_CONVERSATION_START,
  CONTINUE_CONVERSATION_SUCCESS,
  CONTINUE_CONVERSATION_ERROR,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR 
} from '../actions/data';
import api from '../api';

function createStartConversation() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { conversationFlowKey, language } = options.payload;
    try {
      const data = yield call(() => api.startConversation(conversationFlowKey, language));
      const action = { type: START_CONVERSATION_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: START_CONVERSATION_ERROR, error };

      yield put(action);
    }
  };
}

function* startConversationWatcher() {
  yield takeLatest(START_CONVERSATION_START, createStartConversation());
}

function createStartConversationWithTestCode() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { testCode, conversationFlowKey, language } = options.payload;
    try {
      const data = yield call(() => api.startConversationWithTestCode(testCode, conversationFlowKey, language));
      const action = { type: START_CONVERSATION_WITH_TEST_CODE_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: START_CONVERSATION_WITH_TEST_CODE_ERROR, error };

      yield put(action);
    }
  };
}

function* startConversationWithTestCodeWatcher() {
  yield takeLatest(START_CONVERSATION_WITH_TEST_CODE_START, createStartConversationWithTestCode());
}

function createStartConversationWithBenefitCode() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { benefitCode, conversationFlowKey, language } = options.payload;
    try {
      const data = yield call(() => api.startConversationWithBenefitCode(benefitCode, conversationFlowKey, language));
      const action = { type: START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: START_CONVERSATION_WITH_BENEFIT_CODE_ERROR, error };

      yield put(action);
    }
  };
}

function* startConversationWithBenefitCodeWatcher() {
  yield takeLatest(START_CONVERSATION_WITH_BENEFIT_CODE_START, createStartConversationWithBenefitCode());
}

function createCreatePrescriptionConversationWithId() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { prescriptionId, language } = options.payload;
    try {
      const data = yield call(() => api.createPrescriptionConversationWithId(prescriptionId, language));
      const action = { type: CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR, error };

      yield put(action);
    }
  };
}

function* startCreatePrescriptionConversationWithIdWatcher() {
  yield takeLatest(CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START, createCreatePrescriptionConversationWithId());
}

function createUpdateConversation() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { language, conversationId } = options.payload;
    try {
      const data = yield call(() => api.updateConversation(language, conversationId));
      const action = { type: UPDATE_CONVERSATION_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: UPDATE_CONVERSATION_ERROR, error };

      yield put(action);
    }
  };
}

function* updateConversationWatcher() {
  yield takeLatest(UPDATE_CONVERSATION_START, createUpdateConversation());
}

function createGetDashboardData() {
  return function*(options) {
    try {
      const data = yield call(() => api.getDashboardData());
      const action = { type: GET_DASHBOARD_DATA_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: GET_DASHBOARD_DATA_ERROR, error };

      yield put(action);
    }
  };
}

function* getDashboardDataWatcher() {
  yield takeLatest(GET_DASHBOARD_DATA_START, createGetDashboardData());
}

function createUpdateDashboard() {
  return function*(options) {
    // eslint-disable-line consistent-return
    const { language } = options.payload;
    try {
      const data = yield call(() => api.updateDashboard(language));
      const action = { type: UPDATE_DASHBOARD_DATA_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: UPDATE_DASHBOARD_DATA_ERROR, error };

      yield put(action);
    }
  };
}

function* updateDashboardWatcher() {
  yield takeLatest(UPDATE_DASHBOARD_DATA_START, createUpdateDashboard());
}

function createContinueConversation() {
  return function*(options) {
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.continueConversation(options.payload));
      const action = { type: CONTINUE_CONVERSATION_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: CONTINUE_CONVERSATION_ERROR, error };

      yield put(action);
    }
  };
}

function* continueConversationWatcher() {
  yield takeLatest(CONTINUE_CONVERSATION_START, createContinueConversation());
}

function createSubmitAnswer() {
  return function*(options) {
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.submitAnswer(options.payload));
      const action = { type: SUBMIT_ANSWER_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: SUBMIT_ANSWER_ERROR, error };

      yield put(action);
    }
  };
}

export function* submitAnswerWatcher() {
  yield takeLatest(SUBMIT_ANSWER_START, createSubmitAnswer());
}

function createGoBack() {
  return function*(options) {
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.goBack(options.payload));
      const action = { type: GO_BACK_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: GO_BACK_ERROR, error };

      yield put(action);
    }
  };
}

export function* goBackWatcher() {
  yield takeLatest(GO_BACK_START, createGoBack());
}

export default [
  startConversationWatcher(),
  submitAnswerWatcher(),
  goBackWatcher(),
  continueConversationWatcher(),
  updateConversationWatcher(),
  startConversationWithTestCodeWatcher(),
  updateDashboardWatcher(),
  getDashboardDataWatcher(),
  startCreatePrescriptionConversationWithIdWatcher(),
  startConversationWithBenefitCodeWatcher()
];
