import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import withWindow from '../../windowDecorators/withWindow';
import { FormattedMessage } from 'react-intl';
import {
  transferCodeValidator,
  specialCharactersValidator,
  firstNameAndLastNameValidator
} from '../../helpers/stringValidators';
import initialsValidatior from '../../helpers/initialsValidatior';
import Button from '../Button';
import Loader from '../Loader';

import { buttonType } from '../../constants/buttons';
import { directions, dataType } from '../../constants/data';
import submitData from '../Question/submitData';
import { submitAnswer, goBack, submitAnswerError } from '../../actions/data';

import {
  NavigateNext,
  NavigateBefore,
  ShoppingCart,
  PlaylistAddCheck
} from '@material-ui/icons';

import './index.scss';
import {
  getSiteHref,
  generateFileName,
  generatePrescriptionFileName
} from '../../utils/helpers';
import { setUploadVideoError, setVideoData } from '../../actions/videoUpload';
import { videoErrors } from '../UploadVideo';

const handleBackButtonClick = (
  conversationId,
  dispatch,
  setAnimationDirection,
  conversationFlowStateKey
) => () => {
  setAnimationDirection(directions.BACK);
  dispatch(
    goBack({
      conversationId,
      conversationFlowStateKey,
      action: buttonType.BACK
    })
  );
};

const handleForwardButtonClick = (
  conversationId,
  dispatch,
  setAnimationDirection,
  answer,
  question,
  setCurrentScreenAnswer,
  conversationFlowStateKey,
  setClickedButton,
  actionType
) => () => {
  // For now it's surplus because we are sending it through URL but it will be changed
  const userId = localStorage.getItem('userId');
  const trimmedAnswer =
    answer && typeof answer === 'string' ? answer.trim() : answer;
  const answerData = question
    ? submitData(
        conversationId,
        question,
        trimmedAnswer,
        conversationFlowStateKey,
        userId
      )
    : { conversationId, conversationFlowStateKey, userId };

  answerData.action = actionType;
  setAnimationDirection(directions.FORWARD);
  setCurrentScreenAnswer([]);
  setClickedButton();

  // If text question is transferCode we need to validate that value before sending it to backend
  if (question && question.kind === 'text') {
    if (question.key === 'transferCode' && actionType !== buttonType.SKIP) {
      const isTransferCodeValid = transferCodeValidator(answer);
      if (isTransferCodeValid) {
        dispatch(submitAnswer(answerData));
      } else {
        const errorMessage = (
          <FormattedMessage
            id='error.transfer-code-not-valid'
            defaultMessage='Transfer code is not valid, please check and try again.'
          />
        );
        dispatch(submitAnswerError({ message: errorMessage }));
      }
    } else if (question.key === 'initials' && actionType === buttonType.NEXT) {
      const areInitialsValid = initialsValidatior(trimmedAnswer);
      if (areInitialsValid) {
        const cleanInitialAnswer = trimmedAnswer
          .replace(/[^a-zA-Z]/gi, '')
          .toUpperCase();

        const answerData = submitData(
          conversationId,
          question,
          cleanInitialAnswer,
          conversationFlowStateKey,
          userId
        );

        answerData.action = actionType;
        dispatch(submitAnswer(answerData));
      } else {
        const errorMessage = (
          <FormattedMessage
            id='error.initials-not-valid'
            defaultMessage="Entered initials are not valid, please check your entry and try again. Initials must be 2 to 8 characters long and can't contain numbers or special characters."
          />
        );
        dispatch(submitAnswerError({ message: errorMessage }));
      }
    } else if (
      (question.key === 'firstName' || question.key === 'lastName') &&
      actionType === buttonType.NEXT
    ) {
      const isValueValid = firstNameAndLastNameValidator(answer);
      const trimmedLowercaseValue = answer.trim().toLowerCase();
      const isValueTest = trimmedLowercaseValue === 'test';

      if (!isValueValid || isValueTest) {
        const errorMessage = (
          <FormattedMessage
            id='error.string-is-not-valid'
            defaultMessage='The entered value is not valid. Please check your entry and try again.'
          />
        );
        dispatch(submitAnswerError({ message: errorMessage }));
      } else {
        dispatch(submitAnswer(answerData));
      }
    } else {
      const answerContainsSpecialCharacters = specialCharactersValidator(
        answer
      );
      if (answerContainsSpecialCharacters) {
        const errorMessage = (
          <FormattedMessage
            id='error.string-is-not-valid'
            defaultMessage='The entered value is not valid. Please check your entry and try again.'
          />
        );
        dispatch(submitAnswerError({ message: errorMessage }));
      } else {
        dispatch(submitAnswer(answerData));
      }
    }
  } else {
    dispatch(submitAnswer(answerData));
  }
};

const ActionsBar = ({
  actions,
  conversationId,
  dispatch,
  question,
  breakpoint,
  setAnimationDirection,
  animationDirection,
  basketButtonHref,
  answer,
  contentKind,
  dataLoading,
  setCurrentScreenAnswer,
  conversationFlowStateKey,
  closeSource,
  closeModalHandler,
  isModalOpen,
  flowCountry,
  videoData,
  userData,
  sliderViewed
}) => {
  const basketButtonRef = useRef(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [isVideoUploading, setIsVideoUploading] = useState(null);

  // TODO: Figurate out how to solve this in more elegant way
  const backButton = actions.find(button => button.kind === buttonType.BACK);
  const nextButton = actions.find(button => button.kind === buttonType.NEXT);
  const closeButton = actions.find(button => button.kind === buttonType.CLOSE);
  const configButton = actions.find(
    button => button.kind === buttonType.CONFIGURE
  );
  const basketButton = actions.find(
    button => button.kind === buttonType.BASKET
  );
  const skipButton = actions.find(button => button.kind === buttonType.SKIP);
  const isRecommendation =
    contentKind === dataType.RECOMMENDATION ||
    contentKind === dataType.HEALTH_CHECK_RECOMMENDATION ||
    contentKind === dataType.CHECKOUT;
  const isNextButtonDisabled =
    dataLoading ||
    (contentKind === dataType.QUESTION &&
      question &&
      question.kind !== 'upload' &&
      (!answer || answer.length === 0)) ||
    (contentKind === dataType.QUESTION &&
      question &&
      question.kind === 'upload' &&
      !videoData) ||
    ((contentKind === dataType.RECOMMENDATION ||
      contentKind === dataType.CHECKOUT) &&
      !basketButtonHref) ||
    (contentKind === dataType.SLIDER && !sliderViewed);

  useEffect(() => {
    setButtonClicked(null);
  }, [actions]);

  // We are mimicking Next button click
  const enterClickCallback = useCallback(
    event => {
      if (
        event.keyCode === 13 &&
        !isNextButtonDisabled &&
        nextButton &&
        !isRecommendation
      ) {
        handleForwardButtonClick(
          conversationId,
          dispatch,
          setAnimationDirection,
          answer,
          question,
          setCurrentScreenAnswer,
          conversationFlowStateKey,
          () => setButtonClicked(buttonType.NEXT),
          buttonType.NEXT
        )();
      }

      if (event.keyCode === 13 && !isNextButtonDisabled && isRecommendation) {
        basketButtonRef.current.click();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataLoading, contentKind, answer, isNextButtonDisabled]
  );

  // We are mimicking Close button click
  const escButtonFallbackLink = getSiteHref(flowCountry);
  const escClickCallback = useCallback(
    event => {
      if (event.keyCode === 27) {
        if (isModalOpen) {
          closeModalHandler();
        } else {
          window.location.href = closeSource
            ? closeSource
            : escButtonFallbackLink;
        }
      }
    },
    [closeModalHandler, isModalOpen, closeSource, escButtonFallbackLink]
  );

  useEffect(() => {
    document.addEventListener('keyup', enterClickCallback);
    document.addEventListener('keyup', escClickCallback);

    return () => {
      document.removeEventListener('keyup', enterClickCallback);
      document.removeEventListener('keyup', escClickCallback);
    };
  }, [enterClickCallback, escClickCallback]);

  const isSmall = ['xsmall', 'small'].includes(breakpoint);

  const handleNextButtonClick = () => {
    const isUpload = question && question.kind === 'upload';
    const oldAnswer =
      question && question.answerValue && question.answerValue.value;

    if (isUpload && oldAnswer !== answer) {
      setIsVideoUploading(true);
      if (question && userData && videoData) {
        const isPrescriptionData =
          question.profileAttributeKey === 'selfiVideo' ||
          question.profileAttributeKey === 'prescriptionProof';
        const URL = isPrescriptionData
          ? process.env.REACT_APP_PRESCRIPTIONS_URL
          : process.env.REACT_APP_UPLOAD_VIDEO_URL;
        const fileName = isPrescriptionData
          ? generatePrescriptionFileName(
              userData,
              question.profileAttributeKey,
              conversationId
            )
          : generateFileName(userData, question.profileAttributeKey);

        fetch(`${URL}${fileName}`, {
          method: 'POST',
          headers: {
            'Content-Type': videoData.type
          },
          body: videoData
        })
          .then(response => response.json())
          .then(success => {
            setIsVideoUploading(false);
            dispatch(setVideoData(null));
            handleForwardButtonClick(
              conversationId,
              dispatch,
              setAnimationDirection,
              success.selfLink,
              question,
              setCurrentScreenAnswer,
              conversationFlowStateKey,
              () => setButtonClicked(buttonType.NEXT),
              buttonType.NEXT
            )();
          })
          .catch(error => {
            dispatch(setVideoData(null));
            setIsVideoUploading(false);
            dispatch(setUploadVideoError(videoErrors.VIDEO_NOT_UPLOADED));
          });
      }
    } else {
      handleForwardButtonClick(
        conversationId,
        dispatch,
        setAnimationDirection,
        answer,
        question,
        setCurrentScreenAnswer,
        conversationFlowStateKey,
        () => setButtonClicked(buttonType.NEXT),
        buttonType.NEXT
      )();
    }
  };

  const backButtonClasses = cx({
    'actions-bar__button': true,
    'actions-bar__button--back': true,
    'actions-bar__recommendation-back-button': isSmall && isRecommendation,
    'actions-bar__button--loading':
      !isSmall && dataLoading && animationDirection === directions.BACK
  });

  const rightButtonWrapperClasses = cx({
    'actions-bar__right-button-wrapper': true,
    'actions-bar__right-button-wrapper--with-skip': skipButton,
    'actions-bar__right-button-wrapper--only': !backButton
  });

  const actionBarClasses = cx({
    'actions-bar': true
  });

  const nextButtonClasses = cx({
    'actions-bar__button': true,
    'actions-bar__button--next': true,
    'actions-bar__button--loading':
      (dataLoading &&
        animationDirection === directions.FORWARD &&
        buttonClicked === buttonType.NEXT) ||
      isVideoUploading
  });

  const skipButtonClasses = cx({
    'actions-bar__button': true,
    'actions-bar__button--skip': true,
    'actions-bar__button--loading':
      dataLoading &&
      animationDirection === directions.FORWARD &&
      buttonClicked === buttonType.SKIP
  });

  return (
    <div className={actionBarClasses}>
      <div className='actions-bar__content'>
        {backButton && (
          <Button
            onClick={
              backButton.redirectUrl
                ? () => {}
                : handleBackButtonClick(
                    conversationId,
                    dispatch,
                    setAnimationDirection,
                    conversationFlowStateKey
                  )
            }
            href={backButton.redirectUrl || null}
            className={backButtonClasses}
            purple
            noNewTab
            disabled={dataLoading}
            hasDisabledStyle={animationDirection === directions.BACK}
          >
            {dataLoading && animationDirection === directions.BACK ? (
              <Loader />
            ) : (
              <>
                <NavigateBefore fontSize='large' />{' '}
                {!isSmall && (
                  <span className='actions-bar__button-text-wrapper'>
                    <span className='actions-bar__button-text'>
                      {backButton.titleText}
                    </span>
                    {backButton.subTitleText && (
                      <span className='actions-bar__button-subtext'>
                        {backButton.subTitleText}
                      </span>
                    )}
                  </span>
                )}
              </>
            )}
          </Button>
        )}
        <div className={rightButtonWrapperClasses}>
          {skipButton && (
            <Button
              onClick={handleForwardButtonClick(
                conversationId,
                dispatch,
                setAnimationDirection,
                //value is null
                null,
                question,
                setCurrentScreenAnswer,
                conversationFlowStateKey,
                () => setButtonClicked(buttonType.SKIP),
                buttonType.SKIP
              )}
              purple
              className={skipButtonClasses}
              disabled={dataLoading}
              hasDisabledStyle={animationDirection === directions.FORWARD}
            >
              {dataLoading &&
              buttonClicked === buttonType.SKIP &&
              animationDirection === directions.FORWARD ? (
                <Loader />
              ) : (
                <>
                  <span className='actions-bar__button-text'>
                    {skipButton.titleText}
                  </span>{' '}
                  <NavigateNext fontSize='large' />
                </>
              )}
            </Button>
          )}
          {nextButton && !isRecommendation && (
            <Button
              onClick={
                !nextButton.redirectUrl ? handleNextButtonClick : () => {}
              }
              href={nextButton.redirectUrl}
              className={nextButtonClasses}
              orange
              disabled={isNextButtonDisabled}
              hasDisabledStyle={animationDirection === directions.FORWARD}
              isVideoUploading={isVideoUploading}
              noNewTab
            >
              {(dataLoading &&
                buttonClicked === buttonType.NEXT &&
                animationDirection === directions.FORWARD) ||
              isVideoUploading ? (
                <Loader />
              ) : (
                <>
                  <span className='actions-bar__button-text'>
                    {nextButton.titleText}
                  </span>{' '}
                  <NavigateNext fontSize='large' />
                </>
              )}
            </Button>
          )}
          {closeButton &&
            !isRecommendation &&
            (closeSource || closeButton.redirectUrl) && (
              <Button
                orange
                className='actions-bar__button'
                disabled={isNextButtonDisabled}
                href={closeSource || closeButton.redirectUrl}
                noNewTab
              >
                {closeButton.titleText}
              </Button>
            )}
          {(configButton || isRecommendation) && (
            <Button
              className='actions-bar__button actions-bar__button--configure'
              purple
            >
              <PlaylistAddCheck />
              <span className='actions-bar__button-text'>
                <FormattedMessage
                  id='actions.configure'
                  defaultMessage='Configure'
                />
              </span>
            </Button>
          )}
          {(basketButton || isRecommendation) && (
            <Button
              className='actions-bar__button actions-bar__button--basket'
              orange
              disabled={isNextButtonDisabled || !basketButtonHref}
              href={basketButtonHref}
              noNewTab
              buttonRef={basketButtonRef}
            >
              <span className='actions-bar__button-text'>
                <FormattedMessage
                  id='actions.add-to-basket'
                  defaultMessage='Add to basket'
                />
              </span>
              <ShoppingCart />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ActionsBar.propTypes = {
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ]),
  actions: PropTypes.array,
  dispatch: PropTypes.func,
  breakpoint: PropTypes.string,
  setAnimationDirection: PropTypes.func,
  contentKind: PropTypes.string,
  // "Add to basket" button href, getting value only from Test Recommendation page
  basketButtonHref: PropTypes.string,
  conversationId: PropTypes.string,
  question: PropTypes.object,
  dataLoading: PropTypes.bool,
  animationDirection: PropTypes.string,
  setCurrentScreenAnswer: PropTypes.func,
  conversationFlowStateKey: PropTypes.string,
  closeSource: PropTypes.string,
  closeModalHandler: PropTypes.func,
  isModalOpen: PropTypes.bool,
  flowCountry: PropTypes.string,
  videoData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userData: PropTypes.object,
  sliderViewed: PropTypes.bool
};

const mapStateToProps = state => ({
  userData: state.user.get('user'),
  videoData: state.videoUpload.get('videoData'),
  sliderViewed: state.app.get('sliderViewed')
});

export default connect(mapStateToProps)(withWindow(ActionsBar));
