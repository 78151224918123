import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import PropTypes from 'prop-types';
import Button from '../Button';
import { connect } from 'react-redux';
import Recorder from '../Recorder';
import withWindow from '../../windowDecorators/withWindow';
import { ReactComponent as VideoUploadIcon } from '../../../assets/svg/video-upload.svg';
import { ReactComponent as FileUploadIcon } from '../../../assets/svg/file-upload.svg';
import { ReactComponent as VideoCameraIcon } from '../../../assets/svg/video-camera.svg';
import { FormattedMessage } from 'react-intl';
import { setUploadVideoError, setVideoData } from '../../actions/videoUpload';
import CheckIcon from '@material-ui/icons/Check';
import './index.scss';

export const videoErrors = {
  VIDEO_NOT_SELECTED: 'video-not-selected',
  VIDEO_NOT_UPLOADED: 'video-not-uploaded'
};

const UploadVideo = ({
  setCurrentScreenAnswer,
  userData,
  breakpoint,
  userLoading,
  data,
  dispatch,
  videoData,
  videoError
}) => {
  const {
    fileType,
    upload,
    record,
    isPrescriptionUpload
  } = data.question.upload;
  const isVideoUpload = fileType === 'video/*';
  const [videoAlreadyUploaded, setIsVideoAlreadyUploaded] = useState(false);
  const inputRef = useRef(null);
  const [isRecordBlockVisible, setIsRecordBlockVisible] = useState(false);
  const smallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  useEffect(() => {
    if (data) {
      if (
        data.question &&
        data.question.answerValue &&
        data.question.answerValue.value
      ) {
        setCurrentScreenAnswer(data.question.answerValue.value);
        dispatch(setVideoData(data.question.answerValue.value));
        setIsVideoAlreadyUploaded(true);
      }
    }
  }, [data, dispatch, setCurrentScreenAnswer]);

  const onVideoSelect = e => {
    if (videoError) {
      dispatch(setUploadVideoError(null));
    }
    const video = e.target.files[0];
    if (video) {
      setIsVideoAlreadyUploaded(false);
      if (!video.type.includes('video/')) {
        dispatch(setUploadVideoError(videoErrors.VIDEO_NOT_SELECTED));
      } else {
        dispatch(setVideoData(e.target.files[0]));
        // Setting to empty so we can upload new video, there is a check in actionBar
        setCurrentScreenAnswer('');
      }
    }
  };

  // TODO: checkes
  const onFileSelect = e => {
    const file = e.target.files[0];
    dispatch(setVideoData(file));
    setCurrentScreenAnswer('');
  };

  const setVideo = data => {
    dispatch(setVideoData(data));
  };

  const goBack = () => {
    setIsRecordBlockVisible(false);
    setVideo(null);
  };

  if (userLoading) {
    return (
      <div className='upload-video'>
        <div className='upload__content' />
      </div>
    );
  }

  // TODO: Check secure pages
  if (!userData && !userLoading) {
    return (
      <div className='upload-video'>
        <div className='upload__content'>
          <div className='upload__message'>
            <FormattedMessage
              id='upload.please-login-first'
              defaultMessage='Please login first'
            />
          </div>
        </div>
      </div>
    );
  }

  const getUploadText = () => {
    let text = 'upload.upload-or-take-video';
    if (isVideoUpload) {
      if (upload) {
        text = 'upload.select-video';
      }
      if (upload && record) {
        if (smallOrMedium) {
          text = 'upload.upload-or-take-video';
        } else {
          text = 'upload.select-video';
        }
      }
    } else {
      if (upload) {
        if (smallOrMedium) {
          text = 'upload.upload-file-or-take-photo';
        } else {
          text = 'upload.choose-file';
        }
      }
      if (upload && record) {
        if (smallOrMedium) {
          text = 'upload.upload-file-or-take-photo';
        } else {
          text = 'upload.choose-file';
        }
      }
    }

    return text;
  };

  const changeText = isVideoUpload
    ? 'upload.change-video'
    : 'upload.change-file';
  const uploadText = getUploadText();
  const readyToSendText = isVideoUpload
    ? 'upload.video-ready-for-upload'
    : 'upload.file-ready-for-upload';

  return (
    <div className='upload'>
      <div className='upload__content'>
        {data.question && data.question.descriptionText && (
          <div className='upload__markdown-wrapper'>
            <Markdown className='upload__markdown' rehypePlugins={[rehypeRaw]}>
              {data.question.descriptionText}
            </Markdown>
          </div>
        )}
        {isRecordBlockVisible ? (
          <Recorder
            setVideo={setVideo}
            setIsRecordBlockVisible={setIsRecordBlockVisible}
            setCurrentScreenAnswer={setCurrentScreenAnswer}
          />
        ) : (
          <div className='upload__action-buttons'>
            {/*
             * Small and medium for covid
             */}
            {(smallOrMedium ||
              (!smallOrMedium && isPrescriptionUpload && upload)) && (
              <div className='upload__camera-role-wrapper'>
                <input
                  type='file'
                  ref={inputRef}
                  style={{ display: 'none' }}
                  onChange={isVideoUpload ? onVideoSelect : onFileSelect}
                  accept={fileType}
                />
                <button
                  className='upload__action-button'
                  onClick={() => inputRef.current.click()}
                >
                  <span className='upload__icon-wrapper'>
                    {isVideoUpload ? <VideoUploadIcon /> : <FileUploadIcon />}
                  </span>
                  {videoData ? (
                    <FormattedMessage
                      id={changeText}
                      defaultMessage='Change file'
                    />
                  ) : (
                    <FormattedMessage
                      id={uploadText}
                      defaultMessage='Select file'
                    />
                  )}
                </button>
              </div>
            )}
            {isVideoUpload && !smallOrMedium && (
              <button
                className='upload__action-button upload__action-button--record-video'
                onClick={() => setIsRecordBlockVisible(true)}
              >
                <span className='upload__icon-wrapper'>
                  <VideoCameraIcon />
                </span>
                <FormattedMessage
                  id='upload.record'
                  defaultMessage='Record video'
                />
              </button>
            )}
          </div>
        )}
        <div className='upload__buttons-wrapper'>
          {isRecordBlockVisible && (
            <Button
              white
              onClick={goBack}
              className={cx({ 'upload__cancel-button': videoData })}
            >
              <FormattedMessage id='upload.cancel' defaultMessage='Cancel' />
            </Button>
          )}
        </div>
        {videoData && typeof videoData === 'object' && (
          <div
            className={cx('upload__already-uploaded', {
              'upload__already-uploaded--record-block': isRecordBlockVisible
            })}
          >
            <CheckIcon />
            <span className='upload__already-uploaded-text'>
              <FormattedMessage
                id={readyToSendText}
                defaultMessage='File ready for upload'
              />
            </span>
          </div>
        )}
        {!isRecordBlockVisible && videoAlreadyUploaded && (
          <div className='upload__already-uploaded'>
            <CheckIcon />
            <span className='upload__already-uploaded-text'>
              <FormattedMessage
                id='upload.successfully-uploaded'
                defaultMessage='Selected video successfully uploaded'
              />
            </span>
          </div>
        )}
        {videoError && (
          <div className='upload__error-message'>
            {videoError === videoErrors.VIDEO_NOT_SELECTED ? (
              <FormattedMessage
                id='upload.please-select-video'
                defaultMessage='Please select video'
              />
            ) : (
              <FormattedMessage
                id='upload.something-went-wrong'
                defaultMessage='Something went wrong, please select video'
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

UploadVideo.propTypes = {
  data: PropTypes.object,
  setCurrentScreenAnswer: PropTypes.func,
  userData: PropTypes.object,
  breakpoint: PropTypes.string,
  userLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  videoData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  videoError: PropTypes.string
};

const mapStateToProps = state => ({
  videoData: state.videoUpload.get('videoData'),
  videoError: state.videoUpload.get('videoError')
});

export default connect(mapStateToProps)(withWindow(UploadVideo));
