import React from 'react';
import PropTypes from 'prop-types';
import { Heading3, Subtitle2, Body1 } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const MyHealthEmployeeHealthHero = ({ data }) => {
  const { name, logo } = data.companyData;
  return (
    <div className='my-health-employee-health-hero'>
      <Heading3 className='my-health-employee-health-hero__title'>
        <FormattedMessage
          id='my-health.discover-employee-benefits'
          defaultMessage='Discover Your Employee Health Benefits'
        />
      </Heading3>
      <div>
        <Subtitle2 className='my-health-employee-health-hero__text'>
          <FormattedMessage
            id='my-health.employee-program'
            defaultMessage='Employee program'
          />
        </Subtitle2>
        <Subtitle2 className='my-health-employee-health-hero__text'>
          <FormattedMessage
            id='my-health.provided-by'
            defaultMessage='Provided by'
          />
        </Subtitle2>

        {logo ? (
          <div className='my-health-employee-health-hero__logo-wrapper'>
            <img src={logo} alt={`${name} logo`} />
          </div>
        ) : (
          <Body1 className='my-health-employee-health-hero__logo-text'>
            {name}
          </Body1>
        )}
      </div>
    </div>
  );
};

MyHealthEmployeeHealthHero.propTypes = {
  data: PropTypes.object
};

export default MyHealthEmployeeHealthHero;
