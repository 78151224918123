import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyHealthContainer from '../../components/MyHealthContainer';

import './index.scss';

const MyHealthShop = ({ dispatch, lang, changeLanguage }) => {
  return (
    <MyHealthContainer
      dispatch={dispatch}
      lang={lang}
      changeLanguage={changeLanguage}
    >
      <div className='my-health-shop'>Shop</div>
    </MyHealthContainer>
  );
};

MyHealthShop.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default connect()(MyHealthShop);
