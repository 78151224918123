import React from 'react';
import { useSwiper } from 'swiper/react';

import { NavigateNext } from '@material-ui/icons';

import './index.scss';

const SliderNextButton = () => {
  const swiper = useSwiper();

  return (
    <button className='slider-next-button' onClick={() => swiper.slideNext()}>
      <NavigateNext />
    </button>
  );
};

export default SliderNextButton;
