import React from 'react';
import PropTypes from 'prop-types';
import { Body2 } from '../../../../components/Typography';
import Link from '../../../../components/Link';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const ContactViaFormAccordionContent = ({ language }) => {
  const contactFormUrl =
    language === 'en'
      ? 'https://www.css.ch/en/private-customers/my-health/advice/health-coach/contact.html'
      : 'https://www.css.ch/de/privatkunden/meine-gesundheit/beratung/gesundheitscoach/kontakt.html';

  return (
    <div className='contact-via-form-accordion-content'>
      <Body2>
        <FormattedMessage
          id='test-result.partner-code.css-support.app-accordion.text'
          defaultMessage='CSS health coaches answer your questions via email.'
        />
      </Body2>

      <Link
        url={contactFormUrl}
        size='small'
        className='contact-via-form-accordion-content__button'
      >
        <FormattedMessage
          id='test-result.partner-code.css-support.app-accordion.app-button.text'
          defaultMessage='Go to contact form'
        />
      </Link>
    </div>
  );
};

ContactViaFormAccordionContent.propTypes = {
  language: PropTypes.string
};

export default ContactViaFormAccordionContent;
