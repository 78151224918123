import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';

import Modal from '../Modal';
import RecommendationModalProduct from '../RecommendationModalProduct';

import './index.scss';

const renderProducts = (products, flowCountry) => {
  return products.map(product => {
    return (
      <RecommendationModalProduct
        key={product.key}
        data={product}
        flowCountry={flowCountry}
      />
    );
  });
};

const RecommendationModal = ({
  recommendedModalData,
  onCloseButtonClick,
  selectedTest,
  isModalClosing,
  flowCountry
}) => {
  const selectedTestData = recommendedModalData.find(
    item => item.key === selectedTest
  );

  return (
    <Modal
      className='recommendation-modal'
      onCloseButtonClick={onCloseButtonClick}
      closeButtonRight
      isModalClosing={isModalClosing}
    >
      <div className='recommendation-modal__content'>
        <div className='recommendation-modal__header'>
          <span className='recommendation-modal__title'>
            {selectedTestData.titleText}
          </span>
          <span className='recommendation-modal__header-text'>
            <FormattedMessage
              id='recommend.tests-included'
              defaultMessage='The following tests are included'
            />
            :
          </span>
        </div>
        {renderProducts(selectedTestData.products, flowCountry)}
      </div>
      {selectedTestData.extensionText && (
        <div className='recommendation-modal__text-wrapper'>
          <Markdown>{selectedTestData.extensionText}</Markdown>
        </div>
      )}
    </Modal>
  );
};

RecommendationModal.propTypes = {
  recommendedModalData: PropTypes.array,
  onCloseButtonClick: PropTypes.func,
  selectedTest: PropTypes.string,
  isModalClosing: PropTypes.bool,
  flowCountry: PropTypes.string
};

export default RecommendationModal;
