import React from 'react';
import PropTypes from 'prop-types';
import RecommendedActionsDescription from '../RecommendedActionsDescription';
import RecommendedActionsProductCards from '../RecommendedActionsProductCards';
import RecommendedActionsFooterCTA from '../RecommendedActionsFooterCTA';
import RecommendedActionsCTAs from '../RecommendedActionsCTAs';
import RecommendedActionsContentCards from '../RecommendedActionsContentCards';

const RecommendedActionsContent = ({
  content,
  productCards,
  CTAs,
  labReport,
  footerCTA,
  contentCards,
  handleInfo,
  timingKey,
  contentKey,
  language,
  done
}) => {
  return (
    <>
      {content.description && (
        <RecommendedActionsDescription description={content.description} />
      )}
      {contentCards && contentCards.length > 0 && (
        <RecommendedActionsContentCards
          contentCards={contentCards}
          handleInfo={handleInfo}
        />
      )}
      {productCards && (
        <RecommendedActionsProductCards productCards={productCards} />
      )}
      {CTAs && CTAs.length > 0 && (
        <RecommendedActionsCTAs
          timingKey={timingKey}
          contentKey={contentKey}
          ctas={CTAs}
          labReport={labReport}
          language={language}
          done={done}
        />
      )}
      {footerCTA && <RecommendedActionsFooterCTA footerCTA={footerCTA} />}
    </>
  );
};

RecommendedActionsContent.propTypes = {
  content: PropTypes.object,
  done: PropTypes.bool,
  productCards: PropTypes.array,
  footerCTA: PropTypes.object,
  contentCards: PropTypes.array,
  CTAs: PropTypes.array,
  labReport: PropTypes.string,
  handleInfo: PropTypes.func,
  timingKey: PropTypes.string,
  contentKey: PropTypes.string,
  language: PropTypes.string
};

export default RecommendedActionsContent;
