import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWindow from '../../windowDecorators/withWindow';
import Carousel from '../Carousel';
import RiskCard from '../RiskCard';
import { FormattedMessage } from 'react-intl';

import { riskLevel } from '../../constants/data';
import breakpoints from '../../constants/breakpoints';

import './index.scss';

const RISK_LEVELS = [riskLevel.HIGH, riskLevel.MEDIUM, riskLevel.LOW];

const getRisksArray = risks => {
  return RISK_LEVELS.map(level => {
    const filteredRisks = risks.filter(risk => risk.riskLevel === level);
    if (filteredRisks.length > 0) {
      const updatedFirstRisk = {
        ...filteredRisks[0],
        isFirst: true
      };
      filteredRisks.splice(0, 1, updatedFirstRisk);
    }

    return {
      level: level,
      risks: filteredRisks
    };
  });
};

const renderAggregatedRisksCards = risks => {
  const risksArray = getRisksArray(risks);

  return risksArray.map(risk => {
    return <RiskCard data={risk} key={risk.level} aggregated />;
  });
};

const renderRisksCards = risks => {
  return risks.map(risk => {
    return <RiskCard data={risk} key={risk.key} />;
  });
};

const Risks = ({ breakpoint, data, setModalData, setIsModalOpen }) => {
  const { risks, isAggregated, titleText } = data.info;

  const isExtraSmall = breakpoint === breakpoints.name.XSMALL;
  const isSmallOrMedium = [breakpoints.name.XSMALL, breakpoints.name.SMALL, breakpoints.name.MEDIUM].includes(
    breakpoint
  );
  const cards = isAggregated ? renderAggregatedRisksCards(risks) : renderRisksCards(risks);
  const hasNoRisks = risks.length === 0;
  setModalData(getRisksArray(risks));

  return (
    <>
      <div className='risks'>
        <div className='risks__title-wrapper'>
          <h1 className='risks__title'>{hasNoRisks ? 'You are not running any risk' : titleText}</h1>
          {isAggregated && (
            <button onClick={() => setIsModalOpen(true)} className='risks__title-button'>
              <FormattedMessage id='app.show-why' defaultMessage='Show why' />
            </button>
          )}
        </div>
        {isSmallOrMedium ? (
          <Carousel
            centerPadding={isExtraSmall ? 30 : 50}
            breakpoint={breakpoint}
            key={isAggregated ? 'aggregated' : 'notAggregated'}
          >
            {cards}
          </Carousel>
        ) : (
          <div className='risks__content'>{cards}</div>
        )}
      </div>
    </>
  );
};

Risks.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.object,
  setModalData: PropTypes.func,
  setIsModalOpen: PropTypes.func
};

export default connect()(withWindow(Risks));
