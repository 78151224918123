import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import PropTypes from 'prop-types';

import './index.scss';

const StauffacherBlock = ({ title, text }) => {
  return (
    <div className='stauffacher-block'>
      <div className='stauffacher-block__content'>
        <span className='stauffacher-block__title'>{title}</span>
        <p className='stauffacher-block__text'>
          <Markdown rehypePlugins={[rehypeRaw]}>{text}</Markdown>
        </p>
      </div>
    </div>
  );
};

StauffacherBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default StauffacherBlock;
