const codePoints = "1234567890ABCDEFHKMNPRSTUWXYZ";

const numberOfValidInputCharacters = () => {
  return codePoints.length;
}

const codePointFromCharacter = (character) => {
  return codePoints.indexOf(character);
}

const transferCodeValidator = (input) => {
  let factor = 1;
  let sum = 0;
  let n = numberOfValidInputCharacters();

  // Starting from the right, work leftwards
  // Now, the initial "factor" will always be "1"
  // since the last character is the check character.
  for (let i = input.length - 1; i >= 0; i--) {
      let codePoint = codePointFromCharacter(input.charAt(i));
      let addend = factor * codePoint;

      // Alternate the "factor" that each "codePoint" is multiplied by
      factor = (factor === 2) ? 1 : 2;

      // Sum the digits of the "addend" as expressed in base "n"
      addend = (Math.floor(addend / n)) + (addend % n);
      sum += addend;
  }

  let remainder = sum % n;

  return (remainder === 0);
}

const specialCharactersValidator = (input) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[\/\\<>:;\[\]{}|]/;
  return regex.test(input);
}

  // first name and last name should not contain special characters and numbers except for the following: - ' . space and it should have at least one letter
const firstNameAndLastNameValidator = (input) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(?!^[.\s-]*$)[\p{L}.\s-]+$/u;
  return regex.test(input);
}

export {
  transferCodeValidator,
  specialCharactersValidator,
  firstNameAndLastNameValidator
};
