import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Table from '../Table';

import './index.scss';

const TextBlock = ({ data }) => {
  const contentElement = useRef(null);
  const { imageUrl, text, iframe, tableData } = data.info;

  useEffect(() => {
    if (contentElement) {
      const links = contentElement.current.getElementsByTagName('a');

      if (links.length > 0) {
        for (var i = 0; i < links.length; i++) {
          links[i].setAttribute('target', '_blank');
          links[i].setAttribute('rel', 'noopener noreferrer');
        }
      }
    }
  }, [text]);

  const textWrapperClasses = cx({
    'text-block__text-wrapper': true,
    'text-block__text-wrapper--no-image': !imageUrl
  });

  return (
    <div className='text-block'>
      <div ref={contentElement} className='text-block__content'>
        <div className='text-block__text-content'>
          {imageUrl && (
            <div className='text-block__image-wrapper'>
              <img className='text-block__image' src={imageUrl} alt='alt' />
            </div>
          )}
          <div>
            <div className={textWrapperClasses}>
              <Markdown
                className='text-block__text'
                rehypePlugins={[rehypeRaw]}
              >
                {text}
              </Markdown>
            </div>
            {iframe && (
              <div className='text-block__iframe-wrapper'>
                <iframe
                  src={iframe}
                  className='text-block__iframe'
                  title='compendium'
                />
              </div>
            )}
          </div>
        </div>
        {tableData && <Table data={tableData} />}
      </div>
    </div>
  );
};

TextBlock.propTypes = {
  data: PropTypes.object
};

export default TextBlock;
