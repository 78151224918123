import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

import { Close } from '@material-ui/icons';

import './index.scss';

const Modal = ({ onCloseButtonClick, children, small, closeButtonRight, className, isModalClosing }) => {
  useEffect(() => {
    document.body.classList.add('scroll-disabled');

    return () => {
      document.body.classList.remove('scroll-disabled');
    };
  });

  const contentClasses = cx(className, {
    modal__content: true,
    'modal__content--small': small,
    'modal__content--exiting': isModalClosing
  });

  const closeButtonClasses = cx({
    'modal__close-button': true,
    'modal__close-button--right': closeButtonRight
  });

  return (
    <div className='modal'>
      <OutsideClickHandler onOutsideClick={onCloseButtonClick}>
        <div className={contentClasses}>
          <button className={closeButtonClasses} onClick={onCloseButtonClick}>
            <Close className='modal__close-button-icon' />
          </button>
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

Modal.propTypes = {
  onCloseButtonClick: PropTypes.func,
  children: PropTypes.any,
  small: PropTypes.bool,
  closeButtonRight: PropTypes.bool,
  className: PropTypes.string,
  isModalClosing: PropTypes.bool
};

export default Modal;
