function firstLetterCapital(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

function getDecodedUrl(encodedSource) {
  const PARENT_URL = 'yourself.health';
  let decodedResult = null;

  try {
    decodedResult = window.atob(encodedSource);
  } catch (error) {
    decodedResult = error;
  }

  return typeof decodedResult === 'string' && decodedResult.indexOf(PARENT_URL) !== -1 ? decodedResult : null;
}

function getDefaultUrl(language, siteHref) {
  if (language !== 'en') {
    return `${siteHref}/${language}/`;
  }

  return siteHref;
}

function getFlowCountry(conversationFlowKey) {
  let flowCountry = 'switzerland';
  if (conversationFlowKey.endsWith('.germany')) {
    flowCountry = 'germany';
  }
  return flowCountry;
}

function getSiteHref(flowCountry) {
  let siteHref = 'https://yourself.health/';
  if (flowCountry === 'germany') {
    siteHref = 'https://healthyourself.eu/';
  }
  return siteHref;
}

function authHeader() {
  const token = localStorage.getItem('accessToken');

  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function generateFileName(userData, questionName) {
  const { userId } = userData;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = currentDate.getDate();
  day = day < 10 ? `0${day}` : day;
  let hours = currentDate.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = currentDate.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let seconds = currentDate.getSeconds();
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  const firstSixCharsOfId = userId.substring(0,6);

  // if (type.includes('webm')) {
  //   videoType = '.webm';
  // } else if (type.includes('mp4')) {
  //   videoType = '.mp4';
  // } else if (type.includes('mov')) {
  //   videoType = '.mov';
  // } else {
  //   videoType = '.mp4';
  // }
  
  return `${firstSixCharsOfId}_${year}${month}${day}-${hours}${minutes}${seconds}_${questionName}`;
};

function generatePrescriptionFileName(userData, questionName, conversationId) {
  const { userId } = userData;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = currentDate.getDate();
  day = day < 10 ? `0${day}` : day;
  let hours = currentDate.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = currentDate.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let seconds = currentDate.getSeconds();
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  const firstSixCharsOfId = userId.substring(0,6);
  
  return `humanum-uster/${conversationId}/${firstSixCharsOfId}_${year}${month}${day}-${hours}${minutes}${seconds}_${questionName}`;
}

export { firstLetterCapital, getDecodedUrl, getDefaultUrl, getFlowCountry, getSiteHref, authHeader, generateFileName, generatePrescriptionFileName };
