import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Product from './Product';

import './index.scss';

const CANCER_SCREENING_ID = '395';

const getCheckoutButtonHref = includedVariants => {
  let redirectUrl = 'https://yourself.health/cart/add?';

  if (includedVariants.length > 0) {
    for (let i = 0; i < includedVariants.length; i++) {
      const productString =
        i === 0
          ? `id[]=${includedVariants[i]}`
          : `&id[]=${includedVariants[i]}`;
      redirectUrl += productString;
    }
  } else {
    redirectUrl = null;
  }

  return redirectUrl;
};

const HealthCheckRecommendation = ({ data, setBasketButtonHref }) => {
  const { productList, subtitle } = data;
  const recommendedProducts = productList.filter(
    product => product.isRecommended
  );
  const startVariantsFiltered = recommendedProducts.filter(product => {
    return product.id !== CANCER_SCREENING_ID;
  });

  const startVariants = startVariantsFiltered.map(product => product.id);

  const products = productList.filter(product => product.type === 'product');
  const addons = productList.filter(product => product.type === 'addon');

  const [includedVariants, setIncludedVariants] = useState(startVariants);

  const onCheckboxChange = variantId => {
    const isAdded = includedVariants.find(variant => variant === variantId);
    const newVariants = isAdded
      ? includedVariants.filter(variant => variant !== variantId)
      : [...includedVariants, variantId];
    setIncludedVariants(newVariants);
  };

  useEffect(() => {
    const href = getCheckoutButtonHref(includedVariants);
    setBasketButtonHref(href);
  }, [includedVariants, setBasketButtonHref]);

  return (
    <div className='health-check-recommendation'>
      <div className='health-check-recommendation__content'>
        <div className='health-check-recommendation__product-list'>
          {products.map(product => (
            <Product
              key={product.id}
              data={product}
              onCheckboxChange={onCheckboxChange}
              isOpenByDefault
            />
          ))}
        </div>
        {addons.length > 0 && (
          <>
            <span className='health-check-recommendation__subtitle'>
              {subtitle}
            </span>
            <div className='health-check-recommendation__addon-list'>
              {addons.map(addon => (
                <Product
                  key={addon.id}
                  data={addon}
                  onCheckboxChange={onCheckboxChange}
                  isAddon
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

HealthCheckRecommendation.propTypes = {
  data: PropTypes.object.isRequired,
  setBasketButtonHref: PropTypes.func
};

export default HealthCheckRecommendation;
