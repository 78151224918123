import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Accordion from '../../../../components/Accordion';
import MyHealthEmployeeHealthProgramContent from '../MyHealthEmployeeHealthProgramContent';

const MyHealthEmployeeDiscountAccordion = ({
  toggle,
  handleInfo,
  handleModal,
  isOpen
}) => {
  return (
    <Accordion
      type={
        <FormattedMessage
          id='my-health.additional-benefits'
          defaultMessage='Additional benefits'
        />
      }
      title={
        <FormattedMessage
          id='my-health.explore-other-products-and-services'
          defaultMessage='Explore other products & services'
        />
      }
      isFirstAccordionOpen={isOpen}
      onToggle={toggle}
      content={
        <MyHealthEmployeeHealthProgramContent
          description={
            <FormattedMessage
              id='my-health.additional-benefits-description'
              defaultMessage='Through your employer, you also benefit from reduced prices on all Health Yourself products. Use the “Shop” tab to purchase products at member prices. Add products to your cart and discounts will be automatically applied during checkout.'
            />
          }
          descriptionCTA={{
            text: (
              <FormattedMessage
                id='my-health.explore-other-products'
                defaultMessage='Explore other products'
              />
            ),
            type: 'link',
            url:
              'https://yourself.health/discount/EMPLOYEE_BENEFIT_DISCOUNT_10/?redirect=/pages/lab-tests/'
          }}
          handleInfo={handleInfo}
          handleModal={handleModal}
        />
      }
    />
  );
};

MyHealthEmployeeDiscountAccordion.propTypes = {
  toggle: PropTypes.func,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  klenicoCoupon: PropTypes.object
};

export default MyHealthEmployeeDiscountAccordion;
