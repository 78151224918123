import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right.svg';

import './index.scss';

const ParameterLink = ({ data }) => {
  const { iconUrl, title, url, isRead } = data;
  const parameterLinkClasses = cx('parameter-link', {
    'parameter-link--isRead': isRead,
  });

  const parameterLinkArrowClasses = cx('parameter-link__arrow', {
    'parameter-link__arrow--isRead': isRead,
  });

  return (
    <a href={url} className={parameterLinkClasses}>
      <div className='parameter-link__content'>
        <img src={iconUrl} alt={title} className='parameter-link__icon'/>
        {title}
        <ArrowRight className={parameterLinkArrowClasses}/>
      </div>
    </a> 
  );
};

ParameterLink.propTypes = {
  data: PropTypes.object,
};

export default ParameterLink;