import React from 'react';
import PropTypes from 'prop-types';
import ContentCard from '../../../../components/ContentCard';

import './index.scss';

const RecommendedActionsContentCards = ({ contentCards, handleInfo }) => {
  return (
    <div className='recommended-actions-content-cards'>
      {contentCards.map(card => {
        return (
          <ContentCard key={card.title} data={card} handleInfo={handleInfo} />
        );
      })}
    </div>
  );
};

RecommendedActionsContentCards.propTypes = {
  contentCards: PropTypes.array,
  handleInfo: PropTypes.func
};

export default RecommendedActionsContentCards;
