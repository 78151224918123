import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withWindow from '../../windowDecorators/withWindow';
import Markdown from 'react-markdown';

import Button from '../Button';

import './index.scss';

const AccordionTextContent = ({ data, breakpoint }) => {
  const { text, iconUrl, primaryButtonData, secondaryButtonData } = data;
  const isSmall = ['xsmall', 'small'].includes(breakpoint);

  const leftSideClasses = cx('accordion-text-content__left-side', {
    'accordion-text-content__left-side--two-buttons':
      primaryButtonData && secondaryButtonData
  });

  const rightSideClasses = cx('accordion-text-content__right-side', {
    'accordion-text-content__right-side--two-buttons':
      primaryButtonData && secondaryButtonData
  });

  return (
    <div className='accordion-text-content'>
      <div className={leftSideClasses}>
        <img
          src={iconUrl}
          alt='product-icon'
          className='accordion-text-content__image'
        />
        {primaryButtonData && isSmall && (
          <Button
            className='accordion-text-content__button'
            orange
            href={primaryButtonData.url}
          >
            {primaryButtonData.text}
          </Button>
        )}
      </div>
      <div className={rightSideClasses}>
        <span className='accordion-text-content__text'>
          <Markdown>{text}</Markdown>
        </span>
        {secondaryButtonData && isSmall && !secondaryButtonData.disabled && (
          <Button
            className='accordion-text-content__button'
            href={secondaryButtonData.url}
            noNewTab
          >
            {secondaryButtonData.text}
          </Button>
        )}
        {!isSmall && (
          <div className='accordion-text-content__buttons-wrapper'>
            {primaryButtonData && (
              <Button
                className='accordion-text-content__button accordion-text-content__button--primary'
                orange
                href={primaryButtonData.url}
                noNewTab
              >
                {primaryButtonData.text}
              </Button>
            )}
            {secondaryButtonData && !secondaryButtonData.disabled && (
              <Button
                className='accordion-text-content__button'
                href={secondaryButtonData.url}
                noNewTab
              >
                {secondaryButtonData.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AccordionTextContent.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string
};

export default withWindow(AccordionTextContent);
