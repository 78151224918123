import React from 'react';
import cx from 'classnames';

import { Caption, Subtitle2 } from '../Typography';
import { ReactComponent as ShadowEllipse } from '../../assets/svg/shadow-ellipse.svg';
import { ReactComponent as ListBulletedIcon } from '../../assets/storybook-placeholders/svg/format-list-bulleted.svg';
import { ReactComponent as KeyboardArrowRightIcon } from '../../assets/storybook-placeholders/svg/keyboard-arrow-right.svg';
import { ReactComponent as InfoIcon } from '../../assets/storybook-placeholders/svg/info-gradient.svg';
import { FormattedMessage } from 'react-intl';
import { testTypeNames } from './testTypeNames';
import Link from '../Link';
import { getStatusMessage } from './testStatuses';
import formatLastUpdateDate from '../../app/helpers/dateFormat';

import styles from './index.module.scss';

export type TestCardStatus =
  | 'incomplete'
  | 'waiting'
  | 'in_progress'
  | 'doctor_report_pending'
  | 'pharmacy_report_pending'
  | 'completed';

export const testStatusColors: Record<TestCardStatus, string> = {
  incomplete: '#C8585F',
  waiting: '#6F6B72',
  in_progress: '#FF6145',
  doctor_report_pending: '#FF6145',
  pharmacy_report_pending: '#FF6145',
  completed: '#7C9179'
};

export interface TestCardProps {
  activationFlowUrl: string;
  code: string;
  iconUrl: string;
  lastUpdate: string;
  personName: string;
  reorderDiscount: string;
  reorderUrl: string;
  samplingInstructionsUrl: string;
  status: TestCardStatus;
  testResultsUrl: string;
  type: string;
  updateSamplingTimeUrl: string;
  testedPersonName: string;
  testedPersonInitials: string;
  resultsViewed: boolean | null;
}

const TestCard: React.FC<TestCardProps> = ({
  activationFlowUrl,
  iconUrl,
  reorderDiscount,
  reorderUrl,
  status,
  testResultsUrl,
  updateSamplingTimeUrl,
  samplingInstructionsUrl,
  type,
  code,
  testedPersonName,
  lastUpdate,
  testedPersonInitials,
  resultsViewed
}) => {
  const testType = testTypeNames[type];
  const statusColor = testStatusColors[status];
  const statusMessage = getStatusMessage(type, status);

  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [styles.reorderWrapper]: reorderUrl,
        [styles.samplingInstructionsWrapper]: samplingInstructionsUrl
      })}
    >
      <div
        className={cx({
          [styles.cardWrapper]: true,
          [styles.cardWrapperWithReorder]: reorderUrl,
          [styles.cardWrapperWithSamplingInstructions]: samplingInstructionsUrl
        })}
      >
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <img className={styles.image} src={iconUrl} alt='product' />
            <ShadowEllipse className={styles.imageShadow} />
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.textContentWrapper}>
            <div className={styles.titleWrapper}>
              <Subtitle2 className={styles.title}>{testType}</Subtitle2>
              <Caption element='p' className={styles.testCode}>
                {code}
              </Caption>

              <div style={{ color: statusColor }}>
                <Caption element='p' className={styles.description}>
                  <FormattedMessage id={statusMessage} defaultMessage='-' />
                </Caption>
              </div>
            </div>
            <div>
              {(testedPersonName || testedPersonInitials) && (
                <div className={styles.testInfoName}>
                  <Caption element='p' className={styles.testInfoLabel}>
                    <FormattedMessage
                      id='dashboard.tested-person'
                      defaultMessage='Tested person: '
                    />
                  </Caption>
                  <Caption element='p' className={styles.testInfoData}>
                    {testedPersonInitials || testedPersonName}
                  </Caption>
                </div>
              )}

              <Caption element='p' className={styles.testInfoLabel}>
                <FormattedMessage
                  id='dashboard.last-update'
                  defaultMessage='Last update: '
                />
              </Caption>
              <Caption element='p' className={styles.testInfoData}>
                {formatLastUpdateDate(lastUpdate)}
              </Caption>
            </div>
          </div>

          {testResultsUrl && (
            <>
              <Link
                size='small'
                variant={resultsViewed ? 'elevated' : 'primary'}
                url={testResultsUrl}
                className={styles.button}
              >
                {resultsViewed ? (
                  <FormattedMessage
                    id='dashboard.revisit-test-results'
                    defaultMessage='Revisit test results'
                  />
                ) : (
                  <FormattedMessage
                    id='dashboard.view-test-results'
                    defaultMessage='View test results'
                  />
                )}
              </Link>
            </>
          )}
          {activationFlowUrl && (
            <Link
              size='small'
              variant='elevated'
              url={activationFlowUrl}
              className={styles.button}
            >
              <FormattedMessage
                id='dashboard.continue-activation'
                defaultMessage='Continue activation'
                values={{ reorderDiscount }}
              />
            </Link>
          )}
          {updateSamplingTimeUrl && (
            <Link
              className={styles.button}
              size='small'
              variant='elevated'
              url={updateSamplingTimeUrl}
            >
              <FormattedMessage
                id='dashboard.update-sampling-time'
                defaultMessage='Update sampling time'
              />
            </Link>
          )}
        </div>
      </div>
      {reorderUrl && (
        <>
          <div className={styles.line}></div>
          <a
            className={styles.footerWrapper}
            href={reorderUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={styles.footerTextWrapper}>
              <ListBulletedIcon />
              <Caption className={styles.footerText} element='p'>
                {reorderDiscount ? (
                  <FormattedMessage
                    id='dashboard.reorder-discount'
                    defaultMessage={`Re-order with ${reorderDiscount} discount`}
                    values={{ reorderDiscount }}
                  />
                ) : (
                  <FormattedMessage
                    id='dashboard.reorder'
                    defaultMessage='Reorder'
                  />
                )}
              </Caption>
            </div>
            <KeyboardArrowRightIcon />
          </a>
        </>
      )}
      {samplingInstructionsUrl && (
        <>
          <div className={styles.line}></div>
          <a className={styles.footerWrapper} href={samplingInstructionsUrl}>
            <div className={styles.textAndIconWrapper}>
              <Caption element='span'>
                <FormattedMessage
                  id='dashboard.view-sampling-instructions'
                  defaultMessage='View sampling instructions'
                />
              </Caption>
              <InfoIcon className={styles.infoIcon} />
            </div>
          </a>
        </>
      )}
    </div>
  );
};

export default TestCard;
