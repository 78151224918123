import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import cx from 'classnames';

import './index.scss';

const Table = ({ data }) => {
  const { headerItems, rows, text } = data;
  return (
    <div className='table'>
      {text && (
        <div className='table__text'>
          <Markdown rehypePlugins={[rehypeRaw]}>{text}</Markdown>
        </div>
      )}
      <div className='table__content'>
        <div className='table__header'>
          {headerItems.map((item, index) => {
            const classes = cx('table__header-item', {
              'table__header-item--small': index === headerItems.length - 1
            });
            return (
              <div key={item.key} className={classes}>
                {item.text}
              </div>
            );
          })}
        </div>
        <div className='table__body'>
          {rows.map(item => {
            // This is just temporary, will be removed
            return (
              item.key !== 'vitaminK2' && (
                <div key={item.key} className='table__row'>
                  <div className='table__row-item'>{item.text}</div>
                  <div className='table__row-item'>
                    {item.value} {item.unit && item.unit}
                  </div>
                  <div className='table__row-item table__row-item--small'>
                    {item.rdi}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.object
};

export default Table;
