import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '../../../components/Typography';
import DownloadButton from '../../../components/DownloadButton';
import {
  downloadReport,
  clearDownloadReport
} from '../../actions/downloadReport';
import useCreateBlob from './useCreateBlob.hook';

import './index.scss';

export const DocumentTypes = {
  LAB_REPORT: 'labReport',
  REIMBURSEMENT_RECEIPT: 'reimbursementReceipt'
};

const TestResultsDocuments = ({ documents, dispatch, data, error }) => {
  const cleanUpDownloadReportData = () => {
    dispatch(clearDownloadReport());
  };

  const downloadReportError = useCreateBlob(
    data,
    documents.labReport,
    // TODO: try moving this into useefect clearing
    cleanUpDownloadReportData
  );

  const downloadLabReport = (type, report) => {
    dispatch(downloadReport(type, report));
  };

  return (
    <div className='documents'>
      <Typography element='p' variant='overline' className='documents__title'>
        <FormattedMessage id='app.documents' defaultMessage='Documents' />
      </Typography>
      <div className='documents__download-buttons-wrapper'>
        {documents.labReport && (
          <DownloadButton
            text={
              <FormattedMessage
                id='app.lab-report'
                defaultMessage='Lab report'
              />
            }
            description={
              <FormattedMessage
                id='app.download-lab-report'
                defaultMessage='Download lab report'
              />
            }
            onClick={() =>
              downloadLabReport(DocumentTypes.LAB_REPORT, documents.labReport)
            }
            error={error}
            downloadError={downloadReportError}
          />
        )}

        {documents.reimbursementReceipt && (
          <DownloadButton
            className='documents__line'
            text={
              <FormattedMessage
                id='app.reimbursement-receipt'
                defaultMessage='Reimbursement receipt'
              />
            }
            description={
              <FormattedMessage
                id='app.download-reimbursement-receipt'
                defaultMessage='Download reimbursement receipt'
              />
            }
            onClick={() =>
              downloadLabReport(
                DocumentTypes.REIMBURSEMENT_RECEIPT,
                documents.reimbursementReceipt
              )
            }
            error={error}
          />
        )}

        {documents.immunotherapyMedicationPrescription && (
          <DownloadButton
            className='documents__line'
            text={
              <FormattedMessage
                id='test-result.immunotherapy-medication-prescription'
                defaultMessage='Immunotherapy medication prescription'
              />
            }
            description={
              <FormattedMessage
                id='test-result.download-immunotherapy-medication-prescription'
                defaultMessage='Download immunotherapy medication prescription'
              />
            }
            onClick={() =>
              console.log('download mmunotherapy medication prescription')
            }
          />
        )}

        {documents.sympthomsMedicationPrescription && (
          <DownloadButton
            className='documents__line'
            text={
              <FormattedMessage
                id='test-result.sympthoms-medication-prescription'
                defaultMessage='Sympthoms medication prescription'
              />
            }
            description={
              <FormattedMessage
                id='test-result.download-sympthoms-medication-prescription'
                defaultMessage='Download sympthoms medication prescription'
              />
            }
            onClick={() =>
              console.log('download sympthoms medication prescription')
            }
          />
        )}
      </div>
    </div>
  );
};

TestResultsDocuments.propTypes = {
  documents: PropTypes.object,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  data: state.downloadReport.get('data'),
  loading: state.videoUpload.get('loading'),
  error: state.app.get('error')
});

export default connect(mapStateToProps)(TestResultsDocuments);
