function generateValue(question, answer) {
  const { kind, answerOptions } = question;
  let value = [];

  if (kind === 'singleSelect') {
    value = [{ key: answer && answer[0] }];
  } else if (kind === 'text' || kind === 'datepicker' || kind === 'upload' || kind === 'dropdown' || kind === 'multiCheckboxSelect' || kind === 'radioButton') {
    value = answer;
  } else {
    value = answerOptions.map(item => {
      let valueItem = {
        key: item.key,
        value: answer && answer.includes(item.key) ? true : false
      };

      return valueItem;
    });
  }

  return !value || value.length === 0 ? null : value;
}

export default function singleSelectData(conversationId, question, answer, conversationFlowStateKey, userId) {
  return {
    userId: userId,
    conversationId: conversationId,
    conversationFlowStateKey: conversationFlowStateKey,
    content: {
      kind: 'answer',
      answer: {
        question: {
          key: question.key,
          kind: question.kind,
          profileAttributeKey: question.profileAttributeKey
        },
        value: generateValue(question, answer)
      }
    }
  };
}
