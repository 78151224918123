import React from 'react';
import cx from 'classnames';
import ProgressBarSection from './ProgressBarSection';
import { Caption } from '../Typography';
import { ReactComponent as InfiniteIcon } from '../../assets/storybook-placeholders/svg/infinite-icon.svg';

import styles from './index.module.scss';

export interface Range {
  color: string;
  interpretation: string;
  lowerLimit: number;
  upperLimit: number;
  lowerLimitOperator: string;
  upperLimitOperator: string;
}

export interface TestResultsProgressBarProps {
  numberValue: number;
  ranges: Range[];
  className?: string;
}

const TestResultsProgressBar: React.FC<TestResultsProgressBarProps> = ({
  numberValue,
  ranges,
  className
}) => {
  const getMaxUpperLimit = () => {
    const lastUpperLimit = ranges[ranges.length - 1].upperLimit;
    return lastUpperLimit;
  };

  const calculateIndicatorPosition = () => {
    const maxUpperLimit = getMaxUpperLimit();

    const indicatorPosition = (100 * numberValue) / maxUpperLimit;
    if (numberValue <= maxUpperLimit) {
      return indicatorPosition + '%';
    } else if (numberValue > maxUpperLimit) {
      return 100 + '%';
    }
  };

  const indicatorPosition = calculateIndicatorPosition();

  const getIndicatorBackgroundColor = (value: number, ranges: Range[]) => {
    let indicatorColor = '';
    const maxUpperLimit = getMaxUpperLimit();

    if (value > maxUpperLimit) {
      indicatorColor = ranges[ranges.length - 1].color;
    } else {
      for (const range of ranges) {
        const lowerLimitCondition =
          range.lowerLimitOperator === '>='
            ? value >= range.lowerLimit
            : value > range.lowerLimit;
        const upperLimitCondition =
          range.upperLimitOperator === '<='
            ? value <= range.upperLimit
            : value < range.upperLimit;

        if (lowerLimitCondition && upperLimitCondition) {
          indicatorColor = range.color;
          break;
        }
      }
    }

    return indicatorColor;
  };

  const indicatorBackgroundColor = getIndicatorBackgroundColor(
    numberValue,
    ranges
  );

  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true
        },
        className
      )}
    >
      {ranges.map((range, index) => (
        <ProgressBarSection
          key={index}
          color={range.color}
          lowerLimit={range.lowerLimit}
          upperLimit={range.upperLimit}
          isFirstSection={index === 0}
          maxUpperLimit={getMaxUpperLimit()}
        />
      ))}
      <div
        className={styles.indicatorWrapper}
        style={{
          left: indicatorPosition
        }}
      >
        <div
          className={styles.indicator}
          style={{
            backgroundColor: indicatorBackgroundColor
          }}
        >
          <Caption element='span'>{numberValue}</Caption>
        </div>
        <div
          className={styles.indicatorAfter}
          style={{
            borderTop: `solid 5px ${indicatorBackgroundColor}`
          }}
        ></div>
      </div>
      <span className={styles.infiniteIcon}>
        <InfiniteIcon />
      </span>
    </div>
  );
};

export default TestResultsProgressBar;
