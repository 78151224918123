import React from 'react';
import PropTypes from 'prop-types';
import { Body2 } from '../../../../components/Typography';

import './index.scss';

const RecommendedActionsDescription = ({ description }) => {
  return (
    <div>
      <Body2 className='recommended-actions-description'>{description}</Body2>
    </div>
  );
};

RecommendedActionsDescription.propTypes = {
  description: PropTypes.string
};

export default RecommendedActionsDescription;
