export const dataType = {
  RECOMMENDATION: 'productBundleRecommendation',
  HEALTH_CHECK_RECOMMENDATION: 'healthCheckRecommendation',
  CHECKOUT: 'checkout',
  QUESTION: 'question',
  RISKS: 'risks',
  TEXT: 'text',
  SCORECARD: 'scorecard',
  REDIRECT: 'redirect',
  TEST_RESULTS: 'testResults',
  HAYFEVER_SEASONS: 'hayfeverSeasons',
  TREATMENT_PLAN: 'treatmentPlan',
  LOADER: 'loader',
  SLIDER: 'slider',
};

export const questionsType = {
  SINGLE_SELECT: 'singleSelect'
};

export const riskLevel = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

export const directions = {
  FORWARD: 'forward',
  BACK: 'back'
};
