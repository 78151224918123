import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../components/Divider';
import QuickActionCard from '../../../components/QuickActionCard';
import QuickActionButtonCard from '../../../components/QuickActionButtonCard';
import { ReactComponent as SupportIcon } from '../../../assets/svg/user-with-heart.svg';
import { ReactComponent as KnowledgeIcon } from '../../../assets/storybook-placeholders/svg/knowledge-icon.svg';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const SUPPORT_URL = 'https://service.yourself.health/';

const EmployeeHealthProgramQuickActions = ({ onClick }) => {
  return (
    <div className='employee-health-program-quick-actions'>
      <Divider
        title={
          <FormattedMessage
            id='my-health.quick-access'
            defaultMessage='Quick access'
          />
        }
      />
      <div className='employee-health-program-quick-actions__cards'>
        <QuickActionButtonCard
          icon={<KnowledgeIcon />}
          title={
            <FormattedMessage
              id='my-health.how-it-works'
              defaultMessage='How it works'
            />
          }
          className='employee-health-program-quick-actions__activate-test'
          onClick={onClick}
        />
        <QuickActionCard
          icon={<SupportIcon />}
          title={
            <FormattedMessage
              id='my-health.contact-support'
              defaultMessage='Contact Support'
            />
          }
          url={SUPPORT_URL}
          className='employee-health-program-quick-actions__contact-support'
        />
      </div>
    </div>
  );
};

EmployeeHealthProgramQuickActions.propTypes = {
  onClick: PropTypes.func
};

export default EmployeeHealthProgramQuickActions;
