import React, { useState, useEffect } from 'react';
import { createTestOrder, resetTestOrderData } from '../../actions/dashboard';
import { FormattedMessage } from 'react-intl';

const useOrderTestModalContent = (
  handleProceed,
  orderData,
  orderLoading,
  orderError,
  dispatch,
  testData,
  benefitData
) => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [formError, setFormError] = useState('');
  const [company, setCompany] = useState('');

  const fieldRequiredMessage = (
    <FormattedMessage
      id='my-health.field-required'
      defaultMessage='This field is required'
    />
  );

  useEffect(() => {
    if (orderData && !orderError && !orderLoading) {
      handleProceed();
      dispatch(resetTestOrderData());
    } else if (orderError) {
      setFormError(
        <FormattedMessage
          id='my-heatlh.try-again'
          defaultMessage='Something went wrong. Please try again.'
        />
      );
    } else {
      setFormError('');
    }
  }, [orderData, orderError, orderLoading, handleProceed, dispatch]);

  const handleFirstNameOnChange = event => {
    const firstName = event.target.value;
    setFirstName(firstName);
    setFormError('');
  };

  const handleFirstNameOnBlur = event => {
    const firstName = event.target.value;
    if (!firstName) {
      setFirstNameError(fieldRequiredMessage);
    }
  };

  const handleLastNameOnChange = event => {
    const lastName = event.target.value;
    setLastName(lastName);
    setLastNameError('');
  };

  const handleLastNameOnBlur = event => {
    const lastName = event.target.value;
    if (!lastName) {
      setLastNameError(fieldRequiredMessage);
    }
  };

  const handleAddressOnChange = event => {
    const address = event.target.value;
    setAddress(address);
    setAddressError('');
  };

  const handleAddressOnBlur = event => {
    const address = event.target.value;
    if (!address) {
      setAddressError(fieldRequiredMessage);
    }
  };

  const handleApartmentOnChange = event => {
    const apartment = event.target.value;
    setApartment(apartment);
  };

  const handleCityOnChange = event => {
    const city = event.target.value;
    setCity(city);
    setCityError('');
  };

  const handleCityOnBlur = event => {
    const city = event.target.value;
    if (!city) {
      setCityError(fieldRequiredMessage);
    }
  };

  const handleZipCodeOnChange = event => {
    const zipCode = event.target.value;
    setZipCode(zipCode);
    setZipCodeError('');
  };

  const handleZipCodeOnBlur = event => {
    const zipCode = event.target.value;
    if (!zipCode) {
      setZipCodeError(fieldRequiredMessage);
    }
  };

  const handleCompanyOnChange = event => {
    const company = event.target.value;
    setCompany(company);
  };

  const handleOrderTest = e => {
    e.preventDefault();

    let isValid = true;

    if (!firstName) {
      setFirstNameError(fieldRequiredMessage);
      isValid = false;
    }

    if (!lastName) {
      setLastNameError(fieldRequiredMessage);
      isValid = false;
    }

    if (!address) {
      setAddressError(fieldRequiredMessage);
      isValid = false;
    }
    if (!city) {
      setCityError(fieldRequiredMessage);
      isValid = false;
    }
    if (!zipCode) {
      setZipCodeError(fieldRequiredMessage);
      isValid = false;
    }

    if (!isValid) {
      setFormError(
        <FormattedMessage
          id='my-health.all-the-fields'
          defaultMessage='Please fill in all the fields'
        />
      );
      return;
    }

    const orderData = {
      firstName,
      lastName,
      country: 'Switzerland',
      address1: address,
      address2: apartment,
      company,
      city,
      zip: zipCode,
      testType: testData.testType,
      testTypeId: testData.testTypeId,
      benefitId: benefitData.benefitId,
      benefitCode: benefitData.benefitCode
    };

    dispatch(createTestOrder(orderData));
  };

  return {
    firstName: {
      value: firstName,
      set: setFirstName,
      error: firstNameError,
      onChange: handleFirstNameOnChange,
      onBlur: handleFirstNameOnBlur
    },
    lastName: {
      value: lastName,
      set: setLastName,
      error: lastNameError,
      onChange: handleLastNameOnChange,
      onBlur: handleLastNameOnBlur
    },
    address: {
      value: address,
      set: setAddress,
      error: addressError,
      onChange: handleAddressOnChange,
      onBlur: handleAddressOnBlur
    },
    apartment: {
      value: apartment,
      set: setApartment,
      onChange: handleApartmentOnChange
    },
    company: {
      value: company,
      set: setCompany,
      onChange: handleCompanyOnChange
    },
    city: {
      value: city,
      set: setCity,
      error: cityError,
      onChange: handleCityOnChange,
      onBlur: handleCityOnBlur
    },
    zipCode: {
      value: zipCode,
      set: setZipCode,
      error: zipCodeError,
      onChange: handleZipCodeOnChange,
      onBlur: handleZipCodeOnBlur
    },
    formError,
    handleOrderTest
  };
};

export default useOrderTestModalContent;
