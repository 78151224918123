import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
} from '../actions/user';
import api from '../api';

function createGetUser() {
  return function*() {
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.getUserData());
      const action = { type: GET_USER_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: GET_USER_ERROR, error };

      yield put(action);
    }
  };
}

function* getUserWatcher() {
  yield takeLatest(GET_USER_START, createGetUser());
}

export default [
  getUserWatcher()
];
