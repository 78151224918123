export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_BACK_URL = 'SET_BACK_URL';
export const SET_FLOW_COUNTRY = 'SET_FLOW_COUNTRY';
export const SET_SLIDER_VIEWED = 'SET_SLIDER_VIEWED';

export const setLanguage = language => ({
  type: SET_LANGUAGE,
  payload: language
});

export const setBackUrl = backUrl => ({
  type: SET_BACK_URL,
  payload: backUrl
});

export const setFlowCountry = flowCountry => ({
  type: SET_FLOW_COUNTRY,
  flowCountry: flowCountry
});

export const setSliderViewed = () => ({
  type: SET_SLIDER_VIEWED,
});
