import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Scorebar from '../Scorebar';

import breakpoints from '../../constants/breakpoints';

import './index.scss';

const RiskModalCard = ({ data, breakpoint }) => {
  const { iconUrl, titleText, link, reasonText, instructionText, primaryScore, secondaryScore } = data;

  const isSmall = [breakpoints.name.XSMALL, breakpoints.name.SMALL].includes(breakpoint);

  return (
    <div className='risk-modal-card' style={isSmall ? { width: '68vw' } : {}}>
      <div className='risk-modal-card__content'>
        <div className='risk-modal-card__top-block'>
          <div className='risk-modal-card__image-wrapper'>
            <img src={iconUrl} alt='risk' />
          </div>
          <span className='risk-modal-card__title'>{titleText}</span>
          {link && (
            <a className='risk-modal-card__read-more' href={link} target='_blank' rel='noopener noreferrer'>
              <FormattedMessage id='app.read-more' defaultMessage='Read more' />
            </a>
          )}
        </div>
        <div className='risk-modal-card__block'>
          <span className='risk-modal-card__block-title risk-modal-card__block-title--score'>
            <FormattedMessage id='scorecard.your-score' defaultMessage='Your score' />
          </span>
          <Scorebar primaryScore={primaryScore} secondaryScore={secondaryScore} inModal />
        </div>
        <div className='risk-modal-card__bottom-block'>
          <div className='risk-modal-card__block-text'>
            <span className='risk-modal-card__block-title'>
              <FormattedMessage id='scorecard.explanation' defaultMessage='Explanation' />
            </span>
            <span className='risk-modal-card__text'>{reasonText}</span>
          </div>
          <div className='risk-modal-card__block-text'>
            <span className='risk-modal-card__block-title'>
              <FormattedMessage id='scorecard.test-advice' defaultMessage='Test advice' />
            </span>
            <span className='risk-modal-card__text'>{instructionText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

RiskModalCard.propTypes = {
  data: PropTypes.object,
  breakpoint: PropTypes.string
};

export default RiskModalCard;
