import { takeLatest, call, put } from 'redux-saga/effects';

import {
  DOWNLOAD_REPORT_START,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
} from '../actions/downloadReport';
import api from '../api';
import { DocumentTypes } from '../components/TestResultsDocuments';

const LAB_REPORT_PATH = 'user/lab-report/';
const REIMBURSEMENT_RECEIPT_PATH = 'user/reimbursement-receipt/';

function* downloadDocument(action) {
  const { documentType, documentId } = action.payload;
  let endpoint = '';

  switch (documentType) {
    case DocumentTypes.LAB_REPORT:
      endpoint = `${LAB_REPORT_PATH}${documentId}`;
      break;
    case DocumentTypes.REIMBURSEMENT_RECEIPT:
      endpoint = `${REIMBURSEMENT_RECEIPT_PATH}${documentId}`;
      break;
    default:
      const action = { type: DOWNLOAD_REPORT_ERROR, error: 'Invalid document type' };

      yield put(action);
  }

  try {
    const blob = yield call(api.fetchResource, endpoint, {
      method: 'GET',
      responseType: 'blob'
    });
    const action = { type: DOWNLOAD_REPORT_SUCCESS, data: blob };
    yield put(action);

  } catch (error) {
    const action = { type: DOWNLOAD_REPORT_ERROR, error };

    yield put(action);
  }
}

// watcher saga
function* downloadReportWatcher() {
  yield takeLatest(DOWNLOAD_REPORT_START, downloadDocument);
}


export default [downloadReportWatcher()];
