export const START_CONVERSATION_START = 'START_CONVERSATION_START';
export const START_CONVERSATION_WITH_TEST_CODE_START = 'START_CONVERSATION_WITH_TEST_CODE_START';
export const START_CONVERSATION_WITH_TEST_CODE_SUCCESS = 'START_CONVERSATION_WITH_TEST_CODE_SUCCESS';
export const START_CONVERSATION_WITH_TEST_CODE_ERROR = 'START_CONVERSATION_WITH_TEST_CODE_ERROR';
export const START_CONVERSATION_WITH_BENEFIT_CODE_START = 'START_CONVERSATION_WITH_BENEFIT_CODE_START';
export const START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS = 'START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS';
export const START_CONVERSATION_WITH_BENEFIT_CODE_ERROR = 'START_CONVERSATION_WITH_BENEFIT_CODE_ERROR';
export const START_CONVERSATION_SUCCESS = 'START_CONVERSATION_SUCCESS';
export const START_CONVERSATION_ERROR = 'START_CONVERSATION_ERROR';
export const UPDATE_CONVERSATION_START = 'UPDATE_CONVERSATION_START';
export const UPDATE_CONVERSATION_SUCCESS = 'UPDATE_CONVERSATION_SUCCESS';
export const UPDATE_CONVERSATION_ERROR = 'UPDATE_CONVERSATION_ERROR';
export const UPDATE_DASHBOARD_DATA_START = 'UPDATE_DASHBOARD_DATA_START';
export const UPDATE_DASHBOARD_DATA_SUCCESS = 'UPDATE_DASHBOARD_DATA_SUCCESS';
export const UPDATE_DASHBOARD_DATA_ERROR = 'UPDATE_DASHBOARD_DATA_ERROR';
export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const SUBMIT_ANSWER_START = 'SUBMIT_ANSWER_START';
export const SUBMIT_ANSWER_SUCCESS = 'SUBMIT_ANSWER_SUCCESS';
export const SUBMIT_ANSWER_ERROR = 'SUBMIT_ANSWER_ERROR';
export const RESET_ANSWER_ERROR = 'RESET_ANSWER_ERROR';
export const GO_BACK_START = 'GO_BACK_START';
export const GO_BACK_SUCCESS = 'GO_BACK_SUCCESS';
export const GO_BACK_ERROR = 'GO_BACK_ERROR';
export const CONTINUE_CONVERSATION_START = 'CONTINUE_CONVERSATION_START';
export const CONTINUE_CONVERSATION_SUCCESS = 'CONTINUE_CONVERSATION_SUCCESS';
export const CONTINUE_CONVERSATION_ERROR = 'CONTINUE_CONVERSATION_ERROR';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START = 'CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START';
export const CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS = 'CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS';
export const CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR = 'CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR';

export const createPrescriptionConversationWithId = (prescriptionId, language) => ({
  type: CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START,
  payload: {
    prescriptionId, 
    language
  }
});

export const updateToken = token => ({
  type: UPDATE_TOKEN,
  payload: {
    token
  }
});

export const startConversation = (conversationFlowKey, language) => ({
  type: START_CONVERSATION_START,
  payload: {
    conversationFlowKey,
    language
  }
});

export const startConversationWithCode = (testCode, conversationFlowKey, language) => ({
  type: START_CONVERSATION_WITH_TEST_CODE_START,
  payload: {
    testCode,
    conversationFlowKey,
    language
  }
});

export const startConversationWithBenefitCode = (benefitCode, conversationFlowKey, language) => ({
  type: START_CONVERSATION_WITH_BENEFIT_CODE_START,
  payload: {
    benefitCode,
    conversationFlowKey,
    language
  }
});

export const updateConversation = (language, conversationId) => ({
  type: UPDATE_CONVERSATION_START,
  payload: {
    language,
    conversationId
  }
});

export const updateDashboardData = language => ({
  type: UPDATE_DASHBOARD_DATA_START,
  payload: {
    language
  }
});

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA_START,
});

export const continueConversation = conversationId => ({
  type: CONTINUE_CONVERSATION_START,
  payload: conversationId
});

export const submitAnswer = answer => ({
  type: SUBMIT_ANSWER_START,
  payload: answer
});

export const submitAnswerError = error => ({
  type: SUBMIT_ANSWER_ERROR,
  error
});

export const goBack = payload => ({
  type: GO_BACK_START,
  payload
});

export const resetAnswerError = () => ({
  type: RESET_ANSWER_ERROR
});
