import { Map } from 'immutable';

import {SET_VIDEO, SET_UPLOAD_VIDEO_ERROR} from '../actions/videoUpload';

const initialState = Map({
  videoData: null,
  uploadVideoError: null
});

const actionsMap = {
  [SET_VIDEO]: (state, action) => {
    return state.merge(
      Map({
        videoData: action.payload,
        videoError: null
      })
    );
  },
  [SET_UPLOAD_VIDEO_ERROR]: (state, action) => {
    return state.merge(
      Map({
        videoData: null,
        videoError: action.payload,
      })
    );
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
