import React from 'react';
import cx from 'classnames';
import { Subtitle2 } from '../Typography';

import styles from './index.module.scss';

interface QuickActionCardProps {
  icon: React.ReactNode;
  title: string;
  url: string;
  className: string;
}

const QuickActionCard: React.FC<QuickActionCardProps> = ({
  icon,
  title,
  url,
  className
}) => {
  //TODO: Refactor this so that it renders <Link /> when url is internal and <a /> if url is external
  return (
    <a className={styles.wrapper} href={url} target='_blank' rel='noreferrer'>
      <div className={styles.content}>
        {icon}
        <Subtitle2 className={styles.title}>{title}</Subtitle2>
        <div
          className={cx(
            {
              [styles.line]: true
            },
            className && { [className]: true }
          )}
        ></div>
        <div
          className={cx(
            {
              [styles.cardShadow]: true
            },
            className && { [className]: true }
          )}
        ></div>
      </div>
    </a>
  );
};

export default QuickActionCard;
