import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const Price = ({ price, priceCurrency, discount, discountCurrency, className }) => {
  const priceLength = price.toString().length;
  const classes = cx(className, {
    price: true,
    'price--with-discount': discount
  });
  const priceValueClasses = cx({ 'price__value': true, 'price__value--long': priceLength >= 5 }); 
  return (
    <div className={classes}>
      <span className='price__currency'>{priceCurrency}</span>
      <span className={priceValueClasses}>{price}</span>
      {discount > 0 && (
        <div className='price__discount-box'>
          <span className='price__discount'>{`${discountCurrency} ${discount}`}</span>
          <span className='price__discount-text'>
            <FormattedMessage id='app.discount' defaultMessage='Discount' />
          </span>
        </div>
      )}
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  priceCurrency: PropTypes.string,
  discount: PropTypes.number,
  discountCurrency: PropTypes.string,
  className: PropTypes.string
};

export default Price;
