import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Divider from '../../../../components/Divider';
import { Body2 } from '../../../../components/Typography';

import './index.scss';

const NegativeAllergyDiagnosisBlock = ({ text }) => {
  return (
    <div className='negative-allergy-diagnosis'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.allergy-diagnosis'
            defaultMessage='Allergy diagnosis'
          />
        }
      />
      <Body2 className='negative-allergy-diagnosis__text'>{text}</Body2>
    </div>
  );
};

NegativeAllergyDiagnosisBlock.propTypes = {
  text: PropTypes.string
};

export default NegativeAllergyDiagnosisBlock;
