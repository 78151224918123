import React from 'react';
import cx from 'classnames';

import styles from './Input.module.scss';

export interface InputProps {
  wrapperClassName?: string;
  className?: string;
  error?: string;
  id?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  label?: string | React.ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
  placeholder?: string;
  type?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  isLoading?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  readonly?: boolean;
}

const Input: React.FC<InputProps> = ({
  wrapperClassName,
  className,
  error,
  id,
  innerRef,
  label,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  disabled,
  required,
  autoComplete,
  onFocus,
  readonly
}) => {
  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true
        },
        wrapperClassName && { [wrapperClassName]: true }
      )}
    >
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <input
        className={cx(
          {
            [styles.input]: true,
            [styles.error_input]: error
          },
          className && { [className]: true }
        )}
        id={id}
        ref={innerRef}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        onFocus={onFocus}
        readOnly={readonly}
      />
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};

export default Input;
