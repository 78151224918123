import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setUser, removeUser } from '../actions/auth';

import {
  AUTH_ERROR,
  AUTH_ERROR_CODE,
  AUTH_ERROR_CUSTOM_MESSAGE
} from '../constants/errorCodes';
import { PATHS } from '../constants/paths';
import api from '../api';
import {
  getAuth,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  applyActionCode,
  signOut,
  multiFactor,
  PhoneAuthProvider,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';

import { getCallbackFromURL } from '../helpers/authorizationHelpers';

export const userStateValues = {
  EXISTING_USER: 'existingUser',
  NEW_USER: 'newUser',
  ERROR: 'error',
  EMAIL_VERIFICATION: 'emailVerification',
  PHONE_CODE: 'phoneCode',
  RESET_PASSWORD: 'resetPassword',
  FORGOT_PASSWORD: 'forgotPassword',
  CHECK_INBOX: 'checkInbox',
  PASSWORD_UPDATED: 'passwordUpdated'
};

const sendSlackMessage = (error, email, functionName, line) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  const slackMessage = `Error in ${functionName} function (line ${line}, useAuthorization.js) | Error code: ${errorCode} | Error message: ${errorMessage} | user: ${email}`;
  api.sendSlackAuthError(slackMessage);
};

export default function useAuthorization(dispatch) {
  const recaptchaRef = useRef(null);
  const recaptchaResendRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const auth = getAuth();
  const [verificationId, setVerificationId] = useState(null);
  const [userObject, setUserObject] = useState(null);
  const [gresolver, setResolver] = useState(null);
  const [userState, setUserState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [MFANumber, setMFANumber] = useState('');

  useEffect(() => {
    let isMounted = true;

    const unsubscribe = onAuthStateChanged(auth, user => {
      if (isMounted) {
        if (user) {
          setUserObject(user);
          dispatch(setUser(user));
        } else {
          setUserObject(null);
          dispatch(removeUser());
        }
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [auth, dispatch, history]);

  // Helper function for creating email data
  const getActionCodeSettings = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let callback = urlParams.get('callback');
    const benefitCode = urlParams.get('benefitCode');
    if (benefitCode) {
      callback = `${callback}&benefitCode=${benefitCode}`;
    }

    let actionCodeSettings = null;
    if (callback) {
      actionCodeSettings = {
        url: callback,
        handleCodeInApp: true
      };
    }
    return actionCodeSettings;
  };

  // Helper function for getting current language
  const getCurrentLanguage = () => {
    const currentLangauge = localStorage.getItem('hy-language') || 'en';

    return currentLangauge;
  };

  // Helper function for getting recaptcha verifier
  const getRecaptchaVerifier = () => {
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'invisible',
        callback: function(response) {}
      },
      auth
    );

    return recaptchaVerifier;
  };

  // Helper functionn for resetting recaptcha verifier
  const resetRecaptchaVerifier = recaptchaVerifier => {
    recaptchaVerifier.clear();
    recaptchaRef.current.innerHTML = '';
    recaptchaRef.current.innerHTML = "<div id='recaptcha'></div>";
  };

  // First step in registration process is checking if user exist
  const checkIfUserExist = async email => {
    const isJustLogin = location.pathname === PATHS.LOGIN;
    try {
      setIsLoading(true);

      // If user is on login page, we are not checking if user exist
      // because we don't want to give information to user if email exist or not
      if (isJustLogin) {
        setIsLoading(false);
        setUserState(userStateValues.EXISTING_USER);
      } else {
        // We are expecting error, so we can check if user exist or not
        const userExist = await api.checkIfUserExist(email);
        if (userExist) {
          setIsLoading(false);
          setUserState(userStateValues.EXISTING_USER);
        } else {
          setIsLoading(false);
          setUserState(userStateValues.NEW_USER);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setUserState(userStateValues.ERROR);
    }
  };

  // If it doesn't exist we are creating new user and sending email verification
  const registerUser = (email, password) => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        const currentLangauge = getCurrentLanguage();
        const user = userCredential.user;
        const actionCodeSettings = getActionCodeSettings();

        auth.languageCode = currentLangauge;

        sendEmailVerification(user, actionCodeSettings).then(() => {
          setUserState(userStateValues.EMAIL_VERIFICATION);
          api.createUser(email, currentLangauge);
        });
      })
      .catch(error => {
        setIsLoading(false);
        /**
        While creating user we can get next errros
        1. auth/email-already-in-use
        2. auth/invalid-email
        3. auth/operation-not-allowed
        4. auth/weak-password
        We check for all of this on frontend side, so this error should never happen
        So we are setting default message
        */
        setError(AUTH_ERROR_CUSTOM_MESSAGE.CREATE_USER);
        sendSlackMessage(error, email, 'registerUser', '126');
      });
  };

  // If verification email didn't arrive, user can resend verification email
  const resendVerificationEmail = async () => {
    try {
      const actionCodeSettings = getActionCodeSettings();
      auth.languageCode = getCurrentLanguage();

      await sendEmailVerification(auth.currentUser, actionCodeSettings);
    } catch (error) {
      if (error.code === AUTH_ERROR_CODE.TOO_MANY_REQUEST) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE[AUTH_ERROR_CODE.TOO_MANY_REQUEST]);
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        auth.currentUser ? auth.currentUser.email : 'unavailable',
        'resendVerificationEmail',
        '177'
      );
    }
  };

  // Email verification and user redirection to phone number screen or showing error
  const verifyEmail = async (oobCode, continueUrl) => {
    try {
      await applyActionCode(auth, oobCode);
      //By getting here, email is automaticly verified
      if (continueUrl) {
        // TODO: See what's happening on production
        let fixedCallback = continueUrl.replace('..', '.');

        if (fixedCallback.includes('?benefitCode')) {
          fixedCallback = fixedCallback.replace('?benefitCode', '&benefitCode');
        }

        history.push(`${PATHS.PHONE_REGISTRATION}?callback=${fixedCallback}`);
      } else {
        history.push(PATHS.PHONE_REGISTRATION);
      }
    } catch (error) {
      if (error.code === AUTH_ERROR_CODE.INVALID_ACTION_CODE) {
        setError(
          AUTH_ERROR_CUSTOM_MESSAGE[AUTH_ERROR_CODE.INVALID_ACTION_CODE]
        );
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        auth.currentUser ? auth.currentUser.email : 'unavailable',
        'verifyEmail',
        '200'
      );
    }
  };

  const registerPhoneNumber = async phoneNumber => {
    // if user opens some other browser to verify email, then we don't have
    // user data, so user needs to log in again
    const currentUserEmail = auth.currentUser
      ? auth.currentUser.email
      : 'unavailable';

    const firstSlackMessage = `OTP code requested in registation process for user - phone number: ${phoneNumber} | email: ${currentUserEmail}`;
    api.sendSlackAuthError(firstSlackMessage);

    try {
      const multiFactorSession = await multiFactor(
        auth.currentUser
      ).getSession();
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession
      };
      const verificationId = await sendOTPCode(phoneInfoOptions, phoneNumber);
      setVerificationId(verificationId);

      const secondSlackMessage = `OTP code sent in registration process for user - phone number: ${phoneNumber} | email: ${currentUserEmail}`;
      api.sendSlackAuthError(secondSlackMessage);
    } catch (error) {
      setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      const slackMessage = `
      Error in registerPhoneNumber function (line 230, useAuthorization.js) | 
      Error code: ${error.code} | 
      Error message: ${error.message} | 
      User: ${currentUserEmail}`;
      api.sendSlackAuthError(slackMessage);
    }
  };

  const resendVerificationCode = async (phoneNumber, email) => {
    let currentUserEmail = email;

    if (!currentUserEmail) {
      currentUserEmail = auth.currentUser
        ? auth.currentUser.email
        : 'unavailable';
    }

    try {
      const firstSlackMessage = `OTP code requested in resend verification process in registration flow for user - phone number: ${phoneNumber} | email: ${currentUserEmail}`;
      api.sendSlackAuthError(firstSlackMessage);

      const multiFactorSession = await multiFactor(
        auth.currentUser
      ).getSession();

      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession
      };

      const verificationId = await sendOTPCode(phoneInfoOptions, phoneNumber);
      setVerificationId(verificationId);

      const secondSlackMessage = `OTP code sent in resend verification process in registration flow for user phone number: ${phoneNumber} | email: ${currentUserEmail}`;
      api.sendSlackAuthError(secondSlackMessage);
    } catch (error) {
      sendSlackMessage(
        error,
        currentUserEmail,
        'resendVerificationCodeForRegistrationFlow',
        '261'
      );
    }
  };

  const sendOTPCode = async (phoneInfoOptions, phoneNumber) => {
    const recaptchaVerifier = getRecaptchaVerifier();

    try {
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      );
      return verificationId;
    } catch (error) {
      setIsLoading(false);

      if (error.code === AUTH_ERROR_CODE.TOO_MANY_REQUEST) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.LOGIN_TO_MANY_REQUESTS);
      } else {
        setError(AUTH_ERROR.DEFAULT);
      }
      const slackMessage = `
      Error in sendOTPCode function (line 257, useAuthorization.js) | 
      Error code: ${error.code} | 
      Error message: ${error.message} | 
      User: ${phoneNumber}`;
      api.sendSlackAuthError(slackMessage);
    } finally {
      resetRecaptchaVerifier(recaptchaVerifier);
    }
  };

  // Function that is called when user enters OTP code
  const verifyPhoneNumber = async verificationCode => {
    setIsLoading(true);
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await completePhoneEnrollment(multiFactorAssertion);
    } catch (error) {
      setIsLoading(false);
      if (error.code === 'auth/missing-verification-id') {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        auth.currentUser ? auth.currentUser.email : 'unavailable',
        'verifyPhoneNumber',
        '318'
      );
    }
  };

  const completePhoneEnrollment = async multiFactorAssertion => {
    try {
      await multiFactor(auth.currentUser).enroll(
        multiFactorAssertion,
        'My personal phone number'
      );
      // After successfull phone registration, we are checking if there is callback
      // if so, we are redirecting user to that callback
      // if not, we are sending user to myhealth page
      const urlParams = new URLSearchParams(location.search);
      const callback = urlParams.get('callback');
      const benefitCode = urlParams.get('benefitCode');
      if (callback) {
        if (
          callback === process.env.REACT_APP_SECURE_APP_URL ||
          callback === process.env.REACT_APP_SECURE_APP_URL + '/'
        ) {
          window.location.href = `${process.env.REACT_APP_SECURE_APP_URL}${PATHS.MY_HEALTH}`;
        } else {
          if (benefitCode) {
            window.location.href = `${callback}&benefitCode=${benefitCode}`;
            return;
          }
          window.location.href = callback;
          return;
        }
      } else {
        history.push(PATHS.MY_HEALTH);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === AUTH_ERROR_CODE.INVALID_VERIFICATION_CODE) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.INVALID_VERIFICATION_CODE);
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        auth.currentUser ? auth.currentUser.email : 'unavailable',
        'completePhoneEnrollment',
        '343'
      );
    }
  };

  ////////////////////////////////////////
  // Sign in functions
  ////////////////////////////////////////
  const signInUser = (email, password) => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user;
        setUserObject(user);
        if (user && user.uid && user.accessToken) {
          localStorage.setItem('userId', user.uid);
          localStorage.setItem('accessToken', user.accessToken);
        }

        if (!user.emailVerified) {
          sendVerificationEmailToUser(user);
        } else if (!user.multiFactor) {
          redirectUserToPhoneRegistrationScreen();
        }

        setIsLoading(false);
      })
      .catch(async error => {
        console.log('error', error.code);
        switch (true) {
          case error.code === AUTH_ERROR.MULTI_FACTOR_REQUIRED:
            await handleMultiFactorError(error, email);
            break;
          case error.code === AUTH_ERROR.WRONG_PASSWORD:
            setError(AUTH_ERROR.WRONG_PASSWORD);
            break;
          case error.code === AUTH_ERROR_CODE.TOO_MANY_REQUEST:
            setError(AUTH_ERROR_CUSTOM_MESSAGE.LOGIN_TO_MANY_REQUESTS);
            break;
          // This will happen if user who doesn't have account try to log in
          case error.code === AUTH_ERROR.USER_NOT_FOUND:
            console.log('user not found');
            setError(AUTH_ERROR_CUSTOM_MESSAGE.WRONG_COMBINATION);
            break;
          default:
            setError(AUTH_ERROR.DEFAULT);
            break;
        }
        setIsLoading(false);
        if (
          error.code !== AUTH_ERROR.MULTI_FACTOR_REQUIRED &&
          error.code !== AUTH_ERROR.WRONG_PASSWORD
        ) {
          setIsLoading(false);
          sendSlackMessage(
            error,
            email ? email : 'undefined',
            'signInUser',
            '393'
          );
        }
      });
  };

  // This error occure when user needs to enter second factor, in our case OTP code
  const handleMultiFactorError = async (error, email) => {
    try {
      const resolver = getMultiFactorResolver(auth, error);
      setResolver(resolver);

      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      };

      setMFANumber(resolver.hints[0].phoneNumber);

      const firstSlackMessage = `OTP code requested in log in process for user - phone number: ${
        resolver.hints[0].phoneNumber
      } | email: ${email || 'unavailable'}`;
      api.sendSlackAuthError(firstSlackMessage);

      const verificationId = await sendOTPCode(
        phoneInfoOptions,
        resolver.hints[0].phoneNumber
      );

      const secondSlackMessage = `OTP code sent in log in process for user - phone number: ${
        resolver.hints[0].phoneNumber
      } | email: ${email || 'unavailable'}`;
      api.sendSlackAuthError(secondSlackMessage);

      setVerificationId(verificationId);
      setUserState(userStateValues.PHONE_CODE);
      setIsLoading(false);
    } catch (error) {
      sendSlackMessage(
        error,
        email || 'undefined',
        'handleMultiFactorError',
        '443'
      );
    }
  };

  const resendVerificationCodeForSignIn = async (phoneNumber, email) => {
    try {
      const firstSlackMessage = `OTP code requested in resend verification process in log in flow for user - phone number: ${phoneNumber} | email: ${email}`;
      api.sendSlackAuthError(firstSlackMessage);

      const phoneInfoOptions = {
        multiFactorHint: gresolver.hints[0],
        session: gresolver.session
      };

      const verificationId = await sendOTPCode(phoneInfoOptions, phoneNumber);
      setVerificationId(verificationId);

      const secondSlackMessage = `OTP code sent in resend verification process in log in flow for user phone number: ${phoneNumber} | email: ${email}`;
      api.sendSlackAuthError(secondSlackMessage);
    } catch (error) {
      sendSlackMessage(
        error,
        email || 'undefined',
        'resendVerificationCodeForSignInFlow',
        '470'
      );
    }
  };

  const sendVerificationEmailToUser = user => {
    auth.languageCode = getCurrentLanguage();
    sendEmailVerification(user).then(() => {
      setUserState(userStateValues.EMAIL_VERIFICATION);
    });
  };

  const redirectUserToPhoneRegistrationScreen = () => {
    const queryString = window.location.search;
    const callback = queryString.replace('?callback=', '');
    if (callback) {
      history.push(`/phone-registration?callback=${callback}`);
    } else {
      history.push('/phone-registration');
    }
  };

  const finishLogin = async verificationCode => {
    let user = auth.currentUser;
    try {
      setIsLoading(true);
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const userData = await gresolver.resolveSignIn(multiFactorAssertion);
      user = userData.user;
      setUserObject(userData.user);

      if (userData && userData.user) {
        localStorage.setItem('userId', userData.user.uid);
        localStorage.setItem('accessToken', userData.user.accessToken);
      }

      const urlParams = new URLSearchParams(location.search);
      const callback = urlParams.get('callback');
      const benefitCode = urlParams.get('benefitCode');

      const finishLoginMessage = `User with email: ${
        user ? user.email : 'undefined'
      } successfully logged in`;
      await api.sendSlackAuthError(finishLoginMessage);
      if (callback) {
        if (
          callback === process.env.REACT_APP_SECURE_APP_URL ||
          callback === process.env.REACT_APP_SECURE_APP_URL + '/'
        ) {
          window.location.href = `${process.env.REACT_APP_SECURE_APP_URL}${PATHS.MY_HEALTH}`;
          return;
        } else {
          if (benefitCode) {
            window.location.href = `${callback}&benefitCode=${benefitCode}`;
            return;
          }
          window.location.href = callback;
          return;
        }
      } else {
        window.location.href = `${process.env.REACT_APP_SECURE_APP_URL}${PATHS.MY_HEALTH}`;
        return;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === AUTH_ERROR_CODE.INVALID_VERIFICATION_CODE) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.INVALID_VERIFICATION_CODE);
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        user ? user.email : 'undefined',
        'finishLogin',
        '489'
      );
    }
  };

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        dispatch(removeUser());
        localStorage.removeItem('userId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expiredTime');
        window.location.href = 'https://yourself.health/';
      })
      .catch(error => {
        sendSlackMessage(
          error,
          auth.currentUser ? auth.currentUser.email : 'undefined',
          'signOutUser',
          '564'
        );
      });
  };

  const showForgotPasswordForm = () => {
    setUserState(userStateValues.FORGOT_PASSWORD);
  };

  const getNewPassword = (oobCode, continueUrl) => {
    if (continueUrl) {
      let fixedCallback = continueUrl.replace('..', '.');

      if (fixedCallback.includes('?benefitCode')) {
        fixedCallback = fixedCallback.replace('?benefitCode', '&benefitCode');
      }

      history.push(
        `${PATHS.RESET_PASSWORD}?oobCode=${oobCode}&callback=${fixedCallback}`
      );
    } else {
      history.push(`${PATHS.RESET_PASSWORD}?oobCode=${oobCode}`);
    }
  };

  const resetPassword = async (oobCode, newPassword) => {
    try {
      setIsLoading(true);
      await confirmPasswordReset(auth, oobCode, newPassword);
      setUserState(userStateValues.PASSWORD_UPDATED);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.code === AUTH_ERROR.EXPIRED_ACTION_CODE) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.EXPIRED_VERIFICATION_CODE);
      } else if (error.code === AUTH_ERROR.INVALID_ACTION_CODE) {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.INVALID_VERIFICATION_CODE);
      } else {
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
      }

      sendSlackMessage(
        error,
        auth.currentUser ? auth.currentUser.email : 'unavailable',
        'resetPassword',
        '547'
      );
    }
  };

  const sendResetPasswordEmail = async email => {
    try {
      setIsLoading(true);
      const callback = getCallbackFromURL();
      let actionCodeSettings = null;
      if (callback) {
        actionCodeSettings = {
          url: `${process.env.REACT_APP_SECURE_APP_URL}/authorization?callback=${callback}`,
          handleCodeInApp: true
        };
      }

      auth.languageCode = getCurrentLanguage();
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      setIsLoading(false);
      setUserState(userStateValues.CHECK_INBOX);
    } catch (error) {
      // This means that someone who doesn't have account try to reset password
      if (error.code === AUTH_ERROR.USER_NOT_FOUND) {
        setIsLoading(false);
        setUserState(userStateValues.CHECK_INBOX);
      } else {
        setIsLoading(false);
        setError(AUTH_ERROR_CUSTOM_MESSAGE.DEFAULT);
        sendSlackMessage(error, email, 'sendResetPasswordEmail', '572');
      }
    }
  };

  return {
    registerUser,
    signInUser,
    signOutUser,
    verifyEmail,
    registerPhoneNumber,
    verifyPhoneNumber,
    finishLogin,
    checkIfUserExist,
    userState,
    setUserState,
    userStateValues,
    isLoading,
    error,
    setError,
    resendVerificationEmail,
    userObject,
    resendVerificationCode,
    MFANumber,
    sendResetPasswordEmail,
    showForgotPasswordForm,
    resendVerificationCodeForSignIn,
    getNewPassword,
    resetPassword,
    recaptchaRef,
    recaptchaResendRef,
    currentUser: auth.currentUser
  };
}
