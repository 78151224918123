import React from 'react';
import { Body2 } from '../Typography';
import { ReactComponent as KeyboardArrowRightIcon } from '../../assets/storybook-placeholders/svg/keyboard-arrow-right.svg';
import cx from 'classnames';

import styles from './index.module.scss';

export interface BottomDrawerLinkProps {
  icon: React.ReactNode;
  text: React.ReactNode;
  href: string;
}

const BottomDrawerLink: React.FC<BottomDrawerLinkProps> = ({
  icon,
  text,
  href
}) => {
  // TODO: Double check when we should use a tag and when Link component (with Djordje)
  return (
    <a
      href={href}
      className={cx({
        [styles.wrapper]: true,
        [styles.link]: true
      })}
    >
      <div className={styles.iconWrapper}>
        {icon}
        <Body2 className={styles.text}>{text}</Body2>
      </div>
      <KeyboardArrowRightIcon />
    </a>
  );
};

export default BottomDrawerLink;
