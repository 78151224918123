import React from 'react';
import PropTypes from 'prop-types';
import ContactViaFormAccordion from '../ContactViaFormAccordion';
import ContactByPhoneAccordion from '../ContactByPhoneAccordion';

import './index.scss';

const CssSupportAccordions = ({ language }) => {
  return (
    <div className='css-support-accordions'>
      <ContactViaFormAccordion language={language} />
      <ContactByPhoneAccordion />
    </div>
  );
};

CssSupportAccordions.propTypes = {
  language: PropTypes.string
};

export default CssSupportAccordions;
