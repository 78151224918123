import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import TestResultsHeader from '../../../components/TestResultsHeader';
import ReportBox from '../../../components/ReportBox';
import Divider from '../../../components/Divider';
import LabResults from '../LabResults';
import TestResultsNavigation from '../TestResultsNavigation';
import RecommendedActions from '../RecommendedActions';
import TestResultsDocuments from '../TestResultsDocuments';
import StauffacherSupport from '../StauffacherSupport';
import SideDrawer from '../../../components/SideDrawer';
import InfoBanner from '../../../components/InfoBanner';
import CssSupport from '../CssSupport';
import formatLastUpdateDate from '../../helpers/dateFormat';

import { FormattedMessage } from 'react-intl';
import useScrollHandler from './useScrollHandler.hook';
import { useMediaQuery } from 'react-responsive';

import styles from './index.module.scss';

const TestResultsContent = ({ data, language }) => {
  const {
    title,
    description,
    reports,
    labResults,
    overallResult,
    documents,
    recommendedActions,
    infoBlock,
    partnerBlocks,
    resultsAvailableDate
  } = data;

  const { scrollableElementRef, linksData, activeSection } = useScrollHandler({
    data
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const isDesktop = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const handleOpenModal = info => {
    setModalContent(<Markdown rehypePlugins={[rehypeRaw]}>{info}</Markdown>);
    setIsModalOpen(true);
  };
  const formattedResultsAvailableDate = formatLastUpdateDate(
    resultsAvailableDate
  );
  return (
    <div ref={scrollableElementRef} className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <TestResultsHeader
            className={styles.testResultsHeader}
            title={title}
            overallResult={overallResult}
            resultsAvailableDate={formattedResultsAvailableDate}
          />
          <div className={styles.navigationMobileWrapper}>
            <TestResultsNavigation
              className={styles.navigationMobile}
              linksData={linksData}
              activeSection={activeSection}
            />
          </div>
          {description && !reports.doctorReport && (
            <div id='summary' className={styles.summary}>
              <ReportBox className={styles.reportBox} text={description} />
            </div>
          )}

          {reports.doctorReport && (
            <div id='doctor' className={styles.doctorReport}>
              <Divider
                title={
                  <FormattedMessage
                    id='app.doctors-report'
                    defaultMessage="Doctor's report"
                  />
                }
              />
              <ReportBox
                className={styles.reportBox}
                text={reports.doctorReport.reportText}
                name={reports.doctorReport.writtenBy}
              />
            </div>
          )}
          {labResults && labResults.length > 0 && (
            <div id='lab' className={styles.labResultsWrapper}>
              <Divider
                title={
                  <FormattedMessage
                    id='test-result.lab-results'
                    defaultMessage='Lab results'
                  />
                }
              />
              <div className={styles.labResults}>
                <LabResults
                  labResults={labResults}
                  handleOpenModal={handleOpenModal}
                />
              </div>
            </div>
          )}

          {!isDesktop && (
            <div className={styles.documentsWrapperMobile}>
              <TestResultsDocuments documents={documents} />
            </div>
          )}

          {infoBlock && (
            <InfoBanner
              title={infoBlock.title}
              description={infoBlock.description}
              type={infoBlock.type}
              backgroundColor={infoBlock.color}
              url={infoBlock.url}
              handleInfo={() => handleOpenModal(infoBlock.infoText)}
            />
          )}

          {partnerBlocks && partnerBlocks.stauffacherBlock && (
            <div id='stauffacher'>
              <StauffacherSupport />
            </div>
          )}

          {partnerBlocks && partnerBlocks.CSSBlock && (
            <div id='css'>
              <CssSupport language={language} />
            </div>
          )}

          {recommendedActions && (
            <div id='actions' className={styles.actions}>
              <Divider
                title={
                  <FormattedMessage
                    id='app.recommended-actions'
                    defaultMessage='Recommended actions'
                  />
                }
              />
              <div className={styles.recommendedActions}>
                {recommendedActions.map((action, index) => {
                  const isLastRecommendedActionsBlock =
                    index === recommendedActions.length - 1;

                  return (
                    <RecommendedActions
                      key={index}
                      timing={action.timing}
                      actions={action.actions}
                      labReport={documents.labReport}
                      isLastRecommendedActionsBlock={
                        isLastRecommendedActionsBlock
                      }
                      handleInfo={infoData => handleOpenModal(infoData)}
                      language={language}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={styles.sidebarWrapper}>
          <div className={styles.sidebar}>
            <TestResultsNavigation
              className={styles.navigationDesktop}
              linksData={linksData}
              activeSection={activeSection}
            />
            {isDesktop && <TestResultsDocuments documents={documents} />}
          </div>
        </div>
      </div>
      <SideDrawer onClick={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <div>{modalContent}</div>
      </SideDrawer>
    </div>
  );
};

TestResultsContent.propTypes = {
  data: PropTypes.object,
  language: PropTypes.string
};

export default TestResultsContent;
