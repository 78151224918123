import React from 'react';
import PropTypes from 'prop-types';
import RecommendedActionsTiming from './RecommendedActionsTiming';
import RecommendedAction from './RecommendedAction';

const RecommendedActions = ({
  actions,
  timing,
  labReport,
  isLastRecommendedActionsBlock,
  handleInfo,
  language
}) => {
  return (
    <>
      <RecommendedActionsTiming timing={timing} actions={actions} />

      {actions &&
        actions.map((action, index) => {
          const {
            CTAs,
            content,
            done,
            footerCTA,
            productCards,
            contentCards,
            timingKey,
            key
          } = action;

          const isLastAccordion =
            index === actions.length - 1 && isLastRecommendedActionsBlock;

          return (
            <RecommendedAction
              key={content.title}
              content={content}
              CTAs={CTAs}
              done={done}
              footerCTA={footerCTA}
              productCards={productCards}
              isLastAccordion={isLastAccordion}
              contentCards={contentCards}
              labReport={labReport}
              handleInfo={handleInfo}
              timingKey={timingKey}
              contentKey={key}
              language={language}
            />
          );
        })}
    </>
  );
};

RecommendedActions.propTypes = {
  actions: PropTypes.array,
  timing: PropTypes.string,
  labReport: PropTypes.string,
  isLastRecommendedActionsBlock: PropTypes.bool,
  handleInfo: PropTypes.func,
  language: PropTypes.string
};

export default RecommendedActions;
