class IdleTimer {
  constructor({ timeout, onTimeout, onExpired }) {
    // Seconds
    this.timeout = timeout;
    // The callback will be triggered if the users are in the app and have the idle timeout
    this.onTimeout = onTimeout;
    // The callback will be triggered if the users re-open the app after the expired time.
    this.onExpired = onExpired;

    this.eventHandler = this.updateExpiredTime.bind(this);
  }

  start() {
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(async () => {
      const expiredTime = parseInt(
        localStorage.getItem('expiredTime') || 0,
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          await this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem('expiredTime', Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem('expiredTime');
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
