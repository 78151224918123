import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import withWindow from '../../windowDecorators/withWindow';

import useAuthorization from '../../hooks/useAuthorization';

import Background from '../../components/Background';
import FormWrapper from '../../components/FormWrapper';
import PhoneNumberForm from '../../components/PhoneNumberForm';
import PhoneCodeForm from '../../components/PhoneCodeForm';

import backgroundMobile from '../../../assets/svg/phone-registration-mobile.svg';
import backgroundLarge from '../../../assets/svg/phone-registration-desktop.svg';

import './index.scss';

const PhoneRegistration = ({
  breakpoint,
  dispatch,
  intl,
  lang,
  changeLanguage
}) => {
  const {
    verifyPhoneNumber,
    registerPhoneNumber,
    resendVerificationCode,
    currentUser,
    recaptchaRef,
    recaptchaResendRef,
    isLoading,
    error
  } = useAuthorization(dispatch);

  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [hidePhone, setHidePhone] = useState(false);

  const smallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  const handleRegisterPhoneNumber = formatedPhoneNumber => {
    registerPhoneNumber(formatedPhoneNumber);
    setHidePhone(true);
  };

  const handleVerifiyPhoneNumber = () => {
    verifyPhoneNumber(code);
  };

  if (!currentUser) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let callback = urlParams.get('callback');
    const benefitCode = urlParams.get('benefitCode');
    if (benefitCode) {
      callback = `${callback}?benefitCode=${benefitCode}`;
    }
    const url = callback
      ? `/authorization?callback=${callback}`
      : '/authorization';

    return <Redirect to={url} />;
  }

  return (
    <Background className='sign-up__background'>
      <div ref={recaptchaRef}>
        <div id='recaptcha' />
      </div>
      <div ref={recaptchaResendRef}>
        <div id='recaptcha-resend' />
      </div>
      <div className='sign-up'>
        <FormWrapper
          imageUrl={smallOrMedium ? backgroundMobile : backgroundLarge}
          lang={lang}
          changeLanguage={changeLanguage}
        >
          {!hidePhone ? (
            <PhoneNumberForm
              phone={phone}
              setPhone={setPhone}
              submitPhone={handleRegisterPhoneNumber}
              intl={intl}
            />
          ) : (
            <PhoneCodeForm
              code={code}
              setCode={setCode}
              phone={phone}
              onClick={handleVerifiyPhoneNumber}
              resendVerificationCode={resendVerificationCode}
              setHidePhone={setHidePhone}
              isLoading={isLoading}
              hookError={error}
            />
          )}
        </FormWrapper>
      </div>
    </Background>
  );
};

PhoneRegistration.propTypes = {
  breakpoint: PropTypes.string,
  dispatch: PropTypes.func,
  intl: PropTypes.any,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default connect()(withWindow(injectIntl(PhoneRegistration)));
