import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useCreateBlob from '../TestResultsDocuments/useCreateBlob.hook';
import {
  downloadReport,
  clearDownloadReport
} from '../../actions/downloadReport';
import Button from '../../../components/Button';
import { DocumentTypes } from '../TestResultsDocuments';

import styles from './index.module.scss';

const LabReportDownloadButton = ({ cta, labReport, dispatch, data, error }) => {
  const cleanUpDownloadReportData = () => {
    dispatch(clearDownloadReport());
  };

  const downloadReportError = useCreateBlob(
    data,
    labReport,
    cleanUpDownloadReportData
  );

  const downloadLabReport = (type, report) => {
    dispatch(downloadReport(type, report));
  };

  return (
    <div className={styles.wrapper}>
      <Button
        key={cta.text}
        variant={cta.variant}
        size='small'
        onClick={() => downloadLabReport(DocumentTypes.LAB_REPORT, labReport)}
      >
        {cta.text}
      </Button>
      {error ||
        (downloadReportError && (
          <p className={styles.error}>
            <FormattedMessage
              id='test-result.try-again-later'
              defaultMessage='Something went wrong, please try again later'
            />
          </p>
        ))}
    </div>
  );
};

LabReportDownloadButton.propTypes = {
  cta: PropTypes.object,
  labReport: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  data: state.downloadReport.get('data'),
  loading: state.videoUpload.get('loading'),
  error: state.app.get('error')
});

export default connect(mapStateToProps)(LabReportDownloadButton);
