import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

import '../YearPicker/index.scss';

const DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const DayPicker = ({
  chosenDay,
  chosenYear,
  chosenMonth,
  setChosenDay,
  setIsOpen,
  isOpen
}) => {
  const [numberOfDaysInMonth, setNumberOfDaysInMonth] = useState(0);
  const [firstDayInMonth, setFirstDayInMonth] = useState(0);
  const isDayPickerEnabled = chosenYear && chosenMonth.value;

  useEffect(() => {
    setIsOpen(false);
  }, [chosenDay, setIsOpen]);

  useEffect(() => {
    if (chosenYear !== undefined && chosenMonth.value !== undefined) {
      setNumberOfDaysInMonth(
        getNumberDaysInMonth(chosenYear, chosenMonth.value)
      );
      setFirstDayInMonth(getFirstDayInMonth(chosenYear, chosenMonth.value));
    }
  }, [
    chosenYear,
    chosenMonth.value,
    setNumberOfDaysInMonth,
    setFirstDayInMonth
  ]);

  const getCurrentItems = useCallback(() => {
    const currentItems = [];

    for (let i = 0; i < firstDayInMonth; i++) {
      currentItems.push(0);
    }

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      currentItems.push(i);
    }

    return currentItems.map((item, index) => {
      const buttonSelectorClass = cx({
        'picker__button-selector': true,
        'picker__button-selector--selected': chosenDay === item
      });

      return item > 0 ? (
        <button
          className='picker__day-button'
          key={index}
          onClick={() => setChosenDay(item)}
        >
          <span className={buttonSelectorClass}>{item}</span>
        </button>
      ) : (
        <div className='picker__day-button-placeholder' key={index} />
      );
    });
  }, [firstDayInMonth, numberOfDaysInMonth, setChosenDay, chosenDay]);

  const getDaysList = () => {
    return DAYS.map(item => {
      return (
        <span className='picker__day' key={item}>
          {item}
        </span>
      );
    });
  };

  const getNumberDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const getFirstDayInMonth = (year, month) => {
    return new Date(`${month}/1/${year}`).getDay();
  };

  const pickerClasses = cx({
    picker: true,
    'picker--disabled': !isDayPickerEnabled
  });

  const pickerTextClasses = cx({
    picker__text: true,
    'picker__text--black': chosenDay
  });

  const arrowDownClasses = cx({
    'picker__arrow-down': true,
    'picker__arrow-down--disabled': !isDayPickerEnabled
  });

  return (
    <div
      className={pickerClasses}
      onClick={isDayPickerEnabled ? () => setIsOpen(true) : null}
    >
      <span className={pickerTextClasses}>
        {chosenDay ?? (
          <FormattedMessage
            id='date-picker.enter-day'
            defaultMessage='Enter Day'
          />
        )}
      </span>
      <div className={arrowDownClasses} />
      {/* TODO: Update */}
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div className='picker__list'>
            <div className='picker__month-list-header'>
              <span className='picker__current-values'>
                {chosenMonth.name ?? ''} {chosenYear}
              </span>
            </div>
            <div className='picker__days-list'>{getDaysList()}</div>
            <div className='picker__days-items'>{getCurrentItems()}</div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

DayPicker.propTypes = {
  chosenYear: PropTypes.number,
  chosenMonth: PropTypes.object,
  chosenDay: PropTypes.number,
  setChosenDay: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool
};

export default DayPicker;
