export const SET_VIDEO = 'SET_VIDEO';
export const SET_UPLOAD_VIDEO_ERROR = 'SET_UPLOAD_VIDEO_ERROR';

export const setVideoData = video => ({
  type: SET_VIDEO,
  payload: video
});

export const setUploadVideoError = error => ({
  type: SET_UPLOAD_VIDEO_ERROR,
  payload: error
}); 