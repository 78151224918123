import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Slider from 'react-slick';

import breakpoints from '../../constants/breakpoints';

import './index.scss';

let firstClientX, clientX;

const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};

const touchStart = e => {
  firstClientX = e.touches[0].clientX;
};

const Carousel = ({
  children,
  breakpoint,
  centerPadding,
  inModal,
  hasArrows,
  slidesToShow,
  initialSlide,
  variableWidth,
  notCentered
}) => {
  let containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false
        });
      }
    };
  });

  const isSmall = [breakpoints.name.XSMALL, breakpoints.name.SMALL].includes(breakpoint);
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: slidesToShow || (isSmall ? 1 : 2),
    slidesToScroll: slidesToShow || (isSmall ? 1 : 2),
    arrows: hasArrows,
    infinite: false,
    centerMode: !isSmall || notCentered ? false : true,
    centerPadding: `${centerPadding}px`,
    dotsClass: 'carousel__dots',
    className: 'center',
    initialSlide: initialSlide ? initialSlide : 0,
    variableWidth: variableWidth
  };

  const classes = cx({
    carousel: true,
    'carousel--inModal': inModal
  });

  return (
    <div ref={containerRef} className={classes}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.array,
  breakpoint: PropTypes.string,
  centerPadding: PropTypes.number,
  inModal: PropTypes.bool,
  hasArrows: PropTypes.bool,
  slidesToShow: PropTypes.number,
  initialSlide: PropTypes.number,
  variableWidth: PropTypes.bool,
  notCentered: PropTypes.bool
};

export default Carousel;
