import { Map } from 'immutable';

import {
  START_CONVERSATION_WITH_TEST_CODE_START,
  START_CONVERSATION_WITH_TEST_CODE_SUCCESS,
  START_CONVERSATION_WITH_TEST_CODE_ERROR,
  START_CONVERSATION_WITH_BENEFIT_CODE_START,
  START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS,
  START_CONVERSATION_WITH_BENEFIT_CODE_ERROR,
  START_CONVERSATION_START,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_ERROR,
  UPDATE_CONVERSATION_START,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_ERROR,
  CONTINUE_CONVERSATION_START,
  CONTINUE_CONVERSATION_SUCCESS,
  CONTINUE_CONVERSATION_ERROR,
  SUBMIT_ANSWER_START,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_ERROR,
  RESET_ANSWER_ERROR,
  GO_BACK_START,
  GO_BACK_SUCCESS,
  GO_BACK_ERROR,
  UPDATE_TOKEN,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS,
} from '../actions/data';

const initialState = Map({
  loading: false,
  error: null,
  data: null
});

const actionsMap = {
  [UPDATE_TOKEN]: (state, action) => {
    return state.merge(
      Map({
        token: action.data
      })
    );
  },
  [START_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [UPDATE_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [UPDATE_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [UPDATE_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CONTINUE_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [CONTINUE_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [CONTINUE_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [SUBMIT_ANSWER_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [SUBMIT_ANSWER_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [SUBMIT_ANSWER_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [RESET_ANSWER_ERROR]: state => {
    return state.merge(
      Map({
        error: null
      })
    );
  },
  [GO_BACK_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [GO_BACK_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [GO_BACK_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
