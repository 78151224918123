import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Subtitle1, Body2 } from '../../../../components/Typography';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/svg/arrow-down-icon.svg';
import { ReactComponent as InfoGradientIcon } from '../../../../assets/storybook-placeholders/svg/info-gradient.svg';

import './index.scss';

const LabResultsAccordion = ({
  name,
  description,
  content,
  info,
  handleInfo,
  isFirstAccordionOpen,
  onToggle
}) => {
  const [isOpen, setIsOpen] = useState(isFirstAccordionOpen);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef(null);

  useEffect(() => {
    // added timeout here also just in case, for more details why, look into accordion component
    setTimeout(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, 500);
  }, [content]);

  const handleAccordionClick = () => {
    setIsOpen(!isOpen);
    onToggle();
  };

  return (
    <div className='lab-results-accordion'>
      <div
        onClick={handleAccordionClick}
        className='lab-results-accordion__header'
      >
        <div className='lab-results-accordion__header-wrapper'>
          <div className='lab-results-accordion__text-wrapper'>
            <Subtitle1>{name}</Subtitle1>
            {description && (
              <Body2 className='lab-results-accordion__description'>
                {description}
              </Body2>
            )}
          </div>

          {info && (
            <button
              className='lab-results-accordion__info-icon'
              onClick={event => {
                event.stopPropagation();
                handleInfo();
              }}
            >
              <InfoGradientIcon />
            </button>
          )}
        </div>
        <ArrowDownIcon
          className={cx({
            'lab-results-accordion__arrow-down': true,
            'lab-results-accordion__arrow-up': isOpen
          })}
        />
      </div>

      <div
        ref={contentRef}
        className='lab-results-accordion__accordion-content'
        style={{ maxHeight: isOpen ? contentHeight : 0 }}
      >
        {content}
      </div>
    </div>
  );
};

LabResultsAccordion.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
  info: PropTypes.string,
  handleInfo: PropTypes.func,
  isFirstAccordionOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

export default LabResultsAccordion;
