import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_TEST_RESULT_START,
  GET_TEST_RESULT_ERROR,
  GET_TEST_RESULT_SUCCESS,
  MARK_RECOMMENDED_ACTION_AS_DONE_START,
  MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS,
  MARK_RECOMMENDED_ACTION_AS_DONE_ERROR
} from '../actions/testResult';
import api from '../api';

function createGetTestResults() {
  return function*(option) {
    const { testCode, language } = option.payload;
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.getTestResults(testCode, language));
      const action = { type: GET_TEST_RESULT_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: GET_TEST_RESULT_ERROR, error };

      yield put(action);
    }
  };
}

function* getUserWatcher() {
  yield takeLatest(GET_TEST_RESULT_START, createGetTestResults());
}

function createMarkRecommendedActionAsDone() {
  return function* (option) {
    const { testCode, timingKey, contentKey, language } = option.payload;
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.markRecommendedActionAsDone(testCode, timingKey, contentKey, language));
      const action = { type: MARK_RECOMMENDED_ACTION_AS_DONE_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: MARK_RECOMMENDED_ACTION_AS_DONE_ERROR, error };

      yield put(action);
    }
  };
}

function* createMarkRecommendedActionAsDoneWatcher() {
  yield takeLatest(MARK_RECOMMENDED_ACTION_AS_DONE_START, createMarkRecommendedActionAsDone());
}


export default [getUserWatcher(), createMarkRecommendedActionAsDoneWatcher()];
