import React from 'react';
import { Subtitle2 } from '../Typography';
import EmptyCard from './EmptyCard';
import styles from './index.module.scss';
import { FormattedMessage } from 'react-intl';

const DashboardWithNoCards: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Subtitle2 className={styles.title}>
        <FormattedMessage
          id='app.no-test-added'
          defaultMessage={`It looks like you haven't added any tests yet. Let's get started!`}
        />
      </Subtitle2>
      <EmptyCard />
    </div>
  );
};

export default DashboardWithNoCards;
