import React from 'react';
import { Subtitle1, Subtitle2 } from '../Typography';
import styles from './index.module.scss';

export interface TextWithBadgeProps {
  text: any;
  badgeText: any;
  badgeBackgroundColor: string;
}

const TextWithBadge: React.FC<TextWithBadgeProps> = ({
  text,
  badgeText,
  badgeBackgroundColor
}) => {
  return (
    <div className={styles.wrapper}>
      <Subtitle1>{text}</Subtitle1>
      <div
        className={styles.badgeTextWrapper}
        style={{ background: badgeBackgroundColor }}
      >
        <Subtitle2 className={styles.badgeText}>{badgeText}</Subtitle2>
      </div>
    </div>
  );
};

export default TextWithBadge;
