function formatLastUpdateDate(date) {
  const dateInfo = new Date(date);
  const day = dateInfo.getDate();
  const month = dateInfo.getMonth() + 1 > 9 ? dateInfo.getMonth() + 1 : `0${dateInfo.getMonth() + 1}`;
  const year = dateInfo.getFullYear();
  const hour = dateInfo.getHours() > 9 ? dateInfo.getHours() : `0${dateInfo.getHours()}`;
  const minutes = dateInfo.getMinutes() > 9 ? dateInfo.getMinutes() : `0${dateInfo.getMinutes()}`;

  const fixedDay = day < 10 ? `0${day}` : day;

  return `${fixedDay}.${month}.${year} ${hour}:${minutes}`;
}

export default formatLastUpdateDate;
