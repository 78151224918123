import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import OtpInput from 'react-otp-input';
import useCountDown from 'react-countdown-hook';
import { FormattedMessage } from 'react-intl';
import { AUTH_ERROR_CUSTOM_MESSAGE } from '../../constants/errorCodes';

import FormTitle from '../FormWrapper/FormTitle';
import FormText from '../FormWrapper/FormText';
import FormButton from '../FormWrapper/FormButton';

import './index.scss';

const countdownTime = 60 * 1000; // how much we have to wait before enabling user to send new email
const interval = 1000; // interval to change remaining time amount, defaults to 1000

const PhoneCodeForm = ({
  code,
  setCode,
  onClick,
  isLoading,
  resendVerificationCode,
  phone,
  setHidePhone,
  email,
  hookError
}) => {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [timeLeft, { start }] = useCountDown(null, interval);

  useEffect(() => {
    if (timeLeft <= 0 && disabled) {
      setDisabled(false);
      if (error) {
        setError(null);
      }
    }

    if (timeLeft > 0 && !disabled) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, disabled]);

  // There is a edgecase when page is load and focus goes to recaptcha,
  // So with this we are making sure that input will be focused on page load
  // useEffect(() => {
  //   setTimeout(() => {
  //     const firstInput = document.getElementsByClassName(
  //       'phone-code-form__input'
  //     )[0];

  //     firstInput.focus();
  //   }, 1000);
  // }, []);

  useEffect(() => {
    const now = new Date();
    const OTPResendTimeout = localStorage.getItem('OTPResendTimeout');
    let countdownStart;
    // If we have time stored, we are checking that value and using it for countdown
    if (OTPResendTimeout && OTPResendTimeout > now.getTime()) {
      countdownStart = OTPResendTimeout - now.getTime();
      start(countdownStart);
    }

    // If we don't have time stored, we are starting countdown of 90 seconds and adding it to the localstorage
    if (
      !OTPResendTimeout ||
      (OTPResendTimeout && OTPResendTimeout < now.getTime())
    ) {
      // One minute and a half
      countdownStart = countdownTime;
      localStorage.setItem('OTPResendTimeout', now.getTime() + countdownStart);
      start(countdownStart);
    }

    return () => {
      localStorage.removeItem('OTPResendTimeout');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = value => {
    setCode(value);
    if (error) {
      setError(null);
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (code.length < 6) {
      setError(AUTH_ERROR_CUSTOM_MESSAGE.VERIFICATION_CODE_TOO_SHORT);
    } else {
      onClick(code);
    }
  };

  const handleLinkClick = () => {
    resendVerificationCode(phone, email);
    const now = new Date();
    const countdownStart = countdownTime;
    localStorage.setItem('OTPResendTimeout', now.getTime() + countdownStart);
    start(countdownStart);
    setDisabled(true);
    if (error) {
      setError(null);
    }
  };

  const handleBackButtonClick = () => {
    localStorage.removeItem('OTPResendTimeout');
    setHidePhone(false);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <FormTitle
        title='auth.enter-mobile-code'
        defaultTitle='Enter verification code'
      />
      <FormText
        text='auth.verification-code'
        defaultText='We sent you a SMS code to the number {phone}'
        values={{ phone: phone }}
      />
      <OtpInput
        value={code}
        onChange={handleOnChange}
        numInputs={6}
        renderInput={props => <input {...props} type='number' />}
        inputStyle={'phone-code-form__input'}
        containerStyle='phone-code-form__input-wrapper'
        placeholder='000000'
      />
      {(error || hookError) && (
        <span className='phone-code__error'>
          <FormattedMessage id={error || hookError} defaultMessage='Error' />
        </span>
      )}
      <div className='phone-code__text'>
        <FormattedMessage
          id='auth.code-not-received'
          defaultMessage="Didn't receive code?"
        />
        <button
          className='phone-code__button'
          disabled={disabled}
          onClick={handleLinkClick}
          type='button'
        >
          {disabled ? (
            <>
              <FormattedMessage
                id='auth.resend-in'
                defaultMessage='Resend in '
              />
              {Math.floor(timeLeft / 1000)}
              <FormattedMessage id='auth.seconds' defaultMessage=' seconds' />
            </>
          ) : (
            <FormattedMessage
              id='auth.send-again'
              defaultMessage='Send again'
            />
          )}
        </button>
      </div>
      <div
        className={cx('phone-code__buttons-wrapper', {
          'phone-code__buttons-wrapper--hasBackButton': !!setHidePhone
        })}
      >
        {!!setHidePhone && (
          <FormButton
            className='phone-code__back-button'
            text='auth.back'
            defaultText='Back'
            onClick={handleBackButtonClick}
            disabled={isLoading}
            backArrow
          />
        )}
        <FormButton
          className='phone-code__finish-button'
          text='auth.finish'
          defaultText='Finish'
          submit
          isLoading={isLoading}
          disabled={!!error}
        />
      </div>
    </form>
  );
};

PhoneCodeForm.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func,
  isLoading: PropTypes.bool,
  activateRecaptcha: PropTypes.func,
  resendVerificationCode: PropTypes.func,
  phone: PropTypes.string,
  onClick: PropTypes.func,
  setHidePhone: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  email: PropTypes.string,
  hookError: PropTypes.string
};

export default PhoneCodeForm;
