import React from 'react';
import { Body2 } from '../../../../components/Typography';
import Link from '../../../../components/Link';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const ContactByPhoneAccordionContent = () => {
  return (
    <div className='contact-by-phone-accordion-content'>
      <Body2 className='contact-by-phone-accordion-content__text contact-by-phone-accordion-content__text--mobile'>
        <FormattedMessage
          id='test-result.partner-code.css-support.phone-accordion.text-mobile'
          defaultMessage='Health coaches are available by phone from Monday to Friday.'
        />
      </Body2>

      <Body2 className='contact-by-phone-accordion-content__text contact-by-phone-accordion-content__text--desktop'>
        <FormattedMessage
          id='test-result.partner-code.css-support.phone-accordion.text-desktop'
          defaultMessage={
            'Health coaches are available by phone from Monday to Friday.{br}Call health coaches under +41 58 277 46 00'
          }
          values={{
            br: <br />
          }}
        />
      </Body2>

      <Link
        url='tel:+41582774600'
        size='small'
        className='contact-by-phone-accordion-content__button'
      >
        <FormattedMessage
          id='test-result.partner-code.css-support.phone-accordion.button-text'
          defaultMessage='Call health coach'
        />
      </Link>
    </div>
  );
};

export default ContactByPhoneAccordionContent;
