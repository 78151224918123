function extractPathFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    // we go through url and return only pathname
    return parsedUrl.pathname + parsedUrl.search;
  } catch {
    return url;
  }
}

export default extractPathFromUrl