import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const YearPicker = ({chosenYear, setChosenYear}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [leftValue, setLeftValue] = useState(2010);
  const [rightValue, setRightValue] = useState(2021);

  useEffect(() => {
    setIsOpen(false);
  }, [chosenYear])

  const decrease = () => {
    setLeftValue(leftValue - 10);
    setRightValue(rightValue - 10);
  }

  const increase = () => {
    setLeftValue(leftValue + 10);
    setRightValue(rightValue + 10);
  }

  const getCurrentItems = () => {
    const currentItems = [];

    for(let i = leftValue; i <= rightValue; i++) {
      currentItems.push(i);
    }

    return currentItems.map(item => {
      const buttonSelectorClass = cx({
        'picker__button-selector': true,
        'picker__button-selector--selected': chosenYear === item,
      });
      
      return (
        <button 
          className='picker__button' 
          key={item}
          onClick={() => setChosenYear(item)}
        >
          <span className={buttonSelectorClass}>
            {item}
          </span>
        </button>
    )});
  }

  const yearPickerClasses = cx({
    'picker__text': true,
    'picker__text--black': chosenYear,
  });

  return (
    <div className='picker' onClick={() => setIsOpen(true)}>
      <span className={yearPickerClasses}>
        { chosenYear ?? <FormattedMessage id='date-picker.enter-year' defaultMessage='Enter Year' />}
      </span>
      <div className='picker__arrow-down' />
      {isOpen && 
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div className='picker__list picker__list--year'>
            <div className='picker__list-header'>
              <button className='picker__change-button' onClick={decrease}>
                <span className='picker__arrow'/>
              </button>
              <span className='picker__current-values'>{leftValue} - {rightValue}</span>
              <button className='picker__change-button' onClick={increase}>
                <span className='picker__arrow picker__arrow--right'/>
              </button>
            </div>
            <div className='picker__list-items'>
              {getCurrentItems()}
            </div>
         </div> 
        </OutsideClickHandler>
      }
    </div>
  );
}

YearPicker.propTypes = {
  chosenYear: PropTypes.number,
  setChosenYear: PropTypes.func,
}

export default YearPicker
