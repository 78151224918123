import React from 'react';
import { Body2, Subtitle1 } from '../Typography';

import InfoButton from '../../app/components/InfoButton';
import LearnMoreButton from '../../app/components/LearnMoreButton';
import RegularButton from '../../app/components/RegularButton';

import styles from './index.module.scss';

export const ctaComponents: Record<string, React.ComponentType<any>> = {
  info: InfoButton,
  link: LearnMoreButton,
  button: RegularButton
};

export type ContentCardData = {
  title: string;
  description: string;
  cta: { url: string; text: string; type: string; infoText?: string };
};

interface ContentCardProps {
  data: ContentCardData;
  handleInfo: () => void;
}

const ContentCard: React.FC<ContentCardProps> = ({ data, handleInfo }) => {
  const CTAComponent = data.cta ? ctaComponents[data.cta.type] : null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Subtitle1 className={styles.title}>{data.title}</Subtitle1>
        <Body2 className={styles.description}>{data.description}</Body2>
      </div>

      {CTAComponent && <CTAComponent cta={data.cta} handleInfo={handleInfo} />}
    </div>
  );
};

export default ContentCard;
