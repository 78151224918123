import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LabReportDownloadButton from '../../LabReportDownloadButton';
import MarkAsCompleteButton from '../../MarkAsCompleteButton';
import LearnMoreButton from '../../LearnMoreButton';
import JumpToReportButton from '../../JumpToReportButton';

import './index.scss';

const ctaComponents = {
  downloadLabReport: LabReportDownloadButton,
  markAsComplete: MarkAsCompleteButton,
  link: LearnMoreButton,
  jumpToReport: JumpToReportButton
};

const RecommendedActionsCTAs = ({
  ctas,
  labReport,
  contentKey,
  timingKey,
  language,
  done
}) => {
  const ctasHasOnlyMarkAsCompleteAndDone =
    ctas &&
    ctas.length === 1 &&
    ctas.some(cta => cta.type === 'markAsComplete') &&
    done;

  return (
    <div className='recommended-actions-ctas'>
      <div
        className={cx({
          'recommended-actions-ctas__content': true,
          'recommended-actions-ctas__content-without-border': ctasHasOnlyMarkAsCompleteAndDone
        })}
      >
        {ctas.map(cta => {
          const CTAComponent = ctaComponents[cta.type];
          if (CTAComponent) {
            return (
              <CTAComponent
                key={cta.type}
                cta={cta}
                labReport={labReport}
                timingKey={timingKey}
                contentKey={contentKey}
                language={language}
                done={done}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

RecommendedActionsCTAs.propTypes = {
  ctas: PropTypes.array,
  labReport: PropTypes.string,
  timing: PropTypes.string,
  timingKey: PropTypes.string,
  contentKey: PropTypes.string,
  language: PropTypes.string,
  done: PropTypes.bool
};

export default RecommendedActionsCTAs;
