import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Body1, Heading4 } from '../../../components/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InputNumber,
  InputWithIcon,
  RegularInput
} from '../../../components/Input';
import { ReactComponent as LocationIcon } from '../../../assets/svg/location-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close-icon.svg';
import CountryDropdown from '../CountryDropdown';
import Button from '../../../components/Button';
import LoadingCircle from '../LoadingCircle';
import useOrderTestModalContent from './useOrderTestModalContent.hook';

import './index.scss';

const OrderTestModalContent = ({
  handleProceed,
  onClose,
  orderData,
  orderLoading,
  orderError,
  dispatch,
  testData,
  benefitData
}) => {
  const intl = useIntl();
  const {
    firstName,
    lastName,
    address,
    apartment,
    city,
    zipCode,
    formError,
    company,
    handleOrderTest
  } = useOrderTestModalContent(
    handleProceed,
    orderData,
    orderLoading,
    orderError,
    dispatch,
    testData,
    benefitData
  );

  return (
    <form className='order-test-modal-content' onSubmit={handleOrderTest}>
      <Heading4>
        <FormattedMessage
          id='my-health.delivery-address'
          defaultMessage='Delivery Address'
        />
      </Heading4>

      <Body1 className='order-test-modal-content__text'>
        <FormattedMessage
          id='my-health.provide-address'
          defaultMessage='Please provide the complete address for test delivery.'
        />
      </Body1>

      <div className='order-test-modal-content__input-wrapper'>
        <CountryDropdown />
        <RegularInput
          value={firstName.value}
          error={firstName.error}
          onChange={firstName.onChange}
          onBlur={firstName.onBlur}
          type='text'
          placeholder={intl.formatMessage({
            id: 'my-health.form-placeholder.first-name',
            defaultMessage: 'First name'
          })}
        />

        <RegularInput
          value={lastName.value}
          error={lastName.error}
          onChange={lastName.onChange}
          onBlur={lastName.onBlur}
          type='text'
          placeholder={intl.formatMessage({
            id: 'my-health.form-placeholder.last-name',
            defaultMessage: 'Last name'
          })}
        />

        <RegularInput
          value={company.value}
          onChange={company.onChange}
          type='text'
          placeholder={intl.formatMessage({
            id: 'my-health.form-placeholder.company',
            defaultMessage: 'Company'
          })}
        />

        <InputWithIcon
          className='order-test-modal-content__input'
          type='address'
          value={address.value}
          error={address.error}
          placeholder={intl.formatMessage({
            id: 'my-health.form-placeholder.address',
            defaultMessage: 'Address'
          })}
          leftIcon={<LocationIcon />}
          onChange={address.onChange}
          onBlur={address.onBlur}
        />

        <RegularInput
          value={apartment.value}
          onChange={apartment.onChange}
          type='text'
          placeholder={intl.formatMessage({
            id: 'my-health.form-placeholder.apartment',
            defaultMessage: 'Apartment, suite, etc. (optional)'
          })}
        />

        <div className='order-test-modal-content__city-wrapper'>
          <InputNumber
            wrapperClassName='order-test-modal-content__zip-code '
            placeholder={intl.formatMessage({
              id: 'my-health.form-placeholder.zip',
              defaultMessage: 'ZIP code'
            })}
            value={zipCode.value}
            error={zipCode.error}
            onChange={zipCode.onChange}
            onBlur={zipCode.onBlur}
          />
          <RegularInput
            className='order-test-modal-content__city '
            type='text'
            placeholder={intl.formatMessage({
              id: 'my-health.form-placeholder.city',
              defaultMessage: 'City'
            })}
            value={city.value}
            error={city.error}
            onChange={city.onChange}
            onBlur={city.onBlur}
          />
        </div>
      </div>

      <Button
        type='submit'
        className='order-test-modal-content__proceed'
        disabled={orderLoading}
      >
        {orderLoading ? (
          <LoadingCircle />
        ) : (
          <FormattedMessage id='my-health.proceed' defaultMessage='Proceed' />
        )}
      </Button>

      <button
        onClick={onClose}
        className='order-test-modal-content__close-button'
      >
        <CloseIcon />
      </button>

      {formError && (
        <p className='order-test-modal-content__form-error'>{formError}</p>
      )}
    </form>
  );
};

OrderTestModalContent.propTypes = {
  handleProceed: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  orderData: PropTypes.bool,
  orderLoading: PropTypes.bool,
  orderError: PropTypes.string,
  dispatch: PropTypes.func,
  testData: PropTypes.object,
  benefitData: PropTypes.object
};

const mapStateToProps = state => ({
  orderData: state.dashboard.get('orderData'),
  orderError: state.dashboard.get('orderError'),
  orderLoading: state.dashboard.get('orderLoading')
});

export default connect(mapStateToProps)(OrderTestModalContent);
