import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Accordion from '../../../../components/Accordion';
import MyHealthEmployeeHealthProgramContent from '../MyHealthEmployeeHealthProgramContent';

const MyHealthEmployeeKlenicoAccordion = ({
  toggle,
  handleInfo,
  handleModal,
  isOpen,
  klenicoCoupon
}) => {
  const intl = useIntl();
  const kelnicoUrl = intl.formatMessage({
    id: 'my-health.klenico-url'
  });
  const firstCardDescription = intl.formatMessage({
    id: 'my-health.start-online-assessment-description'
  });
  const secondCardDescription = intl.formatMessage({
    id: 'my-health.klenico-copy-code-description'
  });

  const productCardsData = [
    {
      title: (
        <FormattedMessage
          id='my-health.start-online-assessment'
          defaultMessage='Starte deinen Online-Test'
        />
      ),
      price: null,
      description: firstCardDescription,
      cta: {
        url: kelnicoUrl,
        text: (
          <FormattedMessage
            id='my-health.start-health-check'
            defaultMessage='Start Mental Health Check'
          />
        ),
        type: 'link'
      },
      testType: '',
      iconUrl: null
    },
    {
      title: (
        <FormattedMessage
          id='my-health.klenico-copy-code'
          defaultMessage='Copy your voucher code'
        />
      ),
      price: null,
      description: secondCardDescription,
      cta: null,
      testType: '',
      iconUrl: null,
      copyableCode: klenicoCoupon?.code
    }
  ];
  return (
    <Accordion
      type={
        <FormattedMessage
          id='my-health.mental-health'
          defaultMessage='Mental health'
        />
      }
      title={
        <FormattedMessage
          id='my-health.klenico-health-check'
          defaultMessage='Klenico Mental Health Check'
        />
      }
      isFirstAccordionOpen={isOpen}
      onToggle={toggle}
      content={
        <MyHealthEmployeeHealthProgramContent
          description={
            <FormattedMessage
              id='my-health.klenico-health-check-description'
              defaultMessage='The Klenico Online Mental Health Check is a certified medical product to assess 20+ common mental health risks. Simply complete the 20-30 minute, clinically validated online questionnaire and receive an interactive evaluation report with descriptions and explanations of your symptoms.'
            />
          }
          productCards={productCardsData}
          handleInfo={handleInfo}
          handleModal={handleModal}
          footerCTA={{
            text: (
              <FormattedMessage
                id='my-health.online-mental-check-how-it-works'
                defaultMessage='Online Mental Health Check: How it works'
              />
            ),
            infoText: 'my-health.online-mental-health-check-info'
          }}
        />
      }
    />
  );
};

MyHealthEmployeeKlenicoAccordion.propTypes = {
  toggle: PropTypes.func,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  klenicoCoupon: PropTypes.object
};

export default MyHealthEmployeeKlenicoAccordion;
