import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import FormTitle from '../FormWrapper/FormTitle';
import FormButton from '../FormWrapper/FormButton';
import FormTextWithChildren from '../FormWrapper/FormTextWithChildren';
import { AUTH_ERROR_CUSTOM_MESSAGE } from '../../constants/errorCodes';

import Input from '../Input';

import lockIcon from '../../../assets/svg/lock-icon.svg';
import './index.scss';

const checkIfPasswordIsValid = password => {
  return /((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*\W)|(?=.*[A-Z])(?=.*\d)(?=.*\W)|(?=.*[A-Z])(?=.*[a-z])(?=.*\W)).{13,}/.test(
    password
  );
};

const CreatePasswordForm = ({
  password,
  setPassword,
  createAccount,
  email,
  isLoading,
  hookError,
  setUserState,
  intl
}) => {
  const [formError, setFormError] = useState(null);
  const passwordInputLabel = intl.formatMessage({
    id: 'auth.password-input-label'
  });
  const passwordInputPlaceholder = intl.formatMessage({
    id: 'auth.create-password-input-placehoder'
  });

  useEffect(() => {
    setFormError(hookError);
  }, [hookError]);

  const handleOnChange = value => {
    if (formError) {
      setFormError(null);
    }
    setPassword(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isPasswordValid = checkIfPasswordIsValid(password);

    if (isPasswordValid) {
      createAccount(email, password);
    } else {
      setFormError(AUTH_ERROR_CUSTOM_MESSAGE.CREATE_PASSWORD);
    }
  };

  const handleBackButtonClick = () => {
    setUserState(null);
    setPassword('');
    setFormError(null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle title='auth.create-password' defaultTitle='Create password' />
      <FormTextWithChildren>
        <FormattedMessage
          id='auth.create-password-explanation'
          defaultMessage='Choose a password with 13 or more characters. Include characters from at least 3 of the following categories:'
        />
        <ul>
          <li>
            <FormattedMessage
              id='auth.password-category-one'
              defaultMessage='Lower case (e.g. l, d, v)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-two'
              defaultMessage='Uppercase (e.g. L, D, V)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-three'
              defaultMessage='Special characters (for example: !, @, #, %, +, etc.)'
            />
          </li>
          <li>
            <FormattedMessage
              id='auth.password-category-four'
              defaultMessage='Numbers (e.g. 7, 4, 9)'
            />
          </li>
        </ul>
      </FormTextWithChildren>
      <Input
        type='password'
        name='new-password'
        autoComplete='new-password'
        label={passwordInputLabel}
        placeholder={passwordInputPlaceholder}
        value={password}
        onChange={e => handleOnChange(e.target.value)}
        icon={lockIcon}
        error={formError}
        errorTextAbsolute
        autoFocus
      />
      <div
        className={cx('create-password-form__buttons-wrapper', {
          'create-password-form__buttons-wrapper--error': formError
        })}
      >
        <FormButton
          className='create-password-form__back-button'
          text='auth.back'
          defaultText='Back'
          onClick={handleBackButtonClick}
          disabled={isLoading}
          backArrow
        />
        <FormButton
          text='auth.create-account'
          defaultText='Create account'
          submit
          isLoading={isLoading}
          disabled={!!formError || !password || password.length === 0}
        />
      </div>
    </form>
  );
};

CreatePasswordForm.propTypes = {
  password: PropTypes.string,
  setPassword: PropTypes.func,
  createAccount: PropTypes.func,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  hookError: PropTypes.string,
  setUserState: PropTypes.func,
  intl: PropTypes.any
};

export default CreatePasswordForm;
