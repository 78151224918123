import { Map } from 'immutable';

import {GET_USER_SUCCESS, GET_USER_ERROR, GET_USER_START} from '../actions/user';

const initialState = Map({
  user: null,
  error: null,
  loading: null,
});

const actionsMap = {
  [GET_USER_START]: (state) => {
    return state.merge(
      Map({
        error: null,
        loading: true,
      })
    );
  },
  [GET_USER_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        user: action.data,
        loading: false,
      })
    );
  },
  [GET_USER_ERROR]: (state, action) => {
    return state.merge(
        Map({
          error: action.error,
          loading: false,
        })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
