import { Map } from 'immutable';

import { SET_USER, REMOVE_USER, GET_USER } from '../actions/auth';

const initialState = Map({
  user: null,
  error: null,
  loading: true
});

const actionsMap = {
  [GET_USER]: (state, action) => {
    return state.merge(
      Map({
        loading: true
      })
    );
  },
  [SET_USER]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        user: action.payload
      })
    );
  },
  [REMOVE_USER]: state => {
    return state.merge(
      Map({
        loading: false,
        user: null
      })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
