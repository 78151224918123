import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const getScorePosition = score => {
  let position = 0;
  if (score > 1) {
    position = ((score - 1) / 5) * 100;
  }

  return position;
};

const Scorebar = ({ data, scores, primaryScore, secondaryScore, inModal }) => {
  const primaryScoreInfo = primaryScore ? primaryScore : scores.primary.values.find(score => score.key === data.key);
  const secondaryScoreInfo = secondaryScore
    ? secondaryScore
    : scores.secondary.values.find(score => score.key === data.key);

  const primaryScorePosition = getScorePosition(primaryScoreInfo.value);
  const secondaryScorePosition = getScorePosition(secondaryScoreInfo.value);
  const areScoresSame = primaryScorePosition === secondaryScorePosition;

  const scorebarClasses = cx({
    scorebar: true,
    'scorebar--in-modal': inModal
  });

  return (
    <div className={scorebarClasses}>
      {!areScoresSame ? (
        <>
          <div className='scorebar__score' style={{ left: `calc(${primaryScorePosition}% - 12px)` }}>
            <img src={primaryScoreInfo.iconUrl} className='scorebar__score-icon' alt='primary score icon' />
          </div>
          <div className='scorebar__score' style={{ left: `calc(${secondaryScorePosition}% - 12px)` }}>
            <img src={secondaryScoreInfo.iconUrl} className='scorebar__score-icon' alt='secondary score icon' />
          </div>
        </>
      ) : (
        <div className='scorebar__score' style={{ left: `calc(${primaryScorePosition}% - 12px)` }}>
          <img src={primaryScoreInfo.iconUrl} className='scorebar__score-icon' alt='primary score icon' />
        </div>
      )}
    </div>
  );
};

Scorebar.propTypes = {
  data: PropTypes.object,
  scores: PropTypes.object,
  primaryScore: PropTypes.object,
  secondaryScore: PropTypes.object,
  inModal: PropTypes.bool
};

export default Scorebar;
