import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GoodResultsIcon } from '../../assets/storybook-svg/good-results-icon.svg';
import { ReactComponent as BadResultsIcon } from '../../assets/storybook-svg/bad-results-icon.svg';
import { Caption, Heading3 } from '../Typography';
import cx from 'classnames';

import styles from './index.module.scss';

const resultIconMap: Record<
  string,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  positive: BadResultsIcon,
  detected: BadResultsIcon,
  deviating: BadResultsIcon,
  negative: GoodResultsIcon,
  nothingDetected: GoodResultsIcon,
  normal: GoodResultsIcon,
  probably: BadResultsIcon,
  risk: BadResultsIcon,
  deficiency: BadResultsIcon,
  surplus: BadResultsIcon,
  invalid: BadResultsIcon,
  severe: BadResultsIcon,
  borderline: BadResultsIcon,
  low: BadResultsIcon,
  optimum: GoodResultsIcon,
  high: BadResultsIcon,
  excessive: BadResultsIcon,
  toxic: BadResultsIcon,
  // HPV Check
  positiveHighRisk: BadResultsIcon,
  positiveLowRisk: BadResultsIcon,
  positivePotentiallyHighRisk: BadResultsIcon,

  // n360
  nothingDetectedWithFerritinSurplus: GoodResultsIcon,
  nothingDetectedWithFerritinInvalid: GoodResultsIcon,
  detectedWithFerritinSurplus: BadResultsIcon,
  detectedDOrB12Surplus: BadResultsIcon,
  detectedDOrB12SurplusWithFerritinSurplus: BadResultsIcon,
  retestFerritinInvalidInOneMonth: BadResultsIcon
};

export interface TestResultsHeaderProps {
  overallResult: string;
  title: string;
  resultsAvailableDate?: string;
  className: string;
}

const TestResultsHeader: React.FC<TestResultsHeaderProps> = ({
  overallResult,
  title,
  className,
  resultsAvailableDate
}) => {
  const ResultIcon = resultIconMap[overallResult] || GoodResultsIcon;
  return (
    <div className={cx({ [styles.wrapper]: true }, className)}>
      <ResultIcon />
      {title && <Heading3 className={styles.title}>{title}</Heading3>}
      {resultsAvailableDate && (
        <Caption element='p' className={styles.date}>
          <FormattedMessage
            id='test-result.available-from'
            defaultMessage='Available from'
          />
          {resultsAvailableDate}
        </Caption>
      )}
    </div>
  );
};

export default TestResultsHeader;
