import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-icon.svg';
import cx from 'classnames';
import styles from './index.module.scss';

export interface BottomDrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  className: string;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  isOpen = false,
  children,
  onClick,
  className
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen && !isModalOpen) {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalVisible(true);
      }, 50);
    }

    if (!isOpen && isModalOpen) {
      setIsModalVisible(false);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 200);
    }
  }, [isOpen, isModalOpen]);
  return (
    <div
      className={cx(
        {
          [styles.container]: true,
          [styles.open]: isModalOpen
        },
        className && { [className]: true }
      )}
    >
      <div
        className={cx({
          [styles.wrapper]: true,
          [styles.transform]: isModalVisible
        })}
      >
        {children}
        <CloseIcon className={styles.icon} onClick={onClick} />
      </div>
    </div>
  );
};

export default BottomDrawer;
