import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withWindow from '../../windowDecorators/withWindow';

import CheckIcon from '@material-ui/icons/Check';

import './index.scss';

const SelectIndicator = ({ isChecked, isSquare, breakpoint }) => {
  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  const indicatorClasses = cx({
    'select-indicator': true,
    isSquare: isSquare
  });

  return (
    <span className={indicatorClasses}>
      {isChecked && (
        <span className='select-indicator__inner'>
          {isSquare && <CheckIcon fontSize={isSmallOrMedium ? 'small' : 'large'} />}
        </span>
      )}
    </span>
  );
};

SelectIndicator.propTypes = {
  isChecked: PropTypes.bool,
  isSquare: PropTypes.bool,
  breakpoint: PropTypes.string
};

export default withWindow(SelectIndicator);
