import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';

import Button from '../../Button';
import Loader from '../../Loader';

import './index.scss';

const FormButton = ({
  text,
  defaultText = '',
  onClick,
  isLoading,
  disabled,
  className,
  backArrow,
  submit
}) => {
  return (
    <Button
      className={cx('form-button', className, {
        'form-button--back': backArrow
      })}
      orange={!backArrow}
      iconRight
      onClick={onClick}
      disabled={isLoading || disabled}
      type={submit ? 'submit' : 'button'}
    >
      {isLoading ? (
        <Loader small white />
      ) : (
        <span className='form-button__content'>
          {backArrow && <NavigateBefore fontSize='large' />}
          <FormattedMessage id={text} defaultMessage={defaultText} />
          {!backArrow && <NavigateNext fontSize='large' />}
        </span>
      )}
    </Button>
  );
};

FormButton.propTypes = {
  text: PropTypes.string,
  defaultText: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  backArrow: PropTypes.bool,
  submit: PropTypes.bool
};

export default FormButton;
