import React from 'react';
import PropTypes from 'prop-types';
import ParameterResultCard from '../../../../components/ParameterResultCard';
import Divider from '../../../../components/Divider';
import { Body2 } from '../../../../components/Typography';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const allergenReactionColors = {
  weak: '#7C9179',
  moderate: '#E78280',
  strong: '#C8585F',
  veryStrong: '#B3261E'
};

const AllergyDiagnosisBlock = ({ allergensData, handleInfo }) => {
  const { allergens, description } = allergensData;

  return (
    <div className='allergy-diagnosis'>
      <Divider
        title={
          <FormattedMessage
            id='test-result.confirmed-allergy-diagnosis'
            defaultMessage='Confirmed allergy diagnosis'
          />
        }
      />
      <Body2 className='allergy-diagnosis__text'>{description}</Body2>
      <div className='allergy-diagnosis__allergens'>
        {allergens.map(allergen => (
          <ParameterResultCard
            key={allergen.name}
            title={allergen.name}
            text={allergen.description}
            valueType='string'
            stringValue={
              <FormattedMessage
                defaultMessage={allergen.reaction}
                id={`test-result.reaction.${allergen.reaction}`}
              />
            }
            handleInfo={() => handleInfo(allergen.info)}
            info={allergen.info}
            resultBackgroundColor={allergenReactionColors[allergen.reaction]}
          />
        ))}
      </div>
    </div>
  );
};

AllergyDiagnosisBlock.propTypes = {
  allergensData: PropTypes.object,
  handleInfo: PropTypes.func
};

export default AllergyDiagnosisBlock;
