export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const getUser = () => ({
  type: GET_USER
});

export const setUser = userData => ({
  type: SET_USER,
  payload: userData
});

export const removeUser = () => ({
  type: REMOVE_USER
});
