import React, { useState, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EyeIcon } from '../../../assets/svg/eye-cropped.svg';

import './index.scss';

const Input = ({
  name,
  icon,
  type,
  placeholder,
  value = '',
  onChange,
  error,
  errorTextAbsolute,
  label,
  autoFocus = false,
  autoComplete
}) => {
  const inputRef = useRef(null);
  const [inputType, setInputType] = useState(type);
  const [inFocus, setInFocus] = useState(false);
  const isPassword = type === 'password';

  const handleEyeButtonClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
    inputRef.current.focus();
  };

  return (
    <div className='input'>
      <label htmlFor={name}>
        <span className='input__name'>{label}</span>
        <div
          className={cx('input__wrapper', { 'input__wrapper--focus': inFocus })}
        >
          {icon && (
            <div className='input__icon-wrapper'>
              <img src={icon} alt={`${name} icon`} />
            </div>
          )}
          <input
            id={name}
            ref={inputRef}
            type={inputType}
            className='input__input'
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onFocus={() => setInFocus(true)}
            onBlur={() => setInFocus(false)}
            autoFocus={autoFocus}
            name={name}
            autoComplete={autoComplete}
          />
          {isPassword && (
            <button
              className='input__show-password-button'
              type='button'
              onClick={handleEyeButtonClick}
            >
              <EyeIcon />
            </button>
          )}
        </div>
        {error && (
          <span
            className={cx('input__error', {
              'input__error--absolute': errorTextAbsolute
            })}
          >
            <FormattedMessage id={error} defaultMessage='Error' />
          </span>
        )}
      </label>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  // because of the design, sometimes error text has to be position: absolute
  errorTextAbsolute: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  autoComplete: PropTypes.string
};

export default Input;
