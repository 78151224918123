import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyHealthHeader from '../../../components/MyHealthHeader';
import NavbarBottom from '../NavbarBottom';
import CircleLoader from '../CircleLoader';

import { getDashboardData } from '../../actions/dashboard';
import { getUser } from '../../actions/user';
import { useAuthState } from '../../context/firebase';

import './index.scss';

const MyHealthContainer = ({
  children,
  dispatch,
  loading,
  userLoading,
  userData,
  data,
  lang,
  changeLanguage
}) => {
  const { user } = useAuthState();

  useEffect(() => {
    if (!userData) {
      dispatch(getUser());
      localStorage.removeItem('activeTab');
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (!data && userData && !userLoading) {
      dispatch(getDashboardData());
    }
  }, [data, dispatch, user, userData, userLoading]);

  return (
    <div className='my-health-container'>
      <MyHealthHeader
        availableLanguages={['en', 'de']}
        defaultLanguage={lang}
        onChangeLanguage={changeLanguage}
        dispatch={dispatch}
      />
      {(loading || userLoading) && (
        <div className='my-health-container__loader'>
          <CircleLoader />
        </div>
      )}
      {data && !loading && !userLoading ? children : null}
      <NavbarBottom />
    </div>
  );
};

MyHealthContainer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data'),
  error: state.dashboard.get('error'),
  loading: state.dashboard.get('loading'),
  userData: state.user.get('user'),
  userError: state.user.get('error'),
  userLoading: state.user.get('loading')
});

export default connect(mapStateToProps)(MyHealthContainer);
