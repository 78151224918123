import { all } from 'redux-saga/effects';

import dataSagas from './data';
import userSagas from './user';
import testResultSagas from './testResult';
import downloadReportSagas from './downloadReport';
import dashboardSagas from './dashboard';

export default function* rootSaga() {
  yield all([
    ...dataSagas,
    ...userSagas,
    ...testResultSagas,
    ...downloadReportSagas,
    ...dashboardSagas
  ]);
}
