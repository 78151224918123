import React from 'react';
import { Body1, Heading5 } from '../Typography';
import cx from 'classnames';

import styles from './index.module.scss';

interface InfoBlockProps {
  text: React.ReactNode;
  description: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  className?: string;
}

const InfoBlock: React.FC<InfoBlockProps> = ({
  text,
  description,
  backgroundColor,
  textColor,
  className
}) => {
  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true
        },
        className && { [className]: true }
      )}
      style={{ background: backgroundColor, color: textColor }}
    >
      <Heading5 className={styles.text}>{text}</Heading5>
      <Body1>{description}</Body1>
    </div>
  );
};

export default InfoBlock;
