import React from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../CircleLoader';
import AccordionsWrapper from '../AccordionsWrapper';
import Accordion from '../Accordion';

import './index.scss';

const TreatmentPlan = ({ data, userLoading, userData, language }) => {
  const { dataBlocks } = data;
  let openBlockIndex = dataBlocks.findIndex(block => !block.isChecked);
  openBlockIndex = openBlockIndex >= 0 ? openBlockIndex : 0;

  if (userLoading) {
    return (
      <div className='treatment-plan'>
        <div className='treatment-plan__content-wrapper'>
          <div className='treatment-plan__loader'><CircleLoader /></div>
        </div>
      </div>
    );
  }

  return (
    <div className='treatment-plan'>
      <div className='treatment-plan__content-wrapper'>
        <div className='treatment-plan__content'>
          <AccordionsWrapper>
            {dataBlocks.map((block, index) => {
              return (
                <Accordion 
                  key={index} 
                  data={block} 
                  hasProgressLine={true} 
                  isFirstOpen={index === openBlockIndex}
                  isFirst={index === 0} 
                  isLast={index === dataBlocks.length - 1}
                />
              );
            })}
          </AccordionsWrapper>
        </div>
      </div>
    </div>
  );
};

TreatmentPlan.propTypes = {
  data: PropTypes.object,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  language: PropTypes.string
};

export default TreatmentPlan;
