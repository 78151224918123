import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyHealthContainer from '../../components/MyHealthContainer';
import DashboardContent from '../../components/DashboardContent';

const MyHealthProducts = ({
  dispatch,
  data,
  error,
  userError,
  lang,
  changeLanguage
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MyHealthContainer
      dispatch={dispatch}
      lang={lang}
      changeLanguage={changeLanguage}
    >
      <div className='my-health-products'>
        <DashboardContent
          dispatch={dispatch}
          data={data}
          error={error || userError}
        />
      </div>
    </MyHealthContainer>
  );
};

MyHealthProducts.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  userError: PropTypes.object,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data'),
  error: state.dashboard.get('error'),
  userError: state.user.get('error')
});

export default connect(mapStateToProps)(MyHealthProducts);
