import React, { useEffect } from 'react'
import cx from 'classnames';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

import '../YearPicker/index.scss';

const HourPicker = ({chosenDay, chosenMonth, chosenYear, chosenHour, setChosenHour, isOpen, setIsOpen}) => {
  const isHourEnabled = chosenDay && chosenMonth.value && chosenYear;

  useEffect(() => {
    setIsOpen(false);
  }, [chosenHour, setIsOpen]);

  const getCurrentItems = () => {
    const currentItems = [];

    for(let i = 1; i <= 24; i++) {
      currentItems.push(i);
    }

    return currentItems.map(item => {
      const buttonSelectorClass = cx({
        'picker__button-selector': true,
        'picker__button-selector--selected': chosenHour === item,
      });

      return (
        <button 
          className='picker__button' 
          key={item}
          onClick={() => setChosenHour(item)}
        >
           <span className={buttonSelectorClass}>
            {item}
          </span>
        </button>
    )});
  }

  const pickerClasses = cx({
    'picker': true,
    'picker--disabled': !isHourEnabled,
  });

  const pickerTextClasses = cx({
    'picker__text': true,
    'picker__text--black': chosenHour,
  });

  const arrowDownClasses = cx({
    'picker__arrow-down': true,
    'picker__arrow-down--disabled': !isHourEnabled,
  });

  return (
    <div className={pickerClasses} onClick={isHourEnabled ? () => setIsOpen(true) : null}>
      <span className={pickerTextClasses}>
        { chosenHour ? `${chosenHour}h` : <FormattedMessage id='date-picker.enter-hour' defaultMessage='Enter Hour' />}
      </span>
      <div className={arrowDownClasses} />
      {isOpen && 
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div className='picker__list'>
            <div className='picker__month-list-header'>
              <span className='picker__current-values'>
                {`${chosenMonth.name} ${chosenDay}, ${chosenYear}`}
              </span>
            </div>
            <div className='picker__list-items'>{getCurrentItems()}</div>
         </div> 
        </OutsideClickHandler>
      }
    </div>
  );
}

HourPicker.propTypes = {
  chosenYear: PropTypes.number,
  chosenMonth: PropTypes.object,
  chosenDay: PropTypes.number,
  chosenHour: PropTypes.number,
  setChosenHour: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default HourPicker
