import React from 'react';
import PropTypes from 'prop-types';
import OrderProductCard from '../../../../components/OrderProductCard';

import './index.scss';

const RecommendedActionsProductCards = ({ productCards }) => {
  return (
    <div className='recommended-actions-product-cards'>
      {productCards.map(
        card => card && <OrderProductCard key={card.title} productCard={card} />
      )}
    </div>
  );
};

RecommendedActionsProductCards.propTypes = {
  productCards: PropTypes.array
};

export default RecommendedActionsProductCards;
