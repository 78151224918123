import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import { ReactComponent as Dropdown } from '../../../../assets/svg/arrow-dropdown.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/svg/chevron-right.svg';

import './index.scss';

const Product = ({ data, onCheckboxChange, isOpenByDefault, isAddon }) => {
  const {
    priceCurrency,
    price,
    titleText,
    imageUrl,
    isSoldOut,
    descriptionText,
    buttonText,
    buttonLink,
    isRecommended,
    id,
    info
  } = data;

  const [isOpen, setIsOpen] = React.useState(isOpenByDefault);
  const [isChecked, setIsChecked] = React.useState(isRecommended);

  const toggleContentClasses = cx({
    'product__toogle-content': true,
    'product__toogle-content--open': isOpen
  });

  const toggleButtonClasses = cx({
    'product__toogle-button': true,
    'product__toogle-button--open': isOpen
  });

  const titlePriceDescriptionWrapperClasses = cx({
    'product__title-price-description-wrapper': true,
    'product__title-price-description-wrapper--addon': isAddon
  });

  const titlePriceWrapper = cx({
    'product__title-and-price-wrapper': true,
    'product__title-and-price-wrapper--addon': isAddon
  });

  const priceClassesWrapper = cx({
    'product__price-wrapper': true,
    'product__price-wrapper--sold-out': isSoldOut || info
  });

  const productImageClasses = cx({
    product__image: true,
    'product__image--addon': isAddon
  });

  const handleCheckboxChange = () => {
    onCheckboxChange(id);
    setIsChecked(!isChecked);
  };

  return (
    <div className='product'>
      <div className='product__content'>
        <div className='product__checkbox-and-image-wrapper'>
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            isOrange
            disabled={isSoldOut || info}
            className='product__checkbox'
          />
          <div className={productImageClasses}>
            <img src={imageUrl} alt={titleText} />
          </div>
        </div>

        <div className={titlePriceDescriptionWrapperClasses}>
          <div className={titlePriceWrapper}>
            {isSoldOut && (
              <span className='product__sold-out'>
                <FormattedMessage
                  id='product.sold-out'
                  defaultMessage='Sold out'
                />
              </span>
            )}
            {info && <span className='product__sold-out'>{info}</span>}
            <span className='product__title'>{titleText}</span>
            <div className={priceClassesWrapper}>
              <span className='product__price-currency'>{priceCurrency}</span>
              <span className='product__price'>{price}</span>
            </div>
          </div>
          <div className='product__description'>{descriptionText}</div>
        </div>

        <Button href={buttonLink} className='product__learn-more' purple>
          {buttonText}
          <ChevronRight />
        </Button>

        <button
          className={toggleButtonClasses}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Dropdown />
        </button>
      </div>
      <div className={toggleContentClasses}>
        <span className='product__description'>{descriptionText}</span>
        <Button href={buttonLink} className='product__learn-more' purple>
          {buttonText}
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

Product.propTypes = {
  data: PropTypes.object,
  onCheckboxChange: PropTypes.func,
  isOpenByDefault: PropTypes.bool,
  isAddon: PropTypes.bool
};

export default Product;
