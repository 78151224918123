import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareLinkButton from '../../../components/ShareLinkButton';
import { ReactComponent as BellIcon } from '../../../assets/svg/bell-icon.svg';
import { ReactComponent as DiscountIcon } from '../../../assets/svg/discount.svg';
import { FormattedMessage } from 'react-intl';

const iconMap = {
  share: DiscountIcon,
  scheduleReminder: BellIcon,
  link: BellIcon
};

const TestResultsShareLink = ({ text, url, iconType }) => {
  const [popUpContent, setPopUpContent] = useState(null);

  const Icon = iconMap[iconType];

  const handleCopyLink = () => {
    //if it's touch-enabled device
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      navigator
        .share({
          url: url
        })
        .then(() => {
          setPopUpContent({
            text: (
              <FormattedMessage
                id='test-result.link-copied'
                defaultMessage='Link copied'
              />
            ),
            type: 'success'
          });
          setTimeout(() => setPopUpContent(null), 2000);
        })
        .catch(error => {
          if (error.name !== 'AbortError') {
            setPopUpContent({
              text: (
                <FormattedMessage
                  id='test-result.error-occurred'
                  defaultMessage='Error occurred'
                />
              ),
              type: 'error'
            });
            setTimeout(() => setPopUpContent(null), 3000);
          }
        });
    } else {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setPopUpContent({
            text: (
              <FormattedMessage
                id='test-result.link-copied'
                defaultMessage='Link copied'
              />
            ),
            type: 'success'
          });
          setTimeout(() => setPopUpContent(null), 2000);
        })
        .catch(error => {
          setPopUpContent({
            text: (
              <FormattedMessage
                id='test-result.error-occurred'
                defaultMessage='Error occurred'
              />
            ),
            type: 'error'
          });
          setTimeout(() => setPopUpContent(null), 3000);
        });
    }
  };

  return (
    <ShareLinkButton
      onClick={handleCopyLink}
      icon={<Icon />}
      text={text}
      popUpContent={popUpContent}
    />
  );
};

TestResultsShareLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  iconType: PropTypes.string
};

export default TestResultsShareLink;
