export const UPDATE_DASHBOARD_DATA_START = 'UPDATE_DASHBOARD_DATA_START';
export const UPDATE_DASHBOARD_DATA_SUCCESS = 'UPDATE_DASHBOARD_DATA_SUCCESS';
export const UPDATE_DASHBOARD_DATA_ERROR = 'UPDATE_DASHBOARD_DATA_ERROR';
export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const CREATE_TEST_ORDER_START = 'CREATE_TEST_ORDER_START';
export const CREATE_TEST_ORDER_SUCCESS = 'CREATE_TEST_ORDER_SUCCESS';
export const CREATE_TEST_ORDER_ERROR = 'CREATE_TEST_ORDER_ERROR';
export const RESET_TEST_ORDER_DATA = 'RESET_TEST_ORDER_DATA';

export const updateDashboardData = language => ({
  type: UPDATE_DASHBOARD_DATA_START,
  payload: {
    language
  }
});

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA_START
});

export const createTestOrder = orderData => ({
  type: CREATE_TEST_ORDER_START,
  payload: {
    orderData
  }
});

export const resetTestOrderData = () => ({
  type: RESET_TEST_ORDER_DATA
});
