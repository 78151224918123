import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const CircleLoader = ({ small, white, xsmall, className }) => {
  const classes = cx({
    'circle-loader': true,
    'circle-loader--small': small,
    'circle-loader--white': white,
    'circle-loader--xsmall': xsmall,
    [className]: className
  });

  return (
    <div className={classes}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

CircleLoader.propTypes = {
  small: PropTypes.bool,
  white: PropTypes.bool,
  xsmall: PropTypes.bool,
  className: PropTypes.string
};

export default CircleLoader;
