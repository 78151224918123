import React from 'react';
import PropTypes from 'prop-types';

import FormTitle from '../FormWrapper/FormTitle';
import FormButton from '../FormWrapper/FormButton';
import FormText from '../FormWrapper/FormText';

import './index.scss';

const CheckInboxContent = ({ email, setUserState }) => {
  const handleOnSubmit = e => {
    e.preventDefault();
    setUserState(null);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <FormTitle
        title='auth.check-your-inbox'
        defaultTitle='Check your inbox'
      />
      <FormText
        text='auth.we-sent-instructions'
        defaultText='If we have {email} in our database, you will receive email with instructions and a reset password link. It might take a few minutes to arrive.'
        values={{ email: email }}
      />
      <FormButton
        className='check-inbox-content__button'
        text='auth.back-to-log-in'
        defaultText='Back to Log in'
        submit
      />
    </form>
  );
};

CheckInboxContent.propTypes = {
  email: PropTypes.string,
  setUserState: PropTypes.func
};

export default CheckInboxContent;
