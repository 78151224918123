import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormTitle from '../FormWrapper/FormTitle';
import FormButton from '../FormWrapper/FormButton';
import FormText from '../FormWrapper/FormText';
import Input from '../Input';

import { USER_STATE } from '../../constants/userState';

import emailIcon from '../../../assets/svg/simple-email-icon.svg';

import './index.scss';

const checkIfEmailIsValid = email => {
  return /\S+@\S+\.\S+/.test(email);
};

const ForgotPasswordForm = ({
  email,
  setEmail,
  isLoading,
  sendResetPasswordEmail,
  setUserState,
  hookError,
  setHookError,
  intl
}) => {
  useEffect(() => {
    if (hookError) {
      setHookError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState(false);
  const emailInputLabel = intl.formatMessage({ id: 'auth.email-input-label' });

  const handleEmailChange = value => {
    if (error) {
      setError(false);
    }
    setEmail(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isEmailValid = checkIfEmailIsValid(email);

    if (isEmailValid) {
      sendResetPasswordEmail(email);
    } else {
      setError(true);
    }
  };

  const handleBackButtonClick = () => {
    setUserState(USER_STATE.EXISTING_USER);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle
        title='auth.reset-password'
        defaultTitle='Reset your HealthYourself account password'
      />
      <FormText
        text='auth.reset-password-explanation'
        defaultText='Enter your accounts email and we will send 
          you email with instruction how to reset your 
          password.'
      />
      <Input
        type='email'
        placeholder='name@mail.com'
        value={email}
        onChange={e => handleEmailChange(e.target.value)}
        icon={emailIcon}
        name='email'
        label={emailInputLabel}
        error={error ? 'auth.invalid-email' : null}
        autoFocus
      />
      <div className='forgot-password-form__buttons-wrapper'>
        <FormButton
          className='forgot-password-form__back-button'
          text='auth.back'
          defaultText='Back'
          onClick={handleBackButtonClick}
          disabled={isLoading}
          backArrow
        />
        <FormButton
          text='auth.reset'
          defaultTitle='Reset'
          isLoading={isLoading}
          submit
        />
        {hookError && (
          <div className='forgot-password-form__error'>{hookError}</div>
        )}
      </div>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  isLoading: PropTypes.bool,
  sendResetPasswordEmail: PropTypes.func,
  setUserState: PropTypes.func,
  hookError: PropTypes.string,
  setHookError: PropTypes.func,
  intl: PropTypes.any
};

export default ForgotPasswordForm;
