import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withWindow from '../../windowDecorators/withWindow';

import Slider from '../Slider';

import './index.scss';

const SliderScreen = ({ data, breakpoint }) => {
  const { slides } = data.info;
  const isSmall = ['xsmall', 'small'].includes(breakpoint);
  useEffect(() => {
    if (isSmall) {
      setTimeout(() => {
        window.scrollTo({
          top: 100,
          behavior: 'smooth'
        });
      }, 800);
    }
  }, [isSmall]);

  return (
    <div className='slider-screen'>
      <div className='slider-screen__content-wrapper'>
        <div className='slider-screen__content'>
          <Slider slides={slides} />
        </div>
      </div>
    </div>
  );
};

SliderScreen.propTypes = {
  data: PropTypes.object,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  language: PropTypes.string,
  conversationId: PropTypes.string,
  conversationFlowStateKey: PropTypes.string,
  dispatch: PropTypes.func,
  setAnimationDirection: PropTypes.func,
  breakpoint: PropTypes.string
};

export default withWindow(SliderScreen);
