import { getAuth, onAuthStateChanged } from 'firebase/auth';
import IdleTimer from '../helpers/IdleTimer';
import { initializeApp } from 'firebase/app';
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef
} from 'react';

import api from '../api';

import { signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const INACTIVITY_TRASHOLD = 60 * 60; // One hour (60min x 60sec);

export const firebaseApp = initializeApp(firebaseConfig);

export const AuthContext = createContext();

export const AuthContextProvider = props => {
  const auth = getAuth();
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const signoutTimerRef = useRef();

  useEffect(() => {
    signoutTimerRef.current = new IdleTimer({
      timeout: INACTIVITY_TRASHOLD,
      onTimeout: userSingOut,
      onExpired: userSingOut
    });

    const unsubscribe = onAuthStateChanged(auth, setUserData, setErrorData);
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSingOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userId');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiredTime');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      const slackMessage = `Error happened in UserSignOut function in firebase context | ${errorCode} | ${errorMessage}`;
      api.sendSlackAuthError(slackMessage);
    }
  };

  const setUserData = async data => {
    if (data) {
      const expiredTime = parseInt(
        localStorage.getItem('expiredTime') || 0,
        10
      );

      if (expiredTime > 0 && expiredTime < Date.now()) {
        await userSingOut();
        signoutTimerRef.current.cleanUp();
      } else {
        signoutTimerRef.current.start();
        localStorage.setItem('userId', data.uid);
        localStorage.setItem('accessToken', data.accessToken);
        setUser(data);
      }
    } else {
      signoutTimerRef.current.cleanUp();
    }

    setIsLoading(false);
  };

  const setErrorData = error => {
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return <AuthContext.Provider value={{ user, error, isLoading }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null };
};
