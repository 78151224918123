import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Divider from '../../../components/Divider';
import TestCard from '../../../components/TestCard';
import { ButtonText } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';

import './index.scss';

const MyHealthYourProducts = ({ tests, handleOpenBottomModal }) => {
  const sortedTestsByResultsViewedAndLastUpdate = tests.sort((a, b) => {
    const aResultsViewed = a.resultsViewed === null ? true : a.resultsViewed;
    const bResultsViewed = b.resultsViewed === null ? true : b.resultsViewed;
    if (aResultsViewed === bResultsViewed) {
      return new Date(b.lastUpdate) - new Date(a.lastUpdate);
    }
    return aResultsViewed ? 1 : -1;
  });

  return (
    <>
      <Divider
        title={
          <FormattedMessage
            id='my-health.your-products'
            defaultMessage='Your Products'
          />
        }
      />
      {sortedTestsByResultsViewedAndLastUpdate && (
        <div className='my-health-your-products__products'>
          {sortedTestsByResultsViewedAndLastUpdate
            .slice(0, 3)
            .map((test, index) => (
              <TestCard
                key={`t-${index}`}
                iconUrl={test.iconUrl}
                type={test.type}
                status={test.status}
                activationFlowUrl={test.activationFlowUrl}
                code={test.code}
                lastUpdate={test.lastUpdate}
                personName={test.personName}
                reorderUrl={test.reorderUrl}
                reorderDiscount={test.reorderDiscount}
                testResultsUrl={test.testResultsUrl}
                samplingInstructionsUrl={test.samplingInstructionsUrl}
                updateSamplingTimeUrl={test.updateSamplingTimeUrl}
                handleMoreDetails={() => handleOpenBottomModal(test)}
                resultsViewed={test.resultsViewed}
              />
            ))}
        </div>
      )}
      <Link
        to='/myhealth/products'
        className='my-health-your-products__view-all'
      >
        <ButtonText>
          <FormattedMessage id='my-health.view-all' defaultMessage='View all' />
        </ButtonText>
      </Link>
    </>
  );
};

MyHealthYourProducts.propTypes = {
  tests: PropTypes.array,
  handleOpenBottomModal: PropTypes.func
};

export default MyHealthYourProducts;
