import { Map } from 'immutable';

import {
  DOWNLOAD_REPORT_START,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  DOWNLOAD_REPORT_CLEAR
} from '../actions/downloadReport';

const initialState = Map({
  data: null,
  error: null,
  loading: null,
});

const actionsMap = {
  [DOWNLOAD_REPORT_START]: state => {
    return state.merge(
      Map({
        error: null,
        loading: true
      })
    );
  },
  [DOWNLOAD_REPORT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        data: action.data,
        loading: false
      })
    );
  },
  [DOWNLOAD_REPORT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        error: action.error,
        loading: false
      })
    );
  },
  [DOWNLOAD_REPORT_CLEAR]: state => {
    return state.merge(
      Map({
        error: null,
        loading: false,
        data: null
      })
    );
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
