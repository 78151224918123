import React, { useRef, useState, useEffect } from 'react';
import { authHeader } from '../../utils/helpers';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as DocumentIcon } from '../../../assets/svg/google-docs.svg';
import CircleLoaderSmall from '../CircleLoaderSmall';

import markdownFormater from '../../utils/markdownFormater';
import ResultsBlock from './ResultsBlock';
import DoctorsReport from '../DoctorsReport';
import CircleLoader from '../CircleLoader';
import MedgateBlock from '../MedgateBlock';
import PharmacyReport from '../PharmacyReport';
import StauffacherBlock from '../StauffacherBlock';

import './index.scss';

const ERROR_TIMEOUT = 5000;

const TestResults = ({ data, userLoading, userData, language }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorTimeout, setErrorTimeout] = useState(null);
  const contentElement = useRef(null);
  const {
    image,
    text,
    titleText,
    includedTests,
    labReportUrl,
    labReportUrlText,
    labReportHeadline,
    doctorsReport,
    doctorsName,
    medgateData,
    cssData,
    areResultsGrouped,
    pharmacyReport,
    pharmacyName,
    stauffacherData
  } = data.info;

  useEffect(() => {
    return () => {
      clearTimeout(errorTimeout);
      setErrorTimeout(null);
    };
  }, [errorTimeout]);

  const labReportClickHandle = async () => {
    try {
      setError(null);
      setErrorTimeout(null);
      setLoading(true);
      const value = await fetch(
        `${process.env.REACT_APP_API_URL}/user/lab-report/${labReportUrl}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...authHeader()
          }
        }
      );

      const blob = await value.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', labReportUrl);

      document.body.appendChild(link);

      link.click();
      setLoading(false);

      link.parentNode.removeChild(link);
    } catch (error) {
      setError(error);
      setErrorTimeout(error);
      setLoading(false);
      const timeout = setTimeout(() => {
        setError(null);
      }, ERROR_TIMEOUT);

      setErrorTimeout(timeout);
    }
  };

  const textWrapperClasses = cx({
    'test-result__text-wrapper': true,
    'test-result__text-wrapper--no-image': !image
  });

  if (userLoading) {
    return (
      <div className='test-result'>
        <div ref={contentElement} className='test-result__content-wrapper'>
          <div className='test-result__loader'>
            <CircleLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='test-result'>
      <div ref={contentElement} className='test-result__content-wrapper'>
        <div className='test-result__content'>
          <span className='test-result__title-small'>{titleText}</span>
          <div className='test-result__image-wrapper'>
            <img className='test-result__image' src={image} alt='result' />
          </div>
          <div className='test-result__right-content'>
            <span className='test-result__title-large'>{titleText}</span>
            <div className={textWrapperClasses}>{markdownFormater(text)}</div>
            {doctorsReport && userData && (
              <DoctorsReport
                data={doctorsReport}
                doctorsName={doctorsName}
                userData={userData}
                language={language}
              />
            )}
            {pharmacyReport && userData && (
              <PharmacyReport
                pharmacyReport={pharmacyReport}
                pharmacyName={pharmacyName}
                userData={userData}
                language={language}
              />
            )}
            {includedTests && includedTests.length > 0 && (
              <ResultsBlock
                areResultsGrouped={areResultsGrouped}
                results={includedTests}
              />
            )}
            {labReportUrl && (
              <div className='test-result__download-wrapper'>
                <span className='test-result__download-title'>
                  {labReportHeadline}
                </span>
                <div className='test-result__download-content'>
                  <button
                    onClick={labReportClickHandle}
                    className='test-result__download-link'
                  >
                    <DocumentIcon className='test-results__document-icon' />{' '}
                    {labReportUrlText}
                  </button>
                  {loading && <CircleLoaderSmall />}
                </div>
                {error && (
                  <span className='test-result__error'>
                    <FormattedMessage
                      id='error.something-went-wrong'
                      defaultMessage='Something went wrong. Please try again.'
                    />
                  </span>
                )}
              </div>
            )}
            {(medgateData || cssData) && (
              <MedgateBlock
                className={!labReportUrl ? 'test-result__medgate' : ''}
                data={medgateData || cssData}
              />
            )}
            {stauffacherData && (
              <StauffacherBlock
                title={stauffacherData.title}
                text={stauffacherData.text}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TestResults.propTypes = {
  data: PropTypes.object,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  language: PropTypes.string,
  pharmacyReport: PropTypes.string,
  pharmacyName: PropTypes.string
};

export default TestResults;
