import React, { useState } from 'react';
import { Body1, Subtitle2 } from '../Typography';

import styles from './index.module.scss';

export interface TitleWithCheckboxProps {
  title: string;
  checked: boolean;
  readonly?: boolean;
  checkboxList: string[];
}

const TitleWithCheckbox: React.FC<TitleWithCheckboxProps> = ({
  title,
  readonly,
  checked,
  checkboxList
}) => {
  const [checkedState, setCheckedState] = useState<boolean[]>(() =>
    checkboxList.map(() => false)
  );

  const handleCheckboxChange = (index: number) => {
    if (!readonly) {
      const newCheckedState = [...checkedState];
      newCheckedState[index] = !newCheckedState[index];
      setCheckedState(newCheckedState);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Subtitle2 className={styles.title}>{title}</Subtitle2>
      <div className={styles.checkboxWrapper}>
        {checkboxList.map((checkbox, index) => (
          <div key={checkbox} className={styles.checkboxContent}>
            <input
              className={styles.checkbox}
              type='checkbox'
              checked={readonly ? checked : checkedState[index]}
              onChange={() => handleCheckboxChange(index)}
              readOnly={readonly}
              id={checkbox}
            />
            <label htmlFor={checkbox}>
              <Body1>{checkbox}</Body1>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TitleWithCheckbox;
