import React from 'react';
import { Heading6 } from '../../Typography';
import Link from '../../Link';
import styles from './index.module.scss';
import { FormattedMessage } from 'react-intl';

const SWITCHBOARD_URL = '/?conversationFlowKey=switchboard';
const YOURSELF_HEALTH_URL = 'https://yourself.health/';

const EmptyCard: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Heading6 className={styles.title}>
        <FormattedMessage
          id='app.activate-new-test'
          defaultMessage='Activate new test'
        />
      </Heading6>
      <Link className={styles.addTest} url={SWITCHBOARD_URL}>
        <FormattedMessage
          id='app.activate-test'
          defaultMessage='Activate test'
        />
      </Link>
      <Link variant='text' url={YOURSELF_HEALTH_URL}>
        <FormattedMessage
          id='app.buy-test-online'
          defaultMessage={'Buy test online'}
        />
      </Link>
    </div>
  );
};

export default EmptyCard;
