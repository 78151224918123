import React from 'react';
import PropTypes from 'prop-types';
import RecommendedActionsCheckbox from '../RecommendedActionsCheckbox';
import RecommendedActionsAccordion from '../../../../components/RecommendedActionsAccordion';
import RecommendedActionsContent from '../RecommendedActionsContent';

import './index.scss';

const RecommendedAction = ({
  content,
  done,
  CTAs,
  footerCTA,
  productCards,
  labReport,
  contentCards,
  isLastAccordion,
  handleInfo,
  timingKey,
  contentKey,
  language
}) => {
  return (
    <div className='recommended-action__accordion'>
      <div className='recommended-action__accordion-wrapper'>
        <RecommendedActionsCheckbox done={done} />
        <RecommendedActionsAccordion
          contentKey={contentKey}
          type={content.type}
          title={content.title}
          done={done}
          content={
            <RecommendedActionsContent
              content={content}
              CTAs={CTAs}
              done={done}
              footerCTA={footerCTA}
              productCards={productCards}
              labReport={labReport}
              contentCards={contentCards}
              handleInfo={handleInfo}
              timingKey={timingKey}
              contentKey={contentKey}
              language={language}
            />
          }
        />
      </div>
      {!isLastAccordion && (
        <div className='recommended-action__accordion-line'></div>
      )}
    </div>
  );
};

RecommendedAction.propTypes = {
  content: PropTypes.object,
  done: PropTypes.bool,
  CTAs: PropTypes.array,
  footerCTA: PropTypes.object,
  productCards: PropTypes.array,
  contentCards: PropTypes.array,
  labReport: PropTypes.string,
  isLastAccordion: PropTypes.bool,
  handleInfo: PropTypes.func,
  timingKey: PropTypes.string,
  contentKey: PropTypes.string,
  language: PropTypes.string
};

export default RecommendedAction;
