import React from 'react';
import PropTypes from 'prop-types';
import Question from '../Question';
import TextQuestion from '../TextQuestion';
import DatePicker from '../DatePicker';
import UploadVideo from '../UploadVideo';
import Dropdown from '../Dropdown';
import CheckboxesQuestion from '../CheckboxesQuestion';

const SWITCHBOARD_KEY = 'testCode';
const TRANSFER_CODE = 'transferCode';
const PASS_NUMBER = 'passportNumber';
const PARTNER_CODE = 'verificationCode';
const ZIP_CODE = 'ZIP';
const BENEFIT_CODE = 'benefitCode';

const QuestionMapper = ({
  data,
  isModalOpen,
  setIsModalOpen,
  setModalData,
  setCurrentScreenAnswer,
  currentScreenAnswer,
  isMainSlide,
  userData,
  userLoading,
  dispatch,
  switchboardTestCode
}) => {
  if (data.question.kind === 'text') {
    return (
      <TextQuestion
        autocapitalize={
          data.question.key === SWITCHBOARD_KEY ||
          data.question.key === TRANSFER_CODE ||
          data.question.key === PASS_NUMBER ||
          data.question.key === PARTNER_CODE ||
          data.question.key === ZIP_CODE ||
          data.question.key === BENEFIT_CODE
        }
        data={data}
        multiline={data.question.multiline}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
        currentScreenAnswer={currentScreenAnswer}
        switchboardTestCode={switchboardTestCode}
        isSwitchboardScreen={data.question.key === SWITCHBOARD_KEY}
      />
    );
  }

  if (data.question.kind === 'datepicker') {
    return (
      <DatePicker
        answerValue={data.question.answerValue}
        data={data.question.datepicker}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
      />
    );
  }

  if (data.question.kind === 'dropdown') {
    return (
      <Dropdown
        answerValue={
          data.question.answerValue ? data.question.answerValue.value : null
        }
        setCurrentScreenAnswer={setCurrentScreenAnswer}
      />
    );
  }

  if (data.question.kind === 'upload') {
    return (
      <UploadVideo
        userData={userData}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
        userLoading={userLoading}
        data={data}
        dispatch={dispatch}
      />
    );
  }

  if (data.question.kind === 'multiCheckboxSelect') {
    return (
      <CheckboxesQuestion
        data={data.question}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
      />
    );
  }

  if (data.question.kind === 'radioButton') {
    return (
      <CheckboxesQuestion
        isRadioButton
        data={data.question}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
      />
    );
  }

  return (
    <Question
      data={data}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      setModalData={setModalData}
      setCurrentScreenAnswer={setCurrentScreenAnswer}
      isMainSlide={isMainSlide}
    />
  );
};

QuestionMapper.propTypes = {
  data: PropTypes.object,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setModalData: PropTypes.func,
  setCurrentScreenAnswer: PropTypes.func,
  currentScreenAnswer: PropTypes.any,
  isMainSlide: PropTypes.bool,
  userData: PropTypes.object,
  userLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  switchboardTestCode: PropTypes.string
};

export default QuestionMapper;
