import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CSS } from '../../../assets/svg/css-logo.svg';

const cardsWithEmployeeBenefits = [
  {
    eyebrow: (
      <FormattedMessage
        id='my-health.information'
        defaultMessage='Information'
      />
    ),
    icon: null,
    title: (
      <FormattedMessage
        id='my-health.learn-about-health-benefits'
        defaultMessage='Learn about your employee health benefits and how to access them.'
      />
    ),
    background: '#322c36',
    href: '/myhealth/employee-health',
    type: 'inner-link',
  },
  {
    eyebrow: (
      <FormattedMessage
        id='my-health.partner-offer'
        defaultMessage='Partner offer'
      />
    ),
    icon: <CSS />,
    title: (
      <FormattedMessage
        id='my-health.attention-css-costumers'
        defaultMessage='Attention CSS costumers!'
      />
    ),
    description: (
      <FormattedMessage
        id='my-health.order-allergy-hayfever-test'
        defaultMessage='With a supplementary health insuruance, you can now order a Basic Allergy & Hayfever test with 40% discount through the enjoy365 webshop.'
      />
    ),
    background: '#C8585F',
    href: 'https://enjoy365.ch/',
    type: 'outer-link'
  }
];

const cardsWithoutEmployeeBenefits = [
  {
    eyebrow: (
      <FormattedMessage
        id='my-health.partner-offer'
        defaultMessage='Partner offer'
      />
    ),
    icon: <CSS />,
    title: (
      <FormattedMessage
        id='my-health.attention-css-costumers'
        defaultMessage='Attention CSS costumers!'
      />
    ),
    description: (
      <FormattedMessage
        id='my-health.order-allergy-hayfever-test'
        defaultMessage='With a supplementary health insuruance, you can now order a Basic Allergy & Hayfever test with 40% discount through the enjoy365 webshop.'
      />
    ),
    background: '#C8585F',
    href: 'https://enjoy365.ch/',
    type: 'outer-link'
  }
];

export { cardsWithEmployeeBenefits, cardsWithoutEmployeeBenefits };
