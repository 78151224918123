import React from 'react';
import { Body2, Caption, Heading4 } from '../Typography';
import { ReactComponent as ScanIcon } from '../../assets/storybook-placeholders/svg/scan-icon.svg';
import { ReactComponent as UpdateIcon } from '../../assets/storybook-placeholders/svg/update-icon.svg';
import { ReactComponent as KnowledgeIcon } from '../../assets/storybook-placeholders/svg/knowledge-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/storybook-placeholders/svg/plus-icon.svg';
import { ReactComponent as BagIcon } from '../../assets/storybook-placeholders/svg/bag.svg';
import { FormattedMessage } from 'react-intl';
import { testTypeNames } from '../TestCard/testTypeNames';
import BottomDrawerText from '../BottomDrawerText';
import BottomDrawerLink from '../BottomDrawerLink';

import styles from './index.module.scss';

export interface BottomDrawerContentProps {
  status: string;
  type: string;
  statusColor: string;
  testTitle: string;
  prescriptionTitle: string;
  text: string;
  testCodeText: string;
  lastUpdateText: string;
  samplingInstructionsUrl: string;
  activateTest: string;
  buyNewTest: string;
  dashboardTitleModal?: boolean;
}

// TODO: Do check with Djordje related to what will be shown when
const BottomDrawerContent: React.FC<BottomDrawerContentProps> = ({
  status,
  type,
  statusColor,
  testTitle,
  prescriptionTitle,
  text,
  testCodeText,
  lastUpdateText,
  samplingInstructionsUrl,
  activateTest,
  buyNewTest,
  dashboardTitleModal
}) => {
  // Just to be clear, this is Mark's masterpiece
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    };
    return date
      .toLocaleString('en-150', options)
      .replace(/\//g, '.') // Replace all occurrences of '/' with '-'
      .replace(',', ''); // Replace the first occurrence of ',' with an empty string
  };

  const isTestType = type in testTypeNames;
  return (
    <div>
      <div style={{ color: statusColor }}>
        <Caption className={styles.status} element='p'>
          {isTestType && (
            <FormattedMessage
              id={`dashboard.test.status.${status}`}
              defaultMessage={status}
            />
          )}
          {prescriptionTitle && (
            <FormattedMessage
              id={`dashboard.prescription.status.${status}`}
              defaultMessage={status}
            />
          )}
        </Caption>
      </div>

      <Heading4 className={styles.title}>
        {prescriptionTitle || testTitle}
      </Heading4>

      <Body2 className={styles.text}>{text}</Body2>

      <div className={styles.infoWrapper}>
        {testCodeText && (
          <BottomDrawerText
            icon={<ScanIcon />}
            title={
              <FormattedMessage
                id='test-card.code'
                defaultMessage='Test code'
              />
            }
            text={testCodeText}
          />
        )}

        {lastUpdateText && (
          <BottomDrawerText
            icon={<UpdateIcon />}
            title={
              <FormattedMessage
                id='prescription-card.last-update'
                defaultMessage='Last update'
              />
            }
            text={formatDate(lastUpdateText)}
          />
        )}
        {samplingInstructionsUrl && (
          <BottomDrawerLink
            icon={<KnowledgeIcon />}
            text={
              <FormattedMessage
                id='dashboard.sampling-instructions'
                defaultMessage='Sampling instructions'
              />
            }
            href={samplingInstructionsUrl}
          />
        )}
        {dashboardTitleModal && (
          <>
            <BottomDrawerLink
              icon={<PlusIcon />}
              text={
                <FormattedMessage
                  id='dashboard.activate-new-test'
                  defaultMessage='Activate a new test'
                />
              }
              href={activateTest}
            />

            <BottomDrawerLink
              icon={<BagIcon />}
              text={
                <FormattedMessage
                  id='dashboard.buy-new-test'
                  defaultMessage='Buy new test'
                />
              }
              href={buyNewTest}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BottomDrawerContent;
