import React from 'react';
import PropTypes from 'prop-types';

const AccordionsWrapper = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

AccordionsWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default AccordionsWrapper;
