import React from 'react';
import PropTypes from 'prop-types';
import Link from '../TestResultsNavigation/Link';
import { Typography } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import './index.scss';

const TestResultsNavigation = ({ className, linksData, activeSection }) => {
  return (
    <div className={cx({ 'test-results-navigation': true }, className)}>
      <Typography
        element='p'
        variant='overline'
        className='test-results-navigation__title'
      >
        <FormattedMessage
          id='app.quick-navigation'
          defaultMessage='QUICK NAVIGATION'
        />
      </Typography>
      <div className='test-results-navigation__links-wrapper'>
        {linksData.map((link, index) => (
          <Link
            key={index}
            sectionsId={link.sectionsId}
            textId={link.textId}
            isActive={link.sectionsId === activeSection}
          />
        ))}
      </div>
    </div>
  );
};

TestResultsNavigation.propTypes = {
  className: PropTypes.string,
  linksData: PropTypes.array,
  activeSection: PropTypes.string
};

export default TestResultsNavigation;
