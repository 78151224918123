import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const Button = ({
  href,
  onClick,
  children,
  className,
  purple,
  iconRight,
  disabled,
  orange,
  hasDisabledStyle,
  noNewTab,
  buttonRef,
  id,
  isVideoUploading,
  type = 'button'
}) => {
  const classes = cx('button', className, {
    'button--orange': orange,
    'button--purple': purple,
    'button--icon-right': iconRight,
    'button--uploading': isVideoUploading,
    disabled: disabled && hasDisabledStyle,
    'button--disabled-link': disabled && href
  });

  return href ? (
    <a
      href={href}
      ref={buttonRef}
      className={classes}
      target={noNewTab ? '' : '_blank'}
      rel='noopener noreferrer'
      disabled={disabled}
    >
      <span className='button__content'>{children}</span>
    </a>
  ) : (
    <button
      id={id}
      ref={buttonRef}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <span className='button__content'>{children}</span>
    </button>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  iconRight: PropTypes.bool,
  disabled: PropTypes.bool,
  orange: PropTypes.bool,
  purple: PropTypes.bool,
  hasDisabledStyle: PropTypes.bool,
  noNewTab: PropTypes.bool,
  buttonRef: PropTypes.any,
  id: PropTypes.string,
  isVideoUploading: PropTypes.bool,
  type: PropTypes.string
};

export default Button;
