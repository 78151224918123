import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

export type InnerLinkProps = {
  variant?: 'primary' | 'outlined' | 'text' | 'elevated' | 'tonal';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  to: string;
  className?: string;
};

const InnerLink: React.FC<InnerLinkProps> = ({
  to,
  children,
  className,
  variant = 'primary',
  size = 'normal'
}) => {
  const linkClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  return (
    <Link
      className={cx(
        {
          [linkClassName]: true
        },
        className && { [className]: true }
      )}
      to={to}
    >
      {children}
    </Link>
  );
};

export default InnerLink;
