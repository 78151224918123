import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down-icon.svg';
import countryOptions from './countryOptions';

import './index.scss';

const CountryDropdown = () => {
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleSelectCountry = event => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);
  };

  return (
    <div className='country-dropdown'>
      <select
        className='country-dropdown__select'
        value={selectedCountry}
        onChange={handleSelectCountry}
        disabled
      >
        <option value='Switzerland' disabled hidden>
          Switzerland
        </option>
        {countryOptions.map(country => (
          <option
            className='country-dropdown__option'
            key={country.value}
            value={country.label}
          >
            {country.label}
          </option>
        ))}
      </select>
      <ArrowDown />
    </div>
  );
};

export default CountryDropdown;
