import React from 'react';
import cx from 'classnames';
import Link from '../Link';
import { Heading3 } from '../Typography';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

const SWITCHBOARD_URL = '/?conversationFlowKey=switchboard';
const YOURSELF_HEALTH_URL = 'https://yourself.health/';

export interface DashboardTitleProps {
  className?: string;
}

const DashboardTitle: React.FC<DashboardTitleProps> = ({ className }) => {
  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true
        },
        className && { [styles.className]: true }
      )}
    >
      <Heading3 className={styles.title}>
        <FormattedMessage
          id='dashboard.my-products'
          defaultMessage='My Products'
        />
      </Heading3>

      <div className={styles.buttonWrapper}>
        <Link size='small' className={styles.addTest} url={SWITCHBOARD_URL}>
          <FormattedMessage
            id='app.activate-test'
            defaultMessage='Activate test'
          />
        </Link>
        <Link size='small' variant='elevated' url={YOURSELF_HEALTH_URL}>
          <FormattedMessage
            id='app.buy-test-online'
            defaultMessage={'Buy test online'}
          />
        </Link>
      </div>
    </div>
  );
};

export default DashboardTitle;
