import React from 'react';
import { Body1, Heading5 } from '../Typography';
import { ReactComponent as ArrowMore } from '../../assets/svg/arrow-more.svg';

import styles from './index.module.scss';

interface InfoBannerProps {
  title: string;
  description: string;
  type?: string;
  backgroundColor: string;
  url?: string;
  handleInfo: () => void;
}

const InfoBanner: React.FC<InfoBannerProps> = ({
  title,
  description,
  type,
  backgroundColor,
  url,
  handleInfo
}) => {
  return (
    <>
      {type === 'info' ? (
        <div className={styles.wrapper}>
          <button
            onClick={handleInfo}
            className={styles.button}
            style={{ background: backgroundColor }}
          >
            <div className={styles.titleWrapper}>
              <Heading5 className={styles.title}>{title}</Heading5>
              <ArrowMore className={styles.icon} />
            </div>
            {description && (
              <Body1 className={styles.description}>{description}</Body1>
            )}
          </button>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <a
            href={url}
            className={styles.button}
            style={{ background: backgroundColor }}
          >
            <div className={styles.titleWrapper}>
              <Heading5 className={styles.title}>{title}</Heading5>
              <ArrowMore />
            </div>
            {description && (
              <Body1 className={styles.description}>{description}</Body1>
            )}
          </a>
        </div>
      )}
    </>
  );
};

export default InfoBanner;
