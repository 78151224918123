import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import logger from './dev/logger';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
let store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

if (process.env.NODE_ENV === 'production') {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}


sagaMiddleware.run(rootSaga);

export default store;
