import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AccordionTextContent from '../AccordionTextContent';
import ParameterLink from '../ParameterLink';
import { ReactComponent as Checked } from '../../../assets/svg/checkmark-circle-checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/svg/checkmark-circle-unchecked.svg';
import { ReactComponent as Dropup } from '../../../assets/svg/arrow-dropup.svg';
import { ReactComponent as Dropdown } from '../../../assets/svg/arrow-dropdown.svg';

import './index.scss';

const Accordion = ({
  data,
  hasProgressLine,
  isFirst,
  isFirstOpen,
  isLast,
  children,
  contentClassName,
  contentClassNameOpen,
  className,
  subtitle
}) => {
  const { title, parameters, type, tests, isChecked } = data;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isFirstOpen) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accordionClasses = cx('accordion', {
    [className]: !!className
  });

  const accordionContentClasses = cx('accordion__content', {
    'accordion__content--open': isOpen,
    [contentClassName]: !!contentClassName,
    [contentClassNameOpen]: !!contentClassNameOpen && isOpen
  });

  const overflowLineClasses = cx('accordion__overflow-line', {
    'accordion__overflow-line--first': isFirst,
    'accordion__overflow-line--last': isLast
  });

  return (
    <div className={accordionClasses}>
      {hasProgressLine && <div className='accordion__progress-line' />}
      <div className='accordion__content-wrapper'>
        <div
          className='accordion__title-wrapper'
          onClick={() => setIsOpen(!isOpen)}
        >
          {hasProgressLine &&
            (isChecked ? (
              <Checked className='accordion__check-circle' />
            ) : (
              <Unchecked className='accordion__check-circle' />
            ))}
          {hasProgressLine && (isFirst || isLast) && (
            <div className={overflowLineClasses} />
          )}
          <span className='accordion__title'>{title}</span>
          {subtitle && <span className='accordion__subtitle'>{subtitle}</span>}
          {isOpen ? (
            <Dropup className='accordion__arrow' />
          ) : (
            <Dropdown className='accordion__arrow' />
          )}
        </div>
        <div className={accordionContentClasses}>
          {hasProgressLine && isLast && <div className={overflowLineClasses} />}
          {parameters &&
            parameters.map((parameter, index) => {
              return <ParameterLink data={parameter} key={index} />;
            })}
          {type === 'supplementsInfo' && <AccordionTextContent data={data} />}
          {type === 'testsInfo' &&
            tests.map((test, index) => {
              return <AccordionTextContent data={test} key={index} />;
            })}
        </div>
        {children && <div className={accordionContentClasses}>{children}</div>}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  data: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isChecked: PropTypes.bool,
  hasProgressLine: PropTypes.bool,
  isFirstOpen: PropTypes.bool,
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  contentClassNameOpen: PropTypes.string,
  className: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ])
};

export default Accordion;
