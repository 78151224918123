import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';

const RegularButton = ({ children, onClick, disabled }) => {
  return (
    <Button onClick={onClick} size='small' disabled={disabled}>
      {children}
    </Button>
  );
};

RegularButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default RegularButton;
